import React, { useEffect, useState } from 'react';
// mui
import { Stack, Box, DialogContent, DialogTitle, IconButton, Dialog, Button, LinearProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import Time from './Time';
import Parking_time from './Parking_time';
import Energy from './Energy';
import Flat from './Flat';
import Reservation from './Reservation';
import Expires from './expires';
import Limit_min from './limit_min';
import Limit_max from './limit_max';
import CloseIcon from '../../../../../components/customIcon/Close.svg';
import { buttonR, buttonL } from '../../../../../components/button/buttonStyle';
import Duration_time from './duration_time';
// ----------------------------------------------------------------------

export default function Confirmation({ defaultData, showType, tabs }) {
  const [allData, setAllData] = useState({});
  const [groups, setGroups] = useState({});
  const [groupsArr, setGroupsArr] = useState([]);
  const [groups_reservation, setGroups_reservation] = useState({});
  const [groupsArr_reservation, setGroupsArr_reservation] = useState([]);
  const [groups_expires, setGroups_expires] = useState({});
  const [groupsArr_expires, setGroupsArr_expires] = useState([]);
  const [haslimit, sethaslimit] = useState(false);
  const [limitData, setlimitData] = useState({});
  const { t } = useTranslation();

  const processDataByType = (data, typeKey) => {
    const typeGroups = {};

    data.forEach((item) => {
      if (item.price_component) {
        const type = item.price_component[0].type;
        if (!typeGroups[type]) {
          typeGroups[type] = [];
        }
        typeGroups[type].push(item);
      }
    });

    const setGroupsFunc = `setGroups_${typeKey}`;
    const setGroupsArrFunc = `setGroupsArr_${typeKey}`;

    return {
      [setGroupsFunc]: typeGroups,
      [setGroupsArrFunc]: Object.keys(typeGroups),
    };
  };

  useEffect(() => {
    if (defaultData?.elements) {
      setAllData(defaultData);
      if (tabs == 'chargingPeriod') {
        let transData_element = [];
        if (showType == 'platformFee') {
          transData_element = defaultData.elements.filter((item) => item.eleType === 'elements_platForm');
        }
        if (showType == 'charging_time') {
          transData_element = defaultData.elements.filter((item) => item.eleType === 'elements_charging');
        }
        if (showType == 'energy') {
          transData_element = defaultData.elements.filter((item) => item.eleType === 'elements_energy');
        }
        const elementData = processDataByType(transData_element, 'element');
        setGroups(elementData.setGroups_element);
        setGroupsArr(elementData.setGroupsArr_element);
      } else {
        let transData_reservation = [];
        let transData_expires = [];
        if (showType == 'res_platformFee') {
          transData_reservation = defaultData.elements.filter((item) => item.eleType === 'Reservation_Expiry_flat');
        }
        if (showType == 'reservation_time') {
          transData_reservation = defaultData.elements.filter((item) => item.eleType === 'Reservation_time');
        }
        if (showType == 'expires_platformFee') {
          transData_expires = defaultData.elements.filter((item) => item.eleType === 'Expiry_flat');
        }
        if (showType == 'expires_time') {
          transData_expires = defaultData.elements.filter((item) => item.eleType === 'Expiry_time');
        }

        const reservationData = processDataByType(transData_reservation, 'reservation');
        const expiresData = processDataByType(transData_expires, 'expires');

        setGroups_reservation(reservationData.setGroups_reservation);
        setGroupsArr_reservation(reservationData.setGroupsArr_reservation);

        setGroups_expires(expiresData.setGroups_expires);
        setGroupsArr_expires(expiresData.setGroupsArr_expires);
      }
    }
  }, [defaultData]);
  return (
    <Box>
      {tabs == 'chargingPeriod' &&
        groupsArr.map((ele, ind) => {
          switch (ele) {
            case 'FLAT':
              return <Flat key={ind} defaultData={groups[ele]} allData={allData} />;
            case 'TIME':
              // return <Time key={ind} defaultData={groups[ele]} allData={allData} />;
              return <Duration_time key={ind} defaultData={groups[ele]} allData={allData} />;
            case 'PARKING_TIME':
              return <Parking_time key={ind} defaultData={groups[ele]} allData={allData} />;
            case 'ENERGY':
              return <Energy key={ind} defaultData={groups[ele]} allData={allData} />;
            default:
              return null;
          }
        })}
      {tabs == 'reservation' && groupsArr_reservation.length > 0 ? (
        <Reservation type="reservation" defaultData={groups_reservation} allData={allData}></Reservation>
      ) : (
        ''
      )}
      {/* {groupsArr_expires.length > 0 ? ( */}
      {tabs == 'reservation' && groupsArr_expires.length > 0 ? (
        <Expires type="expires" defaultData={groups_expires} allData={allData}></Expires>
      ) : (
        ''
      )}
    </Box>
  );
}
