import React, { useState, useImperativeHandle } from 'react';
// mui
import { Box, TextField, MenuItem } from '@mui/material';
// components
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// ----------------------------------------------------------------------

export default function InformationFunc({ childRef, formData, setFormData }) {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();

  useImperativeHandle(childRef, () => ({
    onSubmit,
  }));
  const statusOptions = [
    {
      value: 'Accepted',
      label: t('Accepted'),
    },
    { value: 'Expired', label: t('Expired') },
    { value: 'Blocked', label: t('Blocked') },
  ];
  const whitelistOptions = [
    {
      value: 'ALWAYS',
      label: t('Always'),
    },
    { value: 'ALLOWED', label: t('Allowed') },
    { value: 'ALLOWED_OFFLINE', label: t('Allowed') + ' ' + t('Offline') },
    { value: 'NEVER', label: t('Never') },
  ];
  const profileOptions = [
    {
      value: 'CHEAP',
      label: t('Cheap'),
    },
    { value: 'FAST', label: t('Fast') },
    { value: 'GREEN', label: t('Green') },
    { value: 'REGULAR', label: t('Regular') },
  ];

  const [errors, setErrors] = useState({
    status: '',
    whitelist: '',
  });

  const onSubmit = () => {
    setLoading(false);
    let empty = false;
    const newHasEmpty = { ...errors };
    for (const key in newHasEmpty) {
      if ((formData.hasOwnProperty(key) && formData[key] === '') || formData[key] === null) {
        newHasEmpty[key] = t('required fields');
        empty = true;
      } else {
        newHasEmpty[key] = '';
      }
    }
    setErrors(newHasEmpty);

    if (hasError || empty) {
      return false;
    }
    const filteredData = {};
    for (const key in formData) {
      if (key == 'default_profile_type' || key == 'expiry') {
        formData[key] !== '' ? (filteredData[key] = formData[key]) : null;
      } else {
        filteredData[key] = formData[key];
      }
    }
    return filteredData;
  };

  const handle = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'whitelist' || name == 'status') {
      setErrors({
        ...errors,
        [name]: '',
      });
    }
  };

  const formatDate = (date1) => {
    if (date1 == null) {
      return date1;
    }
    const date = new Date(date1);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  return (
    <Box>
      <span style={{ color: 'red' }}>* {t('Mandatory fields')}</span>
      <TextField
        disabled
        margin="dense"
        size="small"
        name="name"
        label={<>{t('Name')}</>}
        onChange={(e) => handle(e)}
        value={formData.name}
        fullWidth
        variant="outlined"
      />
      <TextField
        margin="dense"
        size="small"
        select
        fullWidth
        name="status"
        label={
          <>
            <span style={{ color: 'red' }}>* </span>
            {t('Status')}
          </>
        }
        value={formData.status}
        onChange={(e) => {
          handle(e);
        }}
        error={!!errors.status}
        helperText={errors.status}
      >
        {statusOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {/* <TextField
        margin="dense"
        size="small"
        select
        fullWidth
        name="whitelist"
        label={
          <>
            <span style={{ color: 'red' }}>* </span>
            {t('White List')}
          </>
        }
        value={formData.whitelist}
        onChange={(e) => {
          handle(e);
        }}
        error={!!errors.whitelist}
        helperText={errors.whitelist}
      >
        {whitelistOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField> */}
      {/* <TextField
        margin="dense"
        size="small"
        select
        fullWidth
        name="default_profile_type"
        label={<>{t('Profile Type')}</>}
        value={formData.default_profile_type}
        onChange={(e) => {
          handle(e);
        }}
      >
        {profileOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField> */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          name="expiry"
          label={t('Expiry Date')}
          views={['year', 'month', 'day']}
          inputFormat="DD/MM/YYYY"
          value={formData.expiry == 'None' ? null : formData.expiry}
          onChange={(date) => {
            setFormData({
              ...formData,
              expiry: formatDate(date),
            });
          }}
          renderInput={(params) => <TextField fullWidth margin="dense" size="small" {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
}
