import { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography, TextField, Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Cognito
import UserPool from '../../../../pages/auth/AWSCognito/UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';
import { buttonR, buttonL } from '../../../../components/button/buttonStyle';
// ----------------------------------------------------------------------

let socket1;

export default function VerificationForm({ email, getCode, setLoading_pre, getVerifyCode, handleClose, AddUpdate }) {
  const navigate = useNavigate();
  const from = '/Success';
  const [confirmationcode, setConfirmationcode] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const { t } = useTranslation();
  const [butDis, setButDis] = useState(false);
  useEffect(() => {
    setErrMsg('');
  }, [confirmationcode]);
  const onSubmit = (event) => {
    if (confirmationcode == '') {
      setErrMsg(t('Required fields cannot be empty'));
    }
    setLoading_pre(true);
    event.preventDefault();
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    user.confirmRegistration(confirmationcode, true, (err, data) => {
      if (err) {
        console.log(err);
        setErrMsg(err.message);
      }
      if (data !== null) {
        console.log(data);
        handleClose();
        AddUpdate(Math.random());
        // navigate(from, { replace: true });
      }
      setLoading_pre(false);
    });
  };

  return (
    <>
      <Box
        spacing={3}
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '24px' }}
      >
        <TextField
          fullWidth
          name="Verification Code"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Verification Code')}
            </>
          }
          onChange={(e) => setConfirmationcode(e.target.value)}
          value={confirmationcode}
        />
        {getCode == false ? (
          <Button
            variant="outlined"
            disabled={getCode == false ? false : true}
            onClick={() => {
              getVerifyCode();
            }}
            sx={{
              background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
              boxShadow: 'none',
              color: 'primary.contrastText',
              fontFamily: 'Arial',
              fontWeight: 700,
              fontSize: '14px',
              textAlign: 'center',
              height: '50px',
              width: '250px',
              marginLeft: '10px',
            }}
          >
            {t('Get Verification Code')}
          </Button>
        ) : (
          <Button
            variant="outlined"
            disabled
            sx={{
              boxShadow: 'none',
              fontFamily: 'Arial',
              fontWeight: 700,
              fontSize: '14px',
              // lineHeight: '22px',
              textAlign: 'center',
              height: '50px',
              width: '250px',
              marginLeft: '10px',
            }}
          >
            {t('Check Your Email')}
          </Button>
        )}
        {/* <Button
          variant="outlined"
          disabled={getCode == false ? false : true}
          onClick={() => {
            getVerifyCode();
          }}
          sx={{
            boxShadow: 'none',
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '14px', 
            textAlign: 'center',
            height: '50px',
            width: '250px',
            marginLeft: '10px',
          }}
        >
          {getCode == false ? t('Get Verification Code') : t('Check Your Email')}
        </Button> */}
      </Box>
      <br />
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <Button variant="outlined" onClick={handleClose} sx={buttonL}>
          {t('Cancel')}
        </Button>
        {getCode == false ? (
          <Button variant="outlined" disabled sx={buttonL}>
            {t('Add')}
          </Button>
        ) : (
          <LoadingButton onClick={onSubmit} sx={buttonR}>
            {t('Add')}
          </LoadingButton>
        )}
      </Stack>
      {/* <LoadingButton 
        size="large" 
        variant="contained"
        onClick={onSubmit}
        sx={{ background: 'linear-gradient(to left, #66D0D5, #00B0B9)' }}
      >
        {t('Add')}
      </LoadingButton> */}
      <Typography variant="subtitle1" sx={{ pl: 1, color: 'error.main' }}>
        {errMsg}
      </Typography>
    </>
  );
}
