import React, { useEffect, useState } from 'react';
// mui
import { Stack, Typography, Card, CardContent, CardHeader, ListItem, ListItemText, useTheme, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Con_reservation({ defaultData, allData, type }) {
  const [formData, setFormData] = useState([]);
  const [objKeys, setObjKeys] = useState([]);
  const { t } = useTranslation();
  const theme = useTheme();
  useEffect(() => {
    // includes Reservation and expires
    if (defaultData) {
      setFormData(defaultData);
      // console.log(defaultData);
      setObjKeys(Object.keys(defaultData));
    }
  }, [defaultData]);
  function getDayType(daysArray) {
    // const weekdays = ['Friday', 'Monday', 'Thursday', 'Tuesday', 'Wednesday'];
    const weekdays = ['FRIDAY', 'MONDAY', 'THURSDAY', 'TUESDAY', 'WEDNESDAY'];
    //const weekends = ['Saturday', 'Sunday'];
    const weekends = ['SATURDAY', 'SUNDAY'];

    const sortedDays = [...daysArray].sort(); // Sort the days for comparison

    if (sortedDays.toString() === weekdays.toString()) {
      return 'weekdays';
    } else if (sortedDays.toString() === weekends.toString()) {
      return 'weekends';
    } else if (daysArray.length === 7) {
      return 'Every day';
    } else {
      return daysArray;
    }
  }
  return (
    <>
      {objKeys.map((obj, index) => (
        <div key={index}>
          {formData[obj].map((ele, ind) => {
            let sentence = '';

            if (ele.restrictions_show) {
              if (ele.restrictions.day_of_week.length !== 0) {
                let trans = getDayType(ele.restrictions.day_of_week);
                if (trans == 'weekdays' || trans == 'weekends') {
                  sentence += ' ' + t('sentence_on') + ' ' + t(trans);
                } else if (trans == 'Every day') {
                  sentence += ' ' + t('Every day');
                } else {
                  sentence += ' ' + t('sentence_on') + ' ';
                  for (let index = 0; index < trans.length; index++) {
                    const element = trans[index];
                    sentence += t(element) + ',';
                  }
                }
              }
              const start = ele.restrictions.start_time;
              const end = ele.restrictions.end_time;

              if (start && end && start !== '' && end !== '') {
                sentence += ` ${t('between_and', { start, end })}, `;
              }
              if (ele.restrictions.min_duration && ele.restrictions.min_duration !== '') {
                sentence += `${t('Min Duration')} : ${parseFloat(
                  (ele.restrictions.min_duration / 3600).toFixed(3)
                )} ${t('hours')}, `;
              }
              if (ele.restrictions.max_duration && ele.restrictions.max_duration !== '') {
                sentence += `${t('Max Duration')} : ${parseFloat(
                  (ele.restrictions.max_duration / 3600).toFixed(3)
                )} ${t('hours')}`;
              }
            }

            return (
              <Card
                sx={{
                  boxShadow: 0,
                  borderRadius: '0',
                  color: 'texts.main',
                  border: theme.palette.CollapseRow.border,
                  background: theme.palette.background.pagerWhite,
                  marginTop: '10px',
                  padding: '10px 15px !important',
                }}
              >
                <Box key={ind}>
                  <Typography sx={{ color: theme.palette.title.main }}>
                    {t('Priority Level')}&nbsp;&nbsp;{ele.price_component[0].priority_level}
                  </Typography>
                  {sentence && (
                    <ul style={{ marginLeft: '20px' }}>
                      <li>{sentence}</li>
                    </ul>
                  )}
                  <ul style={{ marginLeft: '40px' }}>
                    <li key={ind}>
                      {ele.price_component[0].price} {t(allData.currency)}&nbsp;&nbsp;
                      {
                        ele.eleType === 'reservation'
                          ? obj === 'FLAT'
                            ? t('reservation_fee') // (excl. VAT)'
                            : t('per_min') //' per minute (excl.VAT)'
                          : obj === 'FLAT'
                          ? t('reservation_platformFee_tip') //'reservation platform fee (excl. VAT) '
                          : t('reservation_time_fee_tip') //'per hour (excl.VAT) (billed when a reservation expires and is not followed by a charging session)'
                      }
                    </li>
                  </ul>
                </Box>
                <React.Fragment key={ind}>
                  {obj === 'TIME' && formData.TIME ? (
                    <Box key={ind}>
                      <ul style={{ marginLeft: '40px' }}>
                        <li>
                          {ele.price_component[0].vat} % {t('VAT')}
                        </li>
                        <li>
                          {t('Billed_per_min', {
                            min: parseFloat((ele.price_component[0].step_size / 60).toFixed(3)),
                            sec: ele.price_component[0].step_size,
                          })}

                          {/* Billed per {parseFloat((formData.TIME[0].price_component[0].step_size / 60).toFixed(3))} min (
                      {formData.TIME[0].price_component[0].step_size} {t('seconds')}) */}
                        </li>
                      </ul>
                    </Box>
                  ) : obj === 'FLAT' && !formData.TIME ? (
                    <Box key={ind}>
                      <ul style={{ marginLeft: '40px' }}>
                        <li>
                          {ele.price_component[0].vat} % {t('VAT')}
                        </li>
                      </ul>
                    </Box>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              </Card>
            );
          })}
        </div>
      ))}
    </>
  );
}
