import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
// mui
import { Box, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { ThemeContext } from '../../../../App';
import { list_calendar_view } from 'src/api/AllAPI';
import { useTranslation } from 'react-i18next';
import { buttonR, buttonL } from 'src/components/button/buttonStyle';
import { object } from 'prop-types';

const localizer = momentLocalizer(moment);

export default function MyCalendar({ propsData, searchWord, refs }) {
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  const [events, setEvents] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [CalendarDefaultDate, setCalendarDefaultDate] = useState(new Date());
  const [selectDate, setSelectDate] = useState([new Date(), new Date()]);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const searchIndexList = () => {
    searchList(selectDate[0], selectDate[1]);
  };
  useImperativeHandle(refs, () => ({
    searchIndexList,
  }));
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = 'transparent';
    if (event.type === 'Completed') {
      backgroundColor = '#5470C6';
    } else if (event.type === 'Published') {
      backgroundColor = '#91CC75';
    }
    // event.title = `<p style=" width: 10px; height: 10px; border-radius: 50%; border: 3px solid red; "></p>fgdgdfg`;
    return {
      style: {
        backgroundColor,
      },
    };
  };
  useEffect(() => {
    let today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 6); // 6 days before
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    lastDayOfMonth.setDate(firstDayOfMonth.getDate() + 6); // 6 days after
    setSelectDate([firstDayOfMonth, lastDayOfMonth]);
  }, []);

  useEffect(() => {
    let today = new Date();
    if (Object.keys(propsData).length > 0) {
      today = new Date(propsData.schedule_time);
    }
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 6); // 6 days before
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    lastDayOfMonth.setDate(firstDayOfMonth.getDate() + 6); // 6 days after
    searchList(firstDayOfMonth, lastDayOfMonth);
    setCalendarDefaultDate(today);
    setSelectDate([firstDayOfMonth, lastDayOfMonth]);
  }, [propsData]);
  const searchList = (start, end) => {
    const transData = {
      start_date: new Date(start),
      end_date: new Date(end),
      search: searchWord,
    };
    list_calendar_view(transData, setErrorMessage, setError).then((res) => {
      if (res.category === 'Success') {
        const data = res.data.map((item) => {
          return {
            title: item.schedule_time.split(' ')[1] + item.schedule_name,
            type: item.status,
            start: new Date(item.schedule_time),
            end: new Date(item.schedule_time),
          };
        });
        setEvents(data);
      } else {
        setError(true);
        setErrorMessage(res.message);
      }
    });
  };
  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.onNavigate('PREV');
      const today = new Date(toolbar.date);
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 6); // 6 days before
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      lastDayOfMonth.setDate(firstDayOfMonth.getDate() + 6); // 6 days after
      searchList(firstDayOfMonth, lastDayOfMonth);
      setSelectDate([firstDayOfMonth, lastDayOfMonth]);
    };

    const goToNext = () => {
      toolbar.onNavigate('NEXT');
      const today = new Date(toolbar.date);
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 6); // 6 days before
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 2, 1);
      lastDayOfMonth.setDate(firstDayOfMonth.getDate() + 6); // 6 days after
      searchList(firstDayOfMonth, lastDayOfMonth);
      setSelectDate([firstDayOfMonth, lastDayOfMonth]);
    };
    const changeView = (view) => {
      toolbar.onView(view);
    };

    //  const goToToday = () => {
    //       toolbar.onNavigate('TODAY');
    //     };
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px',
          userSelect: 'none',
        }}
      >
        <Box>
          <LoadingButton variant="outlined" onClick={() => changeView('month')} sx={buttonL}>
            {t('Month')}
          </LoadingButton>
          {/* <LoadingButton variant="outlined" onClick={() => changeView('week')} sx={buttonL}>
            {t('Week')}
          </LoadingButton>
          <LoadingButton variant="outlined" onClick={() => changeView('day')} sx={buttonL}>
            {t('Day')}
          </LoadingButton> */}
          <LoadingButton variant="outlined" onClick={() => changeView('agenda')} sx={buttonL}>
            {t('Agenda')}
          </LoadingButton>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <p onClick={goToBack} style={{ cursor: 'pointer', color: '#00ACBB' }} title="Prev">
            {'<'}&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
          <p style={{ textAlign: 'center' }}>
            {toolbar.view == 'agenda'
              ? moment(toolbar.label.split(' – ')[0]).format('DD-MM-YYYY') +
                ' -- ' +
                moment(toolbar.label.split(' – ')[1]).format('DD-MM-YYYY')
              : toolbar.label}
          </p>
          <p onClick={goToNext} style={{ cursor: 'pointer', color: '#00ACBB' }} title="Next">
            &nbsp;&nbsp;&nbsp;&nbsp;{'>'}
          </p>
        </Box>

        {/* <span onClick={goToToday}>Today</span> */}
      </div>
    );
  };
  const globalStyles = `
  .rbc-off-range-bg {
    background-color: #17262A;  
  }
  .rbc-today {
    background-color: #344955;  
  }
  .rbc-month-row + .rbc-month-row{
    border-top: 1px solid #232F34;
  }
  .rbc-day-bg + .rbc-day-bg {
     border-left: 1px solid #232F34; 
  }
  .rbc-month-view{
    border:1px solid #232F34; 
  }
  .rbc-header{
    border:1px solid #232F34; 
  }
  .rbc-header + .rbc-header {
    border-left: 1px solid #232F34;
  }
  .rbc-show-more{
    background-color: transparent;  
    color: #00ACBB;
    text-align: end;
    width: 100%;
    margin-top: 10px;
  }
  .rbc-event-content{
    font-size:12px
  }
  .rbc-agenda-table .rbc-agenda-date-cell{
    background-color: transparent;
`;
  return (
    <div>
      <style>{darkTheme && globalStyles}</style>
      <Snackbar open={success} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Box display="flex" alignItems="center">
        <p
          style={{ width: '10px', height: '10px', display: 'inline-block', borderRadius: '50%', background: '#5470C6' }}
        ></p>
        &nbsp;&nbsp;
        <span>{t('Completed')}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <p
          style={{ width: '10px', height: '10px', display: 'inline-block', borderRadius: '50%', background: '#91CC75' }}
        ></p>
        &nbsp;&nbsp;
        <span>{t('Published')}</span>
      </Box>
      <Calendar
        key={CalendarDefaultDate.toString()}
        localizer={localizer}
        events={events}
        defaultDate={CalendarDefaultDate}
        components={{
          toolbar: CustomToolbar,
        }}
        eventPropGetter={eventStyleGetter}
        style={{ height: 600 }}
        max={3}
      />
    </div>
  );
}
