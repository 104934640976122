import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Table,
  Stack,
  styled,
  Dialog,
  Button,
  Tooltip,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
  tableCellClasses,
} from '@mui/material';
// components
import Icon from '../../../../../../../../components/customIcon/icon-on-off-16.svg';
import { buttonR, buttonL } from '../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../components/customIcon/Close.svg';
import { SubmitStop } from '../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectStop(props) {
  const { t } = useTranslation();
  const { evseName, user_name, mobile_id, evseInfo } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [loading_pre, setLoading_pre] = useState(false);
  const column = [
    {
      id: 'name',
      label: t('EVSE'),
      width: 50,
    },
    {
      id: 'user',
      label: t('User'),
      width: 120,
    },
    {
      id: 'function',
      label: t('Function'),
      width: 110,
    },
  ];
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    setLoading_pre(true);
    SubmitStop(mobile_id, evseInfo.id, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.StopUpdate(Math.random());
        props.setSuccess(true);
        handleClose();
      }
      setLoading(false);
      setLoading_pre(false);
    });
  };

  return (
    <Box>
      <Tooltip title={t('Stop')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="StopDialog" fullWidth maxWidth="md">
        {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="Stop">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Stop')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={3}>
            <Typography variant="body1">
              {t('tit_reviewRemote')}
              {/* Please review the selected EVSE and user for remote stop transaction to be carried out. */}
            </Typography>
            <Table aria-labelledby="tableTitles">
              <TableHead>
                <StyledTableRow>
                  {column.map((headCell) => (
                    <TableCell key={headCell.id} align="left" padding="normal" sx={{ width: headCell.width }}>
                      {headCell.label}
                    </TableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell align="left" padding="normal">
                    {evseName}
                  </StyledTableCell>
                  <StyledTableCell>
                    {t('name')}: {user_name}
                    <br />
                    {t('mobile ID')}: {mobile_id}
                  </StyledTableCell>
                  <StyledTableCell>{t('Remote Stop Transaction')}</StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
          <br />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button variant="outlined" disabled={loading} onClick={handleClose} sx={buttonL}>
              {t('Cancel')}
            </Button>
            <Button onClick={onSubmit} disabled={loading} sx={buttonR}>
              {t('Submit')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));
