import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
// mui
import { Box, Snackbar, Alert } from '@mui/material';
// components
import Default from './Default';
import Elements from './Elements/elements_platForm';
import ElementsCharging from './Elements/elements_charging';
import ElementsEnergy from './Elements/elements_energy';
import Platform from './Reservation/platform';
import PlatformExpiry from './Reservation/platform_expiry';
import ReservationTime from './Reservation/reservation_time';
import ExpiresTime from './Reservation/expires_time';
import { addTariffFunction, editTariffFunction, fetchTenantConfiguration } from '../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import Row from './tableRow';

// ----------------------------------------------------------------------

export default function editTariff_index(
  {
    operation,
    propsData,
    updateList,
    setParentGetData,
    setLoading_pro,
    setOpen,
    setSuccess,
    setErrorMessage,
    setError,
    setChangePage,
  },
  ref
) {
  const { t, i18n } = useTranslation();
  const childRef_default = useRef();
  const childRef_elements = useRef();
  const childRef_elements_charging = useRef();
  const childRef_elements_energy = useRef();
  const childRef_reservation = useRef();
  const childRef_expiry_platform = useRef();
  const childRef_expiry = useRef();
  const childRef_reservation_expiry_platform = useRef();

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [currency, setCurrency] = useState(propsData !== null ? propsData.currency : 'SGD');
  const [defaultData, setDefaultData] = useState({});
  const [defaultData_record, setDefaultData_record] = useState({});
  const [removeId, setRemoveId] = useState([]);
  const [steps, setSteps] = useState(['General Information', 'Charging Period', 'Reservation/Expiry']);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertMsg, setOpenAlertMsg] = useState('');
  const [tenantConfiguration, setTenantConfiguration] = useState({
    energy_default_price: '1.00',
    flat_default_price: '1.00',
    parking_time_default_price: '1.00',
    time_default_price: '1.00',
    reservation_expires_flat_default_price: '1.00',
    reservation_expires_time_default_price: '1.00',
    reservation_flat_default_price: '1.00',
    reservation_time_default_price: '1.00',
  });
  useImperativeHandle(ref, () => ({
    getVerifyData,
    onSubmit,
  }));
  const startData = {
    id: '',
    name: '',
    currency: currency,
    tariff_type: 'REGULAR',
    tariff_text: [],
    description: '',
    start_date_time: null,
    end_date_time: null,
    language: selectedLanguage,
    // elements: [],
    min_price: {
      excl_vat: '',
      incl_vat: '',
    },
    max_price: {
      excl_vat: '',
      incl_vat: '',
    },
  };

  useEffect(() => {
    fetchTenantConfiguration(setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setTenantConfiguration(promiseResult.data);
      } else {
        setError(true);
        setErrorMessage(promiseResult.response?.data.message);
      }
    });
  }, []);

  useEffect(() => {
    if (operation && operation == 'edit') {
      setDefaultData(propsData);
      setDefaultData_record(propsData);
    } else if (operation && operation == 'add') {
      setDefaultData(startData);
      setDefaultData_record(startData);
    }
  }, [operation, propsData]);

  const handleClose = () => {
    setOpen(false);
    setDefaultData({});
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };
  const formatDate = (date1) => {
    const date = new Date(date1);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const formatData = () => {
    console.log('startData', defaultData);
    const transData = {};
    let eleData = [];
    defaultData.language = selectedLanguage; // set language if you need
    if (defaultData.tariff_type === '') {
      transData.tariff_type = null;
    }
    const dataArr = Object.keys(defaultData);
    for (const element of dataArr) {
      if (element !== 'elements' && element !== 'reservation_show' && element !== 'reservationExpires_show') {
        transData[element] = defaultData[element];
      } else {
        eleData = defaultData[element]; //elements part
      }
    }
    transData.min_price_excl_vat = defaultData.min_price.excl_vat
      ? parseFloat(defaultData.min_price.excl_vat).toFixed(2)
      : null;
    transData.min_price_incl_vat = defaultData.min_price.incl_vat
      ? parseFloat(defaultData.min_price.incl_vat).toFixed(2)
      : null;
    transData.max_price_excl_vat = defaultData.max_price.excl_vat
      ? parseFloat(defaultData.max_price.excl_vat).toFixed(2)
      : null;
    transData.max_price_incl_vat = defaultData.max_price.incl_vat
      ? parseFloat(defaultData.max_price.incl_vat).toFixed(2)
      : null;
    defaultData.end_date_time
      ? (transData.end_date_time = formatDate(defaultData.end_date_time))
      : (transData.end_date_time = null);
    defaultData.start_date_time
      ? (transData.start_date_time = formatDate(defaultData.start_date_time))
      : (transData.start_date_time = null);

    if (defaultData.id == '' || defaultData.tariff_text.length == 0) {
      transData.tariff_text = [
        { description: defaultData.description ? defaultData.description : null, language: selectedLanguage },
      ];
    } else {
      transData.tariff_text = [{ description: defaultData.description, language: selectedLanguage }];
    }
    //elements part
    let transElesArrData = [];
    for (const ele of eleData) {
      let obj = { ...ele.price_component[0] };
      obj.vat = Number(obj.vat);
      obj.id = ele.id;
      if (ele.restrictions) {
        for (const key of Object.keys(ele.restrictions)) {
          ele.restrictions[key] = ele.restrictions[key] || null;
        }
        ele.restrictions.days = ele.restrictions.day_of_week;
        ele.restrictions.start_date = ele.restrictions.start_date ? formatDate(ele.restrictions.start_date) : null;
        ele.restrictions.end_date = ele.restrictions.end_date ? formatDate(ele.restrictions.end_date) : null;
      }
      if (ele.eleType == 'Reservation_time' || ele.eleType == 'Reservation_Expiry_flat') {
        ele.restrictions.reservation = 'RESERVATION';
      }
      if (ele.eleType == 'Expiry_time' || ele.eleType == 'Expiry_flat') {
        ele.restrictions.reservation = 'RESERVATION_EXPIRES';
      }

      obj = { ...obj, ...ele.restrictions };
      transElesArrData.push(obj);
    }
    transData.elements = transElesArrData;
    if (removeId.length !== 0) {
      transData.remove_id = removeId;
    }
    console.log('transData', transData);
    console.log('transData', JSON.stringify(transData));
    return transData;
  };

  const onSubmit = () => {
    const data = formatData();
    setLoading_pro(true);
    if (defaultData.id == '') {
      data.id = Math.random().toString(36).substr(2, 9);
      addTariffFunction(JSON.stringify(data), setError, setErrorMessage).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          setSuccess(true);
          handleClose();
          updateList({ oprType: 'add' });
          setLoading_pro(false);
          setChangePage('table');
          //clear word
        } else {
          setError(true);
          setErrorMessage(promiseResult.response?.data.message);
          setLoading_pro(false);
          setOpen(false);
        }
      });
    } else {
      editTariffFunction(JSON.stringify(data), setError, setErrorMessage).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          setSuccess(true);
          handleClose();
          updateList({ oprType: 'edit' });
          setLoading_pro(false);
          setChangePage('table');
        } else {
          setError(true);
          setErrorMessage(promiseResult.response?.data.message);
          setLoading_pro(false);
          setOpen(false);
        }
      });
    }
  };

  useEffect(() => {
    console.log('defaultData', defaultData);
    setParentGetData(defaultData);
  }, [defaultData]);

  const getVerifyData = () => {
    let verifyError = false;
    const transData = getFormData();
    if (transData == 'verifyError') {
      setOpenAlert(true);
      setOpenAlertMsg(t('Incorrect entry!'));
      verifyError = true;
    } else if (transData == 'verifyError_charging') {
      setOpenAlert(true);
      setOpenAlertMsg(t('Charging Period') + ':' + t('contains mandatory fields'));
      verifyError = true;
    } else if (transData == 'verifyError2') {
      verifyError = true;
    } else if (transData == 'verifyError_level') {
      verifyError = true;
    }

    return verifyError;
  };
  const PageDisplay = (item) => {
    switch (item) {
      case 'General Information':
        return (
          <Default
            cRef={childRef_default}
            defaultData={defaultData}
            setSteps={setSteps}
            steps={steps}
            currency={currency}
            setCurrency={setCurrency}
            setOpenAlert={setOpenAlert}
            setOpenAlertMsg={setOpenAlertMsg}
          />
        );
      case 'Charging Period':
        return (
          <>
            <Elements
              type="elements_platForm"
              removeId={removeId}
              setRemoveId={setRemoveId}
              cRef={childRef_elements}
              defaultData={defaultData}
              currency={currency}
              setCurrency={setCurrency}
              setOpenAlert={setOpenAlert}
              setOpenAlertMsg={setOpenAlertMsg}
              tenantConfiguration={tenantConfiguration}
            />
            <ElementsCharging
              type="elements_charging"
              removeId={removeId}
              setRemoveId={setRemoveId}
              cRef={childRef_elements_charging}
              defaultData={defaultData}
              currency={currency}
              setCurrency={setCurrency}
              setOpenAlert={setOpenAlert}
              setOpenAlertMsg={setOpenAlertMsg}
              tenantConfiguration={tenantConfiguration}
            />
            <ElementsEnergy
              type="elements_energy"
              removeId={removeId}
              setRemoveId={setRemoveId}
              cRef={childRef_elements_energy}
              defaultData={defaultData}
              currency={currency}
              setCurrency={setCurrency}
              setOpenAlert={setOpenAlert}
              setOpenAlertMsg={setOpenAlertMsg}
              tenantConfiguration={tenantConfiguration}
            />
          </>
        );
      case 'Reservation/Expiry':
        return (
          <>
            <Platform
              type="Reservation_Expiry_flat"
              removeId={removeId}
              setRemoveId={setRemoveId}
              cRef={childRef_reservation_expiry_platform}
              defaultData={defaultData}
              currency={currency}
              setOpenAlert={setOpenAlert}
              setOpenAlertMsg={setOpenAlertMsg}
              tenantConfiguration={tenantConfiguration}
            />
            <ReservationTime
              type_ref="Reservation_time"
              removeId={removeId}
              setRemoveId={setRemoveId}
              cRef={childRef_reservation}
              defaultData={defaultData}
              currency={currency}
              setOpenAlert={setOpenAlert}
              setOpenAlertMsg={setOpenAlertMsg}
              tenantConfiguration={tenantConfiguration}
            />
            <PlatformExpiry
              type="Expiry_flat"
              removeId={removeId}
              setRemoveId={setRemoveId}
              cRef={childRef_expiry_platform}
              defaultData={defaultData}
              currency={currency}
              setOpenAlert={setOpenAlert}
              setOpenAlertMsg={setOpenAlertMsg}
              tenantConfiguration={tenantConfiguration}
            />
            <ExpiresTime
              type="Expiry_time"
              removeId={removeId}
              setRemoveId={setRemoveId}
              cRef={childRef_expiry}
              defaultData={defaultData}
              currency={currency}
              setOpenAlert={setOpenAlert}
              setOpenAlertMsg={setOpenAlertMsg}
              tenantConfiguration={tenantConfiguration}
            />
          </>
        );
    }
  };

  // the same time have at lest two same type data && no setting  is no allowed
  const isValidArray = (arr) => {
    const typeGroups = {};

    arr.forEach((item) => {
      const type = item.price_component[0].type;
      if (!typeGroups[type]) {
        typeGroups[type] = [];
      }
      typeGroups[type].push(item);
    });
    let groups = Object.keys(typeGroups);
    let results = false; // right

    for (const group of groups) {
      if (typeGroups[group].length > 1) {
        for (const element of typeGroups[group]) {
          if (element.restrictions_show) {
            results = false; // right
            break;
          } else {
            results = true; //error
          }
        }
      }
    }
    return results;
  };
  const unique_level_Data = (position, data) => {
    const uniqueData = {};
    let transResult = false;
    data.forEach((item) => {
      const priorityLevel = item.price_component[0].priority_level;
      const key = `priorityLevel_${priorityLevel}`;
      if (uniqueData[key]) {
        // exist
        transResult = t('unique_level', {
          position: t(position),
          // type: t(capitalizeSubstring(type, type)),
          priorityLevel: priorityLevel,
        });

        return transResult;
      } else {
        uniqueData[key] = item;
      }
    });
    return transResult;
  };
  const getFormData = () => {
    let curr = false;
    if (steps.includes('General Information') && childRef_default.current == null) {
      curr = true;
    }
    if (steps.includes('Charging Period') && childRef_elements.current == null) {
      curr = true;
    }
    if (steps.includes('Charging Period') && childRef_elements_charging.current == null) {
      curr = true;
    }
    if (steps.includes('Charging Period') && childRef_elements_energy.current == null) {
      curr = true;
    }
    if (steps.includes('Reservation/Expiry') && childRef_reservation.current == null) {
      curr = true;
    }
    if (steps.includes('Reservation/Expiry') && childRef_expiry.current == null) {
      curr = true;
    }
    if (steps.includes('Reservation/Expiry') && childRef_reservation_expiry_platform.current == null) {
      curr = true;
    }
    if (steps.includes('Reservation/Expiry') && childRef_expiry_platform.current == null) {
      curr = true;
    }

    if (curr) {
      console.log('childRef=null');
      return;
    }
    let transData = { ...defaultData_record };
    let setTransData = {};
    let hasError = true;
    let verifyData_default = {};
    //General Information part
    if (steps.includes('General Information')) {
      verifyData_default = childRef_default.current.getComponentData();
      if (!verifyData_default) {
        console.log(transData);
        return 'verifyError';
      }
      hasError = false;
      setTransData = { ...transData, ...verifyData_default };
    }
    let verifyData_elements = {},
      verifyData_elements_charging = {},
      verifyData_elements_energy = {};
    //Charging Period part
    if (steps.includes('Charging Period')) {
      verifyData_elements = childRef_elements.current.getComponentData();
      verifyData_elements_charging = childRef_elements_charging.current.getComponentData();
      verifyData_elements_energy = childRef_elements_energy.current.getComponentData();
      if (
        verifyData_elements_energy.length == 0 &&
        verifyData_elements.length == 0 &&
        verifyData_elements_charging.length == 0
      ) {
        return 'verifyError_charging';
      }
      if (
        (verifyData_elements.length != 0 && !verifyData_elements) ||
        (verifyData_elements_charging.length != 0 && !verifyData_elements_charging) ||
        (verifyData_elements_energy.length != 0 && !verifyData_elements_energy)
      ) {
        return 'verifyError';
      }
      let data = transData.elements;
      if (data) {
        data = data.filter(
          (element) =>
            element.eleType !== 'elements_platForm' &&
            element.eleType !== 'elements_charging' &&
            element.eleType !== 'elements_energy'
        );

        data = [...data, ...verifyData_elements, ...verifyData_elements_charging, ...verifyData_elements_energy];
        setTransData = {
          ...setTransData,
          elements: data,
        };
      } else {
        setTransData = {
          ...setTransData,
          elements: [...verifyData_elements, ...verifyData_elements_charging, ...verifyData_elements_energy],
        };
      }
      const unique_level = unique_level_Data(
        'Charging-Period_PlatForm-Fee',
        setTransData.elements.filter((item) => item.eleType == 'elements_platForm')
      );
      const unique_level2 = unique_level_Data(
        'Charging-Period_Charging-Time',
        setTransData.elements.filter((item) => item.eleType == 'elements_charging')
      );
      const unique_level3 = unique_level_Data(
        'Charging-Period_Enery',
        setTransData.elements.filter((item) => item.eleType == 'elements_energy')
      );
      if (unique_level !== false) {
        setOpenAlert(true);
        setOpenAlertMsg(t(unique_level));
        return 'verifyError_level';
      }
      if (unique_level2 !== false) {
        setOpenAlert(true);
        setOpenAlertMsg(unique_level2);
        return 'verifyError_level';
      }
      if (unique_level3 !== false) {
        setOpenAlert(true);
        setOpenAlertMsg(unique_level3);
        return 'verifyError_level';
      }
      if (
        isValidArray(verifyData_elements) ||
        isValidArray(verifyData_elements_charging) ||
        isValidArray(verifyData_elements_energy)
      ) {
        hasError = true;
      } else {
        hasError = false;
      }
    }
    // Reservation/Expiry part
    let verifyData_reservation = {},
      verifyData_expiry = {},
      verifyData_reservation_expiry_platform = {},
      verifyData_expiry_platform = {};
    if (steps.includes('Reservation/Expiry')) {
      verifyData_reservation = childRef_reservation.current.getComponentData();
      verifyData_expiry = childRef_expiry.current.getComponentData();
      verifyData_reservation_expiry_platform = childRef_reservation_expiry_platform.current.getComponentData();
      verifyData_expiry_platform = childRef_expiry_platform.current.getComponentData();
      if (
        (verifyData_reservation.length != 0 && !verifyData_reservation) ||
        (verifyData_expiry.length != 0 && !verifyData_expiry) ||
        (verifyData_reservation_expiry_platform.length != 0 && !verifyData_reservation_expiry_platform) ||
        (verifyData_expiry_platform.length != 0 && !verifyData_expiry_platform)
      ) {
        return 'verifyError';
      }
      let data = setTransData.elements.filter(
        (element) =>
          element.eleType !== 'Reservation_Expiry_flat' &&
          element.eleType !== 'Expiry_flat' &&
          element.eleType !== 'Reservation_time' &&
          element.eleType !== 'Expiry_time'
      );
      data = [
        ...data,
        ...verifyData_reservation,
        ...verifyData_expiry,
        ...verifyData_expiry_platform,
        ...verifyData_reservation_expiry_platform,
      ];
      setTransData = {
        ...setTransData,
        elements: data,
      };
      const unique_level = unique_level_Data(
        'Reservation/Expiry_Platform-Fee',
        setTransData.elements.filter((item) => item.eleType == 'Reservation_Expiry_flat')
      );
      if (unique_level !== false) {
        setOpenAlert(true);
        setOpenAlertMsg(t(unique_level));
        return 'verifyError_level';
      }
      const unique_level2 = unique_level_Data(
        'Reservation/Expiry_Expires-Time',
        setTransData.elements.filter((item) => item.eleType == 'Expiry_time')
      );
      if (unique_level2 !== false) {
        setOpenAlert(true);
        setOpenAlertMsg(t(unique_level2));
        return 'verifyError_level';
      }
      const unique_level3 = unique_level_Data(
        'Reservation/Expiry_Reservation-Time',
        setTransData.elements.filter((item) => item.eleType == 'Reservation_time')
      );
      if (unique_level3 !== false) {
        setOpenAlert(true);
        setOpenAlertMsg(t(unique_level3));
        return 'verifyError_level';
      }
      const unique_level4 = unique_level_Data(
        'Reservation/Expiry_Reservation-Time',
        setTransData.elements.filter((item) => item.eleType == 'Expiry_flat')
      );
      if (unique_level4 !== false) {
        setOpenAlert(true);
        setOpenAlertMsg(t(unique_level4));
        return 'verifyError_level';
      }
      if (
        isValidArray(verifyData_reservation) ||
        isValidArray(verifyData_expiry) ||
        isValidArray(verifyData_reservation_expiry_platform)
      ) {
        hasError = true;
      } else {
        hasError = false;
      }
    } else {
      // let data = setTransData.elements.filter((element) => element.eleType !== 'reservation');
      // setTransData = {
      //   ...setTransData,
      //   elements: data,
      // };
    }

    console.log('setTransData', setTransData);
    setDefaultData(setTransData);
    if (hasError) {
      setOpenAlert(true);
      setOpenAlertMsg(t('same_type_data'));
      setTimeout(() => {
        setOpenAlert(false);
      }, 5000);
      return 'verifyError2';
    } else {
      setOpenAlert(false);
    }
  };

  return (
    <Box sx={{ marginTop: '15px' }}>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="warning">{openAlertMsg}</Alert>
      </Snackbar>
      {steps.map((item, ind) => (
        <Row
          key={ind}
          partName={item}
          pageDisplay={PageDisplay(item)}
          // transItem={item}
          // childRef_default={childRef_default}
          // childRef_reservation={childRef_reservation}
          // childRef_expires={childRef_expires}
          // defaultData={defaultData}
          // setSteps={setSteps}
          // steps={steps}
          // setCurrency={setCurrency}
          // currency={currency}
          // removeId={removeId}
          // setRemoveId={setRemoveId}
          // childRef_elements={childRef_elements}
        />
      ))}
      <br />
    </Box>
  );
}
editTariff_index = forwardRef(editTariff_index);
