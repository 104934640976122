import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// mui
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------

function VerificationForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const LoginSchema = Yup.object().shape({
    code: Yup.string()
      .required('Verification Code is required')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(6, 'Must be exactly 6 digits')
      .max(6, 'Must be exactly 6 digits'),
  });

  const defaultValues = {
    code: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = () => {
    navigate('/pages/userprofile', { replace: true });
  };

  const handleClick = async () => {
    navigate('/pages/changeemail', { replace: true });
  };

  return (
    <Card sx={{ width: 500 }}>
      <CardContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h5" gutterBottom>
            {t('tip_enterCode')}:
          </Typography>
          <RHFTextField name="code" label="Verification Code" />
          <LoadingButton onClick={handleClick}>{t('Cancel')}</LoadingButton>
          <LoadingButton type="submit" loading={isSubmitting}>
            {t('Confirm')}
          </LoadingButton>
        </FormProvider>
      </CardContent>
    </Card>
  );
}

export default VerificationForm;
