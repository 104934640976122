import React, { useEffect, useState, useContext } from 'react';
import io from 'socket.io-client';
// @mui
import { Grid, Stack, Card, CardHeader, Box, LinearProgress, useTheme } from '@mui/material';
// sections
import AppTotalChargers from '../../../../components/apexcharts/Donut';
import OverviewChart from '../../../../components/apexcharts/BarChart';
import AppWidgetSummary from '../../../../components/Widget';
import WidgetSelect from '../../../../components/Select/WidgetSelect';
import GraphSelect from '../../../../components/Select/GraphSelect';
import date from '../../../../components/LastDate';
import LoginAgain from '../../../../components/errors/LoginAgain';
import { socketUrl } from '../../../../api/socket';
import User from '../../../../components/customIcon/icon-users-64.svg';
import Location from '../../../../components/customIcon/icon-location-64.svg';
import ChargedEnergy from '../../../../components/customIcon/icon-chargedenergy-64.svg';
import Revenue from '../../../../components/customIcon/icon-revenue-64.svg';
import ChargingSession from '../../../../components/customIcon/icon-chargesession-64.svg';
import CarbonReduction from '../../../../components/customIcon/icon-carbon-32.svg';

import UserLight from '../../../../components/lightMode/icon-users-64.svg';
import LocationLight from '../../../../components/lightMode/icon-location-64.svg';
import ChargedEnergyLight from '../../../../components/lightMode/icon-chargedenergy-64.svg';
import RevenueLight from '../../../../components/lightMode/icon-revenue-64.svg';
import ChargingSessionLight from '../../../../components/lightMode/icon-chargesession-64.svg';

import { fetchSummaryOverview } from '../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../../App';

// ----------------------------------------------------------------------

// const socket = io.connect(socketUrl, {
//   extraHeaders: {
//     X_My_Auth: sessionStorage.getItem('access_token'),
//   },
// });

export default function DashboardOverview(props) {
  const { darkTheme } = useContext(ThemeContext);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [dbdata, setDbdata] = useState([]);
  const [dataChargedEnergy, setDataChargedEnergy] = useState([]);
  const [dataRevenue, setDataRevenue] = useState([]);
  const [dataChargingSession, setDataChargingSession] = useState([]);
  const [widgetSelect, setWidgetSelect] = useState(1);
  const [graphSelect, setGraphSelect] = useState(2);
  const [graphdataOverview, setGraphDataOverview] = useState();
  const [TableYOverview, setTableYOverview] = useState();
  const [dataIn, setDataIn] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [chartData, setChartData] = useState();
  const [loading_pre, setLoading_pre] = useState(false);
  const [haveData, sethaveData] = useState(false);
  const [dataCarbon, setDataCarbon] = useState(0);
  const { t } = useTranslation();
  const theme = useTheme();
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    setLoading_pre(true);
    fetchSummaryOverview(setError, setErrorMessage, setUnauthorized).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setDbdata(promiseResult.data[0]);
        setChartData(promiseResult.data[0].chart);
        sethaveData(true);
        // setDataCarbon();
      } else {
        props.setError(true);
        props.setErrorMessage(promiseResult.response?.data?.message);
        sethaveData(true);
      }
      setLoading_pre(false);
    });
    setDataIn(true);
  }, []);

  console.log('dbdata', dbdata);

  // useEffect(() => {
  //   socket.on('chart_data', (data) => {
  //     setChartData(data);
  //   });
  // }, [socket]);

  const handleCallbackOverview = (childData) => {
    setWidgetSelect(childData);
  };

  useEffect(() => {
    if (widgetSelect === 2) {
      setDataChargedEnergy(dbdata.charged_energy_365);
      setDataRevenue(dbdata.revenue_365);
      setDataChargingSession(dbdata.charging_session_365);
    } else if (widgetSelect === 3) {
      setDataChargedEnergy(dbdata.charged_energy_30);
      setDataRevenue(dbdata.revenue_30);
      setDataChargingSession(dbdata.charging_session_30);
    } else if (widgetSelect === 4) {
      setDataChargedEnergy(dbdata.charged_energy_7);
      setDataRevenue(dbdata.revenue_7);
      setDataChargingSession(dbdata.charging_session_7);
    } else {
      setDataChargedEnergy(dbdata.charged_energy_overview);
      setDataRevenue(dbdata.revenue_overview);
      setDataChargingSession(dbdata.charging_session_overview);
    }
    setDataCarbon(dbdata.carbon_reduced);
  }, [handleCallbackOverview]);

  const handleCallbackGraphOverview = (childData) => {
    setGraphSelect(childData);
  };

  useEffect(() => {
    props.LocationList(dbdata.location);
    if (graphSelect === 2) {
      setGraphDataOverview(dbdata.monthly);
      setTableYOverview(date.Last7Months);
    } else if (graphSelect === 3) {
      setGraphDataOverview(dbdata.yearly);
      setTableYOverview(date.Last7Years);
    } else {
      setGraphDataOverview(dbdata.daily);
      setTableYOverview(date.Last7Days);
    }
    // setDataCarbon();
  }, [dataChargedEnergy, graphSelect]);

  return (
    <>
      {unauthorized && <LoginAgain />}
      <Box>
        {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <Card
          sx={{
            mb: 2,
            borderRadius: '4px',
            pl: 3,
            pb: 3,
            pr: 3,
            boxShadow: 0,
            background: theme.palette.background.pagerWhite,
            border: theme.palette.background.border,
          }}
        >
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <WidgetSelect parentCallback={handleCallbackOverview} datafromparent={widgetSelect} />
          </Stack>
          <Stack direction="row" justifyContent="space-around" alignItems="flex-start" spacing={3}>
            <AppWidgetSummary
              title={t('Total Users')}
              total={dbdata.user}
              color="available"
              icon={darkTheme ? User : UserLight}
            />
            <AppWidgetSummary
              title={t('Total Locations')}
              total={dbdata.total_location}
              color="faulty"
              icon={darkTheme ? Location : LocationLight}
            />
            <AppWidgetSummary
              title={t('Total Charged Energy')}
              total={dataChargedEnergy}
              unit="kWh"
              color="inuse"
              icon={darkTheme ? ChargedEnergy : ChargedEnergyLight}
            />
            <AppWidgetSummary
              title={t('Total Revenue')}
              currency={dbdata.currency}
              total={dataRevenue ? Number(dataRevenue).toFixed(2) : '0.00'}
              color="unavailable"
              icon={darkTheme ? Revenue : RevenueLight}
            />
            <AppWidgetSummary
              title={t('Total Charging Sessions')}
              total={dataChargingSession}
              color="reserve"
              icon={darkTheme ? ChargingSession : ChargingSessionLight}
            />
            <AppWidgetSummary
              title={t('Carbon Reduced')}
              unit="tCO₂"
              // total="5"
              total={dataCarbon}
              color="unavailable"
              icon={CarbonReduction}
              // icon={darkTheme ? CarbonReduction :CarbonReductionLight}
            />
          </Stack>
        </Card>
        <Box sx={{ display: haveData ? 'block' : 'none' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={7}>
              <Card
                sx={{
                  boxShadow: 0,
                  borderRadius: '4px',
                  background: theme.palette.background.pagerWhite,
                  border: theme.palette.background.border,
                }}
              >
                <CardHeader
                  title={t('Energy Usage')}
                  action={<GraphSelect parentCallback={handleCallbackGraphOverview} datafromparent={graphSelect} />}
                />
                <OverviewChart
                  yData={TableYOverview}
                  xLabel={t('Energy (kWh)')}
                  xData={
                    graphdataOverview
                      ? graphdataOverview
                      : [
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                        ]
                  }
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <AppTotalChargers
                title={t('Operational Status')}
                chartData={
                  chartData
                    ? chartData
                    : [
                        {
                          label: 'Available',
                          value: 0,
                        },
                        {
                          label: 'In Used',
                          value: 0,
                        },
                        {
                          label: 'Faulty',
                          value: 0,
                        },
                        {
                          label: 'Unavailable',
                          value: 0,
                        },
                        {
                          label: 'Reserved',
                          value: 0,
                        },
                        {
                          label: 'Unknown',
                          value: 0,
                        },
                        {
                          label: 'Removed',
                          value: 0,
                        },
                      ]
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
