import React, { useState, useEffect, useRef } from 'react';
// mui
import {
  Container,
  Typography,
  Stack,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Box,
  Tooltip,
  Snackbar,
  Alert,
  Button,
  useTheme,
  Card,
  Tab,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// components
import Page from '../../../../components/Page';
import useResponsive from '../../../../hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import bcakButton from '../../../../components/customIcon/icon-expandedarrow-24.svg';
import Tabs_charging from './tabs_charging';
import Tabs_reservation from './tabs_reservation';
import { buttonR, buttonL } from '../../../../components/button/buttonStyle';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------

export default function Tariff_summary({ setChangePage, changePage, propsData }) {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('1');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };
  const handleClickBack = () => {
    setChangePage('table');
  };
  useEffect(() => {
    if (propsData) {
      console.log(propsData);
    }
  }, [propsData]);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Page sx={{ display: changePage == 'summary' ? 'block' : 'none' }}>
      <Container maxWidth="false" sx={{ position: 'relative' }}>
        <Snackbar open={success} autoHideDuration={4000} onClose={handleCloseSnackbar}>
          <Alert severity="success">{t('Success')}!</Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseSnackbar}>
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            position: 'fixed',
            top: '30px',
            height: '110px',
            paddingTop: '70px',
            paddingBottom: '30px',
            backgroundColor: theme.palette.background.default,
            width: isDesktop ? 'calc(100% - 330px)' : 'calc(100% - 45px)',
            zIndex: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={t('Back')}>
              <Box
                component="img"
                src={bcakButton}
                sx={{ transform: 'rotate(90deg)', cursor: 'pointer', marginRight: '10px' }}
                onClick={handleClickBack}
              />
            </Tooltip>
            <div
              style={{
                fontFamily: 'Arial',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '36px',
                color: 'texts.main',
                mb: 2,
              }}
            >
              {propsData.name}
            </div>
          </Box>
          <Box direction="row" justifyContent="flex-end" alignItems="center" sx={{ pb: 1 }}>
            <LoadingButton variant="outlined" sx={buttonL} onClick={handleClickBack}>
              {t('Back')}
            </LoadingButton>
          </Box>
        </Stack>
        {/* General Information */}
        <Box sx={{ marginTop: isDesktop ? '30px' : '55px' }}>
          <Typography
            sx={{
              fontFamily: 'Arial',
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '22px',
              color: 'texts.main',
              marginBottom: '10px',
              marginLeft: '15px',
            }}
          >
            {t('General Information')}
          </Typography>
          <Card
            sx={{
              boxShadow: 0,
              color: 'texts.main',
              border: theme.palette.CollapseRow.border,
              background: theme.palette.background.pagerWhite,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              gap: '6%',
              padding: '10px 15px',
              marginBottom: '10px',
            }}
          >
            <Stack>
              <Typography sx={{ color: theme.palette.title.main, marginBottom: '5px' }}>{t('Currency')}</Typography>
              <Typography>{t(propsData.currency)}</Typography>
            </Stack>
            <Stack>
              <Typography sx={{ color: theme.palette.title.main, marginBottom: '5px' }}>{t('Tariff Type')}</Typography>
              <Typography>{t(propsData.tariff_type)}</Typography>
            </Stack>
            <Stack>
              <Typography sx={{ color: theme.palette.title.main, marginBottom: '5px' }}>
                {t('Start - End Date')}
              </Typography>
              <Typography>
                {propsData.start_date_time ? propsData.start_date_time : '-'}
                &nbsp;{t('to')}&nbsp;
                {propsData.end_date_time ? propsData.end_date_time : '-'}
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ color: theme.palette.title.main, marginBottom: '5px' }}>
                {t('Minimum') + ' ' + t('Price')}
              </Typography>
              <Typography>
                {propsData.min_price_excl_vat ? propsData.min_price_excl_vat : '-'}&nbsp;
                {t(propsData.currency)}({t('excl.VAT')})
              </Typography>
              <Typography>
                {propsData.min_price_incl_vat ? propsData.min_price_incl_vat : '-'}&nbsp;
                {t(propsData.currency)}({t('incl.VAT')})
              </Typography>
            </Stack>
            <Stack>
              <Typography sx={{ color: theme.palette.title.main, marginBottom: '5px' }}>
                {t('Maximum') + ' ' + t('Price')}
              </Typography>
              <Typography>
                {propsData.max_price_excl_vat ? propsData.max_price_excl_vat : '-'}&nbsp;
                {t(propsData.currency)}({t('excl.VAT')})
              </Typography>
              <Typography>
                {propsData.max_price_incl_vat ? propsData.max_price_incl_vat : '-'}&nbsp;
                {t(propsData.currency)}({t('incl.VAT')})
              </Typography>
            </Stack>
          </Card>
          <Card
            sx={{
              boxShadow: 0,
              color: 'texts.main',
              border: theme.palette.CollapseRow.border,
              background: theme.palette.background.pagerWhite,
              marginBottom: '10px',
              padding: '10px 15px',
            }}
          >
            <Typography sx={{ color: theme.palette.title.main, marginBottom: '5px' }}>{t('Description')}</Typography>
            <Typography sx={{ wordBreak: 'break-word', textIndent: '20px' }}>
              {t(propsData.description ? propsData.description : '-')}
            </Typography>
          </Card>
        </Box>
        {/* change the tabs */}
        <Box>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label={t('tabs')} textColor="secondary" indicatorColor="secondary">
                <Tab
                  label={t('Charging Period')}
                  value="1"
                  sx={{
                    fontFamily: 'Arial',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '22px',
                  }}
                />
                <Tab
                  label={t('Reservation/Expiry')}
                  value="2"
                  sx={{
                    fontFamily: 'Arial',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '22px',
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: '15px 0px' }}>
              <Tabs_charging propsData={propsData}></Tabs_charging>
            </TabPanel>
            <TabPanel value="2" sx={{ padding: '15px 0px' }}>
              <Tabs_reservation propsData={propsData}></Tabs_reservation>
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </Page>
  );
}
