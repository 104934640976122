import React, { useState, useEffect, useRef } from 'react';
// mui
import { Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import img_charging_time from '../../../../components/customIcon/tariffSummary/charging.svg';
import img_platformFee from '../../../../components/customIcon/tariffSummary/platform-fee.svg';
import img_energy from '../../../../components/customIcon/tariffSummary/energy.svg';
import Part_information from './part_information';

export default function Tabs_charging({ propsData }) {
  const { t } = useTranslation();

  return (
    <Box display="flex" gap="20px">
      <Stack sx={{ width: 'calc(33% - 20px)' }}>
        <Box component="img" src={img_platformFee} sx={{}} />
        <Part_information defaultData={propsData} showType="platformFee" tabs="chargingPeriod" />
      </Stack>
      <Stack sx={{ width: 'calc(33% - 20px)' }}>
        <Box component="img" src={img_charging_time} sx={{}} />
        <Part_information defaultData={propsData} showType="charging_time" tabs="chargingPeriod" />
      </Stack>
      <Stack sx={{ width: 'calc(33% - 20px)' }}>
        <Box component="img" src={img_energy} sx={{}} />
        <Part_information defaultData={propsData} showType="energy" tabs="chargingPeriod" />
      </Stack>
    </Box>
  );
}
