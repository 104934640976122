import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Alert,
  Stack,
  Table,
  Button,
  Snackbar,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  InputLabel,
  FormControl,
  TableFooter,
  OutlinedInput,
  InputAdornment,
  TablePagination,
  tableCellClasses,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import TablePaginationActions from '../../../components/tablePaginationActions';
import UserAddDialog from './UserAddDialog';
import UserRefreshButton from './UserRefreshButton';
import UserMoreInfo from './useractions/rfidListInfo';
import UserEdit from './useractions/editUser/index';
import UserReserve from './useractions/UserReserve';
import UserCancel from './useractions/UserCancel';
import UserDelete from './useractions/UserDelete';
import useAuth from '../../../hooks/useAuth';
import LoginAgain from '../../../components/errors/LoginAgain';
import Icon from '../../../components/customIcon/icon-search-16.svg';
import Export from '../../../components/customIcon/icon-export-18.svg';
import { utils, writeFile } from 'xlsx';
import { fetchUser, fetchCS, fetchUselistCS } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function UserTable() {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dbdata, setDbdata] = useState([]);
  const [count, setCount] = useState(0);
  // const [user, setUser] = useState([]);
  const [update, setUpdate] = useState();
  const [loading_pre, setLoading_pre] = useState(false);
  const [chargingStationList, setChargingStationList] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const columns = [
    { field: 'id', headerName: t('No.'), width: 40 },
    { field: 'name', headerName: t('Name'), width: 190 },
    { field: 'mobile_id', headerName: t('Mobile ID'), width: 150 },
    // { field: 'rfid', headerName: t('RFID'), width: 150 },
    { field: 'status', headerName: t('Status'), width: 150 },
    { field: 'reservation', headerName: t('Reservation Status'), width: 150 },
    { field: 'action', headerName: t('Actions'), width: 200 },
  ];
  useEffect(() => {
    fetchUselistCS(setErrorMessage, setError, setUnauthorized).then((promiseResult) => {
      if (promiseResult.data) {
        setChargingStationList(promiseResult.data);
      }
    });
  }, [update]);

  useEffect(() => {
    getlist('search');
  }, [query, rowsPerPage, page, update]);

  const getlist = (opr) => {
    let data = {
      page_number: page + 1,
      page_size: rowsPerPage,
      mobile_id: query,
    };
    // if (opr == 'edit') {
    //   data = {
    //     page_number: 1,
    //     page_size: 10,
    //     mobile_id: '',
    //   };
    //   setRowsPerPage('10');
    //   setPage('0');
    // }
    setLoading_pre(true);
    setDbdata([]);
    setCount(0);
    fetchUser(data, setErrorMessage, setError, setUnauthorized).then((promiseResult) => {
      if (!promiseResult.message) {
        // setUser(promiseResult);
        setDbdata(promiseResult.data);
        setCount(promiseResult.total_record);
      } else {
        setDbdata([]);
        setError(true);
        setErrorMessage(promiseResult.message);
      }
      setLoading_pre(false);
    });
  };

  // const filteredItems = getFilteredItems(query, user);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
  };

  const handleExport = () => {
    const reordered = dbdata.map((item, ind) => ({
      No: ind + 1,
      AdminName: item.name,
      MobileID: item.mobile_id,
      // RFID: item.rfid,
      Status: item.status,
      ReservationStatus: item.reservation === true ? 'Yes' : 'No',
    }));
    const wb = utils.book_new(),
      ws = utils.json_to_sheet(reordered);

    utils.book_append_sheet(wb, ws, 'MySheet1');
    writeFile(wb, 'MyExcel.xlsx');
  };

  const Pagination = () => {
    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={t('rows per page')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
        sx={{ color: 'texts.main', fontFamily: 'Arial', fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
      />
    );
  };

  return (
    <Box sx={{ height: 660, width: '100%', position: 'relative' }}>
      {unauthorized && <LoginAgain />}
      <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pb: 1 }}>
        <FormControl fullWidth sx={{ mr: 1, width: '100%' }}>
          <InputLabel htmlFor="outlined-adornment-search">{t('Search')}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            startAdornment={
              <InputAdornment position="start">
                <Box component="img" src={Icon} sx={{ width: '16px', height: '16px' }} />
              </InputAdornment>
            }
            label={t('Search')}
            onChange={(event) => {
              setQuery(event.target.value);
              setPage(0);
            }}
            sx={{ height: '32px' }}
          />
        </FormControl>
        <UserAddDialog AddUpdate={(childData) => setUpdate(childData)} />
        {/* <UserRefreshButton RefreshUpdate={(childData) => setUpdate(childData)} /> */}
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ color: 'texts.main', fontFamily: 'Arial', fontWeight: 700, fontSize: '14px', lineHeight: '22px' }}
              colSpan={2}
            >
              {t('total_User', { userlength: count })}
              {/* Total User: {user.length} */}
              <StyledButton
                startIcon={<Box component="img" src={Export} sx={{ width: '16px', height: '16px' }} />}
                onClick={handleExport}
              >
                {t('Export')}
              </StyledButton>
            </TableCell>
            <Pagination />
          </TableRow>
          {loading_pre && (
            <div style={{ marginTop: '0px', width: '100%', position: 'absolute' }}>
              <LinearProgress />
            </div>
          )}
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell align="left" sx={{ width: column.width }} key={column.headerName}>
                {column.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dbdata.map((row, index) => (
            <StyledTableRow align="left" key={row.id} hover>
              <StyledTableCell sx={sx}>{index + 1}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.name}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.mobile_id}</StyledTableCell>
              {/* <StyledTableCell sx={sx}>{row.rfid[0].rfid_num}</StyledTableCell> */}
              <StyledTableCell sx={sx}>{t(row.status)}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.reservation === true ? t('Yes') : t('No')}</StyledTableCell>
              <StyledTableCell sx={sx}>
                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                  {row.reservation === false ? (
                    <UserReserve
                      info={row}
                      chargingStationList={chargingStationList}
                      ReserveUpdate={getlist}
                      setSuccess={(childData) => setSuccess(childData)}
                      setErrorMessage={(childData) => setErrorMessage(childData)}
                      setError={(childData) => setError(childData)}
                    />
                  ) : (
                    <UserCancel
                      info={row}
                      CancelUpdate={getlist}
                      setSuccess={(childData) => setSuccess(childData)}
                      setErrorMessage={(childData) => setErrorMessage(childData)}
                      setError={(childData) => setError(childData)}
                    />
                  )}
                  <UserEdit
                    dataInfo={row}
                    EditUpdate={getlist}
                    setSuccess={(childData) => setSuccess(childData)}
                    setErrorMessage={(childData) => setErrorMessage(childData)}
                    setError={(childData) => setError(childData)}
                  />
                  {row?.rfid.length > 0 ? (
                    <UserMoreInfo
                      info={row}
                      chargingStationList={chargingStationList}
                      ReserveUpdate={getlist}
                      setSuccess={(childData) => setSuccess(childData)}
                      setErrorMessage={(childData) => setErrorMessage(childData)}
                      setError={(childData) => setError(childData)}
                    />
                  ) : null}
                  {/* <UserDelete key={row.name} info={row} setSuccess={(childData) => setSuccess(childData)}
                    setErrorMessage={(childData) => setErrorMessage(childData)}
                    setError={(childData) => setError(childData)} /> */}
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}

const getFilteredItems = (query, user) => {
  if (!query) {
    return user;
  }
  const search = user.filter((user) => {
    // const name = user.name.toLowerCase().includes(query);
    // const Name = user.name.includes(query);
    const mobileId = user.mobile_id.toLowerCase().includes(query);
    const MobileId = user.mobile_id.includes(query);
    // const RFID = user.rfid.toLowerCase().includes(query); // empty RFID need to return '' instead of null
    const status = user.status.toLowerCase().includes(query);
    const Status = user.status.includes(query);
    // const Rstatus = user.reservation.toLowerCase().includes(query); // need to be in string instead of boolean
    return MobileId || mobileId || Status || status;
  });
  return search;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#17262A',
    // color: '#F4F4F4',
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    // color: '#F4F4F4',
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));

const sx = { pt: '3.5px', pb: '3.5px' };
const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: '15px',
  '&:hover': {
    color: '#00B0B9',
    background: theme.palette.action.hover,
  },
}));
