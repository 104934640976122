import React, { useState, useEffect, useRef } from 'react';
// mui
import {
  Box,
  Step,
  Stack,
  Dialog,
  Stepper,
  StepLabel,
  IconButton,
  DialogTitle,
  DialogContent,
  Tooltip,
  Alert,
  Snackbar,
  LinearProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import StationModel from './StationModel';
import EnergyModel from './EnergyModel/EnergyModel';
import OpeningHourModel from './OpeningHourModel/OpeningHourModel';
import {
  // SubmitChangeAvailabilityCP,
  Charger_AddStationEnergy,
  Charger_AddStationOpening,
  Charger_AddStation,
  // Charger_Listfacility,
} from '../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../components/customIcon/icon-add-24.svg';
import { buttonR, buttonL } from '../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../components/customIcon/Close.svg';
// ----------------------------------------------------------------------

export default function StationModelAdd(props) {
  const { t } = useTranslation();
  const childRef_station = useRef();
  const childRef_energy = useRef();
  const childRef_opening = useRef();
  const [removeId, setRemoveId] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alert_open, setAlert_open] = useState(false);
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [stationData, setStationData] = useState({});
  const [energyData, setEnergyData] = useState({});
  const [openingData, setOpeningData] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertMsg, setOpenAlertMsg] = useState('');

  const steps = [t('Station Model'), t('Energy Model'), t('Opening Hour Model')];

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setStationData({});
    setPage(0);
    setEnergyData({});
    setOpeningData({});
    setAlert_open(false);
    setOpenAlert(false);
    setOpen(false);
  };

  const onSubmit = async () => {
    const verify = verifyData(childRef_opening);

    if (verify === false) {
      return false;
    } else {
      if (!verify.exceptional_period) {
        verify.exceptional = [];
        verify.exceptional_closings = [];
        verify.exceptional_openings = [];
      }
      if (verify['24/7']) {
        verify.regular_hours = [];
      }
      if (verify.exceptional.length == 0) {
        verify.exceptional_closings = [];
        verify.exceptional_openings = [];
      }
      setOpeningData(verify);
    }
    const trans_energyData = { ...energyData };
    const energyArr_source = trans_energyData.energy_sources.filter((element) => element.isVisible !== false);
    if (energyArr_source.length == 1 && energyArr_source[0].source == '') {
      trans_energyData.energy_sources = [];
    } else {
      trans_energyData.energy_sources = energyArr_source;
    }

    const energyArr_env = trans_energyData.environmental_impact.filter((element) => element.isVisible !== false);
    if (energyArr_env.length == 1 && energyArr_env[0].category == '') {
      trans_energyData.environmental_impact = [];
    } else {
      trans_energyData.environmental_impact = energyArr_env.filter(
        (element) => element.category != '' && (element.amount != 0 || element.amount != '')
      );
    }
    console.log('Station=>', stationData, 'Energy=>', trans_energyData, 'openingData=>', verify);
    console.log(
      'Station=>',
      JSON.stringify(stationData),
      'Energy=>',
      JSON.stringify(trans_energyData),
      'openingData=>',
      JSON.stringify(verify)
    );
    setLoading(true);
    try {
      const promiseResult = await Charger_AddStation(JSON.stringify(stationData), setError, setErrorMessage);
      if (promiseResult.category === 'Success') {
        const stationID = promiseResult.data.id;
        trans_energyData.cs_id = stationID; // cs_id
        const energy = await add_energy(trans_energyData);
        if (energy) {
          verify.cs_id = stationID; // cs_id
          await add_opening(verify);
        }
        setLoading(false);
      } else {
        setErrorMessage('StationModel=>' + promiseResult.response?.data?.message);
        setAlert_open(true);
        setLoading(false);
      }
    } catch (error) {
      setAlert_open(true);
      setErrorMessage('StationModel=>' + error.response?.data?.message);
      setLoading(false);
    }
  };

  const add_energy = async (data) => {
    try {
      const promiseResult = await Charger_AddStationEnergy(JSON.stringify(data), setError, setErrorMessage);
      if (promiseResult.category === 'Success') {
        return true;
      } else {
        setErrorMessage('EnergyModel=>' + promiseResult.response?.data?.message);
        setAlert_open(true);
        return false;
      }
    } catch (error) {
      setAlert_open(true);
      setErrorMessage(error.response?.data?.message);
      return false;
    }
  };

  const add_opening = async (data) => {
    setLoading(true);
    try {
      const promiseResult = await Charger_AddStationOpening(JSON.stringify(data), setError, setErrorMessage);
      if (promiseResult.category === 'Success') {
        handleClose();
        // update
        props.AddCSUpdate(Math.random());
        setLoading(false);
        return true;
      } else {
        setErrorMessage('Opening=>' + promiseResult.response?.data?.message);
        setAlert_open(true);
        setLoading(false);
        return false;
      }
    } catch (error) {
      setAlert_open(true);
      setErrorMessage(error.response?.data?.message);
      setLoading(false);
      return false;
    }
  };
  const onNext = () => {
    const name = t(steps[page]);

    if (name === t('Station Model')) {
      const verify = verifyData(childRef_station);
      if (verify === false) {
        return false;
      } else {
        setStationData(verify);
        console.log('Station', verify);
        setPage((currPage) => currPage + 1);
      }
    }
    if (name === t('Energy Model')) {
      // cs_id
      const verify = verifyData(childRef_energy);
      if (verify === false) {
        return false;
      } else {
        setEnergyData(verify);
        console.log('Energy1', verify);
        setPage((currPage) => currPage + 1);
      }
    }
  };
  const verifyData = (childRef) => {
    // cs_id
    if (childRef.current == null) {
      console.log('childRef=null');
      return;
    }
    let verifyData = childRef.current.onSubmit();
    return verifyData || false;
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert_open(false);
    setOpenAlert(false);
  };
  return (
    <Box>
      <Tooltip title={t('btn_addNewStation')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="AddNewDialog" fullWidth maxWidth="md">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <Snackbar
          open={openAlert}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="warning">{openAlertMsg}</Alert>
        </Snackbar>
        <DialogTitle id="AddNewChargingStation">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('btn_addNewStation')}
            {/* Add New Charging Station */}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
          <Stepper activeStep={page} sx={{ pt: 1 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{t(label)}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent>
          <Snackbar open={alert_open} autoHideDuration={5000} onClose={handleCloseSnackbar}>
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>

          <PageDisplay
            page={page}
            setError={setError}
            setErrorMessage={setErrorMessage}
            childRef_station={childRef_station}
            stationData={stationData}
            props={props}
            setStationData={setStationData}
            childRef_energy={childRef_energy}
            energyData={energyData}
            setEnergyData={setEnergyData}
            removeId={removeId}
            setRemoveId={setRemoveId}
            childRef_opening={childRef_opening}
            openingData={openingData}
            setOpeningData={setOpeningData}
            setOpenAlertMsg={setOpenAlertMsg}
            setOpenAlert={setOpenAlert}
          />
          <br />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <LoadingButton
              variant="outlined"
              loading={loading}
              onClick={() => {
                if (page === 0) {
                  handleClose();
                } else {
                  setPage((currPage) => currPage - 1);
                }
              }}
              sx={buttonL}
            >
              {page === 0 ? t('Cancel') : t('Back')}
            </LoadingButton>
            {page === steps.length - 1 ? (
              <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
                {t('Submit')}
              </LoadingButton>
            ) : (
              <LoadingButton
                sx={buttonR}
                onClick={onNext}
                // onClick={() => {
                //   setPage((currPage) => currPage + 1);
                // }}
              >
                {t('Next')}
              </LoadingButton>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

const PageDisplay = ({
  page,
  setError,
  setErrorMessage,
  childRef_station,
  stationData,
  props,
  setStationData,
  childRef_energy,
  energyData,
  setEnergyData,
  removeId,
  setRemoveId,
  childRef_opening,
  openingData,
  setOpeningData,
  setOpenAlert,
  setOpenAlertMsg,
}) => {
  if (page === 0) {
    return (
      <StationModel
        setError={setError}
        setErrorMessage={setErrorMessage}
        childRef={childRef_station}
        stationData={stationData}
        location={props.location}
        url={props.url}
        setStationData={setStationData}
      />
    );
  }
  if (page === 1) {
    return (
      <EnergyModel
        childRef={childRef_energy}
        energyData={energyData}
        setEnergyData={setEnergyData}
        removeId={removeId}
        setRemoveId={setRemoveId}
      />
    );
  }
  return (
    <OpeningHourModel
      setOpenAlert={setOpenAlert}
      setOpenAlertMsg={setOpenAlertMsg}
      childRef={childRef_opening}
      openingData={openingData}
      setOpeningData={setOpeningData}
    />
  );
};
