import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { deleteMobileAccount } from '../../../api/AllAPI';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function DeactivateAccountFlow() {
  let { id } = useParams();
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setMessage('');
  }, [email, password]);

  const deleteAccount = () => {
    deleteMobileAccount(email, password, id)
      .then((data) => {
        console.log(data);
        setMessage('Account deleted');
      })
      .catch((err) => {
        console.log(err);
        setMessage('Account deletion failed');
      });
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          Delete your account
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              How to delete account on mobile app
            </AccordionSummary>
            <AccordionDetails>
              <h1>Account Deletion</h1>
              <p>Follow these steps to deactivate your account:</p>
              <br />
              <div className="step">
                <div className="title">Step 1: Log in to your account</div>
                <div className="description">Go to the login page and enter your username and password.</div>
              </div>
              <br />
              <div className="step">
                <div className="title">Step 2: Go to your profile</div>
                <div className="description">
                  Once you're logged in, navigate to your profile page by clicking on the menu option in the top left
                  and select Profile.
                </div>
              </div>
              <br />
              <div className="step">
                <div className="title">Step 3: Choose "Delete My Account"</div>
                <div className="description">
                  In your profile, find and click on the "Delete My Account" option located at the bottom of the page.
                </div>
              </div>
              <br />
              <div className="step">
                <div className="title">Step 4: Confirm removal of your account</div>
                <div className="description">
                  You will be asked to confirm the removal of your account. Click "Confirm" to proceed.
                </div>
              </div>
              <br />
              <div className="step">
                <div className="title">Step 5: Enter password</div>
                <div className="description">
                  You will be required to enter your password to confirm the removal of your account. Click "Confirm" to
                  proceed.
                </div>
              </div>
              <br />
              <div className="step">
                <div className="title">Step 6: Account removed</div>
                <div className="description">If the password you enter is correct. Your account is now removed.</div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
              How do delete account on web app
            </AccordionSummary>
            <AccordionDetails>
              {message}
              <br />
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email address"
              />
              <br />
              <input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
              />
              <br />
              <button onClick={() => deleteAccount()}>Deactivate Account</button>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
