import React, { useState, useEffect, useContext } from 'react';
// mui
import { styled } from '@mui/material/styles';
import {
  useTheme,
  Box,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  tableCellClasses,
} from '@mui/material';
// sections
import EVSEMenu from './menu/index';
import Restart from './restart/index';
import Unlock from './unlock/index';
import Start from './start/index';
import Stop from './stop/index';
import MoveTo from './moveto/index';
import Retry from './retry/index';
import Available from '../../../../../../../components/customIcon/Available.svg';
import Faulty from '../../../../../../../components/customIcon/Faulty.svg';
import Inused from '../../../../../../../components/customIcon/Inused.svg';
import Reserved from '../../../../../../../components/customIcon/Reserve.svg';
import Unavailable from '../../../../../../../components/customIcon/Unavailable.svg';
import type1Icon from '../../../../../../../components/customIcon/icon-type-1.svg';
import type2Icon from '../../../../../../../components/customIcon/icon-type-2.svg';
import type2Icon_light from '../../../../../../../components/lightMode/icon-type-2.svg';
import type1Icon_light from '../../../../../../../components/lightMode/icon-type-1.svg';

import ccstype1Icon from '../../../../../../../components/customIcon/icon-ccs-type 1.svg';
import ccstype1Icon_light from '../../../../../../../components/lightMode/icon-ccs-type 1.svg';
import ccstype2Icon from '../../../../../../../components/customIcon/icon-ccs-type 2.svg';
import ccstype2Icon_light from '../../../../../../../components/lightMode/icon-ccs-type 2.svg';

import chademoIcon from '../../../../../../../components/customIcon/icon-chademo-24.svg';
import chademoIcon_light from '../../../../../../../components/lightMode/icon-chademo-24.svg';
import chaojiIcon from '../../../../../../../components/customIcon/icon-chaoji-24.svg';
import chaojiIcon_light from '../../../../../../../components/lightMode/icon-chaoji-24.svg';
import gbtacIcon from '../../../../../../../components/customIcon/icon-gbtac-24.svg';
import gbtacIcon_light from '../../../../../../../components/lightMode/icon-gbtac-24.svg';
import gbtdcIcon from '../../../../../../../components/customIcon/icon-gbtdc-24.svg';
import gbtdcIcon_light from '../../../../../../../components/lightMode/icon-gbtdc-24.svg';

import { fetchL } from '../../../../../../../api/AllAPI';
import { ThemeContext } from '../../../../../../../App';
import { useTranslation } from 'react-i18next';
import Description from './description/index';

// ----------------------------------------------------------------------

export default function EVSETable(props) {
  const { darkTheme } = useContext(ThemeContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const { dataforevse, tariffData } = props;
  const [locationList, setLocationList] = useState();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const columns = [
    { field: 'name', headerName: t('name_evse_id'), width: 120, align: 'left' },
    { field: 'status', headerName: t('Status'), width: 120, align: 'left' },
    { field: 'no_connector', headerName: t('No. of Connector(s)'), width: 120, align: 'center' },
    { field: 'connector_type', headerName: t('Connector Type'), width: 140, align: 'left' },
    { field: 'no_transaction', headerName: t('Total No. of Transaction(s)'), width: 100, align: 'center' },
    { field: 'ocpp_version', headerName: t('OCPP Version'), width: 120, align: 'center' },
    { field: 'description', headerName: t('Description'), width: 140, align: 'center' },
    { field: '', headerName: t('Actions'), align: 'center' },
  ];

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    console.log(dataforevse);
    fetchL(setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.data) {
        setLocationList(promiseResult.data.chargerstation_data);
      }
    });
  }, []);
  return (
    <Box>
      <Table>
        <TableHead sx={{ background: theme.palette.background.tableHeader }}>
          <TableRow sx={{ background: theme.palette.background.tableRow }}>
            {columns.map((column) => (
              <StyledTableCell align={column.align} sx={{ width: column.width }} key={column.headerName}>
                {column.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataforevse?.map((evse) => (
            <StyledTableRow key={evse.cp_id} hover>
              <StyledTableCell align="left">{evse.cp_id}</StyledTableCell>
              <StyledTableCell>{multiConnector(evse)}</StyledTableCell>
              <StyledTableCell align="center">{evse.connector.length}</StyledTableCell>
              <StyledTableCell align="left">
                <Stack direction="row" justifyContent="center" alignItems="center">
                  {evse.connector?.length > 1
                    ? multiConnectorType(evse)
                    : evse.connector?.length == 1
                    ? connectorIcon(evse, darkTheme)
                    : null}
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center">{evse.transaction}</StyledTableCell>
              <StyledTableCell align="center">{evse.ocpp_version.toUpperCase()}</StyledTableCell>
              <StyledTableCell align="center">
                <Description info={evse?.connector[0]} />
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" justifyContent="flex-end" alignItems="center">
                  {evse.ocpp_connection && evse.connector[0]?.status == 'Faulty' && (
                    <Retry
                      evseInfo={evse}
                      evseName={evse.cp_id}
                      setSuccess={(childData) => props.setSuccess(childData)}
                      setError={(childData) => props.setError(childData)}
                      setErrorMessage={(childData) => props.setErrorMessage(childData)}
                    />
                  )}
                  <Restart
                    evseInfo={evse}
                    evseName={evse.cp_id}
                    setSuccess={(childData) => props.setSuccess(childData)}
                    setError={(childData) => props.setError(childData)}
                    setErrorMessage={(childData) => props.setErrorMessage(childData)}
                  />
                  {evse.connector?.length !== 0 && (
                    <>
                      <Unlock
                        evseInfo={evse}
                        evseName={evse.cp_id}
                        numConnector={evse.connector.length}
                        setSuccess={props.setSuccess}
                        setError={props.setError}
                        setErrorMessage={props.setErrorMessage}
                      />
                      <StartStop
                        evse={evse}
                        setSuccess={props.setSuccess}
                        setError={props.setError}
                        setErrorMessage={props.setErrorMessage}
                        delayUpdate={props.delayUpdate}
                      />
                      <MoveTo
                        key={evse.cp_id}
                        evseInfo={evse}
                        locationList={locationList}
                        setSuccess={props.setSuccess}
                        setError={props.setError}
                        setErrorMessage={props.setErrorMessage}
                        MoveToUpdate={props.Update}
                        handleCloseMoveTo={props.Close}
                      />
                    </>
                  )}

                  <EVSEMenu
                    tariffData={tariffData}
                    evse={evse}
                    setSuccess={(childData) => props.setSuccess(childData)}
                    setError={(childData) => props.setError(childData)}
                    setErrorMessage={(childData) => props.setErrorMessage(childData)}
                    Update={(childData) => props.Update(childData)}
                    Close={(childData) => props.Close(childData)}
                  />
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

const StartStop = ({ evse, setSuccess, setError, setErrorMessage, delayUpdate }) => {
  if (evse.mobile_id !== null) {
    return (
      <Stop
        evseName={evse.cp_id}
        evseInfo={evse}
        user_name={evse.user_name}
        mobile_id={evse.mobile_id}
        setSuccess={(childData) => setSuccess(childData)}
        setError={(childData) => setError(childData)}
        setErrorMessage={(childData) => setErrorMessage(childData)}
        StopUpdate={(childData) => delayUpdate(childData)}
      />
    );
  }
  return (
    <Start
      evseInfo={evse}
      evseName={evse.cp_id}
      user_name={evse.user_name}
      mobile_id={evse.mobile_id}
      numConnector={evse.connector.length}
      setSuccess={(childData) => setSuccess(childData)}
      setError={(childData) => setError(childData)}
      setErrorMessage={(childData) => setErrorMessage(childData)}
      StartUpdate={(childData) => delayUpdate(childData)}
    />
  );
};

const connectorIcon = (evse, darkTheme) => {
  let iconSrc = '';
  const type = evse.connector[0].connector_type;
  switch (evse.connector[0] && type) {
    case 'CHADEMO':
      iconSrc = darkTheme ? chademoIcon : chademoIcon_light;
      break;
    case 'CHAOJI':
      iconSrc = darkTheme ? chaojiIcon : chaojiIcon_light;
      break;
    case 'GBT_AC':
      iconSrc = darkTheme ? gbtacIcon : gbtacIcon_light;
      break;
    case 'GBT_DC':
      iconSrc = darkTheme ? gbtdcIcon : gbtdcIcon_light;
      break;
    case 'IEC_62196_T1':
      iconSrc = darkTheme ? type1Icon : type1Icon_light;
      break;
    case 'IEC_62196_T2':
      iconSrc = darkTheme ? type2Icon : type2Icon_light;
      break;
    case 'IEC_62196_T1_COMBO':
      iconSrc = darkTheme ? ccstype1Icon : ccstype1Icon_light;
      break;
    case 'IEC_62196_T2_COMBO':
      iconSrc = darkTheme ? ccstype2Icon : ccstype2Icon_light;
      break;
    default:
      break;
  }
  if (iconSrc == '') {
    return evse.connector[0] && type;
  } else {
    return <Box component="img" src={iconSrc} title={type} sx={{ width: '24px', height: '24px', mr: 1.5 }} />;
  }
};

const multiConnectorType = (evse) => {
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  return evse.connector.map((con, index) => (
    <React.Fragment key={con.connector_type}>
      {index > 0 && <br />}
      {connectorIcon({ connector: [con] }, darkTheme)}
    </React.Fragment>
  ));
};

const multiConnector = (evse) => {
  if (evse.connector.length === 2) {
    if (evse.connector[0].status == 'Unknown') {
      return (
        <>
          {statusIcon(evse.connector[0].status)}
          <br />
          {statusIcon(evse.connector[1].status)}
        </>
      );
    } else {
      return (
        <>
          Connector{evse.connector[0].connector_numbers}:{statusIcon(evse.connector[0].status)}
          <br />
          Connector{evse.connector[1].connector_numbers}:{statusIcon(evse.connector[1].status)}
        </>
      );
    }
  }
  if (evse.connector.length === 3) {
    if (evse.connector[0].status == 'Unknown') {
      return (
        <>
          {statusIcon(evse.connector[0].status)}
          <br />
          {statusIcon(evse.connector[1].status)}
          <br />
          {statusIcon(evse.connector[2].status)}
        </>
      );
    } else {
      return (
        <>
          Connector{evse.connector[0].connector_numbers}:{statusIcon(evse.connector[0].status)}
          <br />
          Connector{evse.connector[1].connector_numbers}:{statusIcon(evse.connector[1].status)}
          <br />
          Connector{evse.connector[2].connector_numbers}:{statusIcon(evse.connector[2].status)}
        </>
      );
    }
  }
  return statusIcon(evse.connector[0] ? evse.connector[0].status : 'None');
};

const statusIcon = (status) => {
  const theme = useTheme();
  const { t } = useTranslation();
  let color = '';
  let value = '';
  if (status === 'Unavailable') {
    color = theme.palette.unavailable.main;
    value = status;
    // return <Box component="img" src={Unavailable} sx={{ height: '14px' }} />;
  }
  if (status === 'Faulted') {
    color = theme.palette.faulty.main;
    value = status;
    // return <Box component="img" src={Faulty} sx={{ height: '14px' }} />;
  }
  if (status === 'Charging') {
    color = theme.palette.inuse.main;
    value = 'In Used';
    // return <Box component="img" src={Inused} sx={{ height: '14px' }} />;
  }
  if (status === 'Reserved') {
    color = theme.palette.reserve.main;
    value = 'Reserved';
    // return <Box component="img" src={Reserved} sx={{ height: '14px' }} />;
  }
  if (status === 'Finishing') {
    color = theme.palette.inuse.main;
    value = 'In Used';
    // return <Box component="img" src={Inused} sx={{ height: '14px' }} />;
  }
  if (status === 'Available') {
    color = theme.palette.available.main;
    value = status;
    // return <Box component="img" src={Available} sx={{ height: '14px' }} />;
  }
  if (status === 'Unknown') {
    color = theme.palette.unknown.main;
    value = 'Offline';
  }
  // console.log(value == '' ? status : value);
  return <Typography sx={{ color }}>{t(value == '' ? status : t(value))}</Typography>;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));
