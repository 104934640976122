import React, { useEffect, useState } from 'react';
// mui
import { Grid, Stack, Card, CardHeader, Box, LinearProgress, useTheme } from '@mui/material';
// sections
import TotalChargers from '../../../../components/apexcharts/Donut';
import LocationChart from '../../../../components/apexcharts/BarChart';
import WidgetSummary from '../../../../components/Widget';
import WidgetSelect from '../../../../components/Select/WidgetSelect';
import LocationSelect from '../../../../components/Select/LocationSelect';
import GraphSelectLocation from '../../../../components/Select/GraphSelect';
import date from '../../../../components/LastDate';
import LoginAgain from '../../../../components/errors/LoginAgain';
import Location from '../../../../components/customIcon/icon-location-64.svg';
import ChargedEnergy from '../../../../components/customIcon/icon-chargedenergy-64.svg';
import Revenue from '../../../../components/customIcon/icon-revenue-64.svg';
import ChargingSession from '../../../../components/customIcon/icon-chargesession-64.svg';
import CarbonReduction from '../../../../components/customIcon/icon-carbon-32.svg';
import { fetchSummaryLocation } from '../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function DashboardLocation(props) {
  const { locationList } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [dataLocation, setDataLocation] = useState([]);
  const [dataCarbon, setDataCarbon] = useState(0);
  const [dataSummary, setDataSummary] = useState(0);
  const [dataEnergy, setDataEnergy] = useState([]);
  const [dataRevenue, setDataRevenue] = useState([]);
  const [dataSession, setDataSession] = useState([]);
  const [widgetSelect, setWidgetSelect] = useState('');
  const [graphSelect, setGraphSelect] = useState('');
  const [selectedLocation, setSelectedLocation] = useState();
  const [graphdataLocation, setGraphDataLocation] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [TableYLocation, setTableYLocation] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [loading_pre, setLoading_pre] = useState(false);
  const [haveData, sethaveData] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleCallbackLocation = (location) => {
    if (location) {
      setLoading_pre(true);
      fetchSummaryLocation(location, setError, setErrorMessage, setUnauthorized).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          setDataLocation(promiseResult.data[0]);
          setWidgetSelect(1);
          setGraphSelect(1);
          sethaveData(true);
        } else {
          props.setErrorMessage(promiseResult.response?.data?.message);
          props.setError(true);
          sethaveData(false);
          setDataEnergy([]);
          setDataRevenue([]);
          setDataSession([]);
          setDataSummary(0);
        }
        setLoading_pre(false);
      });
      setSelectedLocation(location);
    }
  };

  const handleCallbackWidget = (childData) => {
    setWidgetSelect(childData);
  };

  useEffect(() => {
    if (widgetSelect === 2) {
      setDataEnergy(dataLocation.charged_energy_365);
      setDataRevenue(dataLocation.revenue_365);
      setDataSession(dataLocation.charging_session_365);
    } else if (widgetSelect === 3) {
      setDataEnergy(dataLocation.charged_energy_30);
      setDataRevenue(dataLocation.revenue_30);
      setDataSession(dataLocation.charging_session_30);
    } else if (widgetSelect === 4) {
      setDataEnergy(dataLocation.charged_energy_7);
      setDataRevenue(dataLocation.revenue_7);
      setDataSession(dataLocation.charging_session_7);
    } else {
      setDataEnergy(dataLocation.charged_energy_overview);
      setDataRevenue(dataLocation.revenue_overview);
      setDataSession(dataLocation.charging_session_overview);
    }
    setDataSummary(dataLocation.total_charging_station);
    setDataCarbon(dataLocation.carbon_reduced);
  }, [widgetSelect, dataLocation]);

  const handleCallbackGraphLocation = (childData) => {
    setGraphSelect(childData);
  };

  useEffect(() => {
    if (graphSelect === 2) {
      setGraphDataLocation(dataLocation.monthly);
      setTableYLocation(date.Last7Months);
    } else if (graphSelect === 3) {
      setGraphDataLocation(dataLocation.yearly);
      setTableYLocation(date.Last7Years);
    } else {
      setGraphDataLocation(dataLocation.daily);
      setTableYLocation(date.Last7Days);
    }
    // setDataCarbon()
    setDataSummary(dataLocation.total_charging_station);
  }, [graphSelect, dataLocation]);

  return (
    <Box>
      {unauthorized && <LoginAgain />}
      {loading_pre && (
        <div style={{ marginTop: '0px', width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      <Card
        sx={{
          mb: 2,
          borderRadius: '4px',
          pl: 3,
          pb: 3,
          pr: 3,
          boxShadow: 0,
          background: theme.palette.background.pagerWhite,
          border: theme.palette.background.border,
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <LocationSelect parentCallback={handleCallbackLocation} locations={locationList} />
          <WidgetSelect parentCallback={handleCallbackWidget} datafromparent={widgetSelect} />
        </Stack>
        <Stack direction="row" justifyContent="space-around" alignItems="flex-start" spacing={3}>
          <WidgetSummary
            title={t('Total Station in', { loc: selectedLocation })}
            // title="Total Station in ${selectedLocation}"
            // total={dataLocation.total_charging_station}
            total={dataSummary}
            color="faulty"
            icon={Location}
          />
          <WidgetSummary
            title={t('Total Charged Energy')}
            total={dataEnergy}
            unit="kWh"
            color="inuse"
            icon={ChargedEnergy}
          />
          <WidgetSummary
            title={t('Total Revenue')}
            currency={dataLocation.currency}
            total={dataRevenue}
            color="unavailable"
            icon={Revenue}
          />
          <WidgetSummary
            title={t('Total Charging Sessions')}
            total={dataSession}
            color="reserve"
            icon={ChargingSession}
          />
          <WidgetSummary
            title={t('Carbon Reduced')}
            unit="tCO₂"
            // total="5"
            total={dataCarbon}
            color="unavailable"
            icon={CarbonReduction}
          />
        </Stack>
      </Card>
      <Box sx={{ display: haveData ? 'block' : 'none' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={7}>
            <Card
              sx={{
                boxShadow: 0,
                borderRadius: '4px',
                background: theme.palette.background.pagerWhite,
                border: theme.palette.background.border,
              }}
            >
              <CardHeader
                title={t('Energy Used (kWh)')}
                action={
                  <GraphSelectLocation parentCallback={handleCallbackGraphLocation} datafromparent={graphSelect} />
                }
              />
              {graphdataLocation && (
                <LocationChart yData={TableYLocation} xLabel={t('Energy (kWh)')} xData={graphdataLocation} />
              )}
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={5}>
            {dataLocation.chart && <TotalChargers title={t('Operational Status')} chartData={dataLocation.chart} />}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
