import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
// mui
import { Box, LinearProgress, TablePagination, Snackbar, Alert } from '@mui/material';
// component
import LoginAgain from 'src/components/errors/LoginAgain';
import TablePaginationActions from '../../../../components/tablePaginationActions';
import Table from './incompleteTable';
import { schedule_table } from 'src/api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------,

let incompleteTable = (
  { setTableValue, tabValue, setSearchWord, searchWord, tableType, setChangePage, setPropsData },
  ref
) => {
  const { t, i18n } = useTranslation();
  const [dbdata, setDbdata] = useState([]);
  // const [update, setUpdate] = useState();
  const [unauthorized, setUnauthorized] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [Amount_data, setAmount_data] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };
  useImperativeHandle(ref, () => ({
    update_List,
  }));
  // trans form data to display

  const [pageCount, setpageCount] = useState(0);
  useEffect(() => {
    if (pageCount !== 0) {
      update_List({ oprType: 'change' });
    } else {
      setpageCount(pageCount + 1);
    }
  }, [page, rowsPerPage]);

  const update_List = (dataType) => {
    setDbdata([]);
    const type = dataType.oprType;
    let data = {
      page_number: page + 1,
      page_size: rowsPerPage,
      search: searchWord,
      category: tableType,
    };
    switch (type) {
      case 'add':
        setPage(0);
        data.page_number = 1;
        setSearchWord('');
        break;
      case 'edit':
        break;
      case 'delete':
        break;
      case 'change':
        break;
      case 'search':
        setPage(0);
        data.page_number = 1;
        break;
      default:
        break;
    }
    setLoading(true);
    schedule_table(JSON.stringify(data), setErrorMessage, setError).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setDbdata(promiseResult.data.data);
        setAmount_data(promiseResult.data.total_record);
        setSuccess(true);
        setUnauthorized(false);
      } else {
        setErrorMessage(promiseResult.message);
        setError(true);
        setDbdata([]);
        setAmount_data(0);
        setPage(0);
        setUnauthorized(false);
        if (promiseResult.message == 'Network Error') {
          setUnauthorized(true);
        }
      }
      setLoading(false);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
  };

  const Pagination = () => {
    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 50, { label: t('All'), value: -1 }]}
        count={Number(Amount_data)}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={t('rows per page')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
        sx={{
          fontFamily: 'Arial',
          fontWeight: 400,
          fontSize: '14px',
          border: 'none',
        }}
      />
    );
  };

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
          alignContent: 'center',
        }}
      >
        <p
          style={{
            lineHeight: '22px',
            heght: '35px',
            padding: '22px 16px',
            fontSize: '14px',
            fontWeight: 700,
            fontFamily: 'Arial',
          }}
        >
          {t('Total item(s)')}: {Amount_data}
        </p>
        <Pagination />
      </div>
      {unauthorized && <LoginAgain />}
      <Snackbar
        open={success}
        // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={5000}
        // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>

      {loading && (
        <div style={{ marginTop: '-15px', width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      <Table
        setChangePage={setChangePage}
        setPropsData={setPropsData}
        transdata={dbdata}
        updateList={update_List}
        setSuccess={(childData) => setSuccess(childData)}
        setError={(childData) => setError(childData)}
        setErrorMessage={(childData) => setErrorMessage(childData)}
        setTableValue={setTableValue}
        tabValue={tabValue}
      ></Table>

      <Pagination />
    </Box>
  );
};

export default forwardRef(incompleteTable);
