import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { deleteMobileAccount } from '../../../api/AllAPI';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DeleteCN = () => {
  let { id } = useParams();
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setMessage('');
  }, [email, password]);

  const deleteAccount = () => {
    deleteMobileAccount(email, password, id)
      .then((data) => {
        console.log(data);
        setMessage('帳戶已刪除');
      })
      .catch((err) => {
        console.log(err);
        setMessage('帳戶刪除失敗');
      });
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
          刪除帳戶
        </AccordionSummary>
        <AccordionDetails>
          {message}
          <br />
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
          />
          <br />
          <input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
          <br />
          <button onClick={() => deleteAccount()}>刪除</button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DeleteCN;
