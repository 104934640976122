import React, { useEffect, useState } from 'react';
// mui
import { Stack, Typography, Card, CardContent, CardHeader, ListItem, ListItemText, useTheme, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Con_energy({ defaultData, allData }) {
  const [formData, setFormData] = useState([]);
  const { t } = useTranslation();
  const theme = useTheme();
  useEffect(() => {
    if (defaultData) {
      setFormData(defaultData);
      //   console.log(defaultData);
      //   console.log(allData);
    }
  }, [defaultData]);
  function getDayType(daysArray) {
    // const weekdays = ['Friday', 'Monday', 'Thursday', 'Tuesday', 'Wednesday'];
    const weekdays = ['FRIDAY', 'MONDAY', 'THURSDAY', 'TUESDAY', 'WEDNESDAY'];
    //const weekends = ['Saturday', 'Sunday'];
    const weekends = ['SATURDAY', 'SUNDAY'];
    const sortedDays = [...daysArray].sort(); // Sort the days for comparison

    if (sortedDays.toString() === weekdays.toString()) {
      return 'weekdays';
    } else if (sortedDays.toString() === weekends.toString()) {
      return 'weekends';
    } else if (daysArray.length === 7) {
      return 'Every day';
    } else {
      return daysArray;
    }
  }
  return (
    <>
      {formData.map((ele, ind) => {
        const content = ele.price_component[0];
        let sentence = '';

        if (ele.restrictions_show) {
          const typeMenu = {
            min_power: t('charging_more'),
            max_power: t('charging_less'),
            min_current: t('charging_more'),
            max_current: t('charging_less'),
            min_kwh: '',
            max_kwh: 'First',
          };

          const restrictionKeys = Object.keys(ele.restrictions);

          restrictionKeys.forEach((restrictionKey) => {
            if (typeMenu[restrictionKey] || typeMenu[restrictionKey] == '') {
              const value = ele.restrictions[restrictionKey];
              sentence += `${typeMenu[restrictionKey]} ${value} ${
                restrictionKey === 'min_kwh'
                  ? t('kWh onwards')
                  : restrictionKey === 'min_current' || restrictionKey === 'max_current'
                  ? 'A'
                  : 'kWh'
              } `;
            }
          });

          if (ele.restrictions.day_of_week.length !== 0) {
            let trans = getDayType(ele.restrictions.day_of_week);
            if (trans == 'weekdays' || trans == 'weekends') {
              sentence += ' ' + t('sentence_on') + ' ' + t(trans);
            } else if (trans == 'Every day') {
              sentence += ' ' + t('Every day');
            } else {
              sentence += ' ' + t('sentence_on') + ' ';
              for (let index = 0; index < trans.length; index++) {
                const element = trans[index];
                sentence += t(element) + ',';
              }
            }
          }

          const start = ele.restrictions.start_time;
          const end = ele.restrictions.end_time;

          if (start && end && start !== '' && end !== '') {
            sentence += ` ${t('between_and', { start, end })}`;
          }
        }

        return (
          <Card
            sx={{
              boxShadow: 0,
              borderRadius: '0',
              color: 'texts.main',
              border: theme.palette.CollapseRow.border,
              background: theme.palette.background.pagerWhite,
              marginTop: '10px',
              padding: '10px 15px !important',
            }}
          >
            <Box key={ind}>
              <Typography sx={{ color: theme.palette.title.main }}>
                {t('Priority Level')}&nbsp;&nbsp;{ele.price_component[0].priority_level}
              </Typography>
              <ul style={{ marginLeft: '20px' }}>{sentence && <li>{sentence}</li>}</ul>
              <ul style={{ marginLeft: '40px' }}>
                <li>
                  {content.price} {t(allData.currency)} {t('per_kWh')}
                </li>
                <li>
                  {content.vat} % {t('VAT')}
                </li>
                <li>
                  {t('Billed_per_kWh', { kWh: content.step_size })}
                  {/* Billed per {content.step_size} kWh */}
                </li>
              </ul>
            </Box>
          </Card>
        );
      })}
    </>
  );
}
