import React, { useState } from 'react';
// mui
import { Box, Link, Stack, Dialog, Button, Typography, IconButton, DialogTitle, DialogContent } from '@mui/material';
// components
import { buttonL } from '../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../components/customIcon/Close.svg';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Description(props) {
  const { t } = useTranslation();
  const { info } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Link onClick={handleClickOpen}>{info?.error_code?.replace(/([a-z])([A-Z])/g, '$1 $2')}</Link>
      <Dialog open={open} onClose={handleClose} aria-labelledby="TroubleshootingGuideDialog">
        <DialogTitle id="TroubleshootingGuide">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center">
              {t('Troubleshooting Guide')}
            </Stack>
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={3}>
            {info?.error_code_guide?.map((value) => (
              <Typography variant="body1">{value}</Typography>
            ))}
          </Stack>
          <br />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button fullWidth size="large" variant="outlined" onClick={handleClose} sx={buttonL}>
              {t('Cancel')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
