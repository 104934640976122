// Import React and ReactDOM
import React from 'react';
// components
import Page from 'src/components/Page';
import DeactivateAccount from './deactivateAcc';
import ContactUs from './contactUs';
import Chinese from './chinese';
import English from './english';
import Developer from './developer';

// Create a main App component that uses your imported components
export default function PrivacyPolicy() {
  return (
    <Page title={'Privacy Policy'}>
      <h1>Help Center</h1>
      <p>
        Thank you very much for using 'E-ON Mobile' App (hereinafter referred to as the 'App'). This App is developed
        and maintained by Lite-On Singapore Ptd Ltd. (hereinafter referred to as the 'Company'). To safeguard your
        rights, please confirm your agreement to all regulations before using the App. In the future, if the Company
        modifies or changes the terms of the App, the updated terms will be published on this page. If you continue to
        use the service after such modifications or changes, it will be deemed that you have read, understood, and
        agreed to accept such modifications or changes. If you are unable to comply with the content of these App terms
        or do not agree with them, please stop using the App immediately. To ensure your rights, it is recommended that
        you regularly check the latest provisions of these terms.{' '}
      </p>
      <br />
      <English />
      <DeactivateAccount />
      <Developer />
      {/* <h1>E-ON CSMS Privacy Policy</h1>
<p>Last updated 7 Febuary 2024.</p>
<a href="#deactivateAcc" style={{ color: 'grey' }}>
  Deactivating your account
</a>
<br />
<a href="#contactUs" style={{ color: 'grey' }}>
  Contact us
</a>
<div id="deactivateAcc">
  <DeactivateAccount />
</div>
<br />
<div id="contactUs">
  <ContactUs />
</div> */}
    </Page>
  );
}
