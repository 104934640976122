import React, { useState } from 'react';
// mui
import { Box, IconButton, Stack, Snackbar, Alert, Dialog, MenuItem, DialogTitle, LinearProgress } from '@mui/material';
// components
import CloseIcon from '../../../../../../../../components/customIcon/Close.svg';
import SCDialog from './SCDialog';
import VersionErrorClose from '../../../../../../../../components/errors/VersionErrorClose';
import { useTranslation } from 'react-i18next';
import { essentialPlan } from '../../../../../../../../constants/plan';
// import { ListSmartCharging } from '../../../../../../../../api/AllAPI';

// ----------------------------------------------------------------------

export default function SmartCharging(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading_pre, setLoading_pre] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertMsg, setOpenAlertMsg] = useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.andleCloseMain(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };
  return (
    <div>
      <MenuItem onClick={handleClickOpen}>{t('Smart Charging')}</MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="SmartCharging"
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown
      >
        {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <Snackbar
          open={openAlert}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="warning">{openAlertMsg}</Alert>
        </Snackbar>
        <DialogTitle id="SmartCharging">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Charging Station Level')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        {sessionStorage.getItem('plan_role') === essentialPlan ? (
          <VersionErrorClose setOpen={setOpen} />
        ) : (
          <SCDialog
            setOpen={setOpen}
            setLoading_pre={setLoading_pre}
            andleCloseMain={props.andleCloseMain}
            stationID={props.stationID}
            setSuccess={(childData) => props.setSuccess(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={(childData) => props.setError(childData)}
            CAUpdate={(childData) => props.CAUpdate(childData)}
            setOpenAlertMsg={setOpenAlertMsg}
            setOpenAlert={setOpenAlert}
          />
        )}
      </Dialog>
    </div>
  );
}
