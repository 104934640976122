import { useEffect, useContext } from 'react';
// @mui
import { Button, Typography } from '@mui/material';
// Cognito
import Pool from '../MobileUserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Success({ username, setPage }) {
  const { t } = useTranslation();

  const getSession = async () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.log(err);
        } else {
          console.log(session);
        }
      });
    } else {
      console.log('no user');
    }
  };

  return (
    <>
      <Typography variant="subtitle1">{t('tip_mobileLogin')}</Typography>
      <Button onClick={() => setPage(false)}>{t('Back')}</Button>
      <Button onClick={getSession}>{t('Get Session')}</Button>
    </>
  );
}
