import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, Stack, Button, IconButton, InputAdornment, Typography, TextField, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../../components/Iconify';
import { FormProvider } from '../../../../components/hook-form';
import { AccountContext } from '../Account';
// Cognito
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

let socket1;

export default function LoginForm({ setPage, setUsername }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [VeriErrMsg, setVeriErrMsg] = useState(t('login_verification'));
  const [msg, setMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const [confirmUser, setConfirmUser] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState();
  const [showPassword2, setShowPassword2] = useState(false);
  const { authenticate, confirmRegistration } = useContext(AccountContext);

  useEffect(() => {
    setErrMsg('');
  }, [email, password]);

  const onSubmit = (event) => {
    event.preventDefault();
    authenticate(email, password, newPassword, setPage, setUsername, setFirstLogin, navigate, setConfirmUser)
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        setErrMsg(err.message);
        console.log(err);
      });
  };

  const onConfirmation = (event) => {
    event.preventDefault();
    confirmRegistration(email, confirmationCode)
      .then((data) => {
        console.log(data);
        setConfirmUser(false);
        setErrMsg('');
        setMsg(t('verified_success'));
      })
      .catch((err) => {
        console.log(err);
        setVeriErrMsg(err.message);
      });
  };

  return (
    <ContentStyle>
      <FormProvider>
        {!confirmUser && (
          <Typography variant="subtitle1" sx={{ pl: 1 }}>
            {msg}
          </Typography>
        )}
        {!confirmUser && (
          <Stack spacing={3}>
            <Typography
              sx={{
                fontFamily: 'Arial',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '36px',
                color: 'error.main',
              }}
            >
              {errMsg}
            </Typography>
            <TextField
              InputLabelProps={{ shrink: true }}
              name="email"
              label={t('Email Address')}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <TextField
              name="password"
              label={t('Password')}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type={showPassword ? 'text' : 'password'}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {firstLogin && (
              <TextField
                name="newpassword"
                label={t('New Password')}
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                type={showPassword2 ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword2(!showPassword2)} edge="end">
                        <Iconify icon={showPassword2 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Stack>
        )}
        {!confirmUser && (
          <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
            <Link variant="subtitle2" underline="hover" component={RouterLink} to="/AWSCognitoForgotPassword">
              {t('Forget Password')}?
            </Link>
          </Stack>
        )}
        {!confirmUser && (
          <LoadingButton
            fullWidth
            size="large"
            // type="submit"
            variant="contained"
            onClick={onSubmit}
            sx={{ background: 'linear-gradient(to left, #66D0D5, #00B0B9)' }}
          >
            {t('Login')}
          </LoadingButton>
        )}
        {confirmUser && (
          <Typography
            sx={{
              fontFamily: 'Arial',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '36px',
              color: 'error.main',
            }}
          >
            {VeriErrMsg}
          </Typography>
        )}
        {confirmUser && (
          <Box
            spacing={3}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 1 }}
          >
            <TextField
              fullWidth
              name="Verification Code"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Verification Code')}
                </>
              }
              onChange={(e) => setConfirmationCode(e.target.value)}
              value={confirmationCode}
            />
          </Box>
        )}
        {confirmUser && (
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            onClick={onConfirmation}
            sx={{ background: 'linear-gradient(to left, #66D0D5, #00B0B9)', mt: 1 }}
          >
            {t('Login')}
          </LoadingButton>
        )}
      </FormProvider>
    </ContentStyle>
  );
}

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  '& input:-webkit-autofill': {
    '-webkit-box-shadow': `0 0 0 100px ${theme.palette.background.pagerWhite} inset`,
  },
}));

export { socket1 };
