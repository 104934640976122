import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import DashboardLayout from '../../../layouts/dashboard/index';
import Login from './index';

// ----------------------------------------------------------------------

const RequireAuth = () => {
  const location = useLocation();

  if (sessionStorage.getItem('email') !== null) {
    return <DashboardLayout />;
  } else {
    return <Login state={{ from: location }} replace />;
  }
};

export default RequireAuth;
