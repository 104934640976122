import React from 'react';
// mui
import { Stack, Typography, FormControlLabel, TextField, RadioGroup, Radio, FormControl } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function DateTime({ formData, setFormData }) {
  const { t } = useTranslation();

  const handleChange = (event) => {
    setFormData({ ...formData, when: event.target.value });
  };

  const handleDate = (newValue) => {
    setFormData({ ...formData, valueDateTime: newValue });
  };

  return (
    <div>
      <Stack direction="column" spacing={3}>
        <Typography variant="body1">
          {t('tit_slelectData_Restart')}.{/* Select the Date & Time for the Restart to be carried out. */}
        </Typography>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="timing-group-label"
            name="timing-group"
            value={formData.when}
            onChange={handleChange}
          >
            <FormControlLabel value="ASAP" control={<Radio />} label={t('As soon as possible')} />
            {/* <FormControlLabel value="SDAT" control={<Radio />} label="Specific date and time" /> */}
          </RadioGroup>
        </FormControl>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            disabled={formData.when === 'SDAT' ? false : true}
            label="Date&Time picker"
            value={formData.valueDateTime}
            onChange={handleDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider> */}
      </Stack>
      <br />
    </div>
  );
}
