// mui
import { Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import AccountSidebar from '../AccountSidebar';
import ChangeEmailForm from './ChangeEmailForm';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ChangePassword() {
  const { t } = useTranslation();

  return (
    <Page title={t('change password')}>
      <Container maxWidth="xl">
        <AccountSidebar />
        <Typography variant="h4" gutterBottom>
          {t('Account Settings')}
        </Typography>
        <ChangeEmailForm />
      </Container>
    </Page>
  );
}
