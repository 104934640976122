import React, { useState } from 'react';
import { CognitoUser, CognitoRefreshToken, CognitoUserPool } from 'amazon-cognito-identity-js';

const RefreshTokenPage = () => {
  const [refreshToken, setRefreshToken] = useState('');
  const [newToken, setNewToken] = useState('');

  const handleRefreshToken = () => {
    const poolData = {
      UserPoolId: 'ap-southeast-1_a0lPcksMV',
      ClientId: '7ao9of3i7jmsfkd03uar5buplk',
    };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: 'username',
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    const RefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });

    cognitoUser.refreshSession(RefreshToken, (err, session) => {
      if (err) {
        console.error(err);
        return;
      }
      setNewToken(session.getAccessToken().getJwtToken());
      console.log(session);
    });
  };

  return (
    <div>
      <input
        type="text"
        value={refreshToken}
        onChange={(e) => setRefreshToken(e.target.value)}
        placeholder="Enter refresh token"
      />
      <button onClick={handleRefreshToken}>Get New Access Token</button>
      <br />
      New access token:{newToken}
    </div>
  );
};

export default RefreshTokenPage;
