import React, { useState, useEffect } from 'react';
// mui
import {
  Table,
  Stack,
  styled,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  tableCellClasses,
} from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Confirmation({ formData, reset_type }) {
  const { t } = useTranslation();

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const column = [
    {
      id: 'name',
      label: t('Selected EVSE'),
      width: 130,
    },
    {
      id: 'function',
      label: t('Function'),
      width: 120,
    },
    {
      id: 'timedate',
      label: t('Time & Date'),
      width: 110,
    },
  ];
  return (
    <Stack direction="column" spacing={3}>
      <Typography variant="body1">{t('tip_reviewRestart')}:</Typography>
      <Table aria-labelledby="tableTitles">
        <TableHead>
          <StyledTableRow>
            {column.map((headCell) => (
              <TableCell key={headCell.id} align="left" padding="normal" sx={{ width: headCell.width }}>
                {headCell.label}
              </TableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell align="left" padding="normal">
              {formData.checked}
            </StyledTableCell>
            <StyledTableCell>{reset_type === 'Soft' ? t('Soft Restart') : t('Hard Restart')}</StyledTableCell>
            <StyledTableCell>
              {formData.when === 'ASAP' ? (
                <Typography variant="body1">{t('As soon as possible')}</Typography>
              ) : (
                dayjs(formData.valueDateTime.$d).format('HH:mm MM/DD/YYYY')
              )}
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));
