// mui
import { Typography, Card, CardContent, Switch, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function NotificationAlerts() {
  const { t } = useTranslation();

  return (
    <Card sx={{ width: 450 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {t('Alerts')}
        </Typography>
        <FormGroup aria-label={t('position')}>
          <FormControlLabel
            value="1"
            control={<Switch color="success" />}
            label={t('alerts_email')}
            labelPlacement="start"
          />
        </FormGroup>
      </CardContent>
    </Card>
  );
}
