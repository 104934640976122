import { useEffect, useState, useRef, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Divider, Typography, Stack, MenuItem, Tooltip, IconButton, Select } from '@mui/material';
import MenuPopover from 'src/components/MenuPopover';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../constants/languagePacks/index';
import { ThemeContext } from '../../App';

export default function ChangeLanguage() {
  const anchorRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { darkTheme, toggleTheme } = useContext(ThemeContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeLang = (lang_code) => {
    // const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
    // setSelectedLanguage(lang_code);
    setOpen(false);
    localStorage.setItem('languageCode', lang_code);
    // console.log('language:' + lang_code);
  };

  return (
    <>
      <Tooltip title={t('change language')}>
        <IconButton ref={anchorRef} onClick={handleOpen} sx={{ width: 50, height: 50, zIndex: 'modal' }}>
          {darkTheme ? (
            <LanguageIcon style={{ color: '#00B0B9', background: 'black', fontSize: '30px', borderRadius: '50%' }} />
          ) : (
            <LanguageIcon style={{ color: '#00B0B9', background: '#e1e1e1', fontSize: '30px', borderRadius: '50%' }} />
          )}
        </IconButton>
      </Tooltip>

      <MenuPopover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        sx={{
          width: 220,
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack sx={{ p: 1 }}>
          {/* <Select value={selectedLanguage} onChange={onChangeLang}> */}
          {LANGUAGES.map(({ code, label }) => (
            <MenuItem key={code} value={code} onClick={() => onChangeLang(code)}>
              {label}
            </MenuItem>
          ))}
          {/* </Select> */}
        </Stack>
      </MenuPopover>
    </>
  );
}
