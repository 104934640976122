import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
// mui
import {
  Box,
  Alert,
  Stack,
  Button,
  Snackbar,
  Container,
  Typography,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
// components
import Page from '../../../../components/Page';
import TariffTable from './tariffTable/index';
import DiscountButton from './discount';
import Icon from '../../../../components/customIcon/icon-search-16.svg';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

let Tariff = ({ setChangePage, changePage, setPageFunc, setPropsData }, ref) => {
  const { t } = useTranslation();
  const childRef = useRef();
  const [searchWord, setSearchWord] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  useImperativeHandle(ref, () => ({
    updateList,
  }));
  //add Tariff click
  const handleClickOpen = () => {
    setChangePage('add');
    setPageFunc('add');
  };
  const searchHandle = (value) => {
    // console.log(value);
    setSearchWord(value);
  };
  useEffect(() => {
    updateList({ oprType: 'search' });
  }, [searchWord]);
  const updateList = (oprType) => {
    // Check if the ref is assigned and the method exists before calling it
    if (childRef.current && childRef.current.update_List) {
      childRef.current.update_List(oprType); // Call the update_list method on the child component
    }
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };
  return (
    <Page title={t('Tariff Management')} sx={{ display: changePage == 'table' ? 'block' : 'none' }}>
      <Container maxWidth="false">
        <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert severity="success">{t('Success')}!</Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography
            sx={{
              fontFamily: 'Arial',
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '36px',
              color: 'texts.main',
              mb: 2,
            }}
          >
            {t('Tariff Management')}
          </Typography>
          <DiscountButton setError={setError} setErrorMessage={setErrorMessage} setSuccess={setSuccess} />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pb: 1 }}>
          <FormControl fullWidth sx={{ mr: 1, width: '100%' }}>
            <InputLabel htmlFor="outlined-adornment-search">{t('Search')}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-search"
              startAdornment={
                <InputAdornment position="start">
                  <Box component="img" src={Icon} sx={{ width: '16px', height: '16px' }} />
                </InputAdornment>
              }
              value={searchWord}
              label={t('Search')}
              onChange={(event) => {
                searchHandle(event.target.value);
                setPage(0);
              }}
              sx={{ height: '32px' }}
            />
          </FormControl>

          <Button
            variant="contained"
            onClick={handleClickOpen}
            sx={{
              width: '160px',
              minWidth: '150px',
              height: '32px',
              background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
              boxShadow: 'none',
              color: 'primary.contrastText',
              fontFamily: 'Arial',
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '22px',
              textAlign: 'center',
            }}
          >
            + {t('Add New Tariff')}
          </Button>
        </Stack>
        <TariffTable
          ref={childRef}
          setPageFunc={setPageFunc}
          setChangePage={setChangePage}
          changePage={changePage}
          setPropsData={setPropsData}
          setSearchWord={setSearchWord}
          searchWord={searchWord}
        />
      </Container>
    </Page>
  );
};
export default forwardRef(Tariff);
