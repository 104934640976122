import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
// mui
import {
  Box,
  Stack,
  Button,
  Dialog,
  Select,
  Tooltip,
  MenuItem,
  TextField,
  IconButton,
  InputLabel,
  Typography,
  FormControl,
  Chip,
  Switch,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import RegularHours from './regularHours';
import TimeComponet from './TimeComponet';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// ----------------------------------------------------------------------

export default function OpeningHourModel({ childRef, openingData, setOpenAlertMsg, setOpenAlert }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const componentRefs_env = useRef([]);
  const [addCount_env, setaddCount_env] = useState(1);
  const [components_env, setComponents_env] = useState([1]);

  // Exceptional Period part==================================================================
  const [addCount, setaddCount] = useState(1);
  const componentRefs = useRef([]);
  const [components, setComponents] = useState([1]);

  const defaultExcData = {
    begin: null,
    end: null,
    status: 'open',
  };

  useImperativeHandle(childRef, () => ({
    onSubmit,
  }));
  const [formData, setFormData] = useState({
    cs_id: '',
    twentyfourseven: false,
    charging_when_closed: false,
    exceptional_period: false,
    exceptional_closings: [
      {
        begin: '',
        end: '',
        status: 'open',
      },
    ],
    exceptional_openings: [
      {
        begin: '',
        end: '',
        status: 'open',
      },
    ],
    exceptional: [
      {
        begin: '',
        end: '',
        status: 'open',
      },
    ],
    regular_hours: [],
  });
  useEffect(() => {
    if (openingData && Object.keys(openingData).length !== 0) {
      // console.log('resData', openingData);
      const twentyfourseven = openingData.twentyfourseven
        ? openingData.twentyfourseven
        : openingData['24/7']
        ? openingData['24/7']
        : false;
      setFormData({
        cs_id: openingData.cs_id ? openingData.cs_id : '',
        twentyfourseven: twentyfourseven,
        charging_when_closed: openingData.charging_when_closed ? openingData.charging_when_closed : false,
        exceptional_period: openingData.exceptional.length !== 0,
        exceptional: openingData.exceptional
          ? openingData.exceptional
          : [
              {
                begin: '',
                end: '',
              },
            ],
        regular_hours: openingData.regular_hours,
      });
      if (openingData.exceptional && openingData.exceptional.length != 0) {
        const componentsArray = [];
        openingData.exceptional.forEach((ele, index) => {
          componentsArray.push(index + 1);
        });
        setComponents(componentsArray);
      }

      if (openingData.regular_hours && openingData.regular_hours.length != 0) {
        const componentsArray = [];
        openingData.regular_hours.forEach((ele, index) => {
          componentsArray.push(index + 1);
        });
        setComponents_env(componentsArray);
      }
    }
  }, [openingData]);

  const defaluthoursData = {
    weekday: 1,
    period_begin: '',
    period_end: '',
  };

  const [errors, setErrors] = useState({});
  const handleAddForm_env = () => {
    const getData = getComponentData_env();
    if (getData !== false) {
      setaddCount_env(addCount_env + 1);
      setComponents_env([...components_env, addCount_env * 10]);
      setFormData({
        ...formData,
        regular_hours: [...getData, defaluthoursData],
      });
    }
  };
  const handleRemoveForm_env = (index, id) => {
    if (components_env.length > 1) {
      const getData = getComponentData_env();

      if (getData !== false) {
        // id ? setRemoveId([...removeId, id]) : null;
        setComponents_env(components_env.filter((_, i) => i !== index));
        componentRefs_env.current = componentRefs_env.current.filter((ref, i) => i !== index);

        const updatedFormData = getData.filter((_, i) => i !== index);
        // setFormData(updatedFormData);
        setFormData({
          ...formData,
          regular_hours: updatedFormData,
        });
      }
    }
  };

  const getComponentData_env = () => {
    let formDataArray = [];
    let hasError = false;
    componentRefs_env.current.forEach((ref) => {
      if (!ref.current) return;
      const childData = ref.current.onSubmit();
      if (childData === false) {
        hasError = true;
      } else {
        formDataArray.push(childData);
      }
    });

    if (hasError) {
      return false;
    } else {
      return formDataArray;
    }
  };

  const onSubmit = () => {
    setLoading(true);
    // Not empty verify
    let hasError = false;
    let Object = { ...formData };

    if (!Object['twentyfourseven']) {
      // regular_hours model
      const getData_env = getComponentData_env();
      if (getData_env !== false) {
        Object.regular_hours = getData_env;
      } else {
        hasError = true;
      }
    } else {
      Object.regular_hours = [];
    }
    //+++++++++++++++++++++++++++++++++++++++++

    if (!Object.exceptional_period) {
      //+++++++++++
      Object['exceptional_openings'] = [];
      Object['exceptional_closings'] = [];
      Object['exceptional'] = [];
    } else {
      const getData = getComponentData();
      if (getData !== false) {
        const exceptional_openings = [];
        const exceptional_closings = [];
        getData.forEach((data) => {
          if (data.status == 'open') {
            exceptional_openings.push(data);
          } else {
            exceptional_closings.push(data);
          }
        });
        Object['exceptional_openings'] = exceptional_openings;
        Object['exceptional_closings'] = exceptional_closings;
        Object['exceptional'] = getData;
      } else {
        hasError = true;
      }
    }
    //  do not submit
    if (hasError) {
      setLoading(false);
      return false;
    }

    setFormData(Object);
    return Object;
  };

  // Exceptional Period part==================================================================

  const handleAddForm_source = () => {
    const getData = getComponentData();

    if (getData !== false) {
      setaddCount(addCount + 1);
      setComponents([...components, addCount * 10]);
      setFormData({
        ...formData,
        exceptional: [...getData, defaultExcData],
      });
    }
  };
  const handleRemoveForm_source = (index, id) => {
    if (components.length > 1) {
      const getData = getComponentData();

      if (getData !== false) {
        // id ? setRemoveId([...removeId, id]) : null;
        setComponents(components.filter((_, i) => i !== index));
        componentRefs.current = componentRefs.current.filter((ref, i) => i !== index);

        const updatedFormData = getData.filter((_, i) => i !== index);
        setFormData({
          ...formData,
          exceptional: updatedFormData,
        });
      }
    }
  };

  const getComponentData = () => {
    let formDataArray = [];
    let hasError = false;
    componentRefs.current.forEach((childRef) => {
      if (!childRef.current) return;
      const childData = childRef.current.onSubmit();
      if (childData === false) {
        hasError = true;
      } else {
        formDataArray.push(childData);
      }
    });

    if (hasError) {
      return false;
    } else {
      return formDataArray;
    }
  };
  const handleChange_exc = (e) => {
    setaddCount(1);
    setComponents([1]);
    setFormData({
      ...formData,
      exceptional_period: e.target.checked,
      exceptional: [
        {
          begin: '',
          end: '',
          status: 'open',
        },
      ],
    });
  };
  // ========================================================================
  const changeSwitch = (e) => {
    const data = { ...formData };
    data['twentyfourseven'] = e.target.checked;
    data.exceptional_period = false;
    data.regular_hours = [
      {
        weekday: 1,
        period_begin: '',
        period_end: '',
      },
    ];
    data.exceptional = [];
    console.log('data', data);
    setFormData(data);
    setaddCount_env(1);
    setComponents_env([1]);
  };

  return (
    <Box>
      <form onSubmit={onSubmit}>
        {/* spacing={2} sx={{ width: 450 }} */}
        <span style={{ color: 'red' }}>* {t('Mandatory fields')}</span>
        <div style={{ padding: '10px 0 5px 0' }}>
          <Box>
            <span style={{ marginTop: '5px' }}>
              {/* <span style={{ color: 'red' }}>* </span> */}
              {t('24/7')}
            </span>
            <Switch
              checked={formData['twentyfourseven']}
              onChange={(e) => {
                changeSwitch(e);
              }}
              name="twentyfourseven"
            />
          </Box>
          <Box>
            <span style={{ marginTop: '5px' }}>
              {/* <span style={{ color: 'red' }}>* </span> */}
              {t('Exceptional Period')}
            </span>
            <Switch
              checked={formData['exceptional_period']}
              onChange={(e) => {
                handleChange_exc(e);
              }}
              name="exceptional_period"
            />
          </Box>
          <Box sx={{ display: formData['twentyfourseven'] ? 'none' : 'block' }}>
            <span style={{ marginTop: '5px' }}>
              {/* <span style={{ color: 'red' }}>* </span> */}
              {t('Charging When Closed')}
            </span>
            <Switch
              checked={formData['charging_when_closed']}
              onChange={(e) => {
                setFormData({ ...formData, charging_when_closed: e.target.checked });
              }}
              name="charging_when_closed"
            />
          </Box>
          {/* Regular Hours */}
          <Box
            sx={{
              width: '100%',
              border: '1px solid #757575',
              padding: '10px',
              marginTop: '20px',
              display: formData['twentyfourseven'] ? 'none' : 'block',
            }}
          >
            <Typography
              variant="h5"
              sx={{ marginTop: '-25px', width: '150px', background: theme.palette.background.paper }}
            >
              {t('Regular Hours')}
            </Typography>
            <div style={{ float: 'right' }}>
              <AddCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Add')} onClick={handleAddForm_env} />
              {/* <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Remove')} onClick={handleRemoveForm} /> */}
            </div>
            {components_env.map((index, ind) => {
              const childRef_env = React.createRef();
              componentRefs_env.current[index] = childRef_env;
              return (
                <div key={ind}>
                  <RegularHours
                    childRef={childRef_env}
                    // setSourceData={setFormData}
                    sourceData={formData.regular_hours ? formData.regular_hours[ind] : null}
                    key={ind}
                    index={ind}
                    handleRemoveForm={handleRemoveForm_env}
                    componentIndex={ind}
                    setOpenAlertMsg={setOpenAlertMsg}
                    setOpenAlert={setOpenAlert}
                  ></RegularHours>
                </div>
              );
            })}
          </Box>
          {/* Exceptional Period */}
          <Box
            sx={{
              width: '100%',
              border: '1px solid #757575',
              padding: '10px',
              marginTop: '20px',
              position: 'relative',
              display: formData.exceptional_period ? 'block' : 'none',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginTop: '-25px',
                width: '200px',
                background: theme.palette.background.paper,
                textAlign: 'center',
              }}
            >
              {t('Exceptional Period')}
            </Typography>
            <div style={{ position: 'absolute', right: '18px', top: '19px' }}>
              <AddCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Add')} onClick={handleAddForm_source} />
              {/* <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Remove')} onClick={deleteComponent} /> */}
            </div>
            <div
              style={{
                background: theme.palette.popPaper.paper2,
                width: '100%',
                padding: '28px 10px 10px',
              }}
            >
              {/* <div style={{ marginRight: '50px' }}> */}
              {components.map((index, ind) => {
                const ref = React.createRef();
                componentRefs.current[index] = ref;
                return (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} key={index}>
                    <TimeComponet
                      childRef={ref}
                      // setSourceData={setFormData}
                      excData={formData.exceptional[ind]}
                      key={ind}
                      index={ind}
                      handleRemoveForm={handleRemoveForm_source}
                      componentIndex={ind}
                      setOpenAlertMsg={setOpenAlertMsg}
                      setOpenAlert={setOpenAlert}
                    />
                  </div>
                );
              })}
              {/* </div> */}
            </div>
          </Box>
        </div>
      </form>
    </Box>
  );
}
