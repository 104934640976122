import React, { useContext, useEffect, useState } from 'react';
import {
  TextField,
  Menu,
  MenuItem,
  Stack,
  Snackbar,
  Alert,
  LinearProgress,
  Box,
  useTheme,
  Tooltip,
} from '@mui/material';
import EVSEMenu from '../station/CL/CS/CP/menu/index';
import Restart from '../station/CL/CS/CP/restart/index';
import Unlock from '../station/CL/CS/CP/unlock/index';
import Start from '../station/CL/CS/CP/start/index';
import Stop from '../station/CL/CS/CP/stop/index';
import MoveTo from '../station/CL/CS/CP/moveto/index';

import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../../App';
import { fetchChargerStation, fetchL, tariffSelectionListFunction } from '../../../../api/AllAPI';
import LoginAgain from '../../../../components/errors/LoginAgain';
import { styled } from '@mui/material/styles';
import bcakButton from '../../../../components/customIcon/icon-expandedarrow-24.svg';
import type1Icon from '../../../../components/customIcon/icon-type-1.svg';
import type1Icon_light from '../../../../components/lightMode/icon-type-1.svg';
import type2Icon from '../../../../components/customIcon/icon-type-2.svg';
import type2Icon_light from '../../../../components/lightMode/icon-type-2.svg';

import ccstype1Icon from '../../../../components/customIcon/icon-ccs-type 1.svg';
import ccstype1Icon_light from '../../../../components/lightMode/icon-ccs-type 1.svg';
import ccstype2Icon from '../../../../components/customIcon/icon-ccs-type 2.svg';
import ccstype2Icon_light from '../../../../components/lightMode/icon-ccs-type 2.svg';

import chademoIcon from '../../../../components/customIcon/icon-chademo-24.svg';
import chademoIcon_light from '../../../../components/lightMode/icon-chademo-24.svg';
import chaojiIcon from '../../../../components/customIcon/icon-chaoji-24.svg';
import chaojiIcon_light from '../../../../components/lightMode/icon-chaoji-24.svg';
import gbtacIcon from '../../../../components/customIcon/icon-gbtac-24.svg';
import gbtacIcon_light from '../../../../components/lightMode/icon-gbtac-24.svg';
import gbtdcIcon from '../../../../components/customIcon/icon-gbtdc-24.svg';
import gbtdcIcon_light from '../../../../components/lightMode/icon-gbtdc-24.svg';

export default function evseInfoPanel(props) {
  const { t } = useTranslation();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  const [locationList, setLocationList] = useState();
  const [tariffData, setTariffData] = useState();
  const [infoData, setInfoData] = useState({
    name: '',
    count: '',
    rated_power: '',
    stationStatus: '',
    connector: [],
    evseData: {},
  });
  const [dbdata, setDbdata] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    // props.setLoading_pro(true);
    // setDbdata([]);
    // fetchChargerStation(props.setError, props.setErrorMessage, props.setUnauthorized).then((promiseResult) => {
    //   if (promiseResult.category === 'Success') {
    //     const data = promiseResult.data.chargerstation_data;
    //     props.setSuccess(true);
    //     props.setLoading_pro(false);
    //     props.setUnauthorized(false);
    //   } else {
    //     props.setUnauthorized(false);
    //     props.setError(true);
    //     props.setLoading_pro(false);
    //     props.setErrorMessage(promiseResult.message);
    //     if (promiseResult.message == 'Network Error') {
    //       props.setUnauthorized(true);
    //     }
    //   }
    // });
  }, []);
  useEffect(() => {
    if (props.evseInfoData && Object.keys(props.evseInfoData).length != 0) {
      // debugger;
      setInfoData({
        name: props.evseInfoData.cp_id,
        count: props.evseInfoData.connector.length,
        rated_power: props.evseInfoData.rated_power,
        stationStatus: props.evseInfoData.connector?.[0]?.status ?? 'null',
        connector: props.evseInfoData.connector,
        evseData: props.evseInfoData,
      });
    }
  }, [props.evseInfoData]);
  useEffect(() => {
    fetchL(props.setError, props.setErrorMessage).then((promiseResult) => {
      if (promiseResult.data) {
        setLocationList(promiseResult.data.chargerstation_data);
      }
    });

    tariffSelectionListFunction(props.setErrorMessage, props.setError).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        const tariffTrans = [];
        for (let index = 0; index < promiseResult.data.length; index++) {
          const element = promiseResult.data[index];
          tariffTrans.push({
            name: element.name,
            id: element.id,
          });
        }
        setTariffData(tariffTrans);
      } else {
        // setErrorMessage('tariff list:', promiseResult.message);
      }
    });
  }, []);

  const back = () => {
    props.setPanelShow(true);
    props.handleEvseClick();
  };
  const leg_colors = {
    Available: theme.palette.available.main,
    'In Used': theme.palette.inuse.main,
    Faulty: theme.palette.faulty.main,
    Unavailable: theme.palette.unavailable.main,
    Reserved: theme.palette.reserve.main,
    Unknown: theme.palette.unknown.main,
    Removed: theme.palette.removed.main,
  };
  const connectType = (option) => {
    let iconSrc = '';
    switch (option) {
      case 'CHADEMO':
        iconSrc = darkTheme ? chademoIcon : chademoIcon_light;
        break;
      case 'CHAOJI':
        iconSrc = darkTheme ? chaojiIcon : chaojiIcon_light;
        break;
      case 'GBT_AC':
        iconSrc = darkTheme ? gbtacIcon : gbtacIcon_light;
        break;
      case 'GBT_DC':
        iconSrc = darkTheme ? gbtdcIcon : gbtdcIcon_light;
        break;
      case 'IEC_62196_T1':
        iconSrc = darkTheme ? type1Icon : type1Icon_light;
        break;
      case 'IEC_62196_T2':
        iconSrc = darkTheme ? type2Icon : type2Icon_light;
        break;
      case 'IEC_62196_T1_COMBO':
        iconSrc = darkTheme ? ccstype1Icon : ccstype1Icon_light;
        break;
      case 'IEC_62196_T2_COMBO':
        iconSrc = darkTheme ? ccstype2Icon : ccstype2Icon_light;
        break;
      default:
        break;
    }
    if (iconSrc !== '') {
      return (
        <div
          key={option}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            marginLeft: '8px',
          }}
        >
          <Box component="img" src={iconSrc} sx={{ width: '24px', height: '24px' }} />
          {option}
        </div>
      );
    } else {
      return option;
    }
  };
  return (
    <Box
      sx={{
        right: '0',
        top: '3px',
        width: '400px',
        // height: '20%',
        position: 'absolute',
        display: props.allowOprate.includes('clean') ? 'none' : props.panelShow ? 'none' : 'block',
      }}
    >
      {/* ========data.name======== */}
      <Box>
        <Tooltip title={t('Back')}>
          <Box
            component="img"
            src={bcakButton}
            sx={{ position: 'absolute', top: '10px', left: '5px', transform: 'rotate(90deg)', cursor: 'pointer' }}
            onClick={back}
          />
        </Tooltip>
        <Box
          sx={{
            textAlign: 'center',
            lineHeight: '48px',
            backgroundColor: theme.palette.background.pagerWhite,
            height: '48px',
          }}
        >
          {t(infoData.name)}
        </Box>
        <Box
          display="flex"
          sx={{
            backgroundColor: theme.palette.background.paper2,
            padding: '10px',
          }}
        >
          <Stack flex={1} textAlign="center">
            <p style={{ fontSize: '22px' }}>{infoData.count}</p>
            <p style={{ color: theme.palette.title.main }}>{t('No. of Connector(s)')}</p>
          </Stack>
          <Stack flex={1} textAlign="center">
            <p style={{ fontSize: '22px' }}>{infoData.rated_power}</p>
            <p style={{ color: theme.palette.title.main }}>{t('Rated Power (kW)')}</p>
          </Stack>
        </Box>
        {/* =========Type======= */}
        <Box
          sx={{
            textAlign: 'center',
            lineHeight: '48px',
            backgroundColor: theme.palette.background.pagerWhite,
            height: '48px',
          }}
        >
          {t('Connector Type')}
        </Box>
        <Box padding="10px 0" display="flex" justifyContent="center" backgroundColor={theme.palette.background.paper2}>
          <Box display="flex" alignItems="center" sx={{ color: theme.palette.title.main }}>
            {infoData.connector && infoData.connector.length != 0 ? (
              infoData.connector.map((item, ind) => connectType(item.connector_type))
            ) : (
              <p>{t('No Data')}</p>
            )}
          </Box>
        </Box>
        {/* ======Status========== */}
        <Box
          sx={{
            textAlign: 'center',
            lineHeight: '48px',
            backgroundColor: theme.palette.background.pagerWhite,
            height: '48px',
          }}
        >
          {t('Station Status')}
        </Box>
        <Box padding="10px 0" display="flex" justifyContent="center" backgroundColor={theme.palette.background.paper2}>
          <Box display="flex" alignItems="center">
            {infoData.connector && infoData.connector.length != 0 ? (
              infoData.connector.map((item, ind) => {
                return (
                  <>
                    <p
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '100%',
                        margin: '0 5px 0 10px',
                        background: leg_colors[infoData.stationStatus],
                      }}
                    ></p>
                    <p style={{ color: theme.palette.title.main }}>
                      {item.status == 'Unknown' ? t('Offline') : t(item.status)}
                    </p>
                  </>
                );
              })
            ) : (
              <>
                <p
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '100%',
                    marginRight: '10px',
                    background: leg_colors['Unknown'],
                  }}
                ></p>
                <p style={{ color: theme.palette.title.main }}>{t('Offline')}</p>
              </>
            )}
          </Box>
        </Box>
        {/* =======Control========= */}
        <Box
          sx={{
            textAlign: 'center',
            lineHeight: '48px',
            backgroundColor: theme.palette.background.pagerWhite,
            height: '48px',
          }}
        >
          {t('Station Control')}
        </Box>
        <Box padding="10px 0" display="flex" justifyContent="center" backgroundColor={theme.palette.background.paper2}>
          <Box display="flex" alignItems="center">
            <Stack direction="row" justifyContent="flex-end" alignItems="center">
              <Restart
                evseInfo={infoData.evseData}
                evseName={infoData.name}
                setSuccess={(childData) => props.setSuccess(childData)}
                setError={(childData) => props.setError(childData)}
                setErrorMessage={(childData) => props.setErrorMessage(childData)}
              />
              {infoData.connector?.length !== 0 && (
                <>
                  <Unlock
                    evseName={infoData.name}
                    evseInfo={infoData.evseData}
                    numConnector={infoData.connector?.length}
                    setSuccess={props.setSuccess}
                    setError={props.setError}
                    setErrorMessage={props.setErrorMessage}
                  />
                  <StartStop
                    evse={infoData.evseData}
                    setSuccess={props.setSuccess}
                    setError={props.setError}
                    setErrorMessage={props.setErrorMessage}
                    delayUpdate={null}
                  />
                  <MoveTo
                    key={infoData.name}
                    evseInfo={infoData.evseData}
                    locationList={locationList}
                    setSuccess={props.setSuccess}
                    setError={props.setError}
                    setErrorMessage={props.setErrorMessage}
                    MoveToUpdate={(data) => {
                      back();
                      props.refreshDiagram(data);
                    }}
                    handleCloseMoveTo={(data) => null}
                  />
                </>
              )}
              <EVSEMenu
                tariffData={tariffData}
                evse={infoData.evseData}
                setSuccess={(childData) => props.setSuccess(childData)}
                setError={(childData) => props.setError(childData)}
                setErrorMessage={(childData) => props.setErrorMessage(childData)}
                Update={(data) => {
                  back();
                  props.refreshDiagram(data);
                }}
                Close={(childData) => null}
              />
            </Stack>
          </Box>
        </Box>
        {/* ================ */}
      </Box>
    </Box>
  );
}

// const evse = {
//   cp_id: '45wqd',
//   connector: [
//     {
//       cnn_id: 20,
//       connector_number: 1,
//       status: 'Available',
//       connector_type: 'CHADEMO',
//       power_type: 'AC_1_PHASE',
//       connector_format: 'SOCKET',
//       max_voltage: 1,
//       max_amperage: 2,
//       max_electric_power: 3,
//       terms: '',
//       tariff: [1, 5],
//     },
//     {
//       cnn_id: 21,
//       connector_number: 2,
//       status: 'Available',
//       connector_type: 'IEC_62196_T1',
//       power_type: 'AC_1_PHASE',
//       connector_format: 'SOCKET',
//       max_voltage: 1,
//       max_amperage: 2,
//       max_electric_power: 3,
//       terms: '',
//       tariff: [1, 5],
//     },
//   ],
//   rated_power: 0,
//   transaction: 0,
//   mobile_id: null,
//   user_name: null,
//   cs_id: 12,
//   ocpi_physical_reference: 'OCPI',
//   parking_restrictions: null,
//   floor_level: '',
//   ocpp_version: 'ocpp1.6',
//   capability: ['CREDIT_CARD_PAYABLE', 'CHIP_CARD_SUPPORT', 'CHARGING_PROFILE_CAPABLE'],
// };

const leg_list = [
  {
    label: 'Available',
    value: 16,
  },
  {
    label: 'In Used',
    value: 0,
  },
  {
    label: 'Faulty',
    value: 0,
  },
  {
    label: 'Unavailable',
    value: 0,
  },
  {
    label: 'Reserved',
    value: 0,
  },
  {
    label: 'Unknown',
    value: 2,
  },
  {
    label: 'Removed',
    value: 4,
  },
];
const StartStop = ({ evse, setSuccess, setError, setErrorMessage, delayUpdate }) => {
  if (!evse) {
    return;
  }
  if (evse.mobile_id !== null) {
    return (
      <Stop
        evseName={evse.cp_id}
        evseInfo={evse}
        user_name={evse.user_name}
        mobile_id={evse.mobile_id}
        setSuccess={(childData) => setSuccess(childData)}
        setError={(childData) => setError(childData)}
        setErrorMessage={(childData) => setErrorMessage(childData)}
        StopUpdate={(childData) => delayUpdate(childData)}
      />
    );
  }
  return (
    <Start
      evseInfo={evse}
      evseName={evse.cp_id}
      numConnector={evse.connector?.length}
      setSuccess={(childData) => setSuccess(childData)}
      setError={(childData) => setError(childData)}
      setErrorMessage={(childData) => setErrorMessage(childData)}
      StartUpdate={(childData) => delayUpdate(childData)}
    />
  );
};
