// Import React and ReactDOM
import React from 'react';
// mui
import { Typography } from '@mui/material';
// components
import Page from 'src/components/Page';

export default function TermsCondition() {
  return (
    <Page title={'T&C'}>
      <Typography variant="h2" align="center" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
        隱私權政策
      </Typography>
      <br />
      <Typography sx={styles}>
        本《隱私權政策》旨在說明我們會收集哪些資訊和收集資訊的原因、我們會如何使用這些資訊，以及如何審核和更新這些資訊。
      </Typography>
      <br />
      <Typography sx={styles}>我們的服務內容</Typography>
      <Typography sx={styles}>
        當您註冊本服務，我們將會運用您提供的資料建立您與您的裝置的資料以及進行充電服務、預約充電服務、付款服務、收據服務；當您使用本服務時，我們會依據您的使用紀錄進行分析以及依據您提供的定位資訊向您推薦附近可使用充電樁。
      </Typography>
      <br />
      <Typography sx={styles}>我們希望您瞭解在您使用本服務時收集的資訊類型</Typography>
      <Typography sx={styles}>我們向您蒐集的資訊主要可以分為以下幾類：</Typography>
      <Typography sx={styles}>充電樁資訊</Typography>
      <Typography sx={styles}>。您的汽車品牌、型號、車牌號碼</Typography>
      <Typography sx={styles}>。您汽車的輸出電壓（充電）類型、電池容量、充電器類型 充電資訊</Typography>
      <Typography sx={styles}>。充電狀態、充電開始及結束、使用電量、充電樁連接ID、充電站</Typography>
      <Typography sx={styles}>充電資訊</Typography>
      <Typography sx={styles}>。充電狀態、充電開始及結束、使用電量、充電樁連接ID、充電站</Typography>
      <Typography sx={styles}>預約資訊</Typography>
      <Typography sx={styles}>。預約充電站</Typography>
      <Typography sx={styles}>。充電開始及結束</Typography>
      <Typography sx={styles}>。充電樁連接ID</Typography>
      <Typography sx={styles}>信用卡及交易資訊</Typography>
      <Typography sx={styles}>。交易狀態</Typography>
      <Typography sx={styles}>。交易日期</Typography>
      <Typography sx={styles}>。信用卡號</Typography>
      <Typography sx={styles}>。信用卡有效日期</Typography>
      <Typography sx={styles}>。充電站資訊</Typography>
      <Typography sx={styles}>。交易序號</Typography>
      <Typography sx={styles}>。收據序號</Typography>
      <Typography sx={styles}>。銀行資訊</Typography>
      <Typography sx={styles}>。驗證值</Typography>
      <Typography sx={styles}>收據資訊</Typography>
      <Typography sx={styles}>。Email</Typography>
      <Typography sx={styles}>。手機載具</Typography>
      <Typography sx={styles}>。公司名稱</Typography>
      <Typography sx={styles}>。統一編號</Typography>
      <Typography sx={styles}>。交易序號</Typography>
      <Typography sx={styles}>。發票開立日期</Typography>
      <Typography sx={styles}>。稅額</Typography>
      <Typography sx={styles}>。交易金額</Typography>
      <Typography sx={styles}>。交易總額</Typography>
      <Typography sx={styles}>。服務敘述</Typography>
      <br />
      <Typography sx={styles}>您建立或提供給我們的內容</Typography>
      <Typography sx={styles}>
        除了以上資料，您需要向我們提供您的信箱、密碼、姓名、手機號碼、生日，以協助我們建立您的個人檔案。如果您使用Apple或Google帳戶進行註冊，則需要向我們提供您的Apple或Google帳戶資料。
      </Typography>
      <br />
      <Typography sx={styles}>我們運用收集到的資料改善服務</Typography>
      <Typography sx={styles}>
        我們會運用您使用本服務時所提供的資料以改善您的使用體驗，並協助我們提供您更好的服務
      </Typography>
      <br />
      <Typography sx={styles}>您可以管理我們收集的資訊項目和資訊的用途</Typography>
      <Typography sx={styles}>
        如果您要修改您提供的資訊或確認我們目前蒐集到的資料，請依下列路徑進行編輯或查詢：
      </Typography>
      <Typography sx={styles}>［請填寫路徑］</Typography>
      <Typography sx={styles}>編輯信用卡資訊：請回到主畫面，選擇「信用卡」，然後開始進行編輯。</Typography>
      <Typography sx={styles}>編輯個人資料：請進入側邊選單，選擇「帳戶」，然後開始進行編輯。</Typography>
      <Typography sx={styles}>編輯車輛與充電樁資訊：請回到主畫面，選擇「電動車」，然後開始進行編輯。</Typography>
      <Typography sx={styles}>編輯預約資訊：請回到主畫面，選擇「預約」，然後選取取消或繼續操作。</Typography>
      <Typography sx={styles}>
        編輯收據資訊：請進入側邊選單，選擇「設置」，然後選擇「發票」，勾選預設收據方式，點選「箭頭」按鈕編輯收據資訊。
      </Typography>
      <Typography sx={styles}>查詢交易：請回到主畫面，選擇「交易」，交易資訊僅供查詢不可事後變更。</Typography>
      <br />
      <Typography sx={styles}>
        除非符合下列其中一種情況，否則我們不會與本公司以外的公司、機構或個人分享您的個人資訊：
      </Typography>
      <Typography sx={styles}>事先徵得您的同意、供外部處理、基於法律原因</Typography>
      <Typography sx={styles}>
        。除本《隱私權政策》外，我們會隨時以應用程式公告、電子郵件等方式告知您任何修訂內容，如您繼續使用，即代表同意更新後的《隱私權政策》
      </Typography>
      <Typography sx={styles}>
        。如您使用本服務進行付款或進行帳戶串接，我們須向第三方廠商共享您的資料，以協力完成服務
      </Typography>
      <Typography sx={styles}>。為提供您更順暢的服務，我們會基於流程改善或其他必要原因和第三方共享您的資料</Typography>
      <Typography sx={styles}>。如因為適用法規或當地當局要求，我們會基於法令、判決或其他規定，提供您的資料</Typography>
      <br />
      <Typography sx={styles}>您隨時可以匯出自己的資訊的副本，或是將資訊從本服務帳戶中刪除</Typography>
      <Typography sx={styles}>
        。您可以隨時取得您的資料副本，前往<a href="/DeleteRetrieveCN">電腦版此頁面</a>
        ，登入並選擇「導出帳戶所有相關資料」
      </Typography>
      <Typography sx={styles}>
        。您若要停止使用本服務，可至側邊選單，找到「帳戶」，往下滾動，然後選取「刪除我的帳戶」以刪除您的帳戶以及相關資料。除此之外，您也可以前往
        <a href="/DeleteRetrieveCN">電腦版此頁面</a>
        ，登入並刪除您的帳戶以及相關資料。惟若您的服務使用涉及第三方廠商，我們將無法為您向第三方廠商請求刪除
      </Typography>
      <Typography sx={styles}>
        。您刪除帳戶時，我們會保留您的交易資料，但這些交易資料將完全去識別化，無法回復、逆向連結至您本人
      </Typography>
      <br />
      <Typography sx={styles}>資料移轉</Typography>
      <Typography sx={styles}>我們會依據適用法令規定使使用者可以攜帶及轉移資料至其他供應商</Typography>
      <br />
      <Typography sx={styles}>本政策的變更</Typography>
      <Typography sx={styles}>
        在此通知您，我們會不定期修訂本《隱私權政策》。然而，我們不會在未取得您明確同意的情況下，逕自縮減本《隱私權政策》賦予您的權利。我們會標明最後修訂日期，並保存政策的封存版本於網站上供您查閱。如果本《隱私權政策》有重大異動，我們會提供清楚的通知（包括但不限於行動應用程式通知、電子郵件、網站公告等）。
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant="h2" align="center" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
        使用條款
      </Typography>
      <br />
      <Typography sx={styles}>說明您使用我們的服務時同意遵守的規則。</Typography>
      <Typography sx={styles}>我們的服務是由以下公司提供，同時該公司也是您的合約簽訂對象：</Typography>
      <Typography sx={styles}>光寶科技股份有限公司</Typography>
      <br />
      <Typography sx={styles}>資格規定</Typography>
      <Typography sx={styles}>
        您須為台灣法下的成年人且持有台灣政府核發的有效駕照，才能夠註冊本服務。如果您不符合以上條件，請勿繼續註冊。
      </Typography>
      <br />
      <Typography sx={styles}>我們提供的服務</Typography>
      <Typography sx={styles}>
        我們提供您的服務皆適用本條款，且本條款亦可能不定期更新，然而，我們不會在未取得您明確同意的情況下，逕自縮減本《使用條款》賦予您的權利。我們會標明最後修訂日期，並保存政策的封存版本於網站上供您查閱。如果本《使用條款》有重大異動，我們會提供清楚的通知（包括但不限於行動應用程式通知、電子郵件、網站公告等）。如您在更新後繼續使用本服務，即表示您同意更新後的《使用條款》。
      </Typography>
      <br />
      <Typography sx={styles}>開發及改善</Typography>
      <Typography sx={styles}>
        我們會使用您同意我們使用的資料來改善您提供的服務，以提供您更高的服務品質，依據不同考量，我們可能會刪改或新增功能，亦可能需要您升級您的裝置系統以享有最佳服務。
      </Typography>
      <Typography sx={styles}>如您不同意我們的變更，您可以隨時匯出資料，並刪除您的帳戶。</Typography>
      <br />
      <Typography sx={styles}>社群守則</Typography>
      <Typography sx={styles}>為了維護所有人互相尊重的環境，請務必恪守下列基本行為準則：</Typography>
      <Typography sx={styles}>。遵守台灣法規，包括但不限於交通法規或刑法規定</Typography>
      <Typography sx={styles}>。尊重他人的權利，包括隱私權和智慧財產權</Typography>
      <Typography sx={styles}>
        。切勿辱罵或傷害他人或自己
        （或是威脅或鼓吹他人從事這些行為），例如誤導、詐騙、非法冒用他人身分、誹謗、霸凌、騷擾或跟蹤他人
      </Typography>
      <Typography sx={styles}>
        。切勿濫用、破壞、干擾服務或裝置，或是導致服務中斷，例如以詐欺或詐騙手法存取或使用服務、導入惡意軟體、濫發垃圾內容、入侵或規避我們的系統或保護措施。
      </Typography>
      <Typography sx={styles}>。請勿破壞充電樁，亦禁止於使用或即將使用充電樁的一切騷擾或攻擊行為</Typography>
      <br />
      <Typography sx={styles}>請對您上傳的內容負責</Typography>
      <Typography sx={styles}>
        我們的部分服務可讓您上傳、提交、儲存、傳送、接收或分享您的內容。如果您選擇上傳或分享內容，請確認您擁有相關必要權利，且該內容無違法之虞，亦不會侵害他人權益。
      </Typography>
      <br />
      <Typography sx={styles}>安全措施</Typography>
      <Typography sx={styles}>
        為了您的資訊安全著想，請使用安全合法的裝置，並以合規的方式使用，務必定期檢查您的裝置或應用程式的安全性，以確保您的資料或服務使用過程安全無虞。
      </Typography>
      <br />
      <Typography sx={styles}>法律責任</Typography>
      <Typography sx={styles}>
        本服務在法律允許的程度下依「現況」提供，不帶有任何形式的保證，無論是以明示或暗示形式，包含但不限於任何暗示的適售性保證、適用於特定用途之合適性保證，或無侵權保證。雖此資訊在基於我們認定準確的前提下提供，但仍可能包含錯誤或準確性不足的資訊。
      </Typography>
      <br />
      <Typography sx={styles}>
        本服務可能包含與您的車輛、裝置、充電樁或其他硬體、發卡銀行、金流服務平台或其他第三方服務有關，而這些車輛、裝置、充電樁或其他硬體、發卡銀行、金流服務平台或其他第三方服務皆不在我們的控管範圍內。因此，我們對以上設備、裝置、充電樁或其他硬體、發卡銀行、金流服務平台或其他第三方服務的相關責任概不負責。我們僅以提供服務之目的與前述設備、裝置、充電樁或其他硬體、發卡銀行、金流服務平台或其他第三方服務產生直接或間接關聯，並不代表我們對其負擔任何保證責任亦不擔保其產品服務不會對您造成任何潛藏損害。
      </Typography>
      <br />
      <Typography sx={styles}>停權與移除</Typography>
      <Typography sx={styles}>
        如我們確認您的使用方式或上傳內容等有違本條款或適用法律或其餘我們尊重的普世價值，包括但不限於色情、騷擾等，我們將會移除您的帳戶並終止您使用本服務的權益。如經法律或政府要求，我們亦可能提供您的上傳內容或帳戶資料予政府或法院。
      </Typography>
      <br />
      <Typography sx={styles}>紛爭解決</Typography>
      <Typography sx={styles}>
        因與使用本服務所造成的一切爭議，您同意以台灣台北地方法院為管轄法院，並依據台灣法進行解釋及審理。
      </Typography>
      <Typography sx={styles}></Typography>
      <Typography sx={styles}></Typography>
      <br />
    </Page>
  );
}

const styles = {
  // pl: '100px',
  // pr: '100px',
  pb: '20px',
  fontSize: '24px',
};
