import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Dialog, Button, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
// component
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function VersionErrorDialog() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const open = true;

  const handleClose = () => {
    navigate('/pages/summary', { replace: true });
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="version-error-dialog-title"
        aria-describedby="version-error-dialog-description"
      >
        <DialogTitle id="version-error-dialog-title">{t('Unauthorized access')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="version-error-dialog-description">{t('essential_message')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('back_to')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
