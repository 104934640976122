import React, { useState, useEffect } from 'react';
// mui
import { Box, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function LocationSelect(props) {
  const { t } = useTranslation();
  const { datafromparent } = props;
  const [choice, setChoice] = useState(1);

  const handleChange = (event) => {
    setChoice(event.target.value);
    props.parentCallback(event.target.value);
  };

  useEffect(() => {
    if (datafromparent === 1) {
      setChoice(1);
    }
  }, [datafromparent]);

  return (
    <Box>
      <FormControl sx={{ minWidth: 100, width: 'auto', mb: 2, mt: 2, mr: 2 }} size="small">
        <InputLabel id="graph-select-label">{t('Select')}</InputLabel>
        <Select labelId="widget-select-label" id="widget-select" value={choice} label="choice" onChange={handleChange}>
          {dates.map((dates) => (
            <MenuItem value={dates.id} key={dates.id}>
              {t(dates.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

const dates = [
  { id: 1, name: 'Overall' },
  { id: 2, name: 'Last 365 days' },
  { id: 3, name: 'Last 30 days' },
  { id: 4, name: 'Last 7 days' },
];
