import { Link as RouterLink, useNavigate } from 'react-router-dom';
// mui
import { Button, Typography, Container, Box, styled } from '@mui/material';
// components
import Page from '../../components/Page';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { t } = useTranslation();

  return (
    <Page title={t('tit_NotFound')}>
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            {t('Sorry, page not found')}
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            {t('tip_findPage')}
            {/* 'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.' */}
          </Typography>

          <Box
            component="img"
            src="/static/illustrations/404.jpg"
            sx={{ height: 380, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Button onClick={goBack} size="large" variant="contained" component={RouterLink}>
            {t('Go to Home')}
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
