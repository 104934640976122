import { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Cognito
import UserPool from '../UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

let socket1;

export default function VerificationForm({ username }) {
  const navigate = useNavigate();
  const from = '/';
  const [confirmationcode, setConfirmationcode] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setErrMsg('');
  }, [confirmationcode]);

  const onSubmit = (event) => {
    event.preventDefault();
    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
    user.confirmRegistration(confirmationcode, true, (err, data) => {
      if (err) {
        console.log(err);
        setErrMsg(`${err}`);
      }
      if (data !== null) {
        console.log(data);
        navigate(from, { replace: true });
      }
    });
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography variant="subtitle1" sx={{ pl: 1, color: 'error.main' }}>
          {errMsg}
        </Typography>
        <TextField
          name="Verification Code"
          label={t('Verification Code')}
          onChange={(e) => setConfirmationcode(e.target.value)}
          value={confirmationcode}
        />
      </Stack>
      <br />

      <LoadingButton
        fullWidth
        size="large"
        // type="submit"
        variant="contained"
        onClick={onSubmit}
        sx={{ background: 'linear-gradient(to left, #66D0D5, #00B0B9)' }}
      >
        {t('Verify')}
      </LoadingButton>
    </>
  );
}
