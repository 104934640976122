import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  Dialog,
  Select,
  Button,
  Tooltip,
  MenuItem,
  InputLabel,
  Typography,
  IconButton,
  FormControl,
  DialogTitle,
  DialogContent,
  FormHelperText,
  LinearProgress,
} from '@mui/material';
// components
import Icon from '../../../../../../../../components/customIcon/icon-move-16.svg';
import { buttonR, buttonL } from '../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../components/customIcon/Close.svg';
import { fetchCP, SubmitMoveTo } from '../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function MoveTo(props) {
  const { t } = useTranslation();
  const { locationList, evseInfo } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState('');
  const [chargingStationList, setChargingStationList] = useState();
  const [charging_station_id, setCharging_station_id] = useState();
  const [error_location, setError_location] = useState(false);
  const [error_chargingStation, setError_chargingStation] = useState(false);
  const [helperText_loc, setHelperText_loc] = useState('');
  const [helperText_station, setHelperText_station] = useState('');
  const [disabled_station, setDisabled_station] = useState(false);

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
    setLocation('');
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleMenuItemClick = (name) => {
    setError_location(false);
    setHelperText_loc('');
    setLocation(name);
    setDisabled_station(true);
    fetchCP(name, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.charging_station) {
        setChargingStationList(promiseResult.charging_station);
        setDisabled_station(false);
        setTimeout(() => {
          setCharging_station_id(promiseResult.charging_station[0]?.cs_id);
        }, 100);
      }
    });
  };

  const onSubmit = () => {
    if (!location) {
      setError_location(true);
      setHelperText_loc(t('required fields'));
    } else if (!charging_station_id) {
      setError_chargingStation(true);
      setHelperText_station(t('required fields'));
    } else {
      if (evseInfo.cs_id == charging_station_id) {
        setError_chargingStation(true);
        setHelperText_station('Please choose a different charging station!');
        return;
      }

      setError_location(false);
      setError_chargingStation(false);
      setHelperText_loc('');
      setHelperText_station('');
      setLoading(true);

      const transData = {
        cp_id: evseInfo.cp_id,
        old_cs_id: evseInfo.cs_id,
        new_cs_id: charging_station_id,
      };
      SubmitMoveTo(transData, setError, setErrorMessage).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          props.handleCloseMoveTo(Math.random());
          props.MoveToUpdate(Math.random());
          props.setSuccess(true);
          setOpen(false);
        }
        setLoading(false);
      });
    }
  };

  return (
    <div>
      <Tooltip title={t('Move To')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="MoveToDialog">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="MoveTo">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Move To')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" justifyContent="flex-start" spacing={2} sx={{ width: 450 }}>
            <Typography>
              {t('select_EVSE_id')}: {evseInfo.cp_id}
            </Typography>
            <Typography>{t('Relocate EVSE to')}:</Typography>
            <FormControl fullWidth error={error_location}>
              <InputLabel id="location_select">{t('Location')}</InputLabel>
              <Select labelId="location_select" id="location_select" label={t('Location')}>
                {locationList?.map((L) => (
                  <MenuItem
                    key={L.id}
                    value={L.id}
                    onClick={() => {
                      setError_chargingStation(false);
                      handleMenuItemClick(L.name);
                      setHelperText_station('');
                    }}
                  >
                    {L.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{helperText_loc}</FormHelperText>
            </FormControl>
            <FormControl fullWidth error={error_chargingStation}>
              <InputLabel id="charging_station_select">{t('Charging Station')}</InputLabel>
              <Select
                labelId="charging_station_select"
                id="charging_station_select"
                disabled={disabled_station}
                label={t('Charging Station')}
                value={charging_station_id}
              >
                {chargingStationList?.map((cs) => (
                  <MenuItem key={cs.cs_id} value={cs.cs_id} onClick={() => setCharging_station_id(cs.cs_id)}>
                    {cs.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{helperText_station}</FormHelperText>
            </FormControl>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button variant="outlined" disabled={loading} onClick={handleClose} sx={buttonL}>
                {t('Back')}
              </Button>
              <Button onClick={onSubmit} disabled={loading} sx={buttonR}>
                {t('Submit')}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
