import React, { useState, useEffect, useRef } from 'react';
// mui
import {
  Box,
  Step,
  Stack,
  Dialog,
  Button,
  Stepper,
  MenuItem,
  StepLabel,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
import { buttonR, buttonL } from '../../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../../components/customIcon/Close.svg';
import { EditEvseFunction } from '../../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import Connector from './Connector';
import Evse from './Evse';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// ----------------------------------------------------------------------

export default function editEvse(props) {
  const { t } = useTranslation();
  const { evseInfo, tariffData, handleCloseMain, editUpdate } = props;
  const childRef_evse = useRef();
  const childRef_con = useRef();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({});
  const steps = [
    { value: 'EVSE', label: t('EVSE') },
    { value: 'Connector', label: t('Connector') },
  ];
  const [operType, setOperType] = useState(null);
  const defaultEnvData = {
    isVisible: true,
    connector_type: '',
    connector_format: '',
    power_type: '',
    max_voltage: '',
    max_amperage: '',
    max_electric_power: '',
    terms: '',
    tariff: [],
    operType: 'add',
  };
  // ======
  const componentRefs_env = useRef([]);
  const [addCount_env, setaddCount_env] = useState(1);
  const [components_env, setComponents_env] = useState([1]);
  //++++++++

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
    console.log(tariffData);
  }, [error]);

  useEffect(() => {
    if (evseInfo) {
      setFormData(evseInfo);
      const arr = [];
      let sum = 0;
      for (let index = 0; index < evseInfo.connector.length; index++) {
        sum += 1;
        arr.push(index + 1);
      }
      setaddCount_env(sum);
      setComponents_env(arr);
    }
  }, [evseInfo]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseMain();
    setFormData({});
    setPage(0);
    setaddCount_env(1);
    setComponents_env([1]);
  };

  const onNext = () => {
    const name = steps[page].value;
    if (name === 'EVSE') {
      const verify = verifyData(childRef_evse);
      if (verify === false) {
        return false;
      } else {
        const objArr = Object.keys(verify);
        let transData = {};
        for (let index = 0; index < objArr.length; index++) {
          const element = objArr[index];
          transData[element] = verify[element];
        }
        setFormData(verify);
        setPage((currPage) => currPage + 1);
      }
    }
    if (name === 'Connector') {
      const verify = verifyData(childRef_con);
      if (verify === false) {
        return false;
      } else {
        setFormData({ ...formData, connector: verify });
        // console.log('ConnectorData', verify);
        setPage((currPage) => currPage + 1);
      }
    }
  };

  const verifyData = (childRef) => {
    if (childRef.current == null) {
      console.log('childRef=null');
      return;
    }
    // let hasError = true;
    let verifyData = childRef.current.onSubmit();
    return verifyData || false;
  };

  //===========
  const handleAddForm_env = () => {
    // setOperType('add');
    const getData = getComponentData_env('add');
    if (getData !== false) {
      setaddCount_env(addCount_env + 1);
      setComponents_env([...components_env, addCount_env * 10]);
      setFormData({
        ...formData,
        connector: [...getData, defaultEnvData],
      });
    }
  };

  const handleRemoveForm_env = (index, id) => {
    // setOperType('del');
    const getData = getComponentData_env('del');
    if (getData == false) {
      return;
    }
    const Arr_env = getData.filter((element) => element.isVisible != false);
    if (Arr_env.length > 1) {
      const updatedFormData = getData.map((item, i) => {
        if (i == index) {
          return { ...item, isVisible: false, operType: 'del' };
        }
        return item;
      });
      setFormData({
        ...formData,
        connector: updatedFormData,
      });
    }
  };
  const getComponentData_env = (opr) => {
    let formDataArray = [];
    let hasError = false;
    componentRefs_env.current.forEach((ref) => {
      if (!ref.current) return;
      const childData = ref.current.onSubmit(opr);
      if (childData === false) {
        hasError = true;
      } else {
        formDataArray.push(childData);
      }
    });

    if (hasError) {
      return false;
    } else {
      return formDataArray;
    }
  };
  //==========
  const onSubmit = (opr) => {
    // Not empty verify
    setLoading(true);
    let hasError = false;
    const getData_env = getComponentData_env();
    const transData = formData;
    console.log('not found API', transData);
    if (getData_env !== false) {
      setFormData({
        ...formData,
        connector: getData_env,
      });
      transData.connector = getData_env.filter((element) => element.isVisible !== false);
    } else {
      hasError = true;
    }

    //  do not submit
    if (hasError) {
      setLoading(false);
      return false;
    }
    console.log('edit EVSE formData,not found API', transData);
    EditEvseFunction(JSON.stringify(transData), setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        handleClose();
        editUpdate(Math.random());
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <Box>
      <MenuItem onClick={handleClickOpen} disabled={evseInfo.connector[0]?.status === 'Unknown'}>
        {t('Edit')} {t('EVSE')}
      </MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="editEvseDialog">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="editEvseDialog">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Edit') + ' ' + t('EVSE')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
          <Stepper activeStep={page} sx={{ pt: 1 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label.value} {...stepProps}>
                  <StepLabel {...labelProps}>{label.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent>
          {/* <PageDisplay page={page} /> */}
          {page === 0 ? (
            <Evse childRef={childRef_evse} evseData={formData} setEvseData={setFormData} />
          ) : (
            <>
              <div style={{ color: 'red' }}>* {t('Mandatory fields')}</div>
              <div style={{ float: 'right' }}>
                <AddCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Add')} onClick={handleAddForm_env} />
              </div>
              {components_env.map((index, ind) => {
                const childRef_con = React.createRef();
                componentRefs_env.current[index] = childRef_con;

                return (
                  <div key={ind}>
                    <Connector
                      tariffData={tariffData}
                      childRef={childRef_con}
                      connectorData={formData.connector ? formData.connector[ind] : {}}
                      key={ind}
                      index={ind}
                      handleRemoveForm={handleRemoveForm_env}
                      componentIndex={ind}
                    />
                  </div>
                );
              })}
            </>
          )}
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button
              variant="outlined"
              onClick={() => {
                if (page === 0) {
                  handleClose();
                } else {
                  setPage((currPage) => currPage - 1);
                }
              }}
              sx={buttonL}
            >
              {page === 0 ? t('Cancel') : t('Back')}
            </Button>
            {page === steps.length - 1 ? (
              <Button onClick={onSubmit} disabled={loading} sx={buttonR}>
                {t('Submit')}
              </Button>
            ) : (
              <Button
                sx={buttonR}
                onClick={onNext}
                // onClick={() => {
                //   setPage((currPage) => currPage + 1);
                // }}
              >
                {t('Next')}
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
