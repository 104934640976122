// mui
import { Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import AccountSidebar from '../AccountSidebar';
import VerificationForm from './VerificationForm';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function UserProfile() {
  const { t } = useTranslation();

  return (
    <Page title={t('Verification Form')}>
      <Container maxWidth="xl">
        <AccountSidebar />
        <Typography variant="h4" gutterBottom>
          {t('Account Settings')}
        </Typography>
        <VerificationForm />
      </Container>
    </Page>
  );
}
