import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// mui
import { Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('verify_password'))
      .min(8, t('veify_length8'))
      .matches(/^(?=.*[a-z])/, t('veify_lowercase'))
      .matches(/^(?=.*[A-Z])/, t('veify_uppercase'))
      .matches(/^(?=.*[0-9])/, t('veify_number'))
      .matches(/^(?=.*[!@#%&])/, t('veify_specialchar')),
    reenterpassword: Yup.string()
      .required(t('veify_reEnter'))
      .oneOf([Yup.ref('password'), null], 'veify_passwordSame'),
  });

  const defaultValues = {
    password: '',
    reenterpassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    navigate('/', { replace: true });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="password"
          label={t('Password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="reenterpassword"
          label={t('Re-enter Password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          {t('verify_all')}
          {/* 'Password must be at least 8 characters, including a number, a secial character, an uppercase letter and alowercase letter.' */}
        </Typography>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('Set Password')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
