import React, { useState } from 'react';
// mui
import {
  Box,
  Alert,
  Stack,
  Button,
  Dialog,
  Snackbar,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
// components
import { buttonR, buttonL } from '../../../components/button/buttonStyle';
import CloseIcon from '../../../components/customIcon/Close.svg';
import { SubmitUserAddDialog } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function AddNewUserDialog(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [error_text, setError_text] = useState(false);
  const [helperText_text, setHelperText_text] = useState('');
  const [error_text_email, setError_text_email] = useState(false);
  const [helperText_text_email, setHelperText_text_email] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  const handleName = (e) => {
    setError_text(false);
    setHelperText_text('');
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    setError_text_email(false);
    setHelperText_text_email('');
    setEmail(e.target.value);
  };

  const onSubmit = () => {
    if (!email) {
      setHelperText_text_email('required fields');
      setError_text_email(true);
    }
    if (!name) {
      setHelperText_text('required fields');
      setError_text(true);
    } else {
      setLoading(true);
      setHelperText_text('');
      setError_text(false);
      SubmitUserAddDialog(name, email, setError, setErrorMessage).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          props.AddUpdate(Math.random());
          setSuccess(true);
          handleClose();
          setName();
          setEmail();
        } else {
          setErrorMessage(promiseResult.response?.data?.message);
          setError(true);
        }
        setLoading(false);
      });
    }
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          width: '141px',
          height: '32px',
          background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
          boxShadow: 'none',
          color: 'primary.contrastText',
          fontFamily: 'Arial',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '22px',
          textAlign: 'center',
        }}
      >
        + {t('Add New User')}
        <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert severity="success">Success!</Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="AddNewUserDialog">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="AddNewUserDialog">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Add New User')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" justifyContent="flex-start" spacing={2} sx={{ width: 450 }}>
            <TextField
              margin="dense"
              name="name"
              size="small"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Name')}
                </>
              }
              onChange={handleName}
              value={name}
              error={error_text}
              helperText={helperText_text}
              fullWidth
              variant="outlined"
            />
            <TextField
              name="email"
              margin="dense"
              size="small"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Email Address')}
                </>
              }
              onChange={handleEmail}
              value={email}
              error={error_text_email}
              helperText={helperText_text_email}
              fullWidth
              variant="outlined"
            />
            {/* <TextField
              margin="dense"
              id="rfid"
              label={t('RFID (Optional)')}
              onChange={(e) => setRfid(e.target.value)}
              value={rfid}
              fullWidth
              variant="outlined"
            /> */}
            {/* <TextField
                id="status"
                select
                name="status"
                label="Status"
                fullWidth
                SelectProps={{ native: true }}
                variant="outlined"
              >
                {statusOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField> */}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button variant="outlined" onClick={handleClose} sx={buttonL}>
                {t('Back')}
              </Button>
              <Button disabled={loading} onClick={onSubmit} sx={buttonR}>
                {t('Add')}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
