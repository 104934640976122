import React, { useState, useRef } from 'react';
// mui
import {
  Button,
  Stack,
  Box,
  Tooltip,
  Snackbar,
  Alert,
  InputAdornment,
  Typography,
  Divider,
  TextField,
} from '@mui/material';
// components
import { buttonR } from '../../../components/button/buttonStyle';
import { updateInvoice } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import backButton from '../../../components/customIcon/icon-expandedarrow-24.svg';

// ----------------------------------------------------------------------

export default function Invoice({ page, setPage }) {
  const firstTextFieldRef = useRef();
  const secondTextFieldRef = useRef();
  const thirdTextFieldRef = useRef();
  const { t } = useTranslation();
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [validity, setValidity] = useState('');
  const [code, setCode] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [counter, setCounter] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = () => {
    const sendYear = 2000 + parseInt(year) - 89;
    const sendMonth = parseInt(month) - parseInt(validity) + 1;
    const month_year = `${sendYear}0${sendMonth}-${sendYear}${month}`;
    console.log(month_year);
    updateInvoice(code, parseInt(start), parseInt(end), month_year).then((promiseResult) => {
      console.log(promiseResult);
    });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };
  const handleClickBack = () => {
    setPage('main');
  };
  return (
    <>
      <Snackbar open={success} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={t('Back')}>
            <Box
              component="img"
              src={backButton}
              sx={{ transform: 'rotate(90deg)', cursor: 'pointer', marginRight: '10px' }}
              onClick={handleClickBack}
            />
          </Tooltip>
          <div
            style={{
              fontFamily: 'Arial',
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '36px',
              color: 'texts.main',
              mb: 2,
            }}
          >
            {/* {pageFunc === 'add' ? t('New Invoice') : t('Edit Invoice')} */}
            {t('New Invoice')}
          </div>
        </Box>
        <Button sx={buttonR} onClick={onSubmit}>
          {t('Submit')}
        </Button>
      </Stack>
      <Divider sx={{ pb: 2, mb: 2 }} />
      <Typography
        sx={{ font: 'Arial', fontWeight: 400, fontStyle: 'italic', fontSize: '10px', lineHeight: '14px', pb: 2 }}
      >
        All fields are mandatory, unless otherwise stated.
      </Typography>
      <Stack direction="row" spacing={2}>
        <Box>
          <Typography sx={{ font: 'Arial', fontWeight: 700, fontSize: '16px', lineHeight: '18px', pb: 1 }}>
            {t('Invoice Period')}
          </Typography>
          <TextField
            inputProps={{ maxLength: 3 }}
            inputRef={firstTextFieldRef}
            sx={{ width: 60, pb: 2, mr: 0.5 }}
            name="year"
            onChange={(e) => {
              setYear(e.target.value);
              if (e.target.value.length === 3) {
                secondTextFieldRef.current.focus();
              }
            }}
            value={year}
            size="small"
          />
          <TextField
            inputProps={{ maxLength: 2 }}
            inputRef={secondTextFieldRef}
            onKeyDown={(e) => {
              if (e.key === 'Backspace' && e.target.value === '') {
                firstTextFieldRef.current.focus();
              }
            }}
            sx={{ width: 50, pb: 2 }}
            name="month"
            onChange={(e) => {
              setMonth(e.target.value);
              if (e.target.value.length === 2) {
                thirdTextFieldRef.current.focus();
              }
            }}
            value={month}
            size="small"
          />
        </Box>
        <Box>
          <Typography sx={{ font: 'Arial', fontWeight: 700, fontSize: '16px', lineHeight: '18px', pb: 1 }}>
            {t('Validity')}
          </Typography>
          <TextField
            inputProps={{ maxLength: 2 }}
            inputRef={thirdTextFieldRef}
            onKeyDown={(e) => {
              if (e.key === 'Backspace' && e.target.value === '') {
                secondTextFieldRef.current.focus();
              }
            }}
            sx={{ width: 120, pb: 2, mr: 0.5 }}
            name="validity"
            onChange={(e) => setValidity(e.target.value)}
            value={validity}
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">months</InputAdornment>,
            }}
          />
        </Box>
      </Stack>
      <br />
      <Stack direction="row" spacing={2}>
        <TextField
          sx={{ width: 200, pb: 2 }}
          name="code"
          label={t('Company Code')}
          onChange={(e) => setCode(e.target.value)}
          value={code}
          size="small"
        />
        <TextField
          sx={{ width: 200, pb: 2 }}
          name="start"
          label={t('Receipt Start Number')}
          onChange={(e) => {
            setStart(e.target.value);
            if (start < end && end != '') {
              setCounter(end - e.target.value + 1);
            } else {
              setCounter(0);
            }
            console.log(start);
          }}
          value={start}
          size="small"
        />
        <TextField
          sx={{ width: 200, pb: 2 }}
          name="end"
          label={t('Receipt End Number')}
          onChange={(e) => {
            setEnd(e.target.value);
            if (start < end && start != '') {
              setCounter(e.target.value - start + 1);
            } else {
              setCounter(0);
            }
            console.log(end);
          }}
          value={end}
          size="small"
        />
        <TextField
          sx={{ width: 200, pb: 2 }}
          name="counter"
          label={t('Counter')}
          value={counter}
          disabled={true}
          size="small"
        />
      </Stack>
    </>
  );
}
