import React, { useContext, useEffect, useState } from 'react';
import { IconButton, Tooltip, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../App';
import icon_light_onclick_list from '../../../components/lightMode/icon-listview-onclick-40.svg';
import icon_light_default_list from '../../../components/lightMode/icon-listview-default-40.svg';
import icon_dark_default_list from '../../../components/customIcon/icon-listview-default-40.svg';
import icon_dark_onclick_list from '../../../components/customIcon/icon-listview-onclick-40.svg';

import icon_light_onclick_loc from '../../../components/lightMode/icon-mapview-onclick-40.svg';
import icon_light_default_loc from '../../../components/lightMode/icon-mapview-default-40.svg';
import icon_dark_default_loc from '../../../components/customIcon/icon-mapview-default-40.svg';
import icon_dark_onclick_loc from '../../../components/customIcon/icon-mapview-onclick-40.svg';

import icon_light_onclick_view from '../../../components/lightMode/icon-diagramview-onclick-40.svg';
import icon_light_default_view from '../../../components/lightMode/icon-diagramview-default-40.svg';
import icon_dark_default_view from '../../../components/customIcon/icon-diagramview-default-40.svg';
import icon_dark_onclick_view from '../../../components/customIcon/icon-diagramview-onclick-40.svg';

export default function Iconshow(props) {
  const { t } = useTranslation();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  const [iconsrc, setIconsrc] = useState('');

  const iconPaths = {
    list: {
      active: {
        dark: icon_dark_onclick_list,
        light: icon_light_onclick_list,
      },
      default: {
        dark: icon_dark_default_list,
        light: icon_light_default_list,
      },
    },
    loc: {
      active: {
        dark: icon_dark_onclick_loc,
        light: icon_light_onclick_loc,
      },
      default: {
        dark: icon_dark_default_loc,
        light: icon_light_default_loc,
      },
    },
    view: {
      active: {
        dark: icon_dark_onclick_view,
        light: icon_light_onclick_view,
      },
      default: {
        dark: icon_dark_default_view,
        light: icon_light_default_view,
      },
    },
  };

  useEffect(() => {
    icon_status();
  }, [props]);
  const icon_status = () => {
    const { type, active } = props;
    let src = '';
    switch (type) {
      case 'list':
        src = iconPaths[type][active === '1' ? 'active' : 'default'][darkTheme ? 'dark' : 'light'];
        break;
      case 'loc':
        src = iconPaths[type][active === '2' ? 'active' : 'default'][darkTheme ? 'dark' : 'light'];
        break;
      case 'view':
        src = iconPaths[type][active === '3' ? 'active' : 'default'][darkTheme ? 'dark' : 'light'];
        break;
      default:
        break;
    }

    setIconsrc(src);
  };
  return (
    <>
      <Tooltip title={t(props.title)}>
        <Box component="img" src={iconsrc} sx={{ width: '30px', height: '30px' }} />
      </Tooltip>
    </>
  );
}
