// mui
import { Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import LogTable from './OCPPLogTable';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Ocpp() {
  const { t } = useTranslation();

  return (
    <Page title={t('OCPP Log')}>
      <Container maxWidth="xl">
        <Typography
          sx={{
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '36px',
            color: 'texts.main',
            mb: 1,
          }}
        >
          {t('tit_openLog')}
          {/* Open Charge Point Protocol Log */}
        </Typography>
        <LogTable />
      </Container>
    </Page>
  );
}
