import { useState } from 'react';
// mui
import { Container, Typography, styled } from '@mui/material';
// components
import Page from '../../../../components/Page';
// sections
import ForgotPasswordForm from './ForgotPasswordForm';
import FPVerification from './FPVerificationForm';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [username, setUsername] = useState('');

  return (
    <Page title={t('Login')}>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              {t('AWS Forgot Password')}
            </Typography>
            <PageDisplay page={page} setPage={setPage} setUsername={setUsername} username={username} />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

const PageDisplay = ({ page, setPage, setUsername, username }) => {
  if (page === 1) {
    return <ForgotPasswordForm setPage={setPage} setUsername={setUsername} />;
  }
  if (page === 2) {
    return <FPVerification username={username} />;
  }
  return;
};
