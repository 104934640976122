import React, { useState, useImperativeHandle, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
// mui
import {
  Box,
  Switch,
  Button,
  Dialog,
  Select,
  Tooltip,
  MenuItem,
  TextField,
  IconButton,
  InputLabel,
  Typography,
  FormControl,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
// import {  Charger_AddStation } from '../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import { verify_inputNumber, verify_inputNumber_null } from '../../../../../../../../../components/verification';
// ----------------------------------------------------------------------

export default function sourceModel({ childRef, sourceData, componentIndex, handleRemoveForm, index }) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  useImperativeHandle(childRef, () => ({
    onSubmit,
  }));
  const sourcesOptions = [
    { value: t('NUCLEAR_WASTE'), id: 'NUCLEAR_WASTE' },
    { value: t('CARBON_DIOXIDE'), id: 'CARBON_DIOXIDE' },
  ];
  const [formData, setFormData] = useState({
    category: '',
    amount: '',
    isVisible: true,
  });
  useEffect(() => {
    if (sourceData) {
      setFormData(sourceData);
    }
  }, [sourceData]);

  const [errors, setErrors] = useState({
    category: '',
    amount: '',
  });

  const handleChange_after = (e) => {
    const { name, value } = e.target;

    if (name == 'amount') {
      setFormData({
        ...formData,
        [name]: Number(value),
      });
      setErrors({ ...errors, category: '' });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    value !== '' ? setErrors({ ...errors, [name]: '' }) : null;
  };

  const onSubmit = () => {
    // Not empty verify
    // let hasError = false;
    // for (const key in errors) {
    //   const er = errors;
    //   if (formData.hasOwnProperty(key) && formData[key] === '') {
    //     setErrors({ ...errors, [key]: t('required fields') });
    //     er[key] = t('required fields');
    //     hasError = true;
    //   }
    // }
    // //  do not submit
    // if (hasError) {
    //   setLoading(false);
    //   return false;
    // }
    const trans = formData;
    let hasError = false;
    for (const key in errors) {
      const er = errors;
      // have error message
      if (er[key] !== '') {
        hasError = true;
      }
    }
    if (trans.category == '' && trans.amount != '' && trans.amount != 0 && trans.amount != null) {
      setErrors({ ...errors, category: t('required fields') });
      return false;
    }
    if (trans.category !== '' && trans.amount == '' && trans.amount == 0 && trans.amount != null) {
      setErrors({ ...errors, amount: t('required fields') });
      return false;
    }
    if (hasError) {
      setLoading(false);
      return false;
    }
    return formData;
  };
  const remove = () => {
    handleRemoveForm(index, formData.id ? formData.id : null);
  };
  return (
    <Box
      sx={{
        background: theme.palette.popPaper.paper2,
        display: formData.isVisible != false ? 'block' : 'none',
        marginBottom: '15px',
        padding: '10px',
      }}
    >
      <div style={{ float: 'right', marginTop: '-10px' }}>
        <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Remove')} onClick={remove} />
      </div>
      <TextField
        margin="dense"
        select
        fullWidth
        name="category"
        label={
          <>
            {/* <span style={{ color: 'red' }}>* </span> */}
            {t('Category')}
          </>
        }
        value={formData.category ? formData.category : ''}
        onChange={(e) => {
          handleChange_after(e);
        }}
        size="small"
        error={!!errors.category}
        helperText={errors.category}
      >
        {sourcesOptions.map((option) => {
          return (
            <MenuItem key={option.id} value={option.id}>
              {option.value}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        margin="dense"
        size="small"
        // type="number"
        name="amount"
        label={
          <>
            {/* <span style={{ color: 'red' }}>* </span> */}
            {t('Amount')}
          </>
        }
        error={!!errors.amount}
        helperText={errors.amount}
        onChange={(e) => {
          let verify = verify_inputNumber_null(e.target.value.trim(), formData.amount);

          setFormData({
            ...formData,
            amount: verify.value,
            // amount: verify.value == undefined ? '' : !isNaN(Number(verify.value)) ? Number(verify.value) : verify.value,
          });
          setErrors({ ...errors, category: '', amount: verify.error });
        }}
        value={formData.amount}
        fullWidth
        variant="outlined"
        InputProps={{
          endAdornment: <InputAdornment position="end">g/kWh</InputAdornment>,
        }}
      />
    </Box>
  );
}
