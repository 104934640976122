import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../../components/Iconify';
import { FormProvider } from '../../../../components/hook-form';
// Cognito
import UserPool from '../UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

let socket1;

export default function FPVerificationForm({ username }) {
  const navigate = useNavigate();
  const from = '/Success';
  const [confirmationCode, setConfirmationCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setErrMsg('');
  }, [confirmationCode]);

  const onSubmit = (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setErrMsg('Passwords are not the same');
      return;
    }
    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
    user.confirmPassword(confirmationCode, password, {
      onSuccess: (data) => {
        console.log('onSuccess: ', data);
        navigate(from, { replace: true });
      },
      onFailure: (err) => {
        console.log('onFailure: ', err);
        setErrMsg(`${err}`);
      },
    });
  };

  return (
    <FormProvider>
      <Stack spacing={3}>
        <Typography variant="subtitle1" sx={{ pl: 1, color: 'error.main' }}>
          {errMsg}
        </Typography>
        <TextField
          name="Verification Code"
          label={t('Verification Code')}
          onChange={(e) => setConfirmationCode(e.target.value)}
          value={confirmationCode}
        />
        <TextField
          name="password"
          label={t('Password')}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type={showPassword1 ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword1(!showPassword1)} edge="end">
                  <Iconify icon={showPassword1 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="confirmPassword"
          label={t('Confirm Password')}
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          type={showPassword2 ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword2(!showPassword2)} edge="end">
                  <Iconify icon={showPassword2 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <br />

      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <LoadingButton
          fullWidth
          size="large"
          variant="outlined"
          onClick={() => setPage(1)}
          sx={{
            boxShadow: 0,
            color: '#00B0B9',
            textAlign: 'center',
          }}
        >
          {t('Back')}
        </LoadingButton>

        <LoadingButton
          fullWidth
          size="large"
          // type="submit"
          variant="contained"
          onClick={onSubmit}
          sx={{ background: 'linear-gradient(to left, #66D0D5, #00B0B9)' }}
        >
          {t('Reset Password')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
