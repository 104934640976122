import React from 'react';
// mui
import { Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import AdminTable from './AdminTable';
import VersionError from '../../../components/errors/VersionError';
import { useTranslation } from 'react-i18next';
import { essentialPlan } from '../../../constants/plan';

// ----------------------------------------------------------------------

export default function Admin() {
  const { t } = useTranslation();

  return (
    <Page title={t('Admin')}>
      <Container maxWidth="false">
        <Typography
          sx={{
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '36px',
            color: 'texts.main',
            mb: 2,
          }}
        >
          {t('Admin Management')}
        </Typography>
        <Loading />
      </Container>
    </Page>
  );
}

const Loading = () => {
  if (sessionStorage.getItem('plan_role') === essentialPlan) {
    return <VersionError />;
  } else {
    return <AdminTable />;
  }
};
