import React from 'react';
import PropTypes from 'prop-types';
// mui
import {
  Stack,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  tableCellClasses,
} from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Confirmation({ formData }) {
  const name = formData.checked;
  const status = formData.checkedStatus;
  const { t } = useTranslation();

  const results = name.map((v, i) => [v, status[i]]).map(([name, status]) => ({ name, status }));

  const column = [
    {
      id: 'name',
      label: t('Selected EVSE'),
      width: 130,
    },
    {
      id: 'status',
      label: t('Current Status'),
      width: 120,
    },
    {
      id: 'newStatus',
      label: t('New Status'),
      width: 110,
    },
    {
      id: 'timeDate',
      label: t('Time & Date'),
      width: 120,
    },
  ];

  return (
    <Stack direction="column" spacing={3}>
      <Typography variant="body1">
        {t('tip_reviewSelected')}
        {/* Please review the selected EVSE(s) for change of availability to be carried out: */}
      </Typography>
      <Table aria-labelledby="tableTitles">
        <TableHead>
          <StyledTableRow>
            {column.map((headCell) => (
              <TableCell key={headCell.id} align="left" padding="normal" sx={{ width: headCell.width }}>
                {headCell.label}
              </TableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {results.map((checked) => {
            return (
              <TableRow key={checked.name}>
                <StyledTableCell align="left" padding="normal">
                  {checked.name}
                </StyledTableCell>
                <StyledTableCell align="left" padding="normal">
                  {t(checked.status)}
                </StyledTableCell>
                <StyledTableCell>{t(formData.status)}</StyledTableCell>
                <StyledTableCell>
                  {formData.when === 'ASAP' ? (
                    <Typography variant="body1">{t('As soon as possible')}</Typography>
                  ) : (
                    dayjs(formData.valueDateTime.$d).format('HH:mm MM/DD/YYYY')
                  )}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Stack>
  );
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));
