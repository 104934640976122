import React, { useState, useEffect, useRef } from 'react';
// mui
import {
  Box,
  Step,
  Stack,
  Dialog,
  Stepper,
  Tooltip,
  StepLabel,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Icon from '../../../../../../../components/customIcon/icon-add-24.svg';
import { buttonR, buttonL } from '../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../components/customIcon/Close.svg';
import { addEvseFunction } from '../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import Connector from './Connector';
import Evse from './Evse';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// ----------------------------------------------------------------------

export default function EvseAdd(props) {
  const { t } = useTranslation();
  const childRef_evse = useRef();
  const childRef_con = useRef();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [operType, setOperType] = useState(null);
  const [formData, setFormData] = useState({});
  const steps = [
    { name: 'EVSE', value: t('EVSE') },
    { name: 'Connector', value: t('Connector') },
  ];
  const defaultEnvData = {
    isVisible: true,
    operType: 'add',
    connector_type: '',
    connector_format: '',
    power_type: '',
    max_voltage: '',
    max_amperage: '',
    max_electric_power: '',
    terms: '',
    tariff: [],
  };
  // ======
  const componentRefs_env = useRef([]);
  const [addCount_env, setaddCount_env] = useState(1);
  const [components_env, setComponents_env] = useState([1]);
  //++++++++
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({});
    setPage(0);
    setaddCount_env(1);
    setComponents_env([1]);
  };

  const onNext = () => {
    const name = steps[page].name;

    if (name === 'EVSE') {
      const verify = verifyData(childRef_evse);
      if (verify === false) {
        return false;
      } else {
        const objArr = Object.keys(verify);
        let transData = {};
        for (let index = 0; index < objArr.length; index++) {
          const element = objArr[index];
          transData[element] = verify[element];
        }
        setFormData(transData);
        setPage((currPage) => currPage + 1);
      }
    }
    if (name === 'Connector') {
      const verify = verifyData(childRef_con);
      if (verify === false) {
        return false;
      } else {
        setFormData({ ...formData, connector: verify });
        setPage((currPage) => currPage + 1);
      }
    }
  };
  const verifyData = (childRef) => {
    if (childRef.current == null) {
      console.log('childRef=null');
      return;
    }
    let verifyData = childRef.current.onSubmit();
    return verifyData || false;
  };

  //===========
  const handleAddForm_env = () => {
    const getData = getComponentData_env('add');
    if (getData !== false) {
      setOperType('add');
      setaddCount_env(addCount_env + 1);
      setComponents_env([...components_env, addCount_env * 10]);
      setFormData({
        ...formData,
        connector: [...getData, defaultEnvData],
      });
    }
  };
  const handleRemoveForm_env = (index, id) => {
    const getData = getComponentData_env('del');
    if (getData == false) {
      return;
    }
    const Arr_env = getData.filter((element) => element.isVisible != false);
    if (Arr_env.length > 1) {
      const updatedFormData = getData.map((item, i) => {
        if (i === index) {
          return { ...item, isVisible: false, operType: 'del' };
        }
        return item;
      });
      setFormData({
        ...formData,
        connector: updatedFormData,
      });
    }
  };
  const getComponentData_env = (opr) => {
    let formDataArray = [];
    let hasError = false;
    componentRefs_env.current.forEach((ref) => {
      if (!ref.current) return;
      const childData = ref.current.onSubmit(opr);
      if (childData === false) {
        hasError = true;
      } else {
        formDataArray.push(childData);
      }
    });

    if (hasError) {
      return false;
    } else {
      return formDataArray;
    }
  };
  //==========
  const onSubmit = () => {
    // Not empty verify
    setLoading(true);
    let hasError = false;
    const getData_env = getComponentData_env();
    const transData = formData;
    if (getData_env !== false) {
      setFormData({
        ...formData,
        connector: getData_env,
      });
      transData.connector = getData_env.filter((element) => element.isVisible !== false);
      transData.cs_id = props.station.cs_id;
    } else {
      hasError = true;
    }

    //  do not submit
    if (hasError) {
      setLoading(false);
      return false;
    }
    console.log('add EVSE formData', transData, getData_env);
    addEvseFunction(JSON.stringify(transData), setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.Close(false);
        handleClose();
        props.Update(Math.random());
      } else {
        props.setErrorMessage(promiseResult?.response?.data.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <Box>
      <Tooltip title={t('Add') + ' ' + t('EVSE')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="AddNewUserDialog">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="AddNewUserDialog">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Add') + ' ' + t('EVSE')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
          <Stepper activeStep={page} sx={{ pt: 1 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label.value} {...stepProps}>
                  <StepLabel {...labelProps}>{t(label.name)}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent>
          {/* <PageDisplay page={page} /> */}
          {page === 0 ? (
            <Evse childRef={childRef_evse} evseData={formData} />
          ) : (
            <>
              <div style={{ color: 'red' }}>* {t('Mandatory fields')}</div>
              <div style={{ float: 'right' }}>
                <AddCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Add')} onClick={handleAddForm_env} />
              </div>
              {components_env.map((index, ind) => {
                let childRef_con = null;
                if (operType !== 'del') {
                  childRef_con = React.createRef();
                  componentRefs_env.current[index] = childRef_con;
                } else {
                  childRef_con = componentRefs_env.current[index];
                }
                return (
                  <div key={ind}>
                    <Connector
                      childRef={childRef_con}
                      connectorData={formData.connector ? formData.connector[ind] : {}}
                      tariffData={props.tariffData}
                      key={ind}
                      index={ind}
                      handleRemoveForm={handleRemoveForm_env}
                      componentIndex={ind}
                    />
                  </div>
                );
              })}
            </>
          )}
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <LoadingButton
              loading={loading}
              variant="outlined"
              onClick={() => {
                if (page === 0) {
                  handleClose();
                } else {
                  setPage((currPage) => currPage - 1);
                }
              }}
              sx={buttonL}
            >
              {page === 0 ? t('Cancel') : t('Back')}
            </LoadingButton>
            {page === steps.length - 1 ? (
              <LoadingButton onClick={onSubmit} disabled={loading} sx={buttonR}>
                {t('Submit')}
              </LoadingButton>
            ) : (
              <LoadingButton sx={buttonR} onClick={onNext}>
                {t('Next')}
              </LoadingButton>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
