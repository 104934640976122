import React, { useEffect, useState, useImperativeHandle } from 'react';
// mui
import { Stack, Typography, TextField, Autocomplete, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fetchUserStart } from '../../../../../../../../api/AllAPI';
// ----------------------------------------------------------------------

export default function SelectUser({ formData, setFormData, setError, setErrorMessage, cRef, evseInfo }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [error_select, setError_select] = useState(false);
  const [helperText_select, setHelperText_select] = useState('');
  const { t } = useTranslation();

  useImperativeHandle(cRef, () => ({
    handleVerification_selectUser,
  }));
  useEffect(() => {
    fetchUserStart({ cp_id: evseInfo.cp_id, connector_number: formData.connectorId }, setError, setErrorMessage).then(
      (promiseResult) => {
        if (promiseResult?.data) {
          setOptions(promiseResult.data);
        }
      }
    );
  }, []);
  const handleVerification_selectUser = () => {
    if (!formData.checkeduser) {
      setError_select(true);
      setHelperText_select('required fields');
    } else {
      setError_select(false);
      setHelperText_select('');
    }
  };
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    // (async () => {
    //   if (active) {
    //     setOptions([...formData.user]);
    //   }
    // })();

    return () => {
      active = false;
    };
  }, [loading]);

  // useEffect(() => {
  //   if (!open) {
  //     debugger;
  //     setOptions([]);
  //   }
  // }, [open]);
  return (
    <div>
      <Stack direction="column" spacing={3}>
        <Typography variant="body1">
          {t('tit_selectUser')}
          {/* Select user to Start transaction. */}
        </Typography>
        <Autocomplete
          id="search-user"
          fullWidth
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.mobile_id === value.mobile_id}
          getOptionLabel={(option) => `Name: ${option.name}, Mobile ID: ${option.mobile_id}`}
          options={options}
          loading={loading}
          onChange={(event, value) => {
            setError_select(false);
            setHelperText_select('');
            setFormData({ ...formData, checkeduser: value });
          }}
          renderInput={(params) => (
            <TextField
              error={error_select}
              helperText={helperText_select}
              {...params}
              label={t('Search User')}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Stack>
      <br />
    </div>
  );
}
