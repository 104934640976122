import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
// mui
import {
  TextField,
  MenuItem,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  IconButton,
  DialogContent,
  Snackbar,
  Alert,
  LinearProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../components/customIcon/Close.svg';
import { buttonR, buttonL } from '../../../components/button/buttonStyle';
import { OCPIADDFUNC, OCPIEDITFUNC } from '../../../api/AllAPI';
// ----------------------------------------------------------------------

export default function AddOcpiFunc({ operation, versionArr, rowData, randomSum, updateList }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loading_pro, setLoading_pro] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleCloseSnackbar_fun = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };
  const [errors, setErrors] = useState({
    party_name: '',
    version: '',
    url: '',
    credentials_token: '',
  });
  const [formData, setFormData] = useState({
    party_name: '',
    version: '',
    url: '',
    credentials_token: '',
  });
  useEffect(() => {
    if (operation && operation == 'edit') {
      console.log('rowData', rowData);
      setOpen(true);
      setFormData(rowData);
    } else if (operation && operation == 'add') {
      setFormData({ party_name: '', version: '', url: '', credentials_token: '' });
    }
  }, [operation, randomSum]);

  const resetFormData = () => {
    setFormData({
      party_name: '',
      version: '',
      url: '',
      credentials_token: '',
    });

    setErrors({
      party_name: '',
      version: '',
      url: '',
      credentials_token: '',
    });
    setSuccess(false);
    setError(false);
    setLoading(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    resetFormData();
  };
  const verifyData = () => {
    const { party_name, version, url, credentials_token } = formData;
    const newErrors = {};

    if (!party_name || !version) {
      newErrors.party_name = !party_name ? t('required fields') : '';
      newErrors.version = !version ? t('required fields') : '';
    }

    if (url && !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(url)) {
      newErrors.url = t('invalid-url');
    }

    if (credentials_token && credentials_token.length > 64) {
      newErrors.credentials_token = t('invalid input! (max length: 64)');
    }
    if (credentials_token && !credentials_token.includes('-')) {
      newErrors.credentials_token = t('invalid input!');
    }
    if (url && credentials_token == '') {
      newErrors.credentials_token = t('required fields');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0 ? true : false;
  };

  // handleSubmit
  const onSubmit = (event) => {
    // event.preventDefault();
    if (!verifyData()) {
      return false;
    } else {
      console.log(formData);
      setLoading_pro(true);
      if (operation && operation == 'edit') {
        editFunc();
      } else {
        addFunc();
      }
    }
  };
  const addFunc = () => {
    OCPIADDFUNC(formData, setErrorMessage, setError).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        handleClose();
        updateList({ oprType: 'add' });
        setSuccess(true);
      } else {
        setError(true);
        const mes = promiseResult.response.data.message;
        mes ? setErrorMessage(mes) : setErrorMessage(promiseResult.message);
      }
      setLoading_pro(false);
    });
  };
  const editFunc = () => {
    OCPIEDITFUNC(formData, setErrorMessage, setError).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        handleClose();
        updateList({ oprType: 'edit' });
        setSuccess(true);
      } else {
        setError(true);
        setErrorMessage(err.response?.data?.message);
      }
      setLoading_pro(false);
    });
  };
  return (
    <div>
      {operation == 'add' ? (
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{
            width: '145px',
            height: '32px',
            background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
            boxShadow: 'none',
            color: 'primary.contrastText',
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'center',
          }}
        >
          + {t('Add OCPI')}
        </Button>
      ) : (
        ''
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby="Add New">
        {loading_pro && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="AddNew">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {operation === 'add' ? t('Add OCPI') : t('Edit OCPI')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {unauthorized && <LoginAgain />}
          <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar_fun}>
            <Alert severity="success">{t('Success')}!</Alert>
          </Snackbar>
          <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar_fun}>
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
          <form>
            <div>
              <span style={{ color: 'red' }}>* {t('Mandatory fields')}</span>
            </div>
            <TextField
              margin="dense"
              name="party_name"
              disabled={operation == 'edit'}
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Party Name')}
                </>
              }
              value={formData.party_name}
              fullWidth
              size="small"
              onChange={(e) => {
                setFormData({ ...formData, party_name: e.target.value });
                setErrors({ ...errors, party_name: '' });
              }}
              error={!!errors.party_name}
              helperText={errors.party_name}
            />
            <TextField
              margin="dense"
              select
              fullWidth
              name="version"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Version')}
                </>
              }
              value={formData.version}
              onChange={(e) => {
                setFormData({ ...formData, version: e.target.value });
                setErrors({ ...errors, version: '' });
              }}
              size="small"
              error={!!errors.version}
              helperText={errors.version}
            >
              {versionArr &&
                versionArr.map((label, index) => {
                  return (
                    <MenuItem key={label} value={label}>
                      {t(label)}
                    </MenuItem>
                  );
                })}
            </TextField>

            <TextField
              margin="dense"
              name="url"
              label={t('URL')}
              value={formData.url}
              fullWidth
              size="small"
              onChange={(e) => {
                setErrors({ ...errors, url: '' });
                setFormData({ ...formData, url: e.target.value });
              }}
              error={!!errors.url}
              helperText={errors.url}
            />
            <TextField
              margin="dense"
              name="credentials_token"
              label={t('Credentials Token')}
              value={formData.credentials_token}
              fullWidth
              size="small"
              onChange={(e) => {
                setErrors({ ...errors, credentials_token: '' });
                setFormData({ ...formData, credentials_token: e.target.value });
              }}
              error={!!errors.credentials_token}
              helperText={errors.credentials_token}
            />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <LoadingButton
                variant="outlined"
                sx={buttonL}
                onClick={() => {
                  handleClose();
                }}
              >
                {t('Cancel')}
              </LoadingButton>

              <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
                {t('Submit')}
              </LoadingButton>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
