import { useState } from 'react';
// mui
import { Container, Typography, Snackbar, Alert } from '@mui/material';
// components
import { useTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import AccountSidebar from '../AccountSidebar';
import UserProfileForm from './UserProfileForm';

// ----------------------------------------------------------------------

export default function UserProfile() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const { t } = useTranslation();

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };
  return (
    <Page title={t('User Profile')}>
      <Container maxWidth="xl">
        <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert severity="success">{t('Success')}!</Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <AccountSidebar />
        <Typography variant="h4" gutterBottom>
          {t('Account Settings')}
        </Typography>
        <UserProfileForm
          setSuccess={(childData) => setSuccess(childData)}
          setError={(childData) => setError(childData)}
          setErrorMessage={(childData) => setErrorMessage(childData)}
        />
      </Container>
    </Page>
  );
}
