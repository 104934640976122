// component
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'CSMS Summary',
    path: '/pages/summary',
    icon: getIcon('ant-design:pie-chart-outlined'),
  },
  {
    title: 'Revenue',
    path: '/pages/revenue',
    icon: getIcon('streamline:money-cash-dollar-coin-accounting-billing-payment-cash-coin-currency-money-finance'),
  },
  {
    title: 'Charger Management',
    path: '/pages/chargermanagement',
    icon: getIcon('material-symbols:ev-charger-outline-sharp'),
  },
  {
    title: 'Tariff Management',
    path: '/pages/tariffmanagement',
    icon: getIcon('carbon:wallet'),
  },
  {
    title: 'User Management',
    path: '/pages/user',
    icon: getIcon('la:user-edit'),
  },
  {
    title: 'Session Summary',
    path: '/pages/sessionsummary',
    icon: getIcon('ep:document'),
  },
  // {
  //   title: 'OCPP log',
  //   path: '/pages/ocpplog',
  //   icon: getIcon('ant-design:folder-filled'),
  // },
];

export default navConfig;
