import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  Dialog,
  MenuItem,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { buttonR, buttonL } from '../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../components/customIcon/Close.svg';
import { SubmitEditLocation } from '../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function EditChargingLocation(props) {
  const { t } = useTranslation();

  const { location } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [location_name, setLocationNamed] = useState(location);
  const [error_text, setError_text] = useState(false);
  const [helperText_text, setHelperText_text] = useState('');

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setHelperText_text('');
    setError_text(false);
  };

  const onSubmit = () => {
    if (location_name.length < 1 || location_name.length > 100) {
      setError_text(true);
      setHelperText_text(t('Invalid value') + ' (minLength:1, maxLength:100).');
      return;
    }

    setLoading(true);
    console.log('editLoc pro');
    SubmitEditLocation(location, location_name, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.EditUpdate(Math.random());
        props.setSuccess(true);
        props.closeAll();
        handleClose();
      } else {
        // props.setErrorMessage(promiseResult.message);
        props.setErrorMessage(promiseResult.response?.data?.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>{t('Edit Location')}</MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="AddNewUserDialog">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="AddNewUserDialog">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Edit Location')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" justifyContent="flex-start" spacing={2} sx={{ minWidth: 450 }}>
            <span style={{ color: 'red' }}>* {t('Mandatory fields')}</span>
            <TextField
              margin="dense"
              name="location"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Location Name')}
                </>
              }
              onChange={(e) => setLocationNamed(e.target.value)}
              value={location_name}
              error={error_text}
              helperText={helperText_text}
              fullWidth
              variant="outlined"
            />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <LoadingButton variant="outlined" onClick={handleClose} sx={buttonL}>
                {t('Cancel')}
              </LoadingButton>
              <LoadingButton type="submit" onClick={onSubmit} disabled={loading} sx={buttonR}>
                {t('Save')}
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
