import { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import React from 'react';
import { TextField, FormControl, Select, Menu, MenuItem, Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatDateTime } from '../../../../../../../../src/components/LastDate';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export default function TimePickers_Child({
  childRef,
  excData,
  componentIndex,
  setOpenAlertMsg,
  setOpenAlert,
  handleRemoveForm,
  index,
}) {
  const { t } = useTranslation();

  // Methods exposed to the parent component
  useImperativeHandle(childRef, () => ({
    onSubmit,
  }));
  const [endDateError, setEndDateError] = useState(false);
  const [endDateErrorText, setEndDateErrorText] = useState('');
  const [formData, setFormData] = useState({
    begin: null,
    end: null,
    status: 'open',
  });
  useEffect(() => {
    if (excData) {
      setFormData(excData);
    }
  }, [excData]);

  // handleSubmit
  const onSubmit = () => {
    return formData;
  };
  const remove = () => {
    handleRemoveForm(index, formData.id ? formData.id : null);
  };
  return (
    // <Box sx={{ , marginBottom: '15px', padding: '10px' }}>
    <div style={{ width: '100%', display: 'flex' }}>
      <form onSubmit={onSubmit} style={{ width: '100%' }}>
        <div style={{ float: 'right', marginTop: '15px' }}>
          <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Remove')} onClick={remove} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%' }}>
            <DateTimePicker
              sx={{ width: '33.3' }}
              name="begin"
              label={t('Start')}
              value={formData.begin ? formData.begin : null}
              onChange={(date) => {
                if (formData.end && date.isAfter(formData.end, 'day')) {
                  // Show an error or handle the invalid date
                  setOpenAlert(true);
                  setOpenAlertMsg(t('date_after'));
                } else {
                  // Clear the error state if the selected date is valid
                  setOpenAlert(false);
                  setFormData({
                    ...formData,
                    begin: formatDateTime(new Date(date)),
                  });
                }
              }}
              renderInput={(params) => <TextField fullWidth margin="dense" size="small" {...params} />}
            />
            <DateTimePicker
              name="end"
              label={t('End')}
              sx={{ width: '33.3' }}
              value={formData.end ? formData.end : null}
              onChange={(date) => {
                // Check if the selected end date is before the start date
                if (formData.begin && date.isBefore(formData.begin, 'day')) {
                  setOpenAlert(true);
                  setOpenAlertMsg(t('date_before'));
                } else {
                  setOpenAlert(false);
                  setFormData({
                    ...formData,
                    end: formatDateTime(new Date(date)),
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  // error={endDateError}
                  // helperText={endDateErrorText}
                  fullWidth
                  margin="dense"
                  size="small"
                  {...params}
                />
              )}
            />
            <FormControl fullWidth sx={{ marginTop: '8px', width: '33.3' }}>
              <Select
                margin="dense"
                size="small"
                id="status"
                value={formData.status}
                // label={t('Roles')}
                onChange={(event) => {
                  setFormData({ ...formData, status: event.target.value });
                }}
              >
                <MenuItem value={'open'}>{t('Open')}</MenuItem>
                <MenuItem value={'close'}>{t('Close')}</MenuItem>
              </Select>
            </FormControl>
          </LocalizationProvider>
        </div>
      </form>
    </div>
  );
}
// TimePickers_Child = forwardRef(TimePickers_Child);
// export default TimePickers_Child;
