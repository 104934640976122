import React, { useState } from 'react';
// mui
import { Stack, Container, Typography, Button } from '@mui/material';
// component
import Page from '../../../components/Page';
import InvoiceTable from './InvoiceTable';
import Invoice from './NewInvoice';
import { buttonR, buttonL } from '../../../components/button/buttonStyle';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ManageInvoice() {
  const [page, setPage] = useState('main');
  const { t } = useTranslation();

  const handleClick = () => {
    if (page === 'main') {
      setPage('add');
    } else {
      setPage('main');
    }
  };

  return (
    <Page title={t('Manage Invoice')}>
      <Container maxWidth="false">
        {page === 'main' && (
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Typography
              sx={{
                fontFamily: 'Arial',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '36px',
                color: 'texts.main',
                mb: 2,
              }}
            >
              {t('Manage Invoice')}
            </Typography>
            {/* <Button sx={buttonR} onClick={handleClick}>
              {t('New Invoice')}
            </Button> */}
          </Stack>
        )}
        <PageDisplay page={page} setPage={setPage} />
      </Container>
    </Page>
  );
}

const PageDisplay = ({ page, setPage }) => {
  if (page === 'main') {
    return <InvoiceTable />;
  }
  if (page === 'edit' || page === 'add') {
    return <Invoice page={page} setPage={setPage} />;
  }
};
