import { useState } from 'react';
// mui
import { Container, Typography, styled } from '@mui/material';
// components
import Page from '../../../../../components/Page';
// sections
import MobileSignUpForm from './SignUpForm';
import MobileVerification from './VerificationForm';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const PageDisplay = ({ page, setPage, setUsername, username }) => {
  if (page === false) {
    return <MobileSignUpForm setPage={setPage} setUsername={setUsername} />;
  }
  return <MobileVerification username={username} />;
};

export default function MobileSignUp() {
  const [page, setPage] = useState(false);
  const [username, setUsername] = useState('');
  const { t } = useTranslation();

  return (
    <Page title={t('Login')}>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              {t('AWS Mobile Sign Up')}
            </Typography>
            <PageDisplay page={page} setPage={setPage} username={username} setUsername={setUsername} />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
