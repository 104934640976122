import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../../components/Iconify';
import { FormProvider } from '../../../../components/hook-form';
import { AccountContext } from '../Account';
// Cognito
import { useTranslation } from 'react-i18next';
import Pool from '../MobileUserPool';
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Amplify, Auth, Hub } from 'aws-amplify';
import awsConfig from '../../../../aws-exports';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
Amplify.configure(awsConfig);

// ----------------------------------------------------------------------

let socket1;

export default function MobileLoginForm({ setPage, setUsername }) {
  const navigate = useNavigate();
  const success = '/Success';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setErrMsg('');
  }, [email, password]);

  const onSubmit = (event) => {
    event.preventDefault();
    const user = new CognitoUser({ Username: email, Pool });
    const authDetails = new AuthenticationDetails({ Username: email, Password: password });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log('onSuccess: ', data);
        setPage(true);
      },
      onFailure: (err) => {
        console.log('onFailure: ', err);
        setErrMsg(`${err}`);
      },
      newPasswordRequired: (data) => {
        console.log('newPasswordRequired: ', data);
      },
    });
  };

  // const google = (event) => {
  //   console.log(event);
  //   console.log(event.data.access_token);
  //   console.log(event.data.email);
  //   const cognitoUser = new CognitoUser({
  //     Username: event.data.email, // A dummy username for social logins
  //     Pool,
  //   });
  //   const authenticationDetails = new AuthenticationDetails({
  //     Username: event.data.email, // A dummy username for social logins
  //     Password: event.data.access_token,
  //   });
  //   cognitoUser.authenticateUser(authenticationDetails, {
  //     onSuccess: (result) => {
  //       // Handle successful authentication
  //       console.log('Authentication successful', result);
  //     },
  //     onFailure: (error) => {
  //       // Handle authentication failure
  //       console.error('Authentication failed', error);
  //     },
  //   });
  // };

  // useEffect(() => {
  //   /* global google */
  //   google.accounts.id.initialize({
  //     client_id: '402540985306-g0jqu9vrg3stbbjhkd5j84ae63itek71.apps.googleusercontent.com',
  //     callback: handleCallbackResponse,
  //   });
  //   google.accounts.id.renderButton(document.getElementById('signInDiv'), {
  //     theme: 'outline',
  //     size: 'large',
  //     width: '400',
  //     logo_alignment: 'center',
  //   });
  // }, []);

  function handleCallbackResponse(response) {
    console.log(response.credential);
    setPage(true);

    const googleProvider = new CognitoUserAttribute({
      clientId: '402540985306-g0jqu9vrg3stbbjhkd5j84ae63itek71.apps.googleusercontent.com',
    });

    // const cognitoUser = new CognitoUser({ Username: 'sgsmartgrid.re@liteon.com', Pool });

    // Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google }, (err, result) => {
    //   if (err) {
    //     console.log(err);
    //   } else {
    //     console.log(result);
    //     console.log('Successfully signed in with Google.');
    //   }
    // });
    // cognitoUser.federatedSignIn({ provider: googleProvider, token: response.credential }, (err, result) => {
    //   if (err) {
    //     console.log(err);
    //   } else {
    //     console.log(result);
    //     console.log('Successfully signed in with Google.');
    //   }
    // });

    const authenticationDetails = new AuthenticationDetails({
      IdToken: response.credential,
    });

    const userData = {
      Username: 'sgsmartgrid.re@liteon.com',
      Pool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        // User authenticated successfully
        const accessToken = result.getAccessToken().getJwtToken();
        const idToken = result.getIdToken().getJwtToken();

        console.log(result);
      },
      onFailure: (err) => {
        console.log(err);
      },
    });
  }

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log('sign in');
          break;
        case 'signOut':
          console.log('sign out');
          break;
        case 'customOAuthState':
          console.log('custom state');
      }
    });

    Auth.currentAuthenticatedUser()
      .then((currentUser) => console.log(currentUser))
      .catch(() => console.log('Not signed in'));

    return unsubscribe;
  }, []);

  return (
    <FormProvider>
      <Stack spacing={3}>
        <Typography variant="subtitle1" sx={{ pl: 1, color: 'error.main' }}>
          {errMsg}
        </Typography>
        <TextField name="email" label={t('Email Address')} onChange={(e) => setEmail(e.target.value)} value={email} />
        <TextField
          name="password"
          label={t('Password')}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <br />
      <Link variant="subtitle2" underline="hover" component={RouterLink} to="/AWSCognitoMobileSignUp">
        {t('Sign Up')}?
      </Link>

      <LoadingButton
        fullWidth
        size="large"
        variant="contained"
        onClick={onSubmit}
        sx={{
          background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
          color: 'primary.contrastText',
          m: '5px',
          pl: '10px',
          pr: '10px',
          width: '470px',
        }}
      >
        {t('Login')}
      </LoadingButton>
      {/* <LoginSocialFacebook
        appId="1186024868753153"
        onResolve={(e) => {
          console.log(e);
          setPage(true);
        }}
        onReject={(e) => {
          console.log(e);
          setErrMsg(e);
        }}
      >
        <FacebookLoginButton />
      </LoginSocialFacebook> */}
      {/* <LoadingButton fullWidth variant="contained" onClick={facebook} sx={{ background: '#4267B2', mt: 1 }}>
        Facebook Login
      </LoadingButton> */}
      <LoginSocialGoogle
        client_id={'849973756911-vcrj6uki39rk368o38k7grrje2sm6dbf.apps.googleusercontent.com'}
        scope="profile email openid"
        // discoveryDocs="claims_supported"
        // access_type="offline"
        // typeResponse="idToken"
        onResolve={(e) => {
          console.log(e);
          setPage(true);
        }}
        onReject={(e) => {
          console.log(e);
          setErrMsg(e);
        }}
      >
        <GoogleLoginButton />
      </LoginSocialGoogle>
      {/* <LoadingButton fullWidth variant="contained" onClick={google1} sx={{ background: '#DB4437', mt: 1 }}>
        Google Login
      </LoadingButton> */}
      {/* <div id="signInDiv"></div>
      <button onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}>
        {t('Open Google')}
      </button> */}
      <br />
      <Link variant="subtitle2" underline="hover" component={RouterLink} to="/AWSCognitoLogin">
        {t('Back')}
      </Link>
    </FormProvider>
  );
}
export { socket1 };
