import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  Dialog,
  MenuItem,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { buttonR, buttonL } from '../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../components/customIcon/Close.svg';
import Warning from '../../../../../../../components/customIcon/icon-warning-24.svg';
import { Charger_DeleteStation } from '../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function DeleteStationDialog(props) {
  const { t } = useTranslation();
  const { stationData } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    console.log('delete station', stationData.cs_id, stationData);
    Charger_DeleteStation(JSON.stringify({ cs_id: stationData.cs_id }), setError, setErrorMessage).then(
      (promiseResult) => {
        if (promiseResult.category === 'Success') {
          props.DeleteUpdate(Math.random());
          props.DeleteClose(false);
          props.setSuccess(true);
          props.closeAll();
          handleClose();
        } else {
          props.setErrorMessage(promiseResult.response?.data?.message);
          props.setError(true);
        }
        setLoading(false);
      }
    );
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>{t('Delete Station')}</MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="DeleteStationDialog">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="DeleteStation">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Box component="img" src={Warning} sx={{ width: '24px', height: '24px', mr: '5px' }} />
              {t('Delete Station')}
            </Stack>
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ pl: 1, pr: 1, pb: 2 }}>
            {t('tip_delete', { stationName: stationData.name })}
            {/* Are you sure you want to delete {stationData.name} ? */}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <LoadingButton variant="outlined" onClick={handleClose} sx={buttonL}>
              {t('Back')}
            </LoadingButton>
            <LoadingButton onClick={onSubmit} disabled={loading} sx={buttonR}>
              {t('Delete')}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
