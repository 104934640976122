import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function English() {
  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          Personal Data and Privacy Protection Policy
        </AccordionSummary>
        <AccordionDetails>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              1. Personal Data and Privacy Protection
            </AccordionSummary>
            <AccordionDetails>
              <div className="description">
                In relation to your personal information, this company will protect it in accordance with the law, and
                the details are as follows:{' '}
              </div>
              <div className="description">(1) Personal Data Protection</div>
              <div className="description">
                1. Source of Personal Data Collection: This company will obtain personal information provided by the
                party involved, including company name, name, email, phone number, mobile phone, address, and other
                relevant necessary information (such as shipping address) for configuration. The App will send the
                initial password through the Email you provide upon input. Once you activate the login, you can use this
                App.{' '}
              </div>
              <div className="description">
                2. Data Security and Protection Methods: The query system of this App is equipped with a firewall to
                prevent unauthorized access. All transmitted personal data is encrypted (using the HTTPS encryption
                protocol) to prevent illegal exploitation during the transmission process.{' '}
              </div>
              <div className="description">3. Purpose of Personal Data Collection:</div>
              <div className="description">
                (1) The need for business purposes such as obtaining permits from competent authorities, business
                registration items, or the scope of business as defined in the articles of incorporation.
              </div>
              <div className="description">
                (2) Collection, processing, and utilization of personal data in accordance with legal provisions,
                statutory obligations, contracts, or other legal relationships.{' '}
              </div>
              <div className="description">
                (3) For business and technical information, customer management, information and database management,
                statistical surveys, and analysis purposes.
              </div>
              <div className="description">4. Use of Personal Data</div>
              <div className="description">
                (1) With regard to the personal information you register or provide, you agree that this company may use
                it for the purpose of providing charging services.
              </div>
              <div className="description">
                (2) This company processes and utilizes your personal data only for specific purposes and within the
                scope prescribed by relevant laws and regulations. Unless obtaining your consent or as specifically
                required by other laws, this company will never disclose your personal information to third parties or
                use it for purposes other than the originally stated collection purposes.
              </div>
              <div className="description">
                5. Duration, Region, Recipients, and Methods of Personal Data Utilization
              </div>
              <div className="description">
                (1) Duration: This company will continue to use the personal data you provide until you request to stop
                the use or until the date when this company terminates the provision of the App service.
              </div>
              <div className="description">
                (2) Region: The regions where this company, institutions (entities) with which this company has
                contracted for business needs, or cooperating partners are located.
              </div>
              <div className="description">
                "(3) Recipients: This company, institutions (entities) with which this company has contracted or engaged
                in business dealings due to business needs, or cooperating partners."
              </div>
              <div className="description">
                "(4) Methods: Utilized in accordance with the collection's specific purposes and within the scope
                prescribed by laws and regulations."{' '}
              </div>
              <div className="description">6. Rights of Data Subjects</div>
              <div className="description">
                (1) Except for exceptions specified in Article 10 of the Personal Data Protection Act, individuals may
                request inquiries, access requests, and requests for copies from this company in writing. However, this
                company may charge necessary costs in accordance with Article 14 of the Personal Data Protection Act.
              </div>
              <div className="description">
                (2) You can instantly review your personal data or modify your personal data file (including but not
                limited to login password, shipping address) in this App.
              </div>
              <div className="description">
                (3) If you wish to request the cessation or deletion of your personal data, please provide a written
                request specifying your desire to delete personal data, along with your contact information. Send it by
                registered mail to the customer service center of this company, and mark 'Confidential' on the envelope.
                If you choose to stop processing or using certain personal data, the company may be unable to provide
                complete services if the information provided is insufficient or incorrect.
              </div>
              <div className="description">7. Protection of Personal Data</div>
              <div className="description">
                (1) This company will store your personal data securely in our data storage system, implementing
                rigorous protective measures to prevent unauthorized access. Our personnel undergo comprehensive
                information security education, and relevant staff members are bound by confidentiality agreements. Any
                violation of confidentiality obligations will result in disciplinary action in accordance with relevant
                laws and internal company regulations.
              </div>
              <div className="description">
                (2) In order to ensure the integrity and security of your personal data, the data processing systems
                storing your personal information are equipped with various information security devices and necessary
                security measures to safeguard against unauthorized access or destruction of your personal data.
              </div>
              <div className="description">
                (3) If it is necessary to entrust third parties to provide services due to business needs, this company
                will strictly require them to adhere to confidentiality obligations. Necessary inspection procedures
                will also be implemented to ensure their compliance.
              </div>
              <div className="description">
                (2) Privacy Protection: The records, personal data, and other specific information you register and use
                in this App are all protected and regulated in accordance with this Privacy Policy.{' '}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              2. Data Storage and Compliance Requirements of this App
            </AccordionSummary>
            <AccordionDetails>
              <div className="description">
                (1) The identity verification or authentication data used by you in this App will serve as the basis for
                your inquiries. You are obligated to properly safeguard your account and password, and you must not
                disclose them to any third party. Any input of verification data and login data in accordance with the
                methods specified by this App, regardless of whether it is entered by the person themselves, will be
                considered as being used by the account owner.{' '}
              </div>
              <div className="description">
                (2) If you discover that your account and password have been stolen or improperly used by others, you
                must immediately notify the company's customer service hotline at +65-63495456,5456, so that the company
                can take appropriate protective measures.
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              3. User Obligations and Commitments
            </AccordionSummary>
            <AccordionDetails>
              <div className="description">
                (1) In addition to complying with the provisions of the service terms of this App, you agree to adhere
                to the relevant laws and regulations of the Republic of China, various service operating regulations of
                this company, and the relevant provisions of communication network international conventions and
                etiquette. You also agree not to engage in the following behaviors:{' '}
              </div>
              <div className="description">1. Theft, alteration, or destruction of others' information.</div>
              <div className="description">2. Transmission or dissemination of mobile or computer viruses.</div>
              <div className="description">3. Impersonation of others for the use of this App.</div>
              <div className="description">
                4. Any other behavior inconsistent with the intended use of this App or deemed inappropriate by the
                company for valid reasons.
              </div>
              <div className="description">
                (2) If the company becomes aware, or if others report that you have violated any of the above provisions
                or are suspected of doing so, the company has the right to terminate or suspend your use of various
                services in this App. If you disagree with the termination or suspension, you may notify the company. In
                the event of the termination or suspension of your use of the service, the company shall not be
                responsible to you or any third party. In addition to assuming legal responsibility, you shall be liable
                for compensation for any damage or loss suffered by the company, its affiliated companies, employees,
                agents, and other relevant assisting parties (including but not limited to litigation expenses and
                attorney fees incurred in civil, criminal, and administrative proceedings).{' '}
              </div>
              <div className="description">
                (3) If you use this service and are required to pay additional fees or information service fees as
                agreed, you agree to comply with the company's relevant service fee regulations and explanations.{' '}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              4. Service Suspension or Interruption
            </AccordionSummary>
            <AccordionDetails>
              <div className="description">
                The company will reasonably maintain the normal operation of this App through appropriate methods and
                technologies. However, in the event of any of the following circumstances, the company may suspend or
                interrupt all or part of the services in this App, without assuming any compensation liability to you:{' '}
              </div>
              <div className="description">
                1. When migrating, replacing, or maintaining the related system equipment of this App.{' '}
              </div>
              <div className="description">
                2. When the suspension or interruption of this App service is caused by reasons beyond the control of
                the company.
              </div>
              <div className="description">
                3. When necessary maintenance and construction work is carried out on electronic communication
                equipment.
              </div>
              <div className="description">
                4. In the event of sudden failure of electronic communication equipment.
              </div>
              <div className="description">
                5. When your service information is displayed incorrectly, or is counterfeited, tampered with, deleted,
                or extracted due to reasons beyond the control of the company.{' '}
              </div>
              <div className="description">
                6. In the event of any violation of government laws or these service terms by you.
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              5. Intellectual Property Rights
            </AccordionSummary>
            <AccordionDetails>
              <div className="description">
                (1) The software or programs used in this App, as well as all content on the mobile communication
                webpage, are owned by the company or other rights holders in accordance with the law. No one may use,
                modify, reproduce, publicly broadcast, adapt, distribute, publish, publicly disclose, conduct reverse
                engineering, decompile, or disassemble them without authorization. If you wish to quote or reproduce,
                you must obtain prior written consent from the company or other rights holders in accordance with the
                law. Respecting intellectual property rights is your obligation. In case of violation, you shall be
                liable for damages to the company, including but not limited to litigation expenses and attorney fees.{' '}
              </div>
              <div className="description">
                (2) In accordance with the principle of respecting the intellectual property rights of others, you agree
                not to engage in any activities that infringe upon the intellectual property rights of others when using
                this service.{' '}
              </div>
              <div className="description">
                (3) If you are involved in any infringement, the company has the right to cancel and immediately cease
                this App.
              </div>
              <div className="description">
                (4) If you discover any infringement of intellectual property rights, please report the situation and
                provide contact information, along with a truthful statement and a declaration of ownership of legal
                intellectual property rights. Please call the customer service hotline at +65-63495456,5456, and a
                dedicated person will assist you.{' '}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              6. Applicable Law and Jurisdiction
            </AccordionSummary>
            <AccordionDetails>
              <div className="description">
                (1) The interpretation and application of these service terms shall be governed by the laws of the
                Republic of China.
              </div>
              <div className="description">
                (2) In the event of any disputes arising between you and the company due to the use of this App, it is
                agreed to resolve the disputes in good faith. If litigation is necessary, it is agreed that the Taipei
                District Court shall have jurisdiction as the court of first instance.
              </div>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
