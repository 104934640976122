import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
// mui
import {
  Box,
  Stack,
  Table,
  Button,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  tableCellClasses,
  Tooltip,
  IconButton,
} from '@mui/material';
import Icon from '../../../../../components/customIcon/Union.svg';
import { useTranslation } from 'react-i18next';
import RowDelete from './tableRowDelete';

// ----------------------------------------------------------------------

export default function tariffTable({
  transdata,
  setSuccess,
  updateList,
  setErrorMessage,
  setError,
  setPageFunc,
  setPropsData,
  setChangePage,
}) {
  const { t } = useTranslation();

  const [dbdata, setDbdata] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const columns = [
    { field: 'name', headerName: t('Profile Name'), align: 'left', width: 80 },
    { field: 'No_cs', headerName: t('No. of Charging Stations'), align: 'center', width: 80 },
    { field: 'platform_fee', headerName: t('Platform Fee'), align: 'center', width: 100 },
    { field: 'charging_time', headerName: t('Charging Time'), align: 'center', width: 100 },
    { field: 'energy', headerName: t('Energy'), align: 'center', width: 100 },
    { field: 'reservation', headerName: t('Reservation/Expiry'), align: 'center', width: 100 },
    { field: 'last_modified', headerName: t('Last Modified'), align: 'center', width: 100 },
    { field: 'action', headerName: t('Actions'), align: 'center', width: 80 },
  ];
  useEffect(() => {
    if (transdata) {
      console.log(transdata);
      setDbdata(transdata);
    }
  }, [transdata]);
  const handleEdit = (row) => {
    // setOpenEdit(true);
    setChangePage('edit');
    setPageFunc('edit');
    setPropsData(row);
  };

  const add8hours = (originalDateString) => {
    const originalDate = new Date(originalDateString);
    originalDate.setHours(originalDate.getHours() + 8);

    // Format the date as "YYYY-MM-DD HH:mm:ss"
    const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')} ${originalDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${originalDate.getMinutes().toString().padStart(2, '0')}:${originalDate
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;

    return formattedDate;
  };
  const handelOverview = (info) => {
    setChangePage('summary');
    setPropsData(info);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell rowSpan={2} key="name" sx={{ width: '200px' }}>
              {t('Profile Name')}
            </StyledTableCell>
            <StyledTableCell rowSpan={2} key="Stations" sx={{ width: '150px', textAlign: 'center' }}>
              {t('No. of Charging Stations')}
            </StyledTableCell>
            <StyledTableCell align="center" colSpan={4}>
              {t('Elements')}
            </StyledTableCell>
            <StyledTableCell align="center" rowSpan={2} key="last_modified" sx={{ ...sx, width: '150px' }}>
              {t('Last Modified')}
            </StyledTableCell>
            <StyledTableCell align="center" rowSpan={2} key="action" sx={{ ...sx, width: '150px' }}>
              {t('Actions')}
            </StyledTableCell>
          </TableRow>
          <TableRow>
            {columns.slice(2, 6).map((column) => (
              <StyledTableCell align={column.align} sx={{ width: column.width }} key={column.headerName}>
                {column.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dbdata.map((row, index) => (
            <StyledTableRow key={index} hover>
              {/* sx={{ color: '#00ACBB !important' }} */}
              <StyledTableCell>
                <span
                  title={t('Overview')}
                  onClick={() => handelOverview(row)}
                  style={{ color: '#00ACBB', cursor: 'pointer' }}
                >
                  {row.name}
                </span>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ ...sx, width: '160px' }}>
                {row.no_of_cs}
              </StyledTableCell>
              <StyledTableCell align="center" sx={sx}>
                {row.elements.filter((item) => item.eleType === 'elements_platForm').length || 0}
              </StyledTableCell>
              <StyledTableCell align="center" sx={sx}>
                {row.elements.filter((item) => item.eleType === 'elements_charging').length || 0}
              </StyledTableCell>
              <StyledTableCell align="center" sx={sx}>
                {row.elements.filter((item) => item.eleType === 'elements_energy').length || 0}
              </StyledTableCell>
              <StyledTableCell sx={sx} align="center">
                {row.elements.filter((item) => item.eleType.includes('Reservation') || item.eleType.includes('Expiry'))
                  .length || 0}
              </StyledTableCell>
              <StyledTableCell align="center" sx={sx}>
                {row.last_updated ? add8hours(row.last_updated) : '--'}
              </StyledTableCell>
              <StyledTableCell align="center" sx={sx}>
                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}></Stack>
                <Tooltip title={t('Edit')}>
                  <IconButton onClick={() => handleEdit(row)} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
                    <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
                  </IconButton>
                </Tooltip>
                <RowDelete
                  info={row}
                  update_List={updateList}
                  setSuccess={setSuccess}
                  setErrorMessage={setErrorMessage}
                  setError={setError}
                ></RowDelete>
                {/* <Tooltip title={t('asign')}>
                <IconButton onClick={handleAsign} sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
                    <Box component="img" src={moveIcon} sx={{ width: '100%', height: '100%' }} />
                </IconButton>
                </Tooltip> */}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {/* {openEdit ? (
        <EditTariff operation="edit" propsData={row} setOpenEdit={setOpenEdit} updateList={updateList}></EditTariff>
      ) : (
        ''
      )} */}
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#17262A',
    // color: '#F4F4F4',
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    // color: '#F4F4F4',
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));

const sx = {
  pt: '3.5px',
  pb: '3.5px',
  textAlign: '-webkit-center',
};
const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: '15px',
  '&:hover': {
    color: '#00B0B9',
    background: theme.palette.action.hover,
  },
}));
