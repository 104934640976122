import React, { createContext } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from './UserPool';
import { octtUser } from '../../../api/OCTT/OCTTuser';

// ----------------------------------------------------------------------

const AccountContext = createContext();
const success = '/pages/summary';
const octt = '/pages/octt';

const Account = (props) => {
  const getUserAttributes = (user) => {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
        } else {
          const results = {};
          for (let attribute of attributes) {
            const { Name, Value } = attribute;
            results[Name] = Value;
          }
          resolve(results);
        }
      });
    });
  };

  const getSession = async () => {
    try {
      const user = Pool.getCurrentUser();
      if (!user) {
        throw new Error('User not found.');
      }
      const session = await new Promise((resolve, reject) => {
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      });
      const attributes = await getUserAttributes(user);
      return { user, ...session, ...attributes };
    } catch (error) {
      return error;
    }
  };

  const authenticate = async (
    Username,
    Password,
    newPassword,
    setPage,
    setUsername,
    setFirstLogin,
    navigate,
    setConfirmUser
  ) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          sessionStorage.setItem('email', data.idToken.payload.email);
          sessionStorage.setItem('aws_token', data.idToken.jwtToken);
          sessionStorage.setItem('role', data.idToken.payload['custom:role']);
          sessionStorage.setItem('name', data.idToken.payload.name);
          // sessionStorage.setItem('plan_role', data.idToken.payload['custom:plan']);
          sessionStorage.setItem('plan_role', 'Standard');
          sessionStorage.setItem('tenant_id', data.idToken.payload['custom:tenant_id']);
          // sessionStorage.setItem('plan_role', 'Essential');
          if (data.idToken.payload.email_verified === true) {
            if (data.idToken.payload.email === octtUser) {
              navigate(octt, { replace: true });
            } else {
              navigate(success, { replace: true });
            }
          } else {
            // console.log('email not verified');
            user.getAttributeVerificationCode('email', {
              onSuccess: () => {
                // console.log('Verification code sent successfully');
                setPage(true);
                setUsername(Username);
              },
              onFailure: (err) => {
                // console.log('Failed to send verification code:', err);
                reject(err);
              },
            });
          }
          resolve(data);
        },
        newPasswordRequired: (data) => {
          // console.log('newPasswordRequired: ', data);
          sessionStorage.setItem('email', data.email);
          setFirstLogin(true);
          user.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: (data) => {
                sessionStorage.setItem('email', data.idToken.payload.email);
                sessionStorage.setItem('aws_token', data.idToken.jwtToken);
                sessionStorage.setItem('role', data.idToken.payload['custom:role']);
                sessionStorage.setItem('name', data.idToken.payload.name);
                sessionStorage.setItem('plan_role', 'Standard');
                sessionStorage.setItem('tenant_id', data.idToken.payload['custom:tenant_id']);
                if (data.idToken.payload.email_verified === true) {
                  if (data.idToken.payload.email === octtUser) {
                    navigate(octt, { replace: true });
                  } else {
                    navigate(success, { replace: true });
                  }
                } else {
                  // console.log('email not verified');
                  user.getAttributeVerificationCode('email', {
                    onSuccess: () => {
                      // console.log('Verification code sent successfully');
                      setPage(true);
                      setUsername(Username);
                    },
                    onFailure: (err) => {
                      // console.log('Failed to send verification code:', err);
                      reject(err);
                    },
                  });
                }
                resolve(data);
              },
              onFailure: (err) => {
                reject(err);
              },
            }
          );
          resolve(data);
        },
        onFailure: (err) => {
          // console.log('onFailure: ', err);
          if (err.code === 'UserNotConfirmedException') {
            setConfirmUser(true);
            resendConfirmationCode(Username);
          }
          reject(err);
        },
      });
    });
  };

  const resendConfirmationCode = (Username) => {
    const user = new CognitoUser({ Username, Pool });
    // Resend the confirmation code
    user.resendConfirmationCode((err, result) => {
      if (err) {
        console.error(err);
        return;
      }
      // console.log('Confirmation code resent successfully');
    });
  };

  const confirmRegistration = (Username, confirmationCode) => {
    const user = new CognitoUser({ Username, Pool });
    return new Promise((resolve, reject) => {
      user.confirmRegistration(confirmationCode, true, (err, result) => {
        if (err) {
          reject(err);
        }
        resolve(result);
      });
    });
  };

  // const privacyDelete = async (Username, Password) => {
  //   return await new Promise((resolve, reject) => {
  //     const user = new CognitoUser({ Username, Pool });
  //     const authDetails = new AuthenticationDetails({ Username, Password });

  //     user.authenticateUser(authDetails, {
  //       onSuccess: (data) => {
  //         user.getSession(function (err, session) {
  //           if (err) {
  //             console.error(err);
  //             reject(err);
  //           }

  //           // session validity check
  //           console.log('session validity: ' + session.isValid());

  //           user.deleteUser((err, result) => {
  //             if (err) {
  //               console.error(err);
  //               reject(err);
  //             } else {
  //               console.log('Successfully deleted the user.');
  //               console.log(result);
  //               resolve(result);
  //             }
  //           });
  //         });
  //         resolve(data);
  //       },
  //       onFailure: (err) => {
  //         // console.log('onFailure: ', err);
  //         if (err.code === 'UserNotConfirmedException') {
  //           setConfirmUser(true);
  //           resendConfirmationCode(Username);
  //         }
  //         reject(err);
  //       },
  //     });
  //   });
  // };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession((err) => {
        if (err) {
          console.error(err);
          return;
        }
        user.globalSignOut({
          onSuccess: (result) => {
            console.log('Successfully Global Sign Out');
          },
          onFailure: (err) => {
            console.error(err);
          },
        });
      });
    }
  };
  return (
    <AccountContext.Provider value={{ authenticate, getSession, logout, confirmRegistration }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
