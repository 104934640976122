import React, { useContext, useEffect, useState, useImperativeHandle } from 'react';
import { TextField, Menu, MenuItem, Stack, Snackbar, Alert, LinearProgress, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../../App';
import { fetchChargerStation, fetchEVSECSS, fetchStationStatus } from '../../../../api/AllAPI';

// ----------------------------------------------------------------------

export default function leftInfo(props) {
  const { t } = useTranslation();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  const [locArrs, setLocArrs] = useState([]);
  const [stationArrs, setStationArrs] = useState([]);
  const [dbdata, setDbdata] = useState([]);
  const [evseData, setEvseData] = useState([]);
  const [totalEvse, setTotalEvse] = useState(0);
  const theme = useTheme();
  const [formData, setFormData] = useState({
    location: '',
    station: '',
  });

  const defalutArr = [
    {
      label: 'Available',
      value: 0,
    },
    {
      label: 'In Used',
      value: 0,
    },
    {
      label: 'Faulty',
      value: 0,
    },
    {
      label: 'Unavailable',
      value: 0,
    },
    {
      label: 'Reserved',
      value: 0,
    },
    {
      label: 'Offline',
      value: 0,
    },
    // {
    //   label: 'Removed',
    //   value: 0,
    // },
  ];
  const [leg_list, setLeg_list] = useState(defalutArr);
  useImperativeHandle(props.onRef, () => {
    return {
      refreshDiagram,
    };
  });

  // refreshDiagram
  const refreshDiagram = () => {
    searchEvse(formData.station);
  };
  const handleChange_loc = (e) => {
    const { name, value } = e.target;
    setStationArrs(dbdata[value]);
    setFormData({ ...formData, location: value, station: dbdata[value][0]?.cs_id ? dbdata[value][0]?.cs_id : '' });
    searchEvse(dbdata[value][0]?.name ? dbdata[value][0]?.name : '');
    searchStationStatus(dbdata[value][0]?.cs_id ? dbdata[value][0]?.cs_id : '');
    props.handleEvseClick();
  };
  const handleChange_station = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setFormData({ ...formData, station: value });
    searchEvse(stationArrs.filter((item) => item.cs_id == value)[0]?.name);

    searchStationStatus(value);
    props.handleEvseClick();
  };
  const searchEvse = (cs_name) => {
    if (cs_name) {
      props.setLoading_pro(true);
      fetchEVSECSS(JSON.stringify({ cs_name: cs_name }), props.setError, props.setErrorMessage).then(
        (promiseResult) => {
          props.setLoading_pro(false);
          if (promiseResult) {
            props.setEvseArrData(promiseResult);
            setEvseData(promiseResult.evse);
            props.setSuccess(true);
          } else {
            props.setEvseArrData({ evse: [] });
            setEvseData([]);
          }
        }
      );
    } else {
      props.setEvseArrData({ evse: [] });
      setEvseData([]);
    }
  };
  const searchStationStatus = (cs_id) => {
    if (cs_id) {
      props.setLoading_pro(true);
      fetchStationStatus(JSON.stringify({ cs_id }), props.setError, props.setErrorMessage).then((promiseResult) => {
        // props.setLoading_pro(false);
        if (promiseResult.data) {
          // setLeg_list(promiseResult.data?.status.filter((item) => item.label != 'Removed'));
          setLeg_list(
            promiseResult.data?.status.map((item) => {
              let trans = item;
              if (item.label == 'Unknown') {
                trans.label = 'Offline';
                trans.value = item.value;
              }
              return trans;
            })
          );
          setTotalEvse(promiseResult.data?.total_evse);
        } else {
          setLeg_list(defalutArr);
        }
      });
    } else {
      setLeg_list(defalutArr);
    }
  };
  useEffect(() => {
    const stationData = sessionStorage.getItem('changer_diagram_stationData')
      ? JSON.parse(sessionStorage.getItem('changer_diagram_stationData'))
      : '';
    const locationData = sessionStorage.getItem('changer_diagram_locationData')
      ? JSON.parse(sessionStorage.getItem('changer_diagram_locationData'))
      : '';
    const evseData = sessionStorage.getItem('changer_diagram_evseData')
      ? JSON.parse(sessionStorage.getItem('changer_diagram_evseData'))
      : '';

    props.setLoading_pro(true);
    setDbdata([]);
    props.handleEvseClick();
    fetchChargerStation(props.setError, props.setErrorMessage, props.setUnauthorized).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        const data = promiseResult.data.chargerstation_data;
        const locData = [];
        const staData = {};
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          locData.push({
            id: element.id,
            name: element.name,
          });
          staData[element.id] = element.station;
        }
        setDbdata(staData);
        setLocArrs(locData);
        if (stationData && locationData) {
          //click from Table View
          setStationArrs(staData[locationData.id]);
          setFormData({
            ...formData,
            location: locationData.id,
            station: stationData.cs_id,
          });
          searchStationStatus(stationData.cs_id);
        } else if (stationData && !locationData) {
          // click from Map View
          const cs_idToFind = stationData.cs_id;
          let locationId = null;
          Object.keys(staData).some((locationIdString) => {
            locationId = parseInt(locationIdString);
            const stationData = staData[locationId];
            const foundStation = stationData.find((staData) => staData.cs_id === cs_idToFind);
            if (foundStation) {
              return true;
            }
            return false;
          });

          setStationArrs(staData[locationId]);
          setFormData({
            ...formData,
            location: locationId,
            station: stationData.cs_id,
          });
          searchStationStatus(stationData.cs_id);
          searchEvse(stationData.name);
        } else {
          setStationArrs(locData[0] ? staData[locData[0].id] : []);
          setFormData({
            ...formData,
            location: locData[0] ? locData[0].id : '',
            station: locData[0] ? staData[locData[0].id][0].cs_id : '',
          });
          searchStationStatus(locData[0] ? staData[locData[0].id][0].cs_id : '');
        }
        if (evseData) {
          props.setEvseArrData(evseData);
          setEvseData(evseData.evse);
        } else if (!stationData && !evseData) {
          //click from diagram
          searchEvse(locData[0] ? staData[locData[0].id][0].name : '');
        }
        // props.setSuccess(true);
        // props.setLoading_pro(false);
        props.setUnauthorized(false);
      } else {
        props.setUnauthorized(false);
        props.setError(true);
        props.setLoading_pro(false);
        props.setErrorMessage(promiseResult.message);
        if (promiseResult.message == 'Network Error') {
          props.setUnauthorized(true);
        }
      }
      props.setLoading_pro(false);
    });
  }, []);
  const leg_colors = {
    Available: theme.palette.available.main,
    'In Used': theme.palette.inuse.main,
    Faulty: theme.palette.faulty.main,
    Unavailable: theme.palette.unavailable.main,
    Reserved: theme.palette.reserve.main,
    Unknown: theme.palette.unknown.main,
    Offline: theme.palette.unknown.main,
    Removed: theme.palette.removed.main,
  };
  return (
    <>
      <form
        style={{
          right: '0',
          top: '3px',
          width: '400px',
          height: '20%',
          position: 'absolute',
          // display: props.panelShow ? 'block' : 'none',
          display: props.allowOprate.includes('clean') ? 'none' : props.panelShow ? 'block' : 'none',
        }}
      >
        <TextField
          margin="dense"
          select
          fullWidth
          name="location"
          label={<>{t('Location')}</>}
          value={formData.location}
          variant="filled"
          onChange={(e) => {
            handleChange_loc(e);
          }}
          size="small"
          InputProps={{
            style: {
              backgroundColor: theme.palette.background.pagerWhite,
              marginBottom: '-5px',
            },
          }}
        >
          {locArrs.map((option) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          margin="dense"
          select
          fullWidth
          name="station"
          label={
            <>
              {/* <span style={{ color: 'red' }}>* </span> */}
              {t('Station')}
            </>
          }
          value={formData.station}
          onChange={(e) => {
            handleChange_station(e);
          }}
          size="small"
          variant="filled"
          InputProps={{
            style: {
              backgroundColor: theme.palette.background.pagerWhite,
              marginBottom: '3px',
            },
          }}
        >
          {stationArrs.map((option) => {
            return (
              <MenuItem key={option.cs_id} value={option.cs_id} label={option.name}>
                {option.name}
              </MenuItem>
            );
          })}
        </TextField>
        {/* station status */}
        <Box>
          <Box
            sx={{
              textAlign: 'center',
              lineHeight: '48px',
              backgroundColor: theme.palette.background.pagerWhite,
              height: '48px',
            }}
          >
            {t('Station Status')}
          </Box>
          <Box
            display="flex"
            sx={{
              backgroundColor: theme.palette.background.paper2,
              // height: '48px',
              padding: '10px',
            }}
          >
            <Stack flex={1} textAlign="center">
              <p style={{ fontSize: '22px' }}>{evseData ? evseData.length : 0}</p>
              {/* <p style={{ fontSize: '22px' }}>{totalEvse}</p> */}
              <p style={{ color: theme.palette.title.main }}>{t('Total No. of EVSE(s)')}</p>
            </Stack>
            {/* <Stack flex={1} textAlign="center">
              <p style={{ fontSize: '22px' }}>10.0 kW</p>
              <p style={{ color: theme.palette.title.main }}>{t('Total Rated Power')}</p>
            </Stack> */}
          </Box>

          {leg_list.map((item, ind) => (
            <Box
              padding="5px 25px"
              display="flex"
              justifyContent="space-between"
              backgroundColor={theme.palette.background.paper2}
              key={ind}
            >
              <Box display="flex" alignItems="center">
                <p
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '100%',
                    marginRight: '10px',
                    background: leg_colors[item.label],
                  }}
                ></p>
                <p>{t(item.label)}</p>
              </Box>
              <p>{item.value}</p>
            </Box>
          ))}
        </Box>
      </form>
    </>
  );
}
