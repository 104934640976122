import React, { useState, useImperativeHandle, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
// mui
import {
  Box,
  Switch,
  Button,
  Dialog,
  Select,
  Tooltip,
  MenuItem,
  TextField,
  IconButton,
  InputLabel,
  Typography,
  FormControl,
  Chip,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
// import {  Charger_AddStation } from '../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import { verify_inputNumber, verify_inputNumber_null } from '../../../../../../../../../components/verification';
// ----------------------------------------------------------------------

export default function sourceModel({
  childRef,
  sourceData,
  setOpenAlertMsg,
  setOpenAlert,
  componentIndex,
  handleRemoveForm,
  index,
}) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  useImperativeHandle(childRef, () => ({
    onSubmit,
  }));
  const sourcesOptions = [
    { value: t('NUCLEAR'), id: 'NUCLEAR' },
    { value: t('GENERAL_FOSSIL'), id: 'GENERAL_FOSSIL' },
    { value: t('GAS'), id: 'GAS' },
    { value: t('GENERAL_GREEN'), id: 'GENERAL_GREEN' },
    { value: t('SOLAR'), id: 'SOLAR' },
    { value: t('WIND'), id: 'WIND' },
    { value: t('WATER'), id: 'WATER' },
  ];
  const [formData, setFormData] = useState({
    source: '',
    percentage: '',
    isVisible: true,
  });

  useEffect(() => {
    if (sourceData) {
      setFormData(sourceData);
    }
  }, [sourceData]);
  const [errors, setErrors] = useState({
    source: '',
    percentage: '',
  });

  const handleChange_after = (e) => {
    const { name, value } = e.target;
    if (name == 'source') {
      setFormData({
        ...formData,
        [name]: value,
      });
      value !== '' ? setErrors({ ...errors, [name]: '' }) : null;
      return;
    }

    // let errorMessage = '';
    if (name === 'percentage') {
      let verify = verify_inputNumber_null(value.trim(), formData.percentage);
      if (verify.error == '') {
        const numericValue = Number(verify.value);
        if (numericValue < 0 || numericValue > 100) {
          // errorMessage = t('Percentage must be between 0 and 100');
          setOpenAlertMsg(t('Percentage must be between 0 and 100'));
          setOpenAlert(true);
          setErrors({ ...errors, source: '' });
          return;
        }
      }
      setFormData((prevData) => ({
        ...prevData,
        percentage: verify.value,
        // percentage: verify.value == undefined ? '' : !isNaN(Number(verify.value)) ? Number(verify.value) : verify.value,
      }));
      // setErrors({ ...errors, source: '', percentage: verify.value == undefined ? '' : verify.error });
      setErrors({ ...errors, source: '', percentage: verify.error });
    }
  };

  const onSubmit = () => {
    // debugger;
    // Not empty verify
    const trans = formData;
    // if (Object.keys(trans).length == 0) {
    //   setFormData({ source: '', percentage: "" });
    // }
    let hasError = false;
    for (const key in errors) {
      const er = errors;
      // error message
      if (er[key] !== '') {
        hasError = true;
      }
    }
    if (trans.source == '' && trans.percentage != '' && trans.percentage != 0 && trans.percentage != null) {
      setErrors({ ...errors, source: t('required fields') });
      return false;
    }
    trans.percentage = Number(trans.percentage);
    // if (trans.source !== '' && trans.percentage == "") {
    //   setErrors({ ...errors, percentage: t('required fields') });
    //   return false;
    // }
    //  do not submit
    if (hasError) {
      return false;
    }

    return trans;
  };
  const remove = () => {
    handleRemoveForm(index, formData.id ? formData.id : '');
  };
  return (
    <Box
      sx={{
        background: theme.palette.popPaper.paper2,
        display: formData.isVisible != false ? 'block' : 'none',
        marginBottom: '15px',
        padding: '10px',
      }}
    >
      <div style={{ float: 'right', marginTop: '-10px' }}>
        <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Remove')} onClick={remove} />
      </div>
      <TextField
        margin="dense"
        select
        fullWidth
        name="source"
        label={
          <>
            {/* <span style={{ color: 'red' }}>* </span> */}
            {t('Source')}
          </>
        }
        value={formData.source ? formData.source : ''}
        onChange={(e) => {
          handleChange_after(e);
        }}
        size="small"
        error={!!errors.source}
        helperText={errors.source}
      >
        {sourcesOptions.map((option) => {
          return (
            <MenuItem key={option.id} value={option.id}>
              {option.value}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        margin="dense"
        size="small"
        name="percentage"
        // type="number"
        label={<>{t('Percentage')}</>}
        error={!!errors.percentage}
        helperText={errors.percentage}
        onChange={(e) => {
          handleChange_after(e);
        }}
        value={formData.percentage}
        fullWidth
        variant="outlined"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    </Box>
  );
}
