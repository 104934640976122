import React, { useState, useEffect, useRef, useContext } from 'react';
// mui
import {
  Box,
  Radio,
  Stack,
  Switch,
  Button,
  MenuItem,
  useTheme,
  FormLabel,
  TextField,
  Accordion,
  Typography,
  RadioGroup,
  FormControl,
  DialogContent,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// components
import { verify_inputNumber } from '../../../../../../../../components/verification';
import { buttonR, buttonL } from '../../../../../../../../components/button/buttonStyle';
import { ThemeContext } from '../../../../../../../../App';
import TimeComponet from './TimeComponet';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import img_grid from './image/Group 1252.svg';
import img_grid_light from './image/Group 1252.svg';
import img_station from './image/Group 1253.svg';
import img_station_light from './image/Group 1253.svg';
import img_evse from './image/Group 1260.svg';
import img_evse_light from './image/Group 1260.svg';
import img_line from './image/Vector 946.svg';
import { useTranslation } from 'react-i18next';
import { ListSmartCharging, AddSmartCharging, EditSmartCharging } from '../../../../../../../../api/AllAPI';

export default function SmartCharging(props) {
  const { t } = useTranslation();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  const theme = useTheme();
  // Form Data
  const [formData, setFormData] = useState({
    station_breaker_voltage: '400',
    station_breaker_phase: '',
    station_breaker_amp: '100',
    station_breaker_config: true,
    grid_source_voltage: '400',
    grid_source_phase: '',
    grid_source_amp: '100',
    grid_source_config: false,
    renewable_config: false,
    formData: 'female',
    charging_station_config: false,
    allow_export: 'Yes',
  });
  const [timeHasError, setTimeHasError] = useState(false);
  const [errors, setErrors] = useState({
    grid_source_phase: '',
    station_breaker_phase: '',
  });
  const [errorsOption, setErrorsOption] = useState({
    grid_source_voltage: '',
    grid_source_amp: '',
    station_breaker_voltage: '',
    station_breaker_amp: '',
  });
  // Click add button to add a time componet
  const componentRefs = useRef([]);
  const [componentCount, setComponentCount] = useState(1);
  const [components, setComponents] = useState([1]);
  const [time_keep_Data, setTime_keep_Data] = useState([]);
  const [addOrEdit, setAddOrEdit] = useState('add');

  useEffect(() => {
    props.setLoading_pre(true);
    ListSmartCharging({ cs_id: props.stationID }, props.setError, props.setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        const data = promiseResult.data;
        setFormData({
          station_breaker_voltage: data.station_breaker_voltage ? data.station_breaker_voltage : '400',
          station_breaker_phase: data.station_breaker_phase ? data.station_breaker_phase : '',
          station_breaker_amp: data.station_breaker_amp ? data.station_breaker_amp : '100',
          grid_source_voltage: data.grid_source_voltage ? data.grid_source_voltage : '400',
          grid_source_phase: data.grid_source_phase ? data.grid_source_phase : '',
          grid_source_amp: data.grid_source_amp ? data.grid_source_amp : '100',
          charging_station_config: data.charging_station_config ? data.charging_station_config : false,
          renewable_config: data.renewable_config ? data.renewable_config : false,
          allow_export: data.allow_export == false ? 'No' : 'Yes', //true,false,null
          grid_source_config: data.grid_source_config ? true : false,
          station_breaker_config: data.station_breaker_config ? true : false,
        });
        if (!data.renewable_config && data.energy_cost == 0 && !data.charging_station_config) {
          setAddOrEdit('add');
        } else {
          setAddOrEdit('edit');
        }
        if (data.energy_cost.length == 0) {
          setTime_keep_Data([
            {
              start_time: '00:00',
              end_time: '00:00',
              price: 0.0,
            },
          ]);
        } else {
          setTime_keep_Data(data.energy_cost);
          setComponentCount(data.energy_cost.length);
          const componentsArray = [];
          data.energy_cost.forEach((ele, index) => {
            componentsArray.push(index + 1);
          });
          setComponents(componentsArray);
        }
      }
      props.setLoading_pre(false);
    });
  }, []);

  const handleChange = (event) => {
    const val = event.target.value;
    if (val < 0 || val === '') {
      setFormData({
        ...formData,
        [event.target.name]: '0',
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: val,
      });
    }
  };

  // handleKeyPress
  const handleKeyPress = (e) => {
    // not allowed input （.）and（e/E）
    if (e.key === '.' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };
  // handleSubmit
  const handleSubmit = (event) => {
    event.preventDefault();
    // not empty verify
    let hasError = false;
    let transData = {};
    if (formData.charging_station_config) {
      const er = errors;
      if (formData.grid_source_config) {
        if (formData.grid_source_phase == '' || formData.grid_source_phase == null) {
          er['grid_source_phase'] = t('required fields');
          setErrors({ ...errors, grid_source_phase: t('required fields') });
          hasError = true;
        } else {
          transData.grid_source_voltage = Number(formData.grid_source_voltage);
          transData.grid_source_amp = Number(formData.grid_source_amp);
          transData.grid_source_phase = formData.grid_source_phase;
        }
        transData.grid_source_config = formData.grid_source_config;
      } else {
        // transData.grid_source_voltage = 0;
        // transData.grid_source_phase = '';
        // transData.grid_source_amp = 0;
      }
      if (formData.station_breaker_config) {
        if (formData.station_breaker_phase == '' || formData.station_breaker_phase == null) {
          er['station_breaker_phase'] = t('required fields');
          setErrors({ ...errors, station_breaker_phase: t('required fields') });
          hasError = true;
        } else {
          transData.station_breaker_amp = Number(formData.station_breaker_amp);
          transData.station_breaker_voltage = Number(formData.station_breaker_voltage);
          transData.station_breaker_phase = formData.station_breaker_phase;
        }
        transData.station_breaker_config = formData.station_breaker_config;
      } else {
        // transData.station_breaker_amp = 0;
        // transData.station_breaker_phase = '';
        // transData.station_breaker_voltage = 0;
      }
      // transData.charging_station_config = formData.charging_station_config;
      // transData = { ...formData, ...transData };
    } else {
      // transData = {
      //   ...transData,
      //   charging_station_config: formData.charging_station_config,
      //   renewable_config: formData.renewable_config,
      // };
      // const data = { ...formData };
      // data.grid_source_voltage = 0;
      // data.grid_source_phase = '';
      // data.grid_source_amp = 0;
      // data.station_breaker_amp = 0;
      // data.station_breaker_phase = '';
      // data.station_breaker_voltage = 0;
      // transData = { ...transData, ...data };
    }
    transData.charging_station_config = formData.charging_station_config;
    if (formData.renewable_config) {
      if (formData.allow_export == 'Yes') {
        transData.renewable_re = 'max_re_usuage';
        transData.evse_charge_strat = 'amt';
        transData.allow_export = true;
      } else {
        transData.renewable_re = 'max_re_selling';
        transData.evse_charge_strat = '';
        transData.allow_export = false;
      }
    } else {
      transData.renewable_re = '';
      transData.evse_charge_strat = '';
    }
    transData.renewable_config = formData.renewable_config;

    const areAllValuesEmpty = Object.values(errorsOption).every((value) => value === '');
    // do not submit
    if (hasError || !areAllValuesEmpty) {
      return;
    }
    transData.energ;

    const tr = transData.energy_cost.filter((item, ind) => item.haserror);
    if (tr.length !== 0) {
      return;
    }
    transData.cs_id = props.stationID;
    // console.log('transData=>', transData);
    submitData(transData);
  };
  const submitData = (data) => {
    props.setLoading_pre(true);
    if (addOrEdit == 'add') {
      AddSmartCharging(data, props.setError, props.setErrorMessage).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          props.andleCloseMain();
          props.setSuccess(true);
        } else {
          props.setErrorMessage(promiseResult.response?.data?.message);
          props.setError(true);
        }
        props.setLoading_pre(false);
      });
    } else {
      EditSmartCharging(data, props.setError, props.setErrorMessage).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          props.andleCloseMain();
          props.setSuccess(true);
        } else {
          props.setErrorMessage(promiseResult.response?.data?.message);
          props.setError(true);
        }
        props.setLoading_pre(false);
      });
    }
  };

  // Electricity price part==================================================================
  // Click button addComponent function
  const addComponent = () => {
    setComponentCount(componentCount + 1);
    setComponents([...components, componentCount + 1]);
    // change fill data
    const allComponetData = getDataHandler();
    const saveData = allComponetData[allComponetData.length - 1].end_time;
    // var keepData=time_keep_Data
    allComponetData[allComponetData.length - 1].end_time = '';
    setTime_keep_Data([
      ...allComponetData,
      {
        start_time: '',
        end_time: saveData,
        price: 0.0,
      },
    ]);
  };
  // Click button deleteComponent function
  const deleteComponent = () => {
    if (componentCount > 1) {
      const allComponetData = getDataHandler();
      setComponentCount(componentCount - 1);
      setComponents(components.slice(0, -1));
      componentRefs.current.pop();
      // change fill data
      const saveData = allComponetData[allComponetData.length - 1].end_time;
      const data = allComponetData.slice(0, -1);
      data[data.length - 1].end_time = saveData;
      setTime_keep_Data(data);
    }
    console.log(componentRefs.current);
  };
  // get componet data   有错误还能提交？？？？？？？？？
  const getDataHandler = () => {
    const gData = [];
    componentRefs.current.forEach((ref) => {
      const data = ref.current.getData();
      // if (data.haserror) {
      //   setTimeHasError(true);
      // }
      gData.push(data);
      ref.current.getData(); // call componet function
    });

    return gData;
  };
  const getTriggleValue = (val, componentIndex) => {
    const allComponetData = getDataHandler();
    allComponetData[componentIndex].start_time = val;
    allComponetData[componentIndex - 1].end_time = val;
    setTime_keep_Data([...allComponetData]);
  };
  // ========================================================================

  return (
    <div>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {/* Electricity Price Config   */}
          <Accordion defaultExpanded style={{ marginBottom: '16px' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
              style={{ background: theme.palette.popPaper.paper2 }}
            >
              <Typography>{t('Electricity Price Config')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ background: theme.palette.background.tableRow }}>
              <Box style={{ marginTop: '0px' }}>
                <div style={{ padding: '0' }}>
                  {/* add time complate  */}
                  <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', right: '0', top: '13px' }}>
                      <AddCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Add')} onClick={addComponent} />
                      <RemoveCircleOutlineIcon
                        style={{ cursor: 'pointer' }}
                        title={t('Remove')}
                        onClick={deleteComponent}
                      />
                    </div>
                    <div style={{ marginRight: '50px' }}>
                      {components.map((index) => {
                        const ref = React.createRef();
                        componentRefs.current[index] = ref;
                        return (
                          <div
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                            key={index}
                          >
                            <TimeComponet
                              key={index}
                              componentIndex={index}
                              getTriggleValue={getTriggleValue}
                              time_keep_Data={time_keep_Data}
                              ref={ref}
                              setOpenAlertMsg={props.setOpenAlertMsg}
                              setOpenAlert={props.setOpenAlert}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/*  Charging Station Config */}
          <div style={{ padding: '5px 16px ', background: theme.palette.popPaper.paper2 }}>
            <span style={{ marginTop: '5px' }}>{t('Charging Station Config')}</span>
            <Switch
              checked={formData.charging_station_config}
              onChange={function (event) {
                const val = event.target.checked;
                val
                  ? setFormData({
                      ...formData,
                      [event.target.name]: val,
                    })
                  : setFormData({
                      ...formData,
                      grid_source_config: val,
                      station_breaker_config: val,
                      [event.target.name]: val,
                    });
              }}
              name="charging_station_config"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          <div style={{ display: formData.charging_station_config ? 'block' : 'none' }}>
            {/* Station Hardware Config */}
            <Accordion expanded={true} style={{ marginBottom: '0px' }}>
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                  background: theme.palette.background.tableRow,
                  border: 'none',
                }}
              >
                <Typography>{t('Station Hardware Config')}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ background: theme.palette.background.tableRow, padding: '0 30px' }}>
                <div
                  style={{
                    minwWidth: '350px',
                    overflow: 'auto',
                    height: '400px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  {/* Grid Source */}
                  <Box
                    width="180px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                    flexDirection="column"
                  >
                    <div>
                      <p>Grid Breaker</p>
                      <Switch
                        checked={formData.grid_source_config}
                        onChange={function (event) {
                          const val = event.target.checked;
                          const data = formData;
                          if (!val) {
                            data.grid_source_amp = '100';
                            data.grid_source_phase = '';
                            data.grid_source_voltage = '400';
                          }
                          setFormData({
                            ...data,
                            [event.target.name]: val,
                          });
                        }}
                        name="grid_source_config"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </div>
                    <Box
                      component="img"
                      src={darkTheme ? img_grid : img_grid_light}
                      sx={{ height: '160px', minWidth: '100%', minWidth: '90px' }}
                    />
                    <div
                      style={{
                        display: formData.grid_source_config ? 'grid' : 'none',
                      }}
                    >
                      <TextField
                        margin="dense"
                        select
                        name="grid_source_phase"
                        label={
                          <>
                            <span style={{ color: 'red' }}>* </span>
                            {t('Grid Source Phase')}
                          </>
                        }
                        value={formData.grid_source_phase}
                        error={!!errors.grid_source_phase}
                        helperText={errors.grid_source_phase}
                        onChange={(e) => {
                          handleChange(e);
                          setErrors({ ...errors, grid_source_phase: '' });
                        }}
                        size="small"
                        // type="number"
                        style={{ width: '180px' }}
                      >
                        <MenuItem key="3-Phase" value="3-Phase">
                          {t('3-Phase')}
                        </MenuItem>
                        <MenuItem key="2-Phase" value="2-Phase">
                          {t('2-Phase')}
                        </MenuItem>
                        <MenuItem key="1-Phase" value="1-Phase">
                          {t('1-Phase')}
                        </MenuItem>
                      </TextField>
                      <TextField
                        margin="dense"
                        name="grid_source_voltage"
                        label={t('Grid Source Voltage')}
                        value={formData.grid_source_voltage}
                        onChange={(e) => {
                          let verify = verify_inputNumber(e.target.value.trim(), formData.grid_source_voltage);
                          setFormData({
                            ...formData,
                            grid_source_voltage: verify.value == undefined ? '' : verify.value,
                          });
                          setErrorsOption({
                            ...errorsOption,
                            grid_source_voltage: verify.value == undefined ? '' : verify.error,
                          });
                        }}
                        error={!!errorsOption.grid_source_voltage}
                        helperText={errorsOption.grid_source_voltage}
                        // defaultValue="400"
                        size="small"
                        // type="number"
                        onKeyPress={handleKeyPress}
                        style={{ width: '180px' }}
                      />
                      <TextField
                        margin="dense"
                        name="grid_source_amp"
                        label={t('Grid Source Amp')}
                        value={formData.grid_source_amp}
                        onChange={(e) => {
                          let verify = verify_inputNumber(e.target.value.trim(), formData.grid_source_amp);
                          setFormData({
                            ...formData,
                            grid_source_amp: verify.value,
                          });
                          setErrorsOption({
                            ...errorsOption,
                            grid_source_amp: verify.value == undefined ? '' : verify.error,
                          });
                        }}
                        error={!!errorsOption.grid_source_amp}
                        helperText={errorsOption.grid_source_amp}
                        // defaultValue="400"
                        size="small"
                        // type="number"
                        onKeyPress={handleKeyPress}
                        style={{ width: '180px' }}
                      />
                    </div>
                  </Box>
                  <Box component="img" src={img_line} sx={{ width: '120px', marginTop: '130px' }} />
                  {/* station_breaker_config */}
                  <Box
                    width="180px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                    flexDirection="column"
                  >
                    <div>
                      <p>Station Breaker</p>
                      <Switch
                        checked={formData.station_breaker_config}
                        onChange={function (event) {
                          const val = event.target.checked;
                          const data = formData;
                          setFormData({
                            ...data,
                            [event.target.name]: val,
                          });
                        }}
                        name="station_breaker_config"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </div>
                    <Box
                      component="img"
                      src={darkTheme ? img_station : img_station_light}
                      sx={{ minWidth: '90px', height: '160px', minWidth: '100%' }}
                    />
                    <div
                      style={{
                        display: formData.station_breaker_config ? 'grid' : 'none',
                      }}
                    >
                      <TextField
                        margin="dense"
                        select
                        name="station_breaker_phase"
                        label={
                          <>
                            <span style={{ color: 'red' }}>* </span>
                            {t('Station Breaker Phase')}
                          </>
                        }
                        value={formData.station_breaker_phase}
                        onChange={(e) => {
                          handleChange(e);
                          setErrors({ ...errors, station_breaker_phase: '' });
                        }}
                        style={{ width: '180px' }}
                        size="small"
                        // type="number"
                        error={!!errors.station_breaker_phase}
                        helperText={errors.station_breaker_phase}
                      >
                        <MenuItem key="3-Phase" value="3-Phase">
                          {t('3-Phase')}
                        </MenuItem>
                        <MenuItem key="2-Phase" value="2-Phase">
                          {t('2-Phase')}
                        </MenuItem>
                        <MenuItem key="1-Phase" value="1-Phase">
                          {t('1-Phase')}
                        </MenuItem>
                      </TextField>
                      <TextField
                        margin="dense"
                        name="station_breaker_voltage"
                        label={t('Station Breaker Voltage')}
                        value={formData.station_breaker_voltage}
                        // onChange={handleChange}
                        onChange={(e) => {
                          let verify = verify_inputNumber(e.target.value.trim(), formData.station_breaker_voltage);
                          setFormData({
                            ...formData,
                            station_breaker_voltage: verify.value,
                          });
                          setErrorsOption({
                            ...errorsOption,
                            station_breaker_voltage: verify.value == undefined ? '' : verify.error,
                          });
                        }}
                        error={!!errorsOption.station_breaker_voltage}
                        helperText={errorsOption.station_breaker_voltage}
                        size="small"
                        // type="number"
                        onKeyPress={handleKeyPress}
                        style={{ width: '180px' }}
                      />
                      <TextField
                        margin="dense"
                        name="station_breaker_amp"
                        label={t('Station Breaker Amp')}
                        value={formData.station_breaker_amp}
                        // onChange={handleChange}
                        onChange={(e) => {
                          let verify = verify_inputNumber(e.target.value.trim(), formData.station_breaker_amp);
                          setFormData({
                            ...formData,
                            station_breaker_amp: verify.value,
                          });
                          setErrorsOption({
                            ...errorsOption,
                            station_breaker_amp: verify.value == undefined ? '' : verify.error,
                          });
                        }}
                        error={!!errorsOption.station_breaker_amp}
                        helperText={errorsOption.station_breaker_amp}
                        style={{ width: '180px' }}
                        size="small"
                        // type="number"
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </Box>
                  {/* Evse */}
                  <Box component="img" src={img_line} sx={{ width: '120px', marginTop: '130px' }} />
                  <Box
                    width="180px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                    flexDirection="column"
                  >
                    <div>
                      <span>EVSE</span>
                    </div>
                    <Box
                      component="img"
                      src={darkTheme ? img_evse : img_evse_light}
                      sx={{ height: '160px', minWidth: '100%', minWidth: '150px', marginTop: '40px' }}
                    />
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          {/* renewable_config */}
          <Box style={{ margin: '16px 0px' }}>
            <div style={{ padding: '10px 0', padding: '0 16px 10px', background: theme.palette.popPaper.paper2 }}>
              <span style={{ marginTop: '5px' }}>{t('renewable_config')}</span>
              <Switch
                checked={formData.renewable_config}
                onChange={function (event) {
                  setFormData({
                    ...formData,
                    [event.target.name]: event.target.checked,
                  });
                }}
                name="renewable_config"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
            {/* Evse Charge Start */}
            <div
              style={{
                display: formData.renewable_config ? 'block' : 'none',
                background: theme.palette.background.tableRow,
                padding: '15px 15px',
              }}
            >
              <Box>
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t('Allow Energy Export')}</FormLabel>
                  <RadioGroup
                    aria-label="allow_export"
                    name="allow_export"
                    value={formData.allow_export}
                    onChange={(e) => {
                      setFormData({ ...formData, allow_export: e.target.value });
                    }}
                  >
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </div>
          </Box>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button sx={buttonR} variant="outlined" type="submit">
              {t('Submit')}
            </Button>
          </Stack>
        </form>
      </DialogContent>
      {/* </Dialog> */}
    </div>
  );
}
