import { useRef, useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AccountContext } from '../../pages/auth/AWSCognito/Account';
// @mui
import { Box, Divider, Typography, Stack, MenuItem, Tooltip, IconButton } from '@mui/material';
// components
import MenuPopover from 'src/components/MenuPopover';
// mocks_
import useAuth from 'src/hooks/useAuth';
import Icon from 'src/components/customIcon/icon-dp-32.svg';
import Icon_light from 'src/components/lightMode/icon-dp-32.svg';

import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../App';
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { setAuth } = useAuth();
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(null);
  const { logout } = useContext(AccountContext);
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleSignOut = () => {
    const { aws_token, name, role } = '';
    sessionStorage.clear();
    setAuth({ aws_token, name, role });
    setOpen(null);
    logout();
  };

  return (
    <>
      <Tooltip title={t('Account')}>
        <IconButton ref={anchorRef} onClick={handleOpen} sx={{ width: 50, height: 50, zIndex: 'modal' }}>
          <Box component="img" src={darkTheme ? Icon : Icon_light} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: 220,
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {sessionStorage.getItem('name')}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} noWrap>
            {sessionStorage.getItem('role').slice(0, 5)} {sessionStorage.getItem('role').slice(5)}
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary', p: 0 }} noWrap>
            {sessionStorage.getItem('plan_role')}
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary', p: 0 }} noWrap>
            {sessionStorage.getItem('email')}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem
            to={'/pages/userprofile'}
            component={RouterLink}
            onClick={() => {
              sessionStorage.setItem('accountSidebar', 'open');
              handleClose();
            }}
          >
            {t('Account Settings')}
          </MenuItem>
          <MenuItem to={'/'} component={RouterLink} onClick={handleSignOut}>
            {t('Sign Out')}
          </MenuItem>
        </Stack>
      </MenuPopover>
    </>
  );
}
