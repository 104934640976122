import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
// mui
import { Box, Card, Stack, LinearProgress, TablePagination, Snackbar, Alert } from '@mui/material';
// component
import Iconify from '../../../../components/Iconify';
import LoginAgain from '../../../../components/errors/LoginAgain';
import TablePaginationActions from '../../../../components/tablePaginationActions';
import { useTranslation } from 'react-i18next';
import Row from './tableRow';
import Table from './table';
import { OCPIVIEWALL } from '../../../../api/AllAPI';

// ----------------------------------------------------------------------

let CollapsibleTable = ({ versionArr, setGetOcpiData }, ref) => {
  const { t, i18n } = useTranslation();
  const [dbdata, setDbdata] = useState([]);
  // const [update, setUpdate] = useState();
  const [unauthorized, setUnauthorized] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [Amount_data, setAmount_data] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  useImperativeHandle(ref, () => ({
    update_List,
  }));

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };
  useEffect(() => {
    update_List({ oprType: 'change' });
  }, []);
  const [pageCount, setpageCount] = useState(0);
  useEffect(() => {
    if (pageCount !== 0) {
      update_List({ oprType: 'change' });
    } else {
      setpageCount(pageCount + 1);
    }
  }, [page, rowsPerPage]);

  const update_List = (dataType) => {
    const type = dataType.oprType;
    let data = {
      order_by: 'id',
      page_number: page + 1,
      page_size: rowsPerPage,
    };
    switch (type) {
      case 'add':
        setPage(0);
        data.page_number = 1;
        break;
      case 'edit':
        break;
      case 'delete':
        break;
      case 'change':
        break;
      case 'search':
        setPage(0);
        data.page_number = 1;
        break;
      default:
        break;
    }
    setLoading(true);
    OCPIVIEWALL(data, setErrorMessage, setError, setUnauthorized).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setDbdata(promiseResult.data.parties);
        setGetOcpiData(promiseResult.data);
        setAmount_data(promiseResult.data.total_count);
        setSuccess(true);
      }
      setLoading(false);
    });
  };
  useImperativeHandle(ref, () => ({
    update_List,
  }));
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
    // update_List({ oprType: 'change' });
  };

  const Pagination = () => {
    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 50, { label: t('All'), value: -1 }]}
        count={Number(Amount_data)}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={t('rows per page')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
        sx={{
          color: 'texts.main',
          fontFamily: 'Arial',
          fontWeight: 400,
          fontSize: '14px',
          border: 'none',
        }}
      />
    );
  };

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
          // borderBottom: '1px solid #363a3f',
          alignContent: 'center',
        }}
      >
        <p
          style={{
            lineHeight: '22px',
            heght: '35px',
            padding: '22px 16px',
            fontSize: '14px',
            fontWeight: 700,
            color: 'texts.main',
            fontFamily: 'Arial',
          }}
        >
          {t('Amount_data')}: {Amount_data}
        </p>
        <Pagination />
      </div>
      {unauthorized && <LoginAgain />}
      <Snackbar
        open={success}
        // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={4000}
        // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>

      {loading && (
        <div style={{ marginTop: '-15px', width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      {/* {dbdata.map((row, ind) => (
        <Row
          versionArr={versionArr}
          updateList={update_List}
          key={row.party_name}
          row={row}
          // update_List={update_List}
          setSuccess={(childData) => setSuccess(childData)}
          setError={(childData) => setError(childData)}
          setErrorMessage={(childData) => setErrorMessage(childData)}
        />
      ))} */}
      <Table
        versionArr={versionArr}
        updateList={update_List}
        setLoading_pro={setLoading}
        rowData={dbdata}
        setSuccess={(childData) => setSuccess(childData)}
        setError={(childData) => setError(childData)}
        setErrorMessage={(childData) => setErrorMessage(childData)}
      ></Table>
      <Pagination />
    </Box>
  );
};

export default forwardRef(CollapsibleTable);
