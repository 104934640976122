import React, { useState, useEffect } from 'react';
// mui
import {
  Stack,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Tooltip,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Icon from '../../../../components/customIcon/icon-delete-turquoise-16.svg';
import CloseIcon from '../../../../components/customIcon/Close.svg';
import { DeleteTariffFunction } from '../../../../api/AllAPI';
import bcakButton from '../../../../components/customIcon/icon-expandedarrow-24.svg';
import { useTranslation } from 'react-i18next';
import { buttonR, buttonL } from '../../../../components/button/buttonStyle';
// ----------------------------------------------------------------------

export default function DiscardDialog(props) {
  const { t } = useTranslation();
  const { type, setChangePage, setPropsData } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    setChangePage('table');
    setPropsData({});
  };

  return (
    <Box>
      {type == 'back' ? (
        <Tooltip title={t('Back')}>
          <Box
            component="img"
            src={bcakButton}
            sx={{ transform: 'rotate(90deg)', cursor: 'pointer', marginRight: '10px' }}
            onClick={handleClickOpen}
          />
        </Tooltip>
      ) : (
        <LoadingButton variant="outlined" sx={buttonL} onClick={handleClickOpen}>
          {t('Discard')}
        </LoadingButton>
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby="AdminDeleteDialog" sx={{ zIndex: 2000 }}>
        <DialogTitle id="rowDelete">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Confirm')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ pb: 2 }}>
            {t('tip_discard')}
            {/* Are you sure you want to leave without saving? */}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <LoadingButton variant="outlined" onClick={handleClose} sx={buttonL}>
              {t('Cancel')}
            </LoadingButton>
            <LoadingButton onClick={handleConfirm} sx={buttonR}>
              {t('Confirm')}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
