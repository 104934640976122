import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  Dialog,
  Select,
  Button,
  Tooltip,
  MenuItem,
  InputLabel,
  Typography,
  IconButton,
  FormControl,
  DialogTitle,
  DialogContent,
  FormHelperText,
  LinearProgress,
} from '@mui/material';
// components
import Icon from '../../../../../../../../components/customIcon/icon-move-16.svg';
import { buttonR, buttonL } from '../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../components/customIcon/Close.svg';
import { SubmitRetry } from '../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function MoveTo(props) {
  const { t } = useTranslation();
  const { locationList, evseInfo } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    SubmitRetry({ cp_id: evseInfo.cp_id }, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        // props.handleCloseMoveTo(Math.random());
        // props.MoveToUpdate(Math.random());
        props.setSuccess(true);
        setOpen(false);
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <div>
      <Tooltip title={t('Retry')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="RetryDialog" fullWidth minWidth="sm">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="Retry">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Confirm')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack justifyContent="center" alignItems="center" spacing={4}>
            <Typography>
              {t('select_EVSE_id')}: {evseInfo.cp_id}
            </Typography>

            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Button variant="outlined" onClick={handleClose} disabled={loading} sx={buttonL}>
                {t('Cancel')}
              </Button>
              <Button onClick={onSubmit} disabled={loading} sx={buttonR}>
                {t('Retry')}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
