import React, { useState, useEffect, useImperativeHandle } from 'react';
// mui
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, MenuItem, Autocomplete } from '@mui/material';
// components
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function EVSEFunc({ childRef, evseData }) {
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  // submit form
  const methods = useForm({
    reValidateMode: 'onBlur',
  });
  const { control } = methods;

  useImperativeHandle(childRef, () => ({
    onSubmit,
  }));

  const [formData, setFormData] = useState({
    cp_id: '',
    payter_serial_number: '',
    ocpi_physical_reference: '',
    parking_restrictions: '',
    floor_level: '',
    ocpp_version: '',
    capability: [],
  });
  useEffect(() => {
    if (evseData && Object.keys(evseData).length !== 0) {
      console.log('evseData=>', evseData);
      setFormData({
        cp_id: evseData.cp_id ? evseData.cp_id : '',
        payter_serial_number: evseData.payter_serial_number ? evseData.payter_serial_number : '',
        cs_id: evseData.cs_id ? evseData.cs_id : null,
        mobile_id: evseData.cs_id.mobile_id ? evseData.mobile_id : null,
        transaction: evseData.cs_id.transaction ? evseData.transaction : null,
        user_name: evseData.cs_id.user_name ? evseData.user_name : null,
        ocpi_physical_reference: evseData.ocpi_physical_reference ? evseData.ocpi_physical_reference : '',
        parking_restrictions: evseData.parking_restrictions ? evseData.parking_restrictions : null,
        floor_level: evseData.floor_level ? evseData.floor_level : null,
        ocpp_version: evseData.ocpp_version ? evseData.ocpp_version : '',
        capability: evseData.capability ? evseData.capability : [],
        connector: evseData.connector ? evseData.connector : [],
      });
    }
  }, [evseData]);

  const [errors, setErrors] = useState({
    cp_id: '',
  });

  const onSubmit = () => {
    console.log('1');
    let empty = false;
    const newHasEmpty = { ...errors };
    for (const key in newHasEmpty) {
      // Skip validation for 'payter_serial_number'
      if (key === 'payter_serial_number') {
        newHasEmpty[key] = '';
        continue;
      }
      if (formData.hasOwnProperty(key) && formData[key] === '') {
        newHasEmpty[key] = t('required fields');
        empty = true;
      } else {
        newHasEmpty[key] = '';
      }
    }
    console.log(hasError, empty);
    setErrors(newHasEmpty);
    if (hasError || empty) {
      return false;
    }
    console.log('3');
    const filteredData = {};
    for (const key in formData) {
      if (key == 'parking_restrictions') {
        formData[key] !== '' ? (filteredData[key] = formData[key]) : null;
      } else {
        filteredData[key] = formData[key];
      }
    }
    console.log('4');
    console.log('edit EVSE', filteredData);
    return filteredData;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors({
      ...errors,
      [name]: '',
    });
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    console.log(fieldName, value);
    let errorMessage = '';
    setHasError(false);
    if (fieldName === 'cp_id') {
      if (value.length < 1 || value.length > 50 || value == '') {
        setHasError(true);
        errorMessage = t('Invalid length') + ' (minLength:1, maxLength:50).';
      }
    } else if (fieldName === 'ocpi_physical_reference') {
      if (value.length < 1 || value.length > 16 || value == '') {
        setHasError(true);
        errorMessage = t('Invalid length') + ' (minLength:1, maxLength:16).';
      }
    } else if (fieldName === 'floor_level' || value == '') {
      if (value.length < 1 || value.length > 4) {
        // setHasError(true);
        errorMessage = t('Invalid value') + ' (minLength:1, maxLength:4).';
      }
    } else if (fieldName === 'ocpp_version' || value == '') {
      if (value.length < 1 || value.length > 10) {
        setHasError(true);
        errorMessage = t('Invalid value') + ' (minLength:1, maxLength:10).';
      }
    }

    if (fieldName !== 'payter_serial_number') {
      setErrors({
        ...errors,
        [fieldName]: errorMessage,
      });
    }
  };

  // const capabilityOptions = [
  //   { value: 'CHARGING_PROFILE_CAPABLE', label: t('CHARGING_PROFILE_CAPABLE') },
  //   { value: 'CHARGING_PREFERENCES_CAPABLE', label: t('CHARGING_PREFERENCES_CAPABLE') },
  //   { value: 'CHIP_CARD_SUPPORT', label: t('CHIP_CARD_SUPPORT') },
  //   { value: 'CONTACTLESS_CARD_SUPPORT', label: t('CONTACTLESS_CARD_SUPPORT') },
  //   { value: 'CREDIT_CARD_PAYABLE', label: t('CREDIT_CARD_PAYABLE') },
  //   { value: 'DEBIT_CARD_PAYABLE', label: t('DEBIT_CARD_PAYABLE') },
  //   { value: 'PED_TERMINAL', label: t('PED_TERMINAL') },
  //   { value: 'REMOTE_START_STOP_CAPABLE', label: t('REMOTE_START_STOP_CAPABLE') },
  //   { value: 'RESERVABLE', label: t('RESERVABLE') },
  //   { value: 'RFID_READER', label: t('RFID_READER') },
  //   { value: 'START_SESSION_CONNECTOR_REQUIRED', label: t('START_SESSION_CONNECTOR_REQUIRED') },
  //   { value: 'TOKEN_GROUP_CAPABLE', label: t('TOKEN_GROUP_CAPABLE') },
  //   { value: 'UNLOCK_CAPABLE', label: t('UNLOCK_CAPABLE') },
  // ];
  const capabilityOptions = [
    'CHARGING_PROFILE_CAPABLE',
    'CHARGING_PREFERENCES_CAPABLE',
    'CHIP_CARD_SUPPORT',
    'CONTACTLESS_CARD_SUPPORT',
    'CREDIT_CARD_PAYABLE',
    'DEBIT_CARD_PAYABLE',
    'PED_TERMINAL',
    'REMOTE_START_STOP_CAPABLE',
    'RESERVABLE',
    'RFID_READER',
    'START_SESSION_CONNECTOR_REQUIRED',
    'TOKEN_GROUP_CAPABLE',
    'UNLOCK_CAPABLE',
  ];
  const handleCapabilityChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      capability: event.target.value,
    }));
  };
  return (
    <Box>
      <span style={{ color: 'red' }}>* {t('Mandatory fields')}</span>
      <form style={{ marginBottom: '15px' }}>
        <TextField
          disabled
          size="small"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('CP ID')}
            </>
          }
          name="cp_id"
          value={formData.cp_id}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          error={!!errors.cp_id}
          helperText={errors.cp_id}
        />
        <TextField
          label={<>{t('Terminal ID')}</>}
          name="payter_serial_number"
          value={formData.payter_serial_number}
          onChange={handleInputChange}
          fullWidth
          size="small"
          margin="dense"
          error={!!errors.payter_serial_number}
          helperText={errors.payter_serial_number}
        />
        <TextField
          label={
            <>
              {/* <span style={{ color: 'red' }}>* </span> */}
              {t('OCPI Physical Reference')}
            </>
          }
          name="ocpi_physical_reference"
          value={formData.ocpi_physical_reference}
          onChange={handleInputChange}
          fullWidth
          size="small"
          margin="dense"
          error={!!errors.ocpi_physical_reference}
          helperText={errors.ocpi_physical_reference}
        />
        <TextField
          margin="dense"
          select
          fullWidth
          name="parking_restrictions"
          label={
            <>
              {/* <span style={{ color: 'red' }}>* </span> */}
              {t('Parking Restrictions')}
            </>
          }
          value={formData.parking_restrictions}
          onChange={(e) => {
            // setFormData({ ...formData, parking_restrictions: e.target.value });
            handleInputChange(e);
          }}
          size="small"
          error={!!errors.parking_restrictions}
          helperText={errors.parking_restrictions}
        >
          <MenuItem value="EV_ONLY">{t('EV Only')}</MenuItem>
          <MenuItem value="PLUGGED">{t('Plugged')}</MenuItem>
          <MenuItem value="DISABLED">{t('Disabled_special')}</MenuItem>
          <MenuItem value="CUSTOMERS">{t('Customers')}</MenuItem>
          <MenuItem value="MOTORCYCLES">{t('Motorcycles')}</MenuItem>
        </TextField>

        <TextField
          label={
            <>
              {/* <span style={{ color: 'red' }}>* </span> */}
              {t('Floor Level')}
            </>
          }
          name="floor_level"
          value={formData.floor_level}
          onChange={handleInputChange}
          fullWidth
          size="small"
          margin="dense"
          error={!!errors.floor_level}
          helperText={errors.floor_level}
        />
        <TextField
          label={
            <>
              {/* <span style={{ color: 'red' }}>* </span> */}
              {t('OCPP Version')}
            </>
          }
          name="ocpp_version"
          value={formData.ocpp_version}
          onChange={handleInputChange}
          fullWidth
          disabled
          size="small"
          margin="dense"
          error={!!errors.ocpp_version}
          helperText={errors.ocpp_version}
        />

        {/* <FormControl fullWidth margin="dense" variant="outlined" size="small">
          <InputLabel>{t('Capability') + '  (' + t('multiple') + ')'}</InputLabel>
          <Select
            multiple
            // labelId="label-for-appliances"
            label={t//('Capability') + '(' + t('multiple') + ')'}
            value={formData.capability}
            onChange={handleCapabilityChange}
            renderValue={(selected) => (
              <div>
                {selected.map((value) => (
                  <Chip key={value} label={t(value)} />
                ))}
              </div>
            )}
          >
            {capabilityOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <Controller
          control={control}
          name={'capability'}
          render={({ field: { ref, onChange, ...field } }) => (
            <Autocomplete
              multiple
              value={formData.capability}
              options={capabilityOptions}
              getOptionLabel={(option) => t(option)}
              onChange={(_, data) => {
                setFormData((prevData) => ({
                  ...prevData,
                  capability: data,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...field}
                  {...params}
                  fullWidth
                  inputRef={ref}
                  variant="outlined"
                  label={t('Capability') + '(' + t('multiple') + ')'}
                />
              )}
            />
          )}
        />
      </form>
    </Box>
  );
}
