import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  Select,
  Button,
  Dialog,
  Tooltip,
  MenuItem,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Icon from '../../../../components/customIcon/icon-reserve-turquoise-16.svg';
import { buttonR, buttonL } from '../../../../components/button/buttonStyle';
import CloseIcon from '../../../../components/customIcon/Close.svg';
import { fetchUserCP, fetchUserConnector, SubmitUserReserve } from '../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function UserReserve(props) {
  const { t } = useTranslation();
  const { info, chargingStationList } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [charging_station, setCharging_station] = useState();
  const [chargePointList, setChargePointList] = useState([]);
  const [charge_point_id, setChargePoint] = useState();
  const [connector, setConnector] = useState([]);
  const [connector_id, setSelectConnector] = useState();
  const [loading_pre, setLoading_pre] = useState(false);
  const mobile_id = info.mobile_id;

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    if (charging_station) {
      setLoading_pre(true);
      fetchUserCP({ charging_station }, setError, setErrorMessage).then((promiseResult) => {
        if (promiseResult.category == 'Success') {
          setChargePointList(promiseResult.data);
        } else {
          setError(true);
          setErrorMessage(promiseResult.message);
        }
        setLoading_pre(false);
      });
    }
  }, [charging_station]);

  const chooseChargePoint = (event) => {
    setChargePoint(event.target.value);
    const connectors = event.target.value;
    setLoading_pre(true);
    fetchUserConnector(connectors, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category == 'Success') {
        if (promiseResult.data.length != 0) {
          const values = promiseResult.data.map((item, ind) => {
            return {
              name: `Connector ${item.connectors_number}`,
              value: item.connectors_number,
            };
          });
          setConnector(values);
        }
      } else {
        setError(true);
        setErrorMessage(promiseResult.message);
      }
      setLoading_pre(false);
    });
  };

  const chooseConnector = (event) => {
    setSelectConnector(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
    setChargePoint();
  };

  const onSubmit = () => {
    if (!charge_point_id || !mobile_id || !connector_id) {
      props.setErrorMessage(t('Mandatory fields'));
      props.setError(true);
      return;
    }

    setLoading(true);
    setLoading_pre(true);
    SubmitUserReserve(charge_point_id, mobile_id, connector_id, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.ReserveUpdate('search');
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
      setLoading_pre(false);
    });
  };

  return (
    <div>
      <Tooltip title={t('Reserved')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="UserReserve">
        {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="UserReserve">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Make Reservation')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" justifyContent="flex-start" spacing={2} sx={{ width: 450 }}>
            <Typography>
              {t('tit_makeReservation', { selectName: info.name, id: info.mobile_id })}
              {/* You are making a reservation for {info.name} (ID Tag: {info.mobile_id}) */}
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="charging_station_select">
                <span style={{ color: 'red' }}>* </span>
                {t('Charging Station')}
              </InputLabel>
              <Select
                labelId="charging_station_select"
                id="charging_station_select"
                label={t('Charging Station')}
                onChange={(event) => setCharging_station(event.target.value)}
              >
                {chargingStationList.map((cs) => (
                  <MenuItem key={cs.charging_station} value={cs.charging_station}>
                    {cs.charging_station_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="charge_point_select">
                <span style={{ color: 'red' }}>* </span>
                {t('Charge Point')}
              </InputLabel>
              <Select
                disabled={chargingStationList.length == 0}
                labelId="charge_point_select"
                id="charge_point_select"
                label={t('Charge Point')}
                onChange={chooseChargePoint}
              >
                {chargePointList?.map((cs) => (
                  <MenuItem key={cs.id} value={cs.id}>
                    {cs.charge_point_id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="connector_select">
                <span style={{ color: 'red' }}>* </span>
                {t('Connector')}
              </InputLabel>
              <Select
                disabled={chargingStationList.length == 0}
                labelId="connector_select"
                id="connector_select"
                label={t('Connector')}
                onChange={chooseConnector}
              >
                {connector?.map((cs) => (
                  <MenuItem key={cs.value} value={cs.value}>
                    {cs.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <RHFTextField margin="dense" name="chargingstation" label="Charging Station" fullWidth variant="outlined" />
            <RHFTextField margin="dense" name="chargerid" label="Charger ID" fullWidth variant="outlined" /> */}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button variant="outlined" onClick={handleClose} sx={buttonL}>
                {t('Back')}
              </Button>
              <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
                {t('Submit')}
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
