import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: 'ap-southeast-1_a0lPcksMV',
  ClientId: '7ao9of3i7jmsfkd03uar5buplk',
};
// Trail Pool start++++++++++
// const poolData = {
//   UserPoolId: 'ap-southeast-1_yq86Ios0I',
//   ClientId: '2r245i1tuejdrbtr0anj1dpu3f',
// };
// Trail Pool end++++++++++
export default new CognitoUserPool(poolData);
