import React, { useState, useImperativeHandle, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
// mui
// mui
import { useForm, Controller } from 'react-hook-form';
import { Box, Stack, Button, Tooltip, MenuItem, TextField, Autocomplete } from '@mui/material';
// components

import { Charger_AddStation, Charger_Listfacility } from '../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../../components/Iconify';
// ----------------------------------------------------------------------

export default function StationModel({ childRef, location, url, SetStationData, stationData }) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [copyText, setCopyText] = useState('Copy');
  const [open, setOpen] = useState(false);
  const [facilityOptions, setFacilityOptions] = useState([]);
  useImperativeHandle(childRef, () => ({
    onSubmit,
  }));
  // submit form
  const methods = useForm({
    reValidateMode: 'onBlur',
  });
  const { control } = methods;

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    postal_code: '',
    city: '',
    country: '',
    parking_type: '',
    direction: '',
    location: location ? location : '',
    state: '',
    facilities: [],
  });
  useEffect(() => {
    if (stationData && Object.keys(stationData).length !== 0) {
      setFormData(stationData);
    }
  }, [stationData]);
  useEffect(() => {
    Charger_Listfacility(setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        const dataArr = [];
        for (let index = 0; index < promiseResult.data.length; index++) {
          const element = promiseResult.data[index];
          dataArr.push({ value: element.facility, id: element.facility });
        }
        setFacilityOptions(dataArr);
      }
    });
  }, []);
  const parkingTypeOptions = [
    { value: t('ALONG_MOTORWAY'), id: 'ALONG_MOTORWAY' },
    { value: t('PARKING_GARAGE'), id: 'PARKING_GARAGE' },
    { value: t('PARKING_LOT'), id: 'PARKING_LOT' },
    { value: t('ON_DRIVEWAY'), id: 'ON_DRIVEWAY' },
    { value: t('ON_STREET'), id: 'ON_STREET' },
    { value: t('UNDERGROUND_GARAGE'), id: 'UNDERGROUND_GARAGE' },
  ];
  // const facilitiesOptions = [
  //   t('HOTEL'),
  //   t('RESTAURANT'),
  //   t('CAFE'),
  //   t('MALL'),
  //   t('SUPERMARKET'),
  //   t('SPORT'),
  //   t('RECREATION_AREA'),
  //   t('NATURE'),
  //   t('MUSEUM'),
  //   t('BIKE_SHARING'),
  //   t('BUS_STOP'),
  //   t('TAXI_STAND'),
  //   t('TRAM_STOP'),
  //   t('METRO_STATION'),
  //   t('TRAIN_STATION'),
  //   t('AIRPORT'),
  //   t('PARKING_LOT'),
  //   t('FUEL_STATION'),
  //   t('WIFI'),
  // ];

  const [errors, setErrors] = useState({
    name: '',
    address: '',
    postal_code: '',
    country: '',
    direction: '',
    location: '',
    parking_type: '',
    city: '',
  });
  const limitRules = {
    name: { minLength: 0, maxLength: 255 },
    address: { minLength: 0, maxLength: 45 },
    postal_code: { minLength: 0, maxLength: 10 },
    state: { minLength: 0, maxLength: 45 },
    country: { minLength: 0, maxLength: 100 },
    city: { minLength: 0, maxLength: 100 },
    location: { minLength: 0, maxLength: 100 },
    direction: { minLength: 0, maxLength: 255 },
  };
  const handleChange_after = (e) => {
    const { name, value } = e.target;
    const errorArr = Object.keys(errors);
    setFormData({ ...formData, [name]: value });
    if (!errorArr.includes(name)) return;
    if (name == 'parking_type') {
      value !== '' ? setErrors({ ...errors, [name]: '' }) : null;
      return;
    }
    const { minLength, maxLength } = limitRules[name];
    let errorMessage = '';

    if (value.length < minLength || value.length > maxLength) {
      errorMessage = t('Invalid length') + ` (minLength:${minLength}, maxLength:${maxLength}).`;
    }

    setErrors({ ...errors, [name]: errorMessage });
  };
  function filterEmptyValues(obj) {
    const result = {};
    for (const key in obj) {
      if (obj[key] !== '') {
        result[key] = obj[key];
      }
    }
    return result;
  }

  const onSubmit = () => {
    // Not empty verify
    let hasError = false;
    for (const key in errors) {
      const er = errors;
      if (formData.hasOwnProperty(key) && formData[key] === '') {
        if (key == 'direction' || key == 'postal_code' || key == 'state') continue;
        setErrors({ ...errors, [key]: t('required fields') });
        er[key] = t('required fields');
        hasError = true;
      }
    }
    //  do not submit
    if (hasError) {
      setLoading(false);
      return false;
    }
    return filterEmptyValues(formData);
  };

  return (
    <Box>
      <form onSubmit={onSubmit}>
        {/* spacing={2} sx={{ width: 450 }} */}
        <span style={{ color: 'red' }}>* {t('Mandatory fields')}</span>
        <TextField
          margin="dense"
          size="small"
          disabled
          name="location"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Location')}
            </>
          }
          error={!!errors.location}
          helperText={errors.location}
          onChange={(e) => {
            handleChange_after(e);
          }}
          value={formData.location}
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="dense"
          size="small"
          name="name"
          error={!!errors.name}
          helperText={errors.name}
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('name_station')}
            </>
          }
          onChange={(e) => {
            handleChange_after(e);
          }}
          value={formData.name}
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="dense"
          size="small"
          name="address"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Address')}
            </>
          }
          error={!!errors.address}
          helperText={errors.address}
          onChange={(e) => {
            handleChange_after(e);
          }}
          value={formData.address}
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="dense"
          size="small"
          name="state"
          label={<>{t('State_city')}</>}
          onChange={(e) => {
            handleChange_after(e);
          }}
          value={formData.state}
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="dense"
          size="small"
          name="country"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Country')}
            </>
          }
          error={!!errors.country}
          helperText={errors.country}
          onChange={(e) => {
            handleChange_after(e);
          }}
          value={formData.country}
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="dense"
          size="small"
          name="city"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('City')}
            </>
          }
          error={!!errors.city}
          helperText={errors.city}
          onChange={(e) => {
            handleChange_after(e);
          }}
          value={formData.city}
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="dense"
          size="small"
          name="postal_code"
          label={
            <>
              {/* <span style={{ color: 'red' }}>* </span> */}
              {t('Postal Code')}
            </>
          }
          error={!!errors.postal_code}
          helperText={errors.postal_code}
          onChange={(e) => {
            handleChange_after(e);
          }}
          value={formData.postal_code}
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="dense"
          select
          fullWidth
          name="parking_type"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Parking Type')}
            </>
          }
          value={formData.parking_type ? formData.parking_type : ''}
          onChange={(e) => {
            handleChange_after(e);
          }}
          size="small"
          error={!!errors.parking_type}
          helperText={errors.parking_type}
        >
          {parkingTypeOptions.map((option) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            );
          })}
        </TextField>

        <TextField
          margin="dense"
          size="small"
          name="direction"
          label={
            <>
              {/* <span style={{ color: 'red' }}>* </span> */}
              {t('Directions')}
            </>
          }
          onChange={(e) => {
            handleChange_after(e);
          }}
          value={formData.direction}
          fullWidth
          variant="outlined"
          error={!!errors.direction}
          helperText={errors.direction}
        />

        {/* <FormControl fullWidth margin="dense" variant="outlined" size="small">
          <InputLabel>{t('Facilities') + '  (' + t('multiple') + ')'}</InputLabel>
          <Select
            multiple
            label={t//('facilities') + '(' + t('multiple') + ')'}
            value={formData.facilities}
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                facilities: e.target.value,
              }));
            }}
            renderValue={(selected) => (
              <div>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={
                      facilityOptions.length != 0
                        ? t(facilityOptions.find((option) => option.value === value).value)
                        : t(value)
                    }
                  />
                ))}
              </div>
            )}
          >
            {facilityOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {t(option.value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <Controller
          control={control}
          name={'facilities'}
          render={({ field: { ref, onChange, ...field } }) => (
            <Autocomplete
              multiple
              value={formData.facilities.map((id) => facilityOptions.find((option) => option.id === id) || '')}
              options={facilityOptions}
              getOptionLabel={(option) => t(option.value)}
              onChange={(_, data) => {
                const selectedIds = data.map((option) => option.id);
                setFormData((prevData) => ({
                  ...prevData,
                  facilities: selectedIds,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...field}
                  {...params}
                  fullWidth
                  inputRef={ref}
                  variant="outlined"
                  label={t('Facilities') + '(' + t('multiple') + ')'}
                />
              )}
            />
          )}
        />
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <TextField
            disabled
            margin="dense"
            size="small"
            name="url"
            // label={t("URL for Connection of Charging Station")}
            label={t('URL_station')}
            value={url}
            fullWidth
            variant="outlined"
          />

          <CopyToClipboard text={url} onCopy={() => setCopyText('Copied to clip board')}>
            <Tooltip title={copyText}>
              <Button variant="outlined" sx={{ height: 38, borderColor: '#00B0B9', color: '#00B0B9' }}>
                <Iconify icon="material-symbols:content-copy-rounded" width={22} height={22} />
              </Button>
            </Tooltip>
          </CopyToClipboard>
        </Stack>
      </form>
    </Box>
  );
}
