// component
import Iconify from '../Iconify';
import ocpiIcon from '../../components/customIcon/icon-ocpi-24.svg';
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify src="false" icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'CSMS Summary',
    path: '/pages/summary',
    icon: getIcon('ant-design:pie-chart-outlined'),
  },
  {
    title: 'Revenue',
    path: '/pages/revenue',
    icon: getIcon('streamline:money-cash-dollar-coin-accounting-billing-payment-cash-coin-currency-money-finance'),
  },
  {
    title: 'Charger Management',
    path: '/pages/chargermanagement',
    icon: getIcon('material-symbols:ev-charger-outline-sharp'),
  },
  {
    title: 'Tariff Management',
    path: '/pages/tariffmanagement',
    icon: getIcon('carbon:wallet'),
  },
  {
    title: 'User Management',
    path: '/pages/user',
    icon: getIcon('la:user-edit'),
  },
  {
    title: 'Session Summary',
    path: '/pages/sessionsummary',
    icon: getIcon('ep:document'),
  },
  // {
  //   title: 'OCPP log',
  //   path: '/pages/ocpplog',
  //   icon: getIcon('ant-design:folder-filled'),
  // },
  {
    title: 'Admin Management',
    path: '/pages/adminmanagement',
    icon: getIcon('ion:key-outline'),
  },
  // {
  //   title: 'OCTT',
  //   path: '/pages/octt',
  //   icon: getIcon('bx:link'),
  // },
  // {
  //   title: 'OCPI',
  //   path: '/pages/ocpi',
  //   icon: getIcon('bx:link'),
  // },
  {
    title: 'Campaign Planner',
    path: '/pages/campaignPlanner',
    icon: getIcon('fa:send-o'),
  },
  // {<LinkOutlined />
  //   title: 'login',
  //   path: '/',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'forgot password',
  //   path: '/forgotpassword',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'reset message',
  //   path: '/resetmessage',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'reset password',
  //   path: '/resetpassword',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:lock-fill'),
  // },
];

export default navConfig;
