import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
// mui
import {
  Box,
  Stack,
  Table,
  Button,
  Tooltip,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  tableCellClasses,
} from '@mui/material';
import Icon from 'src/components/customIcon/Union.svg';
import SearchIcon from '@mui/icons-material/Search';
import QueueIcon from '@mui/icons-material/Queue';
import { useTranslation } from 'react-i18next';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import RowDelete from './menu/tableRowDelete';
import RowCopy from './menu/tableRowCopy';
// ----------------------------------------------------------------------

export default function incompleteTable({
  transdata,
  setSuccess,
  updateList,
  setErrorMessage,
  setError,
  setPropsData,
  setChangePage,
  tabValue,
  setTableValue,
}) {
  const { t } = useTranslation();

  const [dbdata, setDbdata] = useState([]);
  const columns = [
    { field: 'name', headerName: t('Campaign Name'), align: 'left', width: 70 },
    { field: 'status', headerName: t('Status'), align: 'center', width: 180 },
    { field: 'scheduled', headerName: t('Scheduled Publish'), align: 'center', width: 180 },
    // { field: 'last_modified', headerName: t('Last Modified'), align: 'center', width: 180 },
    { field: 'action', headerName: t('Actions'), align: 'center', width: 180 },
  ];
  const columns1 = [
    { field: 'name', headerName: t('Campaign Name'), align: 'left', width: 70 },
    { field: 'scheduled', headerName: t('Scheduled Publish'), align: 'center', width: 180 },
    // { field: 'last_modified', headerName: t('Last Modified'), align: 'center', width: 180 },
    { field: 'action', headerName: t('Actions'), align: 'center', width: 180 },
  ];
  useEffect(() => {
    if (transdata) {
      setDbdata(transdata);
    }
  }, [transdata]);
  const handleEdit = (row) => {
    setPropsData(row);
    setChangePage('info_edit');
  };
  const handleView = (row) => {
    setPropsData(row);
    setChangePage('info_view');
  };
  const handleCopy = (row) => {
    setPropsData(row);
    setChangePage('info_copy');
  };
  const handleCalendar = (row) => {
    setPropsData(row);
    setTableValue('2');
  };
  const add8hours = (originalDateString) => {
    const originalDate = new Date(originalDateString);
    originalDate.setHours(originalDate.getHours() + 8);

    // Format the date as "YYYY-MM-DD HH:mm:ss"
    const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')} ${originalDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${originalDate.getMinutes().toString().padStart(2, '0')}:${originalDate
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;

    return formattedDate;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            {tabValue == '1'
              ? columns.map((column) => (
                  <StyledTableCell align={column.align} sx={{ width: column.width }} key={column.headerName}>
                    {column.headerName}
                  </StyledTableCell>
                ))
              : columns1.map((column) => (
                  <StyledTableCell align={column.align} sx={{ width: column.width }} key={column.headerName}>
                    {column.headerName}
                  </StyledTableCell>
                ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dbdata.map((row, index) => (
            <StyledTableRow key={row.id} hover>
              <StyledTableCell>{row.schedule_name}</StyledTableCell>
              {tabValue == '1' && <StyledTableCell align="center">{t(row.status)}</StyledTableCell>}
              <StyledTableCell align="center">
                {tabValue == '1' && row.schedule_type == 'Immediate' ? t('Immediate') : row.schedule_time}
              </StyledTableCell>
              <StyledTableCell align="center" sx={sx}>
                <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}></Stack>
                {tabValue == '1' ? (
                  <Tooltip title={t('Edit')}>
                    <IconButton onClick={() => handleEdit(row)} sx={{ width: '35px', height: '35px', zIndex: 'modal' }}>
                      <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={t('View')}>
                    <IconButton onClick={() => handleView(row)} sx={{ width: '35px', height: '35px', zIndex: 'modal' }}>
                      <SearchIcon
                        sx={{ color: row.status === 'Draft' ? '#333' : '#00B0B9', width: '100%', height: '100%' }}
                      ></SearchIcon>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={t('Make a new copy')}>
                  <IconButton onClick={() => handleCopy(row)} sx={{ width: '35px', height: '35px', zIndex: 'modal' }}>
                    <QueueIcon sx={{ color: '#00B0B9', width: '100%', height: '100%' }}></QueueIcon>
                  </IconButton>
                </Tooltip>
                {/* <RowCopy
                  info={row}
                  update_List={updateList}
                  setSuccess={setSuccess}
                  setErrorMessage={setErrorMessage}
                  setError={setError}
                ></RowCopy> */}
                <Tooltip title={t('Calendar')}>
                  <span>
                    <IconButton
                      disabled={row.status === 'Draft'}
                      onClick={() => handleCalendar(row)}
                      sx={{ width: '35px', height: '35px', zIndex: 'modal' }}
                    >
                      <EditCalendarIcon
                        sx={{ color: row.status === 'Draft' ? '#333' : '#00B0B9', width: '100%', height: '100%' }}
                      ></EditCalendarIcon>
                    </IconButton>
                  </span>
                </Tooltip>
                <RowDelete
                  info={row}
                  update_List={updateList}
                  setSuccess={setSuccess}
                  setErrorMessage={setErrorMessage}
                  setError={setError}
                ></RowDelete>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {/* {openEdit ? (
        <EditTariff operation="edit" propsData={row} setOpenEdit={setOpenEdit} updateList={updateList}></EditTariff>
      ) : (
        ''
      )} */}
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#17262A',
    // color: '#F4F4F4',
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    // color: '#F4F4F4',
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));

const sx = {
  pt: '3.5px',
  pb: '3.5px',
  textAlign: '-webkit-center',
};
const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: '15px',
  '&:hover': {
    color: '#00B0B9',
    background: theme.palette.action.hover,
  },
}));
