import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  Dialog,
  MenuItem,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { buttonR, buttonL } from '../../../../../../components/button/buttonStyle';
import Warning from '../../../../../../components/customIcon/icon-warning-24.svg';
import CloseIcon from '../../../../../../components/customIcon/Close.svg';
import { SubmitDeleteLocation } from '../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function DeleteLocationDialog(props) {
  const { t } = useTranslation();
  const { location } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.closeAll();
  };

  const onSubmit = () => {
    setLoading(true);
    console.log('DeleteLocation opr', location);
    SubmitDeleteLocation(location, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.DeleteUpdate(Math.random());
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>{t('Delete Location')}</MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="DeleteLocationDialog">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="DeleteLocation">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Box component="img" src={Warning} sx={{ width: '24px', height: '24px', mr: '5px' }} />
              {t('Delete Location')}
            </Stack>
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ pl: 1, pr: 1, pb: 2 }}>
            {t('tip_delete', { stationName: location })}
            {/* Are you sure you want to delete {location} ? */}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <LoadingButton variant="outlined" onClick={handleClose} sx={buttonL}>
              {t('Back')}
            </LoadingButton>
            <LoadingButton type="submit" onClick={onSubmit} disabled={loading} sx={buttonR}>
              {t('Delete')}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
