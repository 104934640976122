import React, { useState } from 'react';
// mui
import { Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import {
  getConfiguration,
  setConfiguration,
  remoteStart,
  remoteStop,
  sendReset,
  unlockConnector,
  getLocalList,
  sendLocalList,
  updateFirmware,
  getDiagnostics,
  reserveNow,
  changeAvailability,
  cancelReservation,
  triggerMessage,
  setChargingProfile,
  clearCache,
  sendDataTransfer,
  getCompositeSchedule,
  extendedTriggerMessage,
  certificateSigned,
  installCertificate,
  getInstalledCertificateIds,
  deleteCertificate,
  getLog,
  signedUpdateFirmware,
  clearChargingProfile,
} from '../../../api/OCTT/OCTTAPI';

// ----------------------------------------------------------------------

export default function OCTT() {
  const formData1 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    key: [],
    get_variable_data: [],
  };
  const TC_CS_V16_002_2 = (index) => {
    console.log(formData1);
    getConfiguration(formData1).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData2 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    key: 'MeterValueSampleInterval',
    value: '10',
    get_variable_data: [],
  };
  const TC_CS_V16_002_3 = (index) => {
    console.log(formData2);
    setConfiguration(formData2).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData3 = { charge_point_id: 'OCTT_CHARGE_POINT', id_tag: 'octt_user_valid', connector_id: 1 };
  const remoteStartTransaction = (index) => {
    console.log(formData3);
    remoteStart(formData3).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData4 = { charge_point_id: 'OCTT_CHARGE_POINT', id_tag: 'octt_user_valid', connector_id: 1 };
  const TC_CS_V16_012_1 = (index) => {
    console.log(formData4);
    remoteStart(formData4).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };

  const [formData5, setFormData5] = useState({ charge_point_id: 'OCTT_CHARGE_POINT', transaction_id: 23 });
  const TC_CS_V16_012_2 = (index) => {
    console.log(formData5);
    remoteStop(formData5).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData6 = { charge_point_id: 'OCTT_CHARGE_POINT', type: 'Hard' };
  const TC_CS_V16_013 = (index) => {
    console.log(formData6);
    sendReset(formData6).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData7 = { charge_point_id: 'OCTT_CHARGE_POINT', type: 'Soft' };
  const TC_CS_V16_014 = (index) => {
    console.log(formData7);
    sendReset(formData7).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData8 = { charge_point_id: 'OCTT_CHARGE_POINT', key: ['AuthorizeRemoteTxRequests'], get_variable_data: [] };
  const TC_CS_V16_015_1 = (index) => {
    console.log(formData8);
    getConfiguration(formData8).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData9 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    key: 'MeterValueSampleInterval',
    value: '10',
    get_variable_data: [],
  };
  const TC_CS_V16_015_2 = (index) => {
    console.log(formData9);
    setConfiguration(formData9).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData10 = { charge_point_id: 'OCTT_CHARGE_POINT', id_tag: 'octt_user_valid', connector_id: 1 };
  const TC_CS_V16_015_3 = (index) => {
    console.log(formData10);
    remoteStart(formData10).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData11 = { charge_point_id: 'OCTT_CHARGE_POINT', type: 'Hard' };
  const TC_CS_V16_015_4 = (index) => {
    console.log(formData11);
    sendReset(formData11).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData13 = { charge_point_id: 'OCTT_CHARGE_POINT', connector_id: 1 };
  const TC_CS_V16_017 = (index) => {
    console.log(formData13);
    unlockConnector(formData13).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData14 = { charge_point_id: 'OCTT_CHARGE_POINT', id_tag: 'octt_user_valid', connector_id: 1 };
  const TC_CS_V16_018_1_1 = (index) => {
    console.log(formData14);
    remoteStart(formData14).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const TC_CS_V16_018_1_2 = (index) => {
    console.log(formData14);
    unlockConnector(formData14).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData15 = { charge_point_id: 'OCTT_CHARGE_POINT', get_variable_data: [] };
  const TC_CS_V16_019_1 = (index) => {
    console.log(formData15);
    getConfiguration(formData15).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData16 = { charge_point_id: 'OCTT_CHARGE_POINT', key: ['SupportedFeatureProfiles'], get_variable_data: [] };
  const TC_CS_V16_019_2 = (index) => {
    console.log(formData16);
    getConfiguration(formData16).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData17 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    key: 'MeterValueSampleInterval',
    value: '10',
    get_variable_data: [],
  };
  const TC_CS_V16_021 = (index) => {
    console.log(formData17);
    setConfiguration(formData17).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData18 = { charge_point_id: 'OCTT_CHARGE_POINT', id_tag: 'octt_user_valid' };
  const TC_CS_V16_026 = (index) => {
    console.log(formData18);
    remoteStart(formData18).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const [formData19, setFormData19] = useState({ charge_point_id: 'OCTT_CHARGE_POINT', transaction_id: 6 });
  const TC_CS_V16_028 = (index) => {
    // const value = { transaction_id: parseInt(formData19.transaction_id, 10) };
    // console.log(value);
    remoteStop(formData19).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData20 = { charge_point_id: 'OCTT_CHARGE_POINT', connector_id: 1 };
  const TC_CS_V16_030 = (index) => {
    console.log(formData20);
    unlockConnector(formData20).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData21 = { charge_point_id: 'OCTT_CHARGE_POINT', id_tag: 'octt_user_valid', connector_id: 1 };
  const TC_CS_V16_032_1 = (index) => {
    console.log(formData21);
    remoteStart(formData21).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData22_1 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    key: 'MeterValueSampleInterval',
    value: '10',
    get_variable_data: [],
  };
  const TC_CS_V16_040_1 = (index) => {
    console.log(formData22_1);
    setConfiguration(formData22_1).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData22_2 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    key: 'MeterValueSampleInterval',
    value: '-10',
    get_variable_data: [],
  };
  const TC_CS_V16_040_2 = (index) => {
    console.log(formData22_2);
    setConfiguration(formData22_2).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData23 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    key: 'MeterValueSampleInterval',
    value: '10',
    get_variable_data: [],
  };
  const TC_CS_V16_042_1_1 = (index) => {
    console.log(formData23);
    setConfiguration(formData23).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData24 = { charge_point_id: 'OCTT_CHARGE_POINT' };
  const TC_CS_V16_042_1_2 = (index) => {
    console.log(formData24);
    getLocalList(formData24).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData25 = { charge_point_id: 'OCTT_CHARGE_POINT' };
  const TC_CS_V16_042_2 = (index) => {
    console.log(formData25);
    getLocalList(formData25).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData26 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    list_version: 1,
    update_type: 'Full',
    local_authorization_list: [{ id_tag: 'octt_user_valid' }],
  };
  const TC_CS_V16_043 = (index) => {
    console.log(formData26);
    sendLocalList(formData26).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData27 = { charge_point_id: 'OCTT_CHARGE_POINT' };
  const TC_CS_V16_043_5_1 = (index) => {
    console.log(formData27);
    getLocalList(formData27).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData28 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    list_version: 1,
    update_type: 'Differential',
    local_authorization_list: [{ id_tag: 'octt_user_valid' }],
  };
  const TC_CS_V16_043_5_2 = (index) => {
    console.log(formData28);
    sendLocalList(formData28).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const [formData29, setFormData29] = useState({
    charge_point_id: 'OCTT_CHARGE_POINT',
    location: 'ftp://ftpuser:ftpuser@54.255.185.59/log/firmware/firmware.tar.gz',
    retrieve_date: '2020-06-19T09:10:00Z',
  });
  const TC_CS_V16_044 = (index) => {
    console.log(formData29);
    updateFirmware(formData29).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData30 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    location: 'ftp://ftpuser:ftpuser@54.255.185.59/log/diagnostic/log.txt',
    retries: 0,
    retry_interval: 0,
    octt: true,
  };
  const TC_CS_V16_045 = (index) => {
    console.log(formData30);
    getDiagnostics(formData30).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData31 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    connector_id: 1,
    expiry_date: '2023-08-01T06:29:00Z',
    id_tag: 'octt_user_valid',
    reservation_id: 3,
  };
  const TC_CS_V16_046 = (index) => {
    console.log(formData31);
    reserveNow(formData31).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData32 = { charge_point_id: 'OCTT_CHARGE_POINT', connector_id: 1, type: 'Inoperative' };
  const TC_CS_V16_048_3_1 = (index) => {
    console.log(formData32);
    changeAvailability(formData32).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData33 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    connector_id: 1,
    expiry_date: '2023-08-01T06:29:00Z',
    id_tag: 'octt_user_valid',
    reservation_id: 3,
  };
  const TC_CS_V16_048_3_2 = (index) => {
    console.log(formData33);
    reserveNow(formData33).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData34 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    connector_id: 1,
    expiry_date: '2023-08-01T06:29:00Z',
    id_tag: 'octt_user_valid',
    reservation_id: 3,
  };
  const TC_CS_V16_048_4 = (index) => {
    console.log(formData34);
    reserveNow(formData34).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData35 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    connector_id: 0,
    expiry_date: '2023-08-01T06:29:00Z',
    id_tag: 'octt_user_valid',
    reservation_id: 3,
  };
  const TC_CS_V16_049 = (index) => {
    console.log(formData35);
    reserveNow(formData35).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData36 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    connector_id: 1,
    expiry_date: '2023-08-01T06:29:00Z',
    id_tag: 'octt_user_valid',
    reservation_id: 3,
  };
  const TC_CS_V16_051_1 = (index) => {
    console.log(formData36);
    reserveNow(formData36).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData37 = { charge_point_id: 'OCTT_CHARGE_POINT', reservation_id: 3 };
  const TC_CS_V16_051_2 = (index) => {
    console.log(formData37);
    cancelReservation(formData37).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData38 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    connector_id: 1,
    expiry_date: '2023-08-01T06:29:00Z',
    id_tag: 'octt_user_valid',
    reservation_id: 3,
  };
  const TC_CS_V16_052_1 = (index) => {
    console.log(formData38);
    reserveNow(formData38).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const [formData39, setFormData39] = useState({ charge_point_id: 'OCTT_CHARGE_POINT', reservation_id: 4 });
  const TC_CS_V16_052_2 = (index) => {
    console.log(formData39);
    cancelReservation(formData39).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData40 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    connector_id: 1,
    expiry_date: '2023-08-01T06:29:00Z',
    id_tag: 'octt_user_valid',
    reservation_id: 3,
    parent_id_tag: 'octt-ocpp-1616-1111',
  };
  const TC_CS_V16_053 = (index) => {
    console.log(formData40);
    reserveNow(formData40).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData55 = { charge_point_id: 'OCTT_CHARGE_POINT', requested_message: 'MeterValues' };
  const TC_CS_V16_054_1 = (index) => {
    console.log(formData55);
    triggerMessage(formData55).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData56 = { charge_point_id: 'OCTT_CHARGE_POINT', requested_message: 'Heartbeat' };
  const TC_CS_V16_054_2 = (index) => {
    console.log(formData56);
    triggerMessage(formData56).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData57 = { charge_point_id: 'OCTT_CHARGE_POINT', requested_message: 'StatusNotification' };
  const TC_CS_V16_054_3 = (index) => {
    console.log(formData57);
    triggerMessage(formData57).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData58 = { charge_point_id: 'OCTT_CHARGE_POINT', requested_message: 'DiagnosticsStatusNotification' };
  const TC_CS_V16_054_4 = (index) => {
    console.log(formData58);
    triggerMessage(formData58).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData59 = { charge_point_id: 'OCTT_CHARGE_POINT', requested_message: 'FirmwareStatusNotification' };
  const TC_CS_V16_054_5 = (index) => {
    console.log(formData59);
    triggerMessage(formData59).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData60 = { charge_point_id: 'OCTT_CHARGE_POINT', requested_message: 'BootNotification' };
  const TC_CS_V16_054_6 = (index) => {
    console.log(formData60);
    triggerMessage(formData60).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData61 = { charge_point_id: 'OCTT_CHARGE_POINT', requested_message: 'MeterValues', connector_id: 2 };
  const TC_CS_V16_055 = (index) => {
    console.log(formData61);
    triggerMessage(formData61).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData62 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    connector_id: 1,
    cs_charging_profiles: {
      charging_profile_id: 1,
      charging_profile_purpose: 'TxDefaultProfile',
      charging_profile_kind: 'Absolute',
      stack_level: 1,
      charging_schedule: {
        duration: 300,
        charging_rate_unit: 'W',
        charging_schedule_period: [
          {
            start_period: 1,
            limit: 0.1,
          },
        ],
      },
    },
  };
  const TC_CS_V16_056 = (index) => {
    console.log(formData62);
    setChargingProfile(formData62).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const [formData63, setFormData63] = useState({
    charge_point_id: 'OCTT_CHARGE_POINT',
    connector_id: 1,
    cs_charging_profiles: {
      charging_profile_id: 1,
      charging_profile_purpose: 'TxProfile',
      charging_profile_kind: 'Absolute',
      stack_level: 1,
      transaction_id: 48,
      charging_schedule: {
        duration: 300,
        charging_rate_unit: 'W',
        charging_schedule_period: [
          {
            start_period: 1,
            limit: 0.1,
          },
        ],
      },
    },
  });
  const TC_CS_V16_057 = (index) => {
    const newValue = { ...formData63 };
    newValue.cs_charging_profiles.transaction_id = parseInt(formData63.cs_charging_profiles.transaction_id, 10);
    console.log(newValue);
    setChargingProfile(newValue).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData64 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    id_tag: 'octt_user_valid',
    connector_id: 1,
    charging_profile: {
      charging_profile_id: 1,
      charging_profile_purpose: 'TxProfile',
      charging_profile_kind: 'Absolute',
      stack_level: 1,
      transaction_id: 25,
      charging_schedule: {
        duration: 300,
        charging_rate_unit: 'W',
        charging_schedule_period: [
          {
            start_period: 1,
            limit: 0.1,
          },
        ],
      },
    },
  };
  const TC_CS_V16_059 = (index) => {
    console.log(formData64);
    remoteStart(formData64).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData65 = { charge_point_id: 'OCTT_CHARGE_POINT' };
  const TC_CS_V16_061 = (index) => {
    console.log(formData65);
    clearCache(formData65).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData66 = { charge_point_id: 'OCTT_CHARGE_POINT', vendor_id: 'LOS' };
  const TC_CS_V16_062 = (index) => {
    console.log(formData66);
    sendDataTransfer(formData66).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData67 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    connector_id: 1,
    cs_charging_profiles: {
      charging_profile_id: 1,
      charging_profile_purpose: 'TxProfile',
      charging_profile_kind: 'Absolute',
      stack_level: 1,
      transaction_id: 533,
      charging_schedule: {
        duration: 300,
        charging_rate_unit: 'W',
        charging_schedule_period: [
          {
            start_period: 1,
            limit: 0.1,
          },
        ],
      },
    },
  };
  const TC_CS_V16_066_1 = (index) => {
    console.log(formData67);
    setChargingProfile(formData67).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData68 = { charge_point_id: 'OCTT_CHARGE_POINT', connector_id: 0, duration: 300, charging_rate_unit: 'W' };
  const TC_CS_V16_066_2 = (index) => {
    console.log(formData68);
    getCompositeSchedule(formData68).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData69 = { charge_point_id: 'OCTT_CHARGE_POINT' };
  const TC_CS_V16_067 = (index) => {
    console.log(formData69);
    clearChargingProfile(formData69).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData70 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    key: 'AuthorizationKey',
    value: '4F43415F4F4354545F61646D696E5F74657374',
    get_variable_data: [],
  };
  const TC_CS_V16_073 = (index) => {
    console.log(formData70);
    setConfiguration(formData70).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData71 = { charge_point_id: 'OCTT_CHARGE_POINT', requested_message: 'SignChargePointCertificate' };
  const TC_CS_V16_074_1 = (index) => {
    console.log(formData71);
    extendedTriggerMessage(formData71).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData72 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    certificate_chain:
      '-----BEGIN CERTIFICATE-----\nMIIDdTCCAl2gAwIBAgIUeJesuPfkGx0sbYRq46JdaQ1AVqQwDQYJKoZIhvcNAQEL\nBQAwYjELMAkGA1UEBhMCSU4xFTATBgNVBAgTDFV0dGFyUHJhZGVzaDEOMAwGA1UE\nBxMFTm9pZGExDjAMBgNVBAoTBVZFTklEMQswCQYDVQQLEwJJVDEPMA0GA1UEAxMG\nR0xURVNUMB4XDTIzMDgwMzAxNDkwOVoXDTI0MDgwMjAxNDkwOVowYjELMAkGA1UE\nBhMCSU4xFTATBgNVBAgTDFV0dGFyUHJhZGVzaDEOMAwGA1UEBxMFTm9pZGExDjAM\nBgNVBAoTBVZFTklEMQswCQYDVQQLEwJJVDEPMA0GA1UEAxMGR0xURVNUMIIBIjAN\nBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3TiwPcIEXCkX3ZXZY913d4ComVMm\neFOEavB6eJ5D1ciCo3KtRKZY0ANKXANLvuciL7Uw7749o+DJypG0e2M7i2BkLFm5\nm5/paxmPqKLNXzJp5Zr3cbB39V0xs3eEHIf+nezSjwhzf+3Pxw+xerQirLz/XFyN\nB2Ibd33UcsZMf387BtI6WpJWLtPw4Hzr+mU5/AylnDyGJDWM/mCDy1JQ8sWcwboa\ndwWE0CvotVoXgkRy9HdhxOJ4HbQb6u+xmLgVqycyPcXWDUhmP8gPUfK/Wy/GvrIC\nPRFcPpBbHBsNOv0zJpT1qj+F8Y7lJl4WUfwgDrH9sCPO+Zo+5s87lZEatQIDAQAB\noyMwITAMBgNVHRMBAf8EAjAAMBEGA1UdEQQKMAiCBkdMVEVTVDANBgkqhkiG9w0B\nAQsFAAOCAQEAkaxGYY+LuApLKlCizwt/m7AtTdkpqAJTbos2J3Wbtoq7Jsff5tvl\nCF3hnppr0O9fRRq3OIiRwdv0SUmkvNmaMVEh2AsilKsEDYfx71jKkax1YLOPe7Zb\ny1RBDzhargi8xdocdkh0PjKTjmzL/s5suDzwHBtorH6cQHVrqqONUpDilKQPNUiZ\ntJDmmP4CpUFmCSCMH/wZZN0wIZdcEXB8n5wAz5hSGbSkWCHIpKSjC7/zMcDX3afs\naE4QMdnhnei8JiM9SX0NJjonWmolfM3eCX/2GgvrIEYSn1igBvPbpVtNWQjoEgZc\nxhSR2C7UQf0zwfmmUU/NDTZ1D9/aZlc2wg==\n-----END CERTIFICATE-----\n',
  };
  const TC_CS_V16_074_2 = (index) => {
    console.log(formData72);
    certificateSigned(formData72).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData73 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    certificate_type: 'CentralSystemRootCertificate',
    certificate:
      '-----BEGIN CERTIFICATE-----\nMIIDcTCCAlmgAwIBAgIBATANBgkqhkiG9w0BAQsFADBZMRUwEwYDVQQDDAxPQ1RU\nIFJvb3QgQ0ExHTAbBgNVBAoMFE9wZW4gQ2hhcmdlIEFsbGlhbmNlMQswCQYDVQQG\nEwJOTDEUMBIGCgmSJomT8ixkARkWBE9DVFQwIBcNMjMwMTE5MTYwNTI3WhgPMjA2\nMzAxMDkxNjA1MjdaMFkxFTATBgNVBAMMDE9DVFQgUm9vdCBDQTEdMBsGA1UECgwU\nT3BlbiBDaGFyZ2UgQWxsaWFuY2UxCzAJBgNVBAYTAk5MMRQwEgYKCZImiZPyLGQB\nGRYET0NUVDCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAJogNiaZRbLY\np7QEgpWIJhGPeeSbDCQhW4ddlle8EJhQLThs/FHMiUSns8C27VFgB+5oRbJePvee\n2QHEGwgKnD2k91GOQlNf/ozeSIh4Xs8Za7xvGFmgRKB+V4O5bxb0zdAoIpCa6IBU\nn7r4l2B1xIDLSCw/4iHGUbi2Xn68RrjbwhLyZqtFi330Dar7CQ1X0+l4Dr8WiLq6\n6Ttzuehfh6X9NPWz7YZ+0w2yGpy03mMmq28ZfDYRGJUXo9sI1+D+NxdJawIeJNJy\nloZyyn8r7zYn7E9u1o2qvooY/guXB+hOOuBMaO0GmaLaKU/gkgda7A1440b0UD/0\ny14ZlP+U8KkCAwEAAaNCMEAwDwYDVR0TAQH/BAUwAwEB/zAOBgNVHQ8BAf8EBAMC\nAQYwHQYDVR0OBBYEFEIrcaC/rdvm9l5DIkUrgMNQQzM6MA0GCSqGSIb3DQEBCwUA\nA4IBAQBHoI/lOdBIqF4FRMHgn8lYFFzqHvCBklIttqbhOavcUwfspr0muuDXHjdt\nHzCfy3BhFADDc7NlhMWGTyeP4rHGOOkxl0vhtKHMOepEvrX5I+Yk/uWR8CHU7AAA\noIMi/7HHB7RTjJ7WFj1JSLZ8taHaQbGmTeEWdVmFF6hiqIN4XPFV4stLWH+aBSAG\nKfAVBAtwyPPrCl7lsCtL/uAjNThTO263kfNyOJCBzmmpASeLDJReca5ruqN5d7ob\nQt5KNu9WZir3k2eiTMlKdC1mFENVs4QXqCQiCg8reHnamVdBb6rIFNfpWPqo3F0h\nBQZpak/V4YyNs1GIznnSc1pjGD+I\n-----END CERTIFICATE-----',
  };
  const TC_CS_V16_075_1 = (index) => {
    console.log(formData73);
    installCertificate(formData73).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData74 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    certificate_type: 'CentralSystemRootCertificate',
  };
  const TC_CS_V16_075_2 = (index) => {
    console.log(formData74);
    getInstalledCertificateIds(formData74).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData75 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    certificate_hash_data: {
      hash_algorithm: 'SHA256',
      issuer_name_hash: 'de61235ee2958b67c7a47469cccb7ccbf77501d57af72c437ca092467fa00cac',
      issuer_key_hash: '1292cf3be39983c7b5254332d8674ba6a96268eebc14d598b56e0e4c090bc5c3',
      serial_number: '1',
    },
  };
  const TC_CS_V16_076_1 = (index) => {
    console.log(formData75);
    deleteCertificate(formData75).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData76 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    certificate_type: 'CentralSystemRootCertificate',
  };
  const TC_CS_V16_076_2 = (index) => {
    console.log(formData76);
    getInstalledCertificateIds(formData76).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData77 = { charge_point_id: 'OCTT_CHARGE_POINT', requested_message: 'SignChargePointCertificate' };
  const TC_CS_V16_077_1 = (index) => {
    console.log(formData77);
    extendedTriggerMessage(formData77).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData78 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    certificate_chain:
      '-----BEGIN CERTIFICATE-----\nMIIDdTCCAl2gAwIBAgIUeJesuPfkGx0sbYRq46JdaQ1AVqQwDQYJKoZIhvcNAQEL\nBQAwYjELMAkGA1UEBhMCSU4xFTATBgNVBAgTDFV0dGFyUHJhZGVzaDEOMAwGA1UE\nBxMFTm9pZGExDjAMBgNVBAoTBVZFTklEMQswCQYDVQQLEwJJVDEPMA0GA1UEAxMG\nR0xURVNUMB4XDTIzMDgwMzAxNDkwOVoXDTI0MDgwMjAxNDkwOVowYjELMAkGA1UE\nBhMCSU4xFTATBgNVBAgTDFV0dGFyUHJhZGVzaDEOMAwGA1UEBxMFTm9pZGExDjAM\nBgNVBAoTBVZFTklEMQswCQYDVQQLEwJJVDEPMA0GA1UEAxMGR0xURVNUMIIBIjAN\nBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3TiwPcIEXCkX3ZXZY913d4ComVMm\neFOEavB6eJ5D1ciCo3KtRKZY0ANKXANLvuciL7Uw7749o+DJypG0e2M7i2BkLFm5\nm5/paxmPqKLNXzJp5Zr3cbB39V0xs3eEHIf+nezSjwhzf+3Pxw+xerQirLz/XFyN\nB2Ibd33UcsZMf387BtI6WpJWLtPw4Hzr+mU5/AylnDyGJDWM/mCDy1JQ8sWcwboa\ndwWE0CvotVoXgkRy9HdhxOJ4HbQb6u+xmLgVqycyPcXWDUhmP8gPUfK/Wy/GvrIC\nPRFcPpBbHBsNOv0zJpT1qj+F8Y7lJl4WUfwgDrH9sCPO+Zo+5s87lZEatQIDAQAB\noyMwITAMBgNVHRMBAf8EAjAAMBEGA1UdEQQKMAiCBkdMVEVTVDANBgkqhkiG9w0B\nAQsFAAOCAQEAkaxGYY+LuApLKlCizwt/m7AtTdkpqAJTbos2J3Wbtoq7Jsff5tvl\nCF3hnppr0O9fRRq3OIiRwdv0SUmkvNmaMVEh2AsilKsEDYfx71jKkax1YLOPe7Zb\ny1RBDzhargi8xdocdkh0PjKTjmzL/s5suDzwHBtorH6cQHVrqqONUpDilKQPNUiZ\ntJDmmP4CpUFmCSCMH/wZZN0wIZdcEXB8n5wAz5hSGbSkWCHIpKSjC7/zMcDX3afs\naE4QMdnhnei8JiM9SX0NJjonWmolfM3eCX/2GgvrIEYSn1igBvPbpVtNWQjoEgZc\nxhSR2C7UQf0zwfmmUU/NDTZ1D9/aZlc2wg==\n-----END CERTIFICATE-----\n',
  };
  const TC_CS_V16_077_2 = (index) => {
    console.log(formData78);
    certificateSigned(formData78).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData79 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    certificate_type: 'CentralSystemRootCertificate',
    certificate:
      '-----BEGIN CERTIFICATE-----\nMIIDcTCCAlmgAwIBAgIBATANBgkqhkiG9w0BAQsFADBZMRUwEwYDVQQDDAxPQ1RU\nIFJvb3QgQ0ExHTAbBgNVBAoMFE9wZW4gQ2hhcmdlIEFsbGlhbmNlMQswCQYDVQQG\nEwJOTDEUMBIGCgmSJomT8ixkARkWBE9DVFQwIBcNMjMwMTE5MTYwNTI3WhgPMjA2\nMzAxMDkxNjA1MjdaMFkxFTATBgNVBAMMDE9DVFQgUm9vdCBDQTEdMBsGA1UECgwU\nT3BlbiBDaGFyZ2UgQWxsaWFuY2UxCzAJBgNVBAYTAk5MMRQwEgYKCZImiZPyLGQB\nGRYET0NUVDCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAJogNiaZRbLY\np7QEgpWIJhGPeeSbDCQhW4ddlle8EJhQLThs/FHMiUSns8C27VFgB+5oRbJePvee\n2QHEGwgKnD2k91GOQlNf/ozeSIh4Xs8Za7xvGFmgRKB+V4O5bxb0zdAoIpCa6IBU\nn7r4l2B1xIDLSCw/4iHGUbi2Xn68RrjbwhLyZqtFi330Dar7CQ1X0+l4Dr8WiLq6\n6Ttzuehfh6X9NPWz7YZ+0w2yGpy03mMmq28ZfDYRGJUXo9sI1+D+NxdJawIeJNJy\nloZyyn8r7zYn7E9u1o2qvooY/guXB+hOOuBMaO0GmaLaKU/gkgda7A1440b0UD/0\ny14ZlP+U8KkCAwEAAaNCMEAwDwYDVR0TAQH/BAUwAwEB/zAOBgNVHQ8BAf8EBAMC\nAQYwHQYDVR0OBBYEFEIrcaC/rdvm9l5DIkUrgMNQQzM6MA0GCSqGSIb3DQEBCwUA\nA4IBAQBHoI/lOdBIqF4FRMHgn8lYFFzqHvCBklIttqbhOavcUwfspr0muuDXHjdt\nHzCfy3BhFADDc7NlhMWGTyeP4rHGOOkxl0vhtKHMOepEvrX5I+Yk/uWR8CHU7AAA\noIMi/7HHB7RTjJ7WFj1JSLZ8taHaQbGmTeEWdVmFF6hiqIN4XPFV4stLWH+aBSAG\nKfAVBAtwyPPrCl7lsCtL/uAjNThTO263kfNyOJCBzmmpASeLDJReca5ruqN5d7ob\nQt5KNu9WZir3k2eiTMlKdC1mFENVs4QXqCQiCg8reHnamVdBb6rIFNfpWPqo3F0h\nBQZpak/V4YyNs1GIznnSc1pjGD+I\n-----END CERTIFICATE-----',
  };
  const TC_CS_V16_078 = (index) => {
    console.log(formData79);
    installCertificate(formData79).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData80 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    log: { remote_location: 'ftp://ftpuser:ftpuser@54.255.185.59/log/diagnostic/log.txt' },
    log_type: 'SecurityLog',
    request_id: 1,
  };
  const TC_CS_V16_079 = (index) => {
    console.log(formData80);
    getLog(formData80).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData81 = {
    charge_point_id: 'OCTT_CHARGE_POINT',
    request_id: 1,
    firmware: {
      location: '',
      retrieve_date_time: '2023-07-14T07:12:01.399591Z',
      signing_certificate:
        '-----BEGIN CERTIFICATE-----\nMIICUTCCAfugAwIBAgIBADANBgkqhkiG9w0BAQQFADBXMQswCQYDVQQGEwJDTjEL\nMAkGA1UECBMCUE4xCzAJBgNVBAcTAkNOMQswCQYDVQQKEwJPTjELMAkGA1UECxMC\nVU4xFDASBgNVBAMTC0hlcm9uZyBZYW5nMB4XDTA1MDcxNTIxMTk0N1oXDTA1MDgx\nNDIxMTk0N1owVzELMAkGA1UEBhMCQ04xCzAJBgNVBAgTAlBOMQswCQYDVQQHEwJD\nTjELMAkGA1UEChMCT04xCzAJBgNVBAsTAlVOMRQwEgYDVQQDEwtIZXJvbmcgWWFu\nZzBcMA0GCSqGSIb3DQEBAQUAA0sAMEgCQQCp5hnG7ogBhtlynpOS21cBewKE/B7j\nV14qeyslnr26xZUsSVko36ZnhiaO/zbMOoRcKK9vEcgMtcLFuQTWDl3RAgMBAAGj\ngbEwga4wHQYDVR0OBBYEFFXI70krXeQDxZgbaCQoR4jUDncEMH8GA1UdIwR4MHaA\nFFXI70krXeQDxZgbaCQoR4jUDncEoVukWTBXMQswCQYDVQQGEwJDTjELMAkGA1UE\nCBMCUE4xCzAJBgNVBAcTAkNOMQswCQYDVQQKEwJPTjELMAkGA1UECxMCVU4xFDAS\nBgNVBAMTC0hlcm9uZyBZYW5nggEAMAwGA1UdEwQFMAMBAf8wDQYJKoZIhvcNAQEE\nBQADQQA/ugzBrjjK9jcWnDVfGHlk3icNRq0oV7Ri32z/+HQX67aRfgZu7KWdI+Ju\nWm7DCfrPNGVwFWUQOmsPue9rZBgO\n-----END CERTIFICATE-----',
      signature: 'U2lnbmF0dXJl',
    },
  };
  const TC_CS_V16_080 = (index) => {
    console.log(formData81);
    signedUpdateFirmware(formData81).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const [successStates, setSuccessStates] = useState(new Array(80).fill(false));

  return (
    <Page title={'octt'}>
      <Container maxWidth="false">
        <Typography
          sx={{
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '36px',
            color: 'texts.main',
            mb: 2,
          }}
        >
          OCTT
        </Typography>
        TC_CS_V16_002_2 Cold Boot Charge Point - Pending{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_002_2('1')} isSuccess={successStates[1]} />
        <br />
        <br />
        TC_CS_V16_002_3 Cold Boot Charge Point - Pending{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_002_3('2')} isSuccess={successStates[2]} />
        <br />
        <br />
        TC_CS_V16_010 Remote start charging session - cable plugged in first{' '}
        <ButtonInput name="Send" onClick={() => remoteStartTransaction('3')} isSuccess={successStates[3]} />
        <br />
        <br />
        TC_CS_V16_011_1 Remote Start Charging Session – Remote Start First{' '}
        <ButtonInput name="Send" onClick={() => remoteStartTransaction('4')} isSuccess={successStates[4]} />
        <br />
        <br />
        TC_CS_V16_011_2 Remote Start Charging Session - Time Out{' '}
        <ButtonInput name="Send" onClick={() => remoteStartTransaction('5')} isSuccess={successStates[5]} />
        <br />
        <br />
        TC_CS_V16_012_1 Remote stop charging session{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_012_1('6')} isSuccess={successStates[6]} />
        <br />
        <br />
        TC_CS_V16_012_2 Remote stop charging session{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_012_2('7')} isSuccess={successStates[7]} />
        <br />
        transaction_id:{' '}
        <input
          value={formData5.transaction_id}
          onChange={(event) => setFormData5({ ...formData5, transaction_id: event.target.value })}
        />
        <br />
        <br />
        TC_CS_V16_013 Hard reset without transaction{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_013('8')} isSuccess={successStates[8]} />
        <br />
        <br />
        TC_CS_V16_014 Soft reset without transaction{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_014('9')} isSuccess={successStates[9]} />
        <br />
        <br />
        TC_CS_V16_015 Hard reset with transaction{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_015_1('10')} isSuccess={successStates[10]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_015_2('11')} isSuccess={successStates[11]} />{' '}
        <ButtonInput name="Step 3" onClick={() => TC_CS_V16_015_3('12')} isSuccess={successStates[12]} />{' '}
        <ButtonInput name="Step 4" onClick={() => TC_CS_V16_015_4('13')} isSuccess={successStates[13]} />
        <br />
        <br />
        TC_CS_V16_017_1 Unlock connector no charging session running(Not fixed cable){' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_017('15')} isSuccess={successStates[15]} />
        <br />
        <br />
        TC_CS_V16_017_2 Unlock connector no charging session running(Fixed cable){' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_017('16')} isSuccess={successStates[16]} />
        <br />
        <br />
        TC_CS_V16_018_1 Unlock connector with charging session{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_018_1_1('17')} isSuccess={successStates[17]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_018_1_2('18')} isSuccess={successStates[18]} />
        <br />
        <br />
        TC_CS_V16_019_1 Retrieve all configuration keys{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_019_1('19')} isSuccess={successStates[19]} />
        <br />
        <br />
        TC_CS_V16_019_2 Retrieve specific configuration key{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_019_2('20')} isSuccess={successStates[20]} />
        <br />
        <br />
        TC_CS_V16_021 Change/Set configuration{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_021('21')} isSuccess={successStates[21]} />
        <br />
        <br />
        TC_CS_V16_023_1 Start charging session - authorize invalid / blocked / expired (Change IdTag 2 to ZYDX23)
        <br />
        <br />
        TC_CS_V16_023_2 Start Charging Session – Authorize invalid (Change IdTag 2 to octt_user_expired )
        <br />
        <br />
        TC_CS_V16_023_3 Start Charging Session – Authorize invalid (Change IdTag 2 to octt_user_blocked)
        <br />
        <br />
        TC_CS_V16_026 Remote start charging session - rejected{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_026('22')} isSuccess={successStates[22]} />
        <br />
        <br />
        TC_CS_V16_028 Remote stop transaction - rejected (everytime test incremental 1){' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_028('23')} isSuccess={successStates[23]} />
        <br />
        transaction_id:{' '}
        <input
          value={formData19.transaction_id}
          onChange={(event) => setFormData19({ ...formData19, transaction_id: event.target.value })}
        />
        <br />
        <br />
        TC_CS_V16_030 <ButtonInput name="Send" onClick={() => TC_CS_V16_030('24')} isSuccess={successStates[24]} />
        <br />
        <br />
        TC_CS_V16_031 <ButtonInput name="Send" onClick={() => TC_CS_V16_030('25')} isSuccess={successStates[25]} />
        <br />
        <br />
        TC_CS_V16_032_1 <ButtonInput name="Send" onClick={() => TC_CS_V16_032_1('26')} isSuccess={successStates[26]} />
        <br />
        <br />
        TC_CS_V16_037_3 offline start transaction (Change IdTag 2 to ZYDX23)
        <br />
        <br />
        TC_CS_V16_040_1 Configuration keys{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_040_1('27')} isSuccess={successStates[27]} />
        <br />
        <br />
        TC_CS_V16_040_2 Configuration keys{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_040_2('28')} isSuccess={successStates[28]} />
        <br />
        <br />
        TC_CS_V16_042_1 Get Local List Version,Non-Happy case{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_042_1_1('29')} isSuccess={successStates[29]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_042_1_2('30')} isSuccess={successStates[30]} />
        <br />
        <br />
        TC_CS_V16_042_2 Get Local List Version,Local Auth List Empty{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_042_2('31')} isSuccess={successStates[31]} />
        <br />
        <br />
        TC_CS_V16_043_1 SendLocalAuthorizationList-UpdatedStatus=NotSupported{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_043('32')} isSuccess={successStates[32]} />
        <br />
        <br />
        TC_CS_V16_043_3 SendLocalAuthorizationList-UpdatedStatus=FAILED{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_043('33')} isSuccess={successStates[33]} />
        <br />
        <br />
        TC_CS_V16_043_4 Send Local Authorization List - Full{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_043('34')} isSuccess={successStates[34]} />
        <br />
        <br />
        TC_CS_V16_043_5 Send Local Authorization List - Differential{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_043_5_1('35')} isSuccess={successStates[35]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_043_5_2('36')} isSuccess={successStates[36]} />
        <br />
        <br />
        TC_CS_V16_044_1 Firmware Update -Download and Install{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_044('37')} isSuccess={successStates[37]} />
        <br />
        location:{' '}
        <input
          value={formData29.location}
          onChange={(event) => setFormData29({ ...formData29, location: event.target.value })}
        />
        <br />
        <br />
        TC_CS_V16_044_2 Firmware Update -Download-failed ;Status Notification{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_044('38')} isSuccess={successStates[38]} />
        <br />
        location:{' '}
        <input
          value={formData29.location}
          onChange={(event) => setFormData29({ ...formData29, location: event.target.value })}
        />
        <br />
        <br />
        TC_CS_V16_044_3 Firmware Update -Installation-Failed;Status Notification{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_044('39')} isSuccess={successStates[39]} />
        <br />
        location:{' '}
        <input
          value={formData29.location}
          onChange={(event) => setFormData29({ ...formData29, location: event.target.value })}
        />
        <br />
        <br />
        TC_CS_V16_045_1 Get Diagnostics of charge point{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_045('40')} isSuccess={successStates[40]} />
        <br />
        <br />
        TC_CS_V16_045_2 Get Diagnostics of charge point;Upload failed{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_045('41')} isSuccess={successStates[41]} />
        <br />
        <br />
        TC_CS_V16_046 Reservation of a Connector - Local start transaction{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_046('42')} isSuccess={successStates[42]} />
        <br />
        <br />
        TC_CS_V16_047 Reservation of a Connector - Expire{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_046('43')} isSuccess={successStates[43]} />
        <br />
        <br />
        TC_CS_V16_048_1 Reservation of a Connector- Faulted{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_046('44')} isSuccess={successStates[44]} />
        <br />
        <br />
        TC_CS_V16_048_2 Reservation of a Connector- Occupied{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_046('45')} isSuccess={successStates[45]} />
        <br />
        <br />
        TC_CS_V16_048_3 Reservation of a Connector- Unavailable{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_048_3_1('46')} isSuccess={successStates[46]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_048_3_2('47')} isSuccess={successStates[47]} />
        <br />
        <br />
        TC_CS_V16_048_4 Reservation of a Connector- Rejected{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_048_4('48')} isSuccess={successStates[48]} />
        <br />
        <br />
        TC_CS_V16_049 Reservation of a Charge Point- Transaction{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_049('49')} isSuccess={successStates[49]} />
        <br />
        <br />
        TC_CS_V16_051 Cancel Reservation -Accepted{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_051_1('50')} isSuccess={successStates[50]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_051_2('51')} isSuccess={successStates[51]} />
        <br />
        <br />
        TC_CS_V16_052 Cancel Reservation-Rejected (required different reservation id) <br />
        reservation_id: <input value={formData38.reservation_id} />{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_052_1('52')} isSuccess={successStates[52]} />
        <br />
        reservation_id:{' '}
        <input
          value={formData39.reservation_id}
          onChange={(event) => setFormData39({ ...formData39, reservation_id: event.target.value })}
        />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_052_2('53')} isSuccess={successStates[53]} />
        <br />
        <br />
        TC_CS_V16_053 Use a reserved Connector with parentIdTag{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_053('54')} isSuccess={successStates[54]} />
        <br />
        <br />
        TC_CS_V16_054 Trigger Message{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_054_1('55')} isSuccess={successStates[55]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_054_2('56')} isSuccess={successStates[56]} />{' '}
        <ButtonInput name="Step 3" onClick={() => TC_CS_V16_054_3('57')} isSuccess={successStates[57]} />{' '}
        <ButtonInput name="Step 4" onClick={() => TC_CS_V16_054_4('58')} isSuccess={successStates[58]} />{' '}
        <ButtonInput name="Step 5" onClick={() => TC_CS_V16_054_5('59')} isSuccess={successStates[59]} />{' '}
        <ButtonInput name="Step 6" onClick={() => TC_CS_V16_054_6('60')} isSuccess={successStates[60]} />
        <br />
        <br />
        TC_CS_V16_055 <ButtonInput name="Send" onClick={() => TC_CS_V16_055('61')} isSuccess={successStates[61]} />
        <br />
        <br />
        TC_CS_V16_056 <ButtonInput name="Send" onClick={() => TC_CS_V16_056('62')} isSuccess={successStates[62]} />
        <br />
        <br />
        TC_CS_V16_057 latest transaction_id from database{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_057('63')} isSuccess={successStates[63]} />
        <br />
        transaction_id:{' '}
        <input
          value={formData63.cs_charging_profiles.transaction_id}
          onChange={(event) => {
            const newFormData = { ...formData63 };
            newFormData.cs_charging_profiles.transaction_id = event.target.value;
            setFormData63(newFormData);
          }}
        />
        <br />
        <br />
        TC_CS_V16_059 Remote Start Transaction with Charging Profile{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_059('64')} isSuccess={successStates[64]} />
        <br />
        <br />
        TC_CS_V16_061 Clear Authorization Data in Authorization Cache{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_061('65')} isSuccess={successStates[65]} />
        <br />
        <br />
        TC_CS_V16_062 Data Transfer to a Charge Point (Listener){' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_062('66')} isSuccess={successStates[66]} />
        <br />
        <br />
        TC_CS_V16_066 Get Composite Schedule{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_066_1('67')} isSuccess={successStates[67]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_066_2('68')} isSuccess={successStates[68]} />
        <br />
        <br />
        TC_CS_V16_067 Clear Charging Profile{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_067('69')} isSuccess={successStates[69]} />
        <br />
        <br />
        TC_CS_V16_073 Update Charge Point Password for HTTP Basic Authentication{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_073('70')} isSuccess={successStates[70]} />
        <br />
        <br />
        TC_CS_V16_074 Update Charge Point Certificate by request of Central System{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_074_1('14')} isSuccess={successStates[14]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_074_2('71')} isSuccess={successStates[71]} />
        <br />
        <br />
        TC_CS_V16_075 Install a certificate on the Charge Point{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_075_1('72')} isSuccess={successStates[72]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_075_2('73')} isSuccess={successStates[73]} />
        <br />
        <br />
        TC_CS_V16_076 Delete a specific certificate from the Charge Point{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_076_1('74')} isSuccess={successStates[74]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_076_2('75')} isSuccess={successStates[75]} />
        <br />
        <br />
        TC_CS_V16_077 Invalid ChargePointCertificate Security Event{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_077_1('76')} isSuccess={successStates[76]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_077_2('77')} isSuccess={successStates[77]} />
        <br />
        <br />
        TC_CS_V16_078 Invalid CentralSystemCertificate Security Event{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_078('78')} isSuccess={successStates[78]} />
        <br />
        <br />
        TC_CS_V16_079 Get Security Log{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_079('79')} isSuccess={successStates[79]} />
        <br />
        <br />
        TC_CS_V16_080 Secure Firmware Update{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_080('80')} isSuccess={successStates[80]} />
        <br />
      </Container>
    </Page>
  );
}

const ButtonInput = ({ name, onClick, isSuccess }) => {
  const buttonStyles = {
    backgroundColor: isSuccess ? 'green' : 'white',
    fontSize: '18px',
    padding: '7px 15px',
  };
  return (
    <>
      <button style={buttonStyles} onClick={onClick}>
        {name}
      </button>
    </>
  );
};

const FormInput = ({ label, formData, setFormData }) => {
  return (
    <>
      {label}:{' '}
      <input value={formData[label]} onChange={(event) => setFormData({ ...formData, [label]: event.target.value })} />
      <br />
    </>
  );
};
