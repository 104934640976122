import { useState, useEffect, useContext } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
import { AccountContext } from '../../auth/AWSCognito/Account';
// mui
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Stack,
  Dialog,
  Avatar,
  Tooltip,
  TextField,
  IconButton,
  DialogTitle,
  Autocomplete,
  DialogContent,
  LinearProgress,
} from '@mui/material';
// components
import LoginAgain from '../../../components/errors/LoginAgain';
import { FormProvider } from '../../../components/hook-form';
import Icon from '../../../components/customIcon/Union.svg';
import { buttonR, buttonL } from '../../../components/button/buttonStyle';
import CloseIcon from '../../../components/customIcon/Close.svg';
import { fetchCS, SubmitAdminEditDialog } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import { CognitoUserAttribute, CognitoRefreshToken } from 'amazon-cognito-identity-js';
// ----------------------------------------------------------------------

export default function AdminEditDialog(props) {
  const { t } = useTranslation();
  const { info, all_charging_station } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState();
  const [employee_id, setEmployee_id] = useState();
  const [email, setEmail] = useState();
  const [charging_station_id, setCharging_station_id] = useState();
  // const [all_charging_station, setAll_charging_station] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const { getSession, logout } = useContext(AccountContext);

  const [loading_pre, setLoading_pre] = useState(false);
  // useEffect(() => {
  //   fetchCS(setError, setErrorMessage, setUnauthorized).then((promiseResult) => {
  //     if (!promiseResult.message) {
  //       setAll_charging_station(promiseResult);
  //     } else {
  //       setError(true);
  //       setErrorMessage(promiseResult.message);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);
  useEffect(() => {
    setName(info.name);
    // setEmployee_id(info.employee_id);
    setEmail(info.email);
    setCharging_station_id(info.charging_station_name);
  }, [info]);
  const handleClickOpen = () => {
    setOpen(true);
    setError(false);
  };
  const handleClose = () => {
    setOpen(false);
    setName(info.name);
    // setEmployee_id(info.employee_id);
    setEmail(info.email);
    setCharging_station_id(info.charging_station_name);
  };

  const onSubmit = () => {
    setLoading(true);
    setLoading_pre(true);
    // email, name,
    const id = info.id;
    let idArr = [];
    charging_station_id.map((item, ind) => {
      idArr.push(item.charging_station);
    });
    SubmitAdminEditDialog(id, idArr, props.setError, props.setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.EditUpdate(Math.random());
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
      setLoading_pre(false);
    });
  };
  // const onSubmit = (event) => {
  //   event.preventDefault();
  //   getSession().then(({ user }) => {
  //     const attributes = [new CognitoUserAttribute({ Name: 'name', Value: name })];
  //     user.updateAttributes(attributes, (err, results) => {
  //       if (err) {
  //         props.setErrorMessage(promiseResult.message);
  //         props.setError(true);
  //       } else {
  //         console.log(results);
  //         props.setSuccess(true);
  //         props.EditUpdate(Math.random());
  //         handleClose();
  //       }
  //     });
  //   });
  // };
  // submit form
  const methods = useForm({
    reValidateMode: 'onBlur',
  });

  const { control } = methods;

  return (
    <Box>
      <Tooltip title={t('Edit')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '35px', height: '35px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="EditAdminDialog">
        {unauthorized && <LoginAgain />}
        {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="EditAdminDialog">
          <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            {t('Edit Admin')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} minHeight="10vh">
            <IconButton color="primary" aria-label={t('upload picture')} component="label">
              {/* <input hidden accept="image/*" type="file" /> */}
              <Avatar
                sx={{ justifyContent: 'center', alignItems: 'center', m: 4, width: 80, height: 80 }}
                src="/broken-image.jpg"
                // Alt="Profile Picture"
              />
            </IconButton>
          </Box>

          <FormProvider methods={methods}>
            <Stack spacing={3} direction={{ xs: 'column' }} width={500}>
              <TextField
                disabled
                name="name"
                margin="dense"
                label={t('Admin Name')}
                onChange={(e) => setName(e.target.value)}
                value={name}
                fullWidth
                variant="outlined"
              />
              {/* <TextField
                disabled
                name="employee_id"
                margin="dense"
                label={t('Email Address')}
                onChange={(e) => setEmployee_id(e.target.value)}
                value={employee_id}
                fullWidth
                variant="outlined"
              /> */}
              <TextField
                disabled
                name="email"
                margin="dense"
                label={t('Email Address')}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                fullWidth
                variant="outlined"
              />
              <Controller
                control={control}
                name="charging_station_name"
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    multiple
                    value={charging_station_id}
                    options={all_charging_station}
                    getOptionLabel={(option) => option.charging_station_name}
                    onChange={(_, data) => onChange(setCharging_station_id(data))}
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        fullWidth
                        inputRef={ref}
                        variant="outlined"
                        label={t('Charging Station(s)')}
                      />
                    )}
                  />
                )}
              />
              <Stack direction="row" sx={{ justifyContent: 'flex-end' }} spacing={2}>
                <LoadingButton variant="outlined" onClick={handleClose} sx={buttonL}>
                  {t('Cancel')}
                </LoadingButton>
                <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
                  {t('Save')}
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
