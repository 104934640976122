import React, { useState, useEffect } from 'react';
// mui
import { Box, Menu, MenuItem, IconButton, Tooltip, LinearProgress } from '@mui/material';
// component
import ChangeAvailability from './changeavailability/index';
import Smartcharging from './smartcharging/index';
import Icon from '../../../../../../../components/customIcon/icon-kebab-menu-24.svg';
import { useTranslation } from 'react-i18next';
import { fetchEVSECS } from '../../../../../../../api/AllAPI';
import ViewInfomation from '../../../../viewInfomation';
// ----------------------------------------------------------------------

export default function ChargingStationMenu(props) {
  const { t } = useTranslation();
  const { stationID, setErrorMessage, setError } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [evseList, setEvseList] = useState([]);
  const open = Boolean(anchorEl);
  const [loading_pre, setLoading_pre] = useState(false);
  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setLoading_pre(true);
    fetchEVSECS(stationID, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.message) {
        return;
      } else {
        setEvseList(promiseResult);
      }
      setLoading_pre(false);
    });
  }, []);
  const handelDiagram = () => {
    console.log('props.evseData', props.evseData);
    props.setTableValue('3');
    sessionStorage.setItem('changer_diagram_locationData', JSON.stringify(props.locationData));
    sessionStorage.setItem('changer_diagram_stationData', JSON.stringify(props.station));
    props.evseData ? sessionStorage.setItem('changer_diagram_evseData', JSON.stringify(props.evseData)) : null;
  };
  return (
    <div>
      <Tooltip title={t('Menu')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="chargingstationmenu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem disabled={evseList.length == 0 ? true : false}>
          {loading_pre && (
            <div style={{ top: '0px', width: '170px', position: 'absolute' }}>
              <LinearProgress />
            </div>
          )}
          <ChangeAvailability
            handleCloseMain={handleClose}
            evseList={evseList}
            stationID={stationID}
            setSuccess={(childData) => props.setSuccess(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={(childData) => props.setError(childData)}
            CAUpdate={(childData) => props.CAUpdate(childData)}
          />
        </MenuItem>
        <MenuItem>
          <Smartcharging
            andleCloseMain={handleClose}
            stationID={stationID}
            setSuccess={(childData) => props.setSuccess(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={(childData) => props.setError(childData)}
            CAUpdate={(childData) => props.CAUpdate(childData)}
          />
        </MenuItem>
        <MenuItem>
          {' '}
          {/* diagram View */}
          <MenuItem onClick={handelDiagram}>{t('Diagram View')}</MenuItem>
          {/* <ViewInfomation /> */}
          {/* station={station} */}
        </MenuItem>
      </Menu>
    </div>
  );
}
