import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  Button,
  Dialog,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { buttonR, buttonL } from '../../../../components/button/buttonStyle';
import CloseIcon from '../../../../components/customIcon/Close.svg';
import { Charger_AddLocation } from '../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function AddNewLocationDialog(props) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState();
  const [error_text, setError_text] = useState(false);
  const [helperText_text, setHelperText_text] = useState('');

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setError_text(false);
    setHelperText_text('');
    setLocation(e.target.value);
  };

  const onSubmit = () => {
    if (!location) {
      setHelperText_text('required fields');
      setError_text(true);
    } else {
      if (location.length < 1 || location.length > 100) {
        setError_text(true);
        setHelperText_text(t('Invalid value') + ' (minLength:1, maxLength:100).');
        return;
      }
      setHelperText_text('');
      setError_text(false);
      console.log('location=>', location);
      setLoading(true);
      Charger_AddLocation(JSON.stringify({ location: location }), setError, setErrorMessage).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          props.LocationUpdate(Math.random());
          props.setSuccess(true);
          handleClose();
          setLocation();
        } else {
          props.setErrorMessage(promiseResult.message);
          props.setError(true);
        }
        setLoading(false);
      });
    }
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
          boxShadow: 0,
          color: 'primary.contrastText',
          fontFamily: 'Arial',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '22px',
          textAlign: 'center',
          height: '32px',
          width: '135px',
        }}
      >
        + {t('Add Location')}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth aria-labelledby="AddNewLocationDialog">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="AddNewLocationTitle">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Add New Location')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <span style={{ color: 'red' }}>* {t('Mandatory fields')}</span>
          <Stack direction="column" justifyContent="flex-start" spacing={2}>
            <TextField
              margin="dense"
              name="locationName"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Location Name')}
                </>
              }
              error={error_text}
              helperText={helperText_text}
              onChange={handleChange}
              value={location}
              fullWidth
              variant="outlined"
            />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <LoadingButton variant="outlined" onClick={handleClose} sx={buttonL}>
                {t('Cancel')}
              </LoadingButton>
              <LoadingButton onClick={onSubmit} disabled={loading} sx={buttonR}>
                {t('Add')}
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
