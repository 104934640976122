import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
//right left
const ArrowFlow_level = ({ direction, color1, color2, width, id, marginTop, status }) => {
  const [forward, setForward] = useState(true);
  const startPoint = '0';
  const endPoint = width;
  // const width = '200';
  const height = '10';
  const speed = 5000;
  const lineGradient = `url(#${id}-line)`;
  const arrowGradient = `url(#${id}-arrow)`;

  const animatedProps = useSpring({
    from: {
      position: direction == 'right' ? startPoint : endPoint,
    },
    to: {
      position: direction == 'right' ? endPoint : startPoint,
    },
    config: { duration: speed },
    reset: true,
    onRest: () => setForward(!forward),
  });

  return (
    <svg width={width} height={height} style={{ marginTop: marginTop, marginRight: '-3px' }}>
      <defs>
        <linearGradient id={`${id}-line`}>
          <stop offset="0%" stopColor={color1} />
          <stop offset="100%" stopColor={color2} />
        </linearGradient>
        <linearGradient id={`${id}-arrow`}>
          <stop offset="0%" stopColor={color1} />
          <stop offset="100%" stopColor={color2} />
        </linearGradient>
      </defs>

      <line x1={startPoint} y1="5" x2={endPoint} y2="5.00001" stroke={lineGradient} strokeWidth="2" />
      {/* <path d="M10,10 L140,10 Q140,10 150,0," fill="transparent" stroke={'url(#' + id + ')'} /> */}
      {status !== 'static' && (
        <animated.polygon
          points={direction == 'right' ? '0,0 0,10 10,5' : '10,0 10,10 0,5'}
          fill={arrowGradient}
          style={{
            transform: animatedProps.position
              ? animatedProps.position.interpolate((x) => `translateX(${x}px)`)
              : 'translateX(0px)',
          }}
        />
      )}
    </svg>
  );
};
const ArrowFlow_vertical = ({ direction, color1, color2, height, id, status }) => {
  const [forward, setForward] = useState(true);
  const startPoint = '0';
  const endPoint = JSON.stringify(Number(height) - 10);
  const width = '4';
  const speed = 5000;

  const lineGradient = `url(#${id}-line)`;
  const arrowGradient = `url(#${id}-arrow)`;

  const animatedProps1 = useSpring({
    from: {
      position: direction === 'down' ? startPoint : endPoint,
    },
    to: {
      position: direction === 'down' ? endPoint : startPoint,
    },
    config: { duration: speed },
    reset: true,
    onRest: () => setForward(!forward),
  });

  return (
    <svg width={width} height={height} style={{ position: 'relative', overflow: 'inherit' }}>
      <defs>
        <linearGradient id={`${id}-line`}>
          <stop offset="0%" stopColor={color1} />
          <stop offset="100%" stopColor={color2} />
        </linearGradient>
        <linearGradient id={`${id}-arrow`}>
          <stop offset="0%" stopColor={color1} />
          <stop offset="100%" stopColor={color2} />
        </linearGradient>
      </defs>
      <line y1={startPoint} x1="5" y2={height} x2="5.0001" strokeWidth="2" stroke={lineGradient} />
      {status !== 'static' && (
        <animated.polygon
          points={direction === 'down' ? '0,0 5,10 10,0' : '0,10 5,0 10,10'}
          fill={arrowGradient}
          style={{
            position: 'absolute',
            transform: animatedProps1.position
              ? animatedProps1.position.interpolate((x) => `translateY(${x}px)`)
              : 'translateY(0px)',
          }}
        />
      )}
    </svg>
  );
};

//up down
const ArrowFlow_vertical1 = ({ direction, color1, color2, height, id }) => {
  const [forward, setForward] = useState(true);
  const startPoint = '0';
  const endPoint = height;
  const width = '4';
  // const height = '200';
  const speed = 5000;

  const animatedProps1 = useSpring({
    from: {
      position: direction == 'down' ? startPoint : endPoint,
    },
    to: {
      position: direction == 'down' ? endPoint : startPoint,
    },
    config: { duration: speed },
    reset: true,
    onRest: () => setForward(!forward),
  });
  return (
    <svg width={width} height={height} position="relative" overflow="inherit">
      <defs>
        <linearGradient id={id}>
          <stop offset="0%" stop-color={color1} />
          <stop offset="100%" stop-color={color2} />
        </linearGradient>
      </defs>
      <line y1={startPoint} x1="4" y2={height} x2="4.0001" strokeWidth="2" stroke={'url(#' + id + ')'} />

      <animated.polygon
        points={direction == 'down' ? '0,0 5,10 10,0' : '0,10 5,0 10,10'}
        // points="0,0 2,0 2,8 0,8"
        fill="#00ACBB"
        style={{
          position: 'absolute',
          transform: animatedProps1.position
            ? animatedProps1.position.interpolate((x) => `translateY(${x}px)`)
            : 'translateY(0px)',
        }}
      />
    </svg>
  );
};
export { ArrowFlow_vertical, ArrowFlow_level };
