import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { deleteMobileAccount } from '../../../api/AllAPI';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DeactivateAccountFlow = () => {
  let { id } = useParams();
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setMessage('');
  }, [email, password]);

  const deleteAccount = () => {
    deleteMobileAccount(email, password, id)
      .then((data) => {
        console.log(data);
        setMessage('Account deleted');
      })
      .catch((err) => {
        console.log(err);
        setMessage('Account deletion failed');
      });
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          Developers
        </AccordionSummary>
        <AccordionDetails>
          <p>UI designer developer(s): How Yuan Jie</p>
          <p>Frontend developer(s): Benjin Lau, Li Shi Ying</p>
          <p>Backend developer(s): Kow Ken Weng, Gan Siong Hin, Roy Ng</p>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DeactivateAccountFlow;
