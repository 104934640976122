import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
// mui
import { TextField, MenuItem, Switch, InputAdornment, useTheme, Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Flat_advance from '../../components/Flat_advance';
import ParkingTime_advance from '../../components/ParkingTime_advance';
import Time_advance from '../../components/Time_advance';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { verify_inputNumber } from '../../../../../../../components/verification';
// ----------------------------------------------------------------------

export default function Elements({
  type,
  cRef,
  currency,
  defaultData,
  energyObj_show,
  componentIndex,
  handleAddForm,
  handleRemoveForm,
  index,
  setOpenAlert,
  setOpenAlertMsg,
  tenantConfiguration,
}) {
  const { t } = useTranslation();
  const childRef_advance = useRef();
  const theme = useTheme();
  const [errorsOption, setErrorsOption] = useState({
    vat: '',
  });
  const [errors, setErrors] = useState({
    step_size: '',
    type: '',
    price: '',
  });

  // useEffect(() => {
  //   switch (type) {
  //     case 'elements_platForm':
  //       setPartTitle('PlatForm Fee');
  //       break;
  //     case 'elements_charging':
  //       setPartTitle('Charging Time');
  //       break;
  //     case 'elements_energy':
  //       setPartTitle('Energy');
  //       break;
  //     default:
  //       break;
  //   }
  // }, [type]);

  const startData = {
    price_component: [
      {
        step_size: '1',
        type: type == 'elements_platForm' ? 'FLAT' : type == 'elements_charging' ? 'TIME' : 'ENERGY', //PARKING_TIME
        vat: '0.00',
        price: '',
        priority_level: 1,
      },
    ],
    eleType: type,
    restrictions_show: false,
  };
  const [formData, setFormData] = useState(startData);
  useEffect(() => {
    if (defaultData) {
      setFormData(defaultData);
    }
  }, [defaultData]);

  useImperativeHandle(cRef, () => ({
    getComponentData,
  }));
  // handleSubmit
  const getComponentData = (event) => {
    console.log('energy');
    let hasError = false;
    let fd = formData.price_component[0];
    for (const key in fd) {
      const er = errors;
      if (fd.hasOwnProperty(key) && fd[key] === '' && er.hasOwnProperty(key)) {
        er[key] = t('required fields');
        setErrors({ ...errors, [key]: t('required fields') });
        hasError = true;
      }
    }
    formData.price_component[0].tariff_dimension_type = formData.price_component[0].type;
    // do not submit

    if (hasError || errorsOption.vat !== '') {
      return false;
    } else {
      let trans = 'data';
      if (formData.restrictions_show) {
        let advanceData = childRef_advance.current.getAdvanceData();
        if (advanceData == false) {
          trans = false;
        } else {
          formData.restrictions = advanceData;
        }
      } else {
        formData.restrictions = {};
      }

      return trans == false ? false : formData;
    }
  };
  const handleSwitch = (event) => {
    const val = event.target.checked;
    setFormData({ ...formData, restrictions_show: val });
    // setRestrictions(val);
  };
  const handleChange = (event) => {
    const val = event.target.value;
    let newStepSize = '';
    let newPrice = '';

    if (val === 'FLAT') {
      newStepSize = '1';
      newPrice = tenantConfiguration.flat_default_price;
    } else if (val === 'ENERGY') {
      newStepSize = '1';
      newPrice = tenantConfiguration.energy_default_price;
    } else if (val == 'TIME') {
      newStepSize = '';
      newPrice = tenantConfiguration.time_default_price;
    } else if (val == 'PARKING_TIME') {
      newStepSize = '';
      newPrice = tenantConfiguration.parking_time_default_price;
    } else {
      newStepSize = '';
      newPrice = '';
    }
    const updatedPriceComponent = [
      {
        ...formData.price_component[0], // Keep other properties as they are
        type: val,
        step_size: newStepSize,
        price: newPrice,
      },
    ];
    setFormData({
      ...formData,
      price_component: updatedPriceComponent,
      restrictions_show: false,
    });

    setErrors({ step_size: '', type: '', price: '' });
  };
  const remove = () => {
    let data = startData;
    data.price_component[0].price = '1';
    setFormData(data);
    setErrors({
      step_size: '',
      type: '',
      price: '',
    });
    setErrorsOption({
      vat: '',
    });
    setTimeout(() => {
      handleRemoveForm(index, formData.id ? formData.id : null);
    }, 50);
  };

  return (
    <Box sx={{ minWidth: '380px', width: '32.2%', marginRight: theme.spacing(2) }}>
      <div style={{ float: 'right', margin: '20px 10px 0 0' }} title={t('Remove')}>
        {/* <AddCircleOutlineIcon sx={{ cursor: 'pointer', color: '#00ACBB' }} title={t('Add')} onClick={handleAddForm} /> */}
        <RemoveCircleOutlineIcon sx={{ color: '#00ACBB', cursor: 'pointer' }} onClick={remove} />
      </div>
      <form
        onSubmit={getComponentData}
        style={{
          height: '450px',
          background: theme.palette.popPaper.paper2,
          padding: '10px 15px',
          marginBottom: '10px',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          // sx={{ visibility: formData.price_component[0].priority_level === 99999 ? 'hidden' : 'visible' }}
        >
          <p style={{ color: theme.palette.title.main }}>
            <span>*</span>
            {t('Priority Level')}:
          </p>
          <TextField
            variant="standard"
            margin="dense"
            name="priority_level"
            sx={{
              width: '3ch',
              marginLeft: theme.spacing(1),
              '& input:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.popPaper.paper2} inset`,
              },
            }}
            inputProps={{
              sx: { textAlign: 'center' },
            }}
            value={formData.price_component[0].priority_level}
            onBlur={(e) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                price_component: [
                  {
                    ...prevFormData.price_component[0],
                    priority_level: e.target.value == '' ? 1 : Number(e.target.value),
                  },
                  ...prevFormData.price_component.slice(1),
                ],
              }));
            }}
            onChange={(e) => {
              // let verify = verify_inputNumber(e.target.value.trim(), formData.price_component[0].vat);
              setFormData((prevFormData) => ({
                ...prevFormData,
                price_component: [
                  {
                    ...prevFormData.price_component[0],
                    priority_level: e.target.value == '' ? '' : e.target.value < 1 ? 1 : Number(e.target.value),
                  },
                  ...prevFormData.price_component.slice(1),
                ],
              }));
              // setErrorsOption({ ...errorsOption, vat: verify.error }); // verify.value == undefined ? '' :
            }}
            size="small"
            type="number"
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Stack sx={{ marginRight: theme.spacing(3) }}>
            <p style={{ display: 'flex', color: theme.palette.title.main }}>
              <span>* </span>
              {t('Price')}
              {formData.price_component[0].type == 'FLAT' ? (
                <p style={{}}>({t(currency)})</p>
              ) : formData.price_component[0].type == 'ENERGY' ? (
                <p style={{}}>({t(currency) + '/' + t('kWh')})</p>
              ) : (
                <p style={{}}>({t(currency) + '/' + t('min')})</p>
              )}
              :
            </p>
            <TextField
              variant="standard"
              margin="dense"
              name="price"
              inputProps={{
                sx: { textAlign: 'center' },
                autoComplete: 'off',
              }}
              value={formData.price_component[0].price}
              sx={{
                width: '5ch',
              }}
              onChange={(e) => {
                let verify = verify_inputNumber(e.target.value.trim(), formData.price_component[0].price);
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  price_component: [
                    {
                      ...prevFormData.price_component[0],
                      price: verify.value,
                    },
                    ...prevFormData.price_component.slice(1),
                  ],
                }));
                setErrors({ ...errors, price: verify.error });
              }}
              size="small"
              // type="number"
              error={!!errors.price}
              helperText={errors.price}
            />
          </Stack>
          <Stack sx={{ marginRight: theme.spacing(3) }}>
            <p style={{ display: 'flex', color: theme.palette.title.main }}>
              <span>* </span>
              {t('Tax')}(%):
            </p>
            <TextField
              variant="standard"
              margin="dense"
              name="vat"
              inputProps={{
                sx: { textAlign: 'center' },
                autoComplete: 'off',
              }}
              sx={{
                marginRight: theme.spacing(1),
                width: '5ch',
              }}
              value={formData.price_component[0].vat}
              // InputProps={{
              //   endAdornment: <InputAdornment position="end">%</InputAdornment>,
              // }}
              onChange={(e) => {
                let verify = verify_inputNumber(e.target.value.trim(), formData.price_component[0].vat);
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  price_component: [
                    {
                      ...prevFormData.price_component[0],
                      vat: verify.value,
                    },
                    ...prevFormData.price_component.slice(1), // Keep the rest of the array unchanged
                  ],
                }));
                setErrorsOption({ ...errorsOption, vat: verify.error }); // verify.value == undefined ? '' :
              }}
              size="small"
              // type="number"
              error={!!errorsOption.vat}
              helperText={errorsOption.vat}
            />
          </Stack>
          <Stack sx={{ visibility: formData.price_component[0].type === 'FLAT' ? 'hidden' : 'visible' }}>
            <p style={{ display: 'flex', color: theme.palette.title.main }}>
              <span>* </span>
              {t('Step Size')}
              {formData.price_component[0].type == 'FLAT' ? (
                ''
              ) : formData.price_component[0].type == 'ENERGY' ? (
                <p style={{}}>({t('kWh')})</p>
              ) : (
                <p style={{}}>({t('sec')})</p>
              )}
              :
            </p>
            <TextField
              variant="standard"
              disabled={formData.price_component[0].type === 'FLAT' ? true : false}
              sx={{
                marginRight: theme.spacing(1),
                width: '5ch',
              }}
              inputProps={{
                sx: { textAlign: 'center' },
                autoComplete: 'off',
              }}
              margin="dense"
              name="step_size"
              size="small"
              value={formData.price_component[0].step_size}
              onChange={(e) => {
                let verify = verify_inputNumber(e.target.value.trim(), formData.price_component[0].step_size);

                setFormData((prevFormData) => ({
                  ...prevFormData,
                  price_component: [
                    {
                      ...prevFormData.price_component[0],
                      step_size: verify.value,
                    },
                    ...prevFormData.price_component.slice(1), // Keep the rest of the array unchanged
                  ],
                }));
                setErrors({ ...errors, step_size: verify.error });
              }}
              error={!!errors.step_size}
              helperText={errors.step_size}
            />
          </Stack>
        </Box>
        <TextField
          margin="dense"
          select
          sx={{ marginRight: theme.spacing(1), width: '20ch', display: 'none' }}
          name="type"
          label={
            <>
              <span>* </span>
              {t('Type')}
            </>
          }
          value={type == 'elements_platForm' ? 'FLAT' : type == 'elements_charging' ? 'TIME' : 'ENERGY'}
          onChange={handleChange}
          size="small"
          error={!!errors.type}
          helperText={errors.type}
        >
          <MenuItem key="FLAT" value="FLAT">
            {t('Flat')}
          </MenuItem>
          <MenuItem key="TIME" value="TIME">
            {t('Time_type')}
          </MenuItem>

          <MenuItem key="ENERGY" value="ENERGY">
            {t('Energy')}
          </MenuItem>
        </TextField>

        <div style={{ padding: '0', display: formData.price_component[0].priority_level === 99999 ? 'none' : 'block' }}>
          <span style={{ marginTop: '5px', color: theme.palette.title.main }}>{t('Advance Setting')}</span>
          <Switch
            checked={formData.restrictions_show}
            onChange={handleSwitch}
            // name="reservationExpires_show"
          />
        </div>
        <div style={{ padding: '0', display: formData.restrictions_show ? 'block' : 'none' }}>
          {formData.price_component[0].type === 'FLAT' ? (
            <Flat_advance
              key={index}
              adRef={childRef_advance}
              defaultData={defaultData}
              setOpenAlert={setOpenAlert}
              setOpenAlertMsg={setOpenAlertMsg}
              switchSelete={formData.restrictions_show}
            />
          ) : formData.price_component[0].type === 'PARKING_TIME' || formData.price_component[0].type === 'TIME' ? (
            <ParkingTime_advance
              key={index}
              adRef={childRef_advance}
              defaultData={defaultData}
              setOpenAlert={setOpenAlert}
              setOpenAlertMsg={setOpenAlertMsg}
              switchSelete={formData.restrictions_show}
            />
          ) : (
            <Time_advance
              key={index}
              adRef={childRef_advance}
              defaultData={defaultData}
              setOpenAlert={setOpenAlert}
              setOpenAlertMsg={setOpenAlertMsg}
              switchSelete={formData.restrictions_show}
            />
          )}
        </div>
      </form>
    </Box>
  );
}
