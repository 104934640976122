import React, { useState, useEffect } from 'react';
// mui
import { Menu, IconButton, Box, Tooltip } from '@mui/material';
// component
import DiagnosticsReport from './diagnostic/index';
import ChangeAvailability from './changeavailability/index';
import ClearCache from './clearcache/index';
import ChangeParameter from './changeparameter/index';
import LocalList from './locallist/index';
import UpdateFirmware from './update/index';
import DeleteEVSE from './deleteEVSE/index';
import EditEVSE from './editEVSE/index';
import Icon from '../../../../../../../../components/customIcon/icon-kebab-menu-24.svg';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function EVSEMenu(props) {
  const { t } = useTranslation();
  const { evse, tariffData } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // useEffect(() => {
  //   debugger;
  //   console.log('evseInfo', evse);
  // }, [evse]);
  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const email = sessionStorage.getItem('email');

  return (
    <Box>
      <Tooltip title={t('Menu')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="chargingstationmenu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {evse.connector?.length !== 0 && (
          <ChangeAvailability
            key={'ChangeAvailability'}
            handleCloseMain={handleClose}
            evse={evse}
            setSuccess={(childData) => props.setSuccess(childData)}
            setError={(childData) => props.setError(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            CAUpdate={(childData) => props.Update(childData)}
          />
        )}
        <ChangeParameter
          key={'ChangeParameter'}
          evseName={evse.cp_id}
          handleCloseMain={handleClose}
          evseInfo={evse}
          setSuccess={(childData) => props.setSuccess(childData)}
          setError={(childData) => props.setError(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
        />
        <ClearCache
          key={'ClearCache'}
          evse={evse}
          handleCloseMain={handleClose}
          evseName={evse.cp_id}
          setSuccess={(childData) => props.setSuccess(childData)}
          setError={(childData) => props.setError(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
        />
        <EditEVSE
          key={'EditEVSE'}
          tariffData={tariffData}
          handleCloseMain={handleClose}
          evseInfo={evse}
          setSuccess={(childData) => props.setSuccess(childData)}
          setError={(childData) => props.setError(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          editUpdate={(childData) => props.Update(childData)}
          // DeleteClose={(childData) => props.Close(childData)}
        />
        <DeleteEVSE
          key={'DeleteEVSE'}
          handleCloseMain={handleClose}
          evseName={evse.cp_id}
          setSuccess={(childData) => props.setSuccess(childData)}
          setError={(childData) => props.setError(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          DeleteClose={(childData) => props.Close(childData)}
          deleteUpdate={(childData) => props.Update(childData)}
        />
        {/* <DiagnosticsReport
          key={'DiagnosticsReport'}
          evseName={evse.cp_id}
          evseInfo={evse}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
          handleCloseMain={handleClose}
        /> */}
        <LocalList
          key={'LocalList'}
          evseInfo={evse}
          evseName={evse.cp_id}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
          CloseMain={handleClose}
        />
        {email === 'roy.ng@liteon.com' && (
          <UpdateFirmware
            key={'UpdateFirmware'}
            evseName={evse.cp_id}
            evseInfo={evse}
            setSuccess={(childData) => props.setSuccess(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={(childData) => props.setError(childData)}
            handleCloseMain={handleClose}
          />
        )}
        {email === 'hank.hy.lin@liteon.com' && (
          <UpdateFirmware
            key={'UpdateFirmware'}
            evseName={evse.cp_id}
            evseInfo={evse}
            setSuccess={(childData) => props.setSuccess(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={(childData) => props.setError(childData)}
            handleCloseMain={handleClose}
          />
        )}
      </Menu>
    </Box>
  );
}
