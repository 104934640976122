import { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import React from 'react';
import { TextField, InputAdornment, Stack, Menu, MenuItem, Popover, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { verify_inputNumber } from '../../../../../../../../components/verification';
let TimePickers_Child = (props, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const style = {
    customMenuPaper: { height: '100px' },
  };
  const { componentIndex, time_keep_Data, getTriggleValue, setOpenAlertMsg, setOpenAlert } = props;
  // componentIndex is the current componet index

  // Methods exposed to the parent component
  useImperativeHandle(ref, () => ({
    getData,
  }));

  const [start_disable, setStart_disable] = useState(false);
  const [end_disable, setEnd_disable] = useState(false);
  const [end_time_Convergence, setEnd_time_Convergence] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [selectedEndTime, setSelectedEndTime] = useState('00:00');
  const [helperTextPrice, setHelperTextPrice] = useState('');
  const [formData, setFormData] = useState({
    start_time: '00:00',
    end_time: '00:00',
    price: '0.00',
  });
  // add del componet listen
  useEffect(() => {
    if (time_keep_Data.length == 0) return;
    // debugger;
    setFormData(time_keep_Data[componentIndex - 1]);
    setSelectedEndTime(time_keep_Data[componentIndex - 1].end_time);
    // set first and last time item disable
    if (componentIndex == 1 && time_keep_Data.length == 1) {
      setStart_disable(true);
      setEnd_disable(true);
    } else {
      if (componentIndex == 1) {
        setStart_disable(true);
      }
      setEnd_disable(false);
      if (componentIndex === time_keep_Data.length) {
        setEnd_disable(true);
      }
    }
  }, [time_keep_Data]);

  //calculate Time 24Hours
  const formatTime = (timeString) => {
    if (!timeString) return '';
    const [hour, minute] = timeString.split(':');
    const roundedMinute = Math.round(minute / 15) * 15;
    const roundedTime = `${hour.toString().padStart(2, '0')}:${roundedMinute.toString().padStart(2, '0')}`;
    return roundedTime;
  };
  // determine whether the start time is less than end time
  const isStartTimeBeforeEndTime = (startTime, endTime) => {
    const startTimeParts = startTime.split(':');
    const endTimeParts = endTime.split(':');
    const startHour = parseInt(startTimeParts[0]);
    const startMinute = parseInt(startTimeParts[1]);
    const endHour = parseInt(endTimeParts[0]);
    const endMinute = parseInt(endTimeParts[1]);

    // determine  <
    if (startHour < endHour) {
      return true;
    }
    // if equle determine minutes
    else if (startHour === endHour) {
      if (startMinute < endMinute) {
        return true;
      }
    }

    return false;
  };

  // create hour minutes option
  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push(time);
      }
    }
    options.push('23:59');
    return options;
  };

  // value change handle
  const handleChange_start = (event) => {
    var val = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: val,
    });
  };
  const handleChange_menu = (event) => {
    var val = event.currentTarget.innerText;
    var startTime = formData.start_time;
    if (startTime === '') {
      setEnd_time_Convergence(true);
      setHelperText(t('time_required'));
      // setAnchorEl(event.target)
      return;
    }

    if (isStartTimeBeforeEndTime(startTime, val)) {
      setFormData({
        ...formData,
        end_time: val,
      });

      setSelectedEndTime(val);
      getTriggleValue(val, componentIndex);
      setEnd_time_Convergence(false);
      setHelperText('');
    } else {
      // setEnd_time_Convergence(true);
      setOpenAlertMsg(t('time_less'));
      setOpenAlert(true);
    }
  };

  // price handleChange
  const handleChange = (event) => {
    var val = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: val,
    });
  };
  // handleSubmit
  const getData = (event) => {
    // console.log(event)
    const transData = { ...formData };
    let hasError = false;
    if (transData.end_time == '') {
      setEnd_time_Convergence(true);
      setHelperText(t('required fields'));
      hasError = true;
    } else {
      setEnd_time_Convergence(false);
      setHelperText('');
    }
    if (!hasError && helperTextPrice == '') {
      transData.haserror = false;
    } else {
      transData.haserror = true;
    }
    return transData;
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <form onSubmit={getData}>
        <TextField
          disabled
          name="start_time"
          label={t('Start Time')}
          value={formData.start_time}
          type="time"
          margin="dense"
          size="small"
          // defaultValue="00:00"
          // onChange={handleChange_start}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {/* <div>{selectedEndTime}</div> */}
        <TextField
          color="secondary"
          style={{ filter: 'invert(40%)', width: '120px' }}
          disabled={end_disable}
          error={end_time_Convergence}
          helperText={helperText}
          name="end_time"
          label={t('End Time')}
          value={selectedEndTime}
          select
          margin="dense"
          size="small"
          // defaultValue="00:00"
        >
          {/* <div style={{ height: '200px', width: '120px' }}> */}
          {generateTimeOptions().map((time, index) => (
            <MenuItem
              key={index}
              value={time}
              onClick={(e) => {
                handleChange_menu(e);
              }}
            >
              {time}
            </MenuItem>
          ))}
          {/* </div> */}
        </TextField>

        <TextField
          name="price"
          label={t('Price')}
          value={formData.price}
          // type="number"
          margin="dense"
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">$/kwh</InputAdornment>,
          }}
          onChange={function (e) {
            var val = e.target.value;
            let verify = verify_inputNumber(e.target.value.trim(), formData.price);
            setFormData({
              ...formData,
              [e.target.name]: verify.value,
            });
            setHelperTextPrice(verify.error);
          }}
          error={!!helperTextPrice}
          helperText={helperTextPrice}
        />
      </form>
    </div>
  );
};
TimePickers_Child = forwardRef(TimePickers_Child);
export default TimePickers_Child;
