import React, { useEffect } from 'react';
// mui
import { Stack, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Confirmation({ formData, evseName }) {
  const { t } = useTranslation();
  const column1 = [
    {
      id: 'name',
      label: t('Selected EVSE'),
      width: 80,
    },
    {
      id: 'function',
      label: t('Function'),
      width: 160,
    },
    {
      id: 'firmware',
      label: t('New Firmware'),
      width: 200,
    },
    {
      id: 'datetime',
      label: t('Date & Time'),
      width: 160,
    },
  ];
  return (
    <Stack direction="column" spacing={3}>
      <Typography variant="body1">
        {t('tip_selectedFirmware')}
        {/* Please review the selected firmware for Update Firmware to be carried out: */}
      </Typography>
      <Table aria-labelledby="tableTitles">
        <TableHead>
          <TableRow>
            {column1.map((headCell) => (
              <TableCell key={headCell.id} align="left" padding="normal" sx={{ width: headCell.width }}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover>
            <TableCell align="left" padding="normal">
              {evseName}
            </TableCell>
            <TableCell align="left" padding="normal">
              {t('Update Firmware')}
            </TableCell>
            <TableCell>{formData.selectedOption}</TableCell>
            <TableCell>
              {formData.when === 'ASAP' ? (
                <Typography variant="body1">{t('As soon as possible')}</Typography>
              ) : (
                dayjs(formData.valueDateTime.$d).format('HH:mm MM/DD/YYYY')
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
}
