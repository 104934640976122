import { alpha } from '@mui/material/styles';

//  ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  // mui
  0: '#FFFFFF',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
  500_8: alpha('#aab8c5', 0.08),
  500_12: alpha('#aab8c5', 0.12),
  500_16: alpha('#aab8c5', 0.16),
  500_24: alpha('#aab8c5', 0.24),
  500_30: alpha('#aab8c5', 0.3),
  500_32: alpha('#aab8c5', 0.32),
  500_48: alpha('#aab8c5', 0.48),
  500_56: alpha('#aab8c5', 0.56),
  500_80: alpha('#aab8c5', 0.8),
  123: '#00e1ec',
  123_30: alpha('#00e1ec', 0.3),
  123_50: alpha('#00e1ec', 0.5),
  123_60: alpha('#00e1ec', 0.6),
  123_80: alpha('#00e1ec', 0.8),
};
// const GREY = { copy
//   0: '#FFFFFF',
//   100: '#404954',
//   200: '#37404a',
//   300: '#464f5b',
//   400: '#8391a2',
//   500: '#aab8c5',
//   600: '#ced4da',
//   700: '#dee2e6',
//   800: '#e3eaef',
//   900: '#f1f1f1',
//   500_8: alpha('#aab8c5', 0.08),
//   500_12: alpha('#aab8c5', 0.12),
//   500_16: alpha('#aab8c5', 0.16),
//   500_24: alpha('#aab8c5', 0.24),
//   500_32: alpha('#aab8c5', 0.32),
//   500_48: alpha('#aab8c5', 0.48),
//   500_56: alpha('#aab8c5', 0.56),
//   500_80: alpha('#aab8c5', 0.8),
//   123: '#00e1ec',
//   123_30: alpha('#00e1ec', 0.3),
//   123_50: alpha('#00e1ec', 0.5),
//   123_80: alpha('#00e1ec', 0.8),
// };
// const GREY = { original
//   0: '#FFFFFF',
//   100: '#F9FAFB',
//   200: '#F4F6F8',
//   300: '#DFE3E8',
//   400: '#C4CDD5',
//   500: '#919EAB',
//   600: '#637381',
//   700: '#454F5B',
//   800: '#212B36',
//   900: '#161C24',
//   500_8: alpha('#00b0b9', 0.08),
//   500_12: alpha('#00b0b9', 0.12),
//   500_16: alpha('#00b0b9', 0.16),
//   500_24: alpha('#00b0b9', 0.24),
//   500_32: alpha('#00b0b9', 0.32),
//   500_48: alpha('#00b0b9', 0.48),
//   500_56: alpha('#00b0b9', 0.56),
//   500_80: alpha('#00b0b9', 0.8),
//   123: '#00e1ec',
//   123_30: alpha('#00e1ec', 0.3),
//   123_50: alpha('#00e1ec', 0.5),
//   123_80: alpha('#00e1ec', 0.8),
// };

const PRIMARY = {
  // green
  lighter: '#E9FCD4',
  light: '#4caf50',
  main: '#00B0B9',
  dark: GREY[123_30],
  darker: '#08660D',
  contrastText: 'black',
};
// const PRIMARY = {
//   //blue
//   lighter: '#D1E9FC',
//   light: '#42a5f5',
//   main: '#1976d2',
//   dark: '#1565c0',
//   darker: '#061B64',
//   contrastText: '#fff',
// };

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#AF52BF',
  main: '#F4F4F4',
  dark: '#6D1b7B',
  darker: '#091A7A',
  contrastText: '#fff',
};

const INFO = {
  // blue
  text: GREY[123_80],
  icon: '#0C53B7',
  backgroundcolor: '#202428',
  lighter: '#D0F2FF',
  light: '#349FDA',
  main: '#0288d1',
  dark: '#015F92',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  // green
  backgroundcolor: '#202428',
  text: GREY[123_80],
  icon: '#229A16',
  lighter: '#E9FCD4',
  light: '#57975B',
  main: '#2e7d32',
  dark: '#205723',
  darker: '#08660D',
  contrastText: '#fff',
};

const WARNING = {
  // yellow
  lighter: '#FFF7CD',
  light: '#F08934',
  main: '#FFC107',
  dark: '#A54B01',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  // red
  lighter: '#FFE7D9',
  light: '#DB5858',
  main: '#d32f2f',
  dark: '#932020',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const PAPER = {
  main: '#2b3136',
  light: '#2b3136',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  paper: createGradient(PAPER.light, PAPER.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const AVAILABLE = {
  main: '#00B0B9',
};
const FAULTY = {
  main: '#F06549',
};
const RESERVE = {
  main: '#0D6EFD',
};
const INUSE = {
  main: '#52C41A',
};
const UNAVAILABLE = {
  main: '#D9B300',
};
const UNKNOWN = {
  main: '#666666',
};
const REMOVED = {
  main: '#9E86FF',
};
const UNGROUP = {
  main: '#A4F7CC',
};
const TEXT = {
  main: '#F4F4F4',
};
const TITLE = {
  main: '#B5C2CD',
};
const BAR = {
  main: '#232F34',
};
const BARSUB = {
  main: '#232F34',
};
const BARCHART = {
  main: '#92C353',
};
const MAINLOG = {
  main: '#232F34',
};
const NAVBAR = {
  main: '#232F34',
};
const darkPalette = {
  mode: 'dark',
  common: { black: '#000', white: '#fff' },
  mainlog: { ...MAINLOG },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  available: { ...AVAILABLE },
  faulty: { ...FAULTY },
  reserve: { ...RESERVE },
  inuse: { ...INUSE },
  unavailable: { ...UNAVAILABLE },
  unknown: { ...UNKNOWN },
  removed: { ...REMOVED },
  ungroup: { ...UNGROUP },
  texts: { ...TEXT },
  title: { ...TITLE },
  bar: { ...BAR },
  navbar: { ...NAVBAR },
  barsub: { ...BARSUB },
  barchart: { ...BARCHART },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_12],
  text: { primary: GREY[0], secondary: GREY[500_70], disabled: GREY[500_50] },
  background: {
    paper2: '#232F34',
    popPaper: '#17262A',
    paper: '#17262A',
    pagerWhite: '#17262A',
    default: '#121212',
    neutral: GREY[500],
    tableHeader: '#17262A',
    tableRow: alpha('#344955', 0.2),
    tableRowborder: '#363a3f',
    border: 'none',
  },
  CollapseRow: {
    border: 'none',
  },
  popPaper: {
    maskback: `linear-gradient(to bottom, #17262ADE, #121212DE)`,
    paper2: '#232F34',
  },
  action: {
    active: GREY[500],
    hover: '#344955',
    selected: GREY[500_16],
    disabled: GREY[500_30],
    disabledBackground: GREY[500_32],
    focus: GREY[500_12],
    hoverOpacity: 0.12,
    disabledOpacity: 0.24,
  },
};

export default darkPalette;

// sx={{ background: 'linear-gradient(to bottom, #17262A, #121212)' }}
