import React, { useState, useImperativeHandle, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
// mui
import {
  Box,
  Switch,
  Button,
  Dialog,
  Select,
  Tooltip,
  MenuItem,
  TextField,
  IconButton,
  InputLabel,
  Typography,
  FormControl,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
// import {   Charger_AddStation } from '../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function RegularModel({
  childRef,
  sourceData,
  setOpenAlertMsg,
  setOpenAlert,
  componentIndex,
  handleRemoveForm,
  index,
}) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  useImperativeHandle(childRef, () => ({
    onSubmit,
  }));
  const dayArrs = [
    { item: t('MONDAY'), value: 1 },
    { item: t('TUESDAY'), value: 2 },
    { item: t('WEDNESDAY'), value: 3 },
    { item: t('THURSDAY'), value: 4 },
    { item: t('FRIDAY'), value: 5 },
    { item: t('SATURDAY'), value: 6 },
    { item: t('SUNDAY'), value: 7 },
  ];
  const [formData, setFormData] = useState({
    weekday: 1,
    period_begin: '',
    period_end: '',
  });
  useEffect(() => {
    if (sourceData) {
      setFormData(sourceData);
    }
  }, [sourceData]);

  const [errors, setErrors] = useState({
    weekday: '',
    period_begin: '',
    period_end: '',
  });
  const limitRules = {
    weekday: { min: 1, max: 7 },
    period_begin: { minLength: 4, maxLength: 5 },
    period_end: { minLength: 4, maxLength: 5 },
  };

  const handleChange_after = (e) => {
    const { name, value } = e.target;
    const errorArr = Object.keys(errors);
    if (name == 'weekday') {
      setFormData({
        ...formData,
        [name]: Number(value),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    value !== '' ? setErrors({ ...errors, [name]: '' }) : null;

    if (!errorArr.includes(name)) return;
    let errorMessage = '';
    if (name == 'weekday') {
      const { min, max } = limitRules[name];
      if (value < min || value > max) {
        errorMessage = t('Invalid length') + ` (min:${min}, max:${max}).`;
      }
      setErrors({ ...errors, [name]: errorMessage });
      return;
    }
    const { minLength, maxLength } = limitRules[name];
    if (value.length < minLength || value.length > maxLength) {
      errorMessage = t('Invalid length') + ` (minLength:${minLength}, maxLength:${maxLength}).`;
    }
    setErrors({ ...errors, [name]: errorMessage });
  };

  const onSubmit = () => {
    // Not empty verify
    let hasError = false;
    for (const key in errors) {
      const er = errors;
      if (formData.hasOwnProperty(key) && formData[key] === '') {
        setErrors({ ...errors, [key]: t('required fields') });
        er[key] = t('required fields');
        hasError = true;
      }
    }
    //  do not submit
    if (hasError) {
      setLoading(false);
      return false;
    }
    return formData;
  };
  const remove = () => {
    handleRemoveForm(index, formData.id ? formData.id : null);
  };
  return (
    <Box sx={{ background: theme.palette.popPaper.paper2, marginBottom: '15px', padding: '10px' }}>
      <div style={{ float: 'right', marginTop: '-10px' }}>
        <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Remove')} onClick={remove} />
      </div>
      {/* <TextField
        margin="dense"
        size="small"
        // type="number"
        name="weekday"
        label={
          <>
            <span style={{ color: 'red' }}>* </span>
            {t('Weekday')}
          </>
        }
        error={!!errors.weekday}
        helperText={errors.weekday}
        onChange={(e) => {
          handleChange_after(e);
        }}
        value={formData.weekday}
        fullWidth
        variant="outlined"
      /> */}
      <TextField
        margin="dense"
        select
        fullWidth
        name="weekday"
        label={
          <>
            <span style={{ color: 'red' }}>* </span>
            {t('Weekday')}
          </>
        }
        value={formData.weekday}
        onChange={(e) => {
          handleChange_after(e);
        }}
        size="small"
        // error={!!errors.weekday}
        // helperText={errors.weekday}
      >
        {dayArrs.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.item}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        margin="dense"
        name="period_begin"
        size="small"
        type="time"
        InputLabelProps={{
          shrink: true,
        }}
        label={
          <>
            <span style={{ color: 'red' }}>* </span>
            {t('Period Begin')}
          </>
        }
        error={!!errors.period_begin}
        helperText={errors.period_begin}
        onChange={(val) => {
          if (formData.period_end && val.target.value >= formData.period_end) {
            setOpenAlert(true);
            setOpenAlertMsg(t('time_after'));
          } else {
            setOpenAlert(false);
            handleChange_after(val);
          }
        }}
        value={formData.period_begin}
        fullWidth
        variant="outlined"
      />
      <TextField
        margin="dense"
        size="small"
        type="time"
        InputLabelProps={{
          shrink: true,
        }}
        name="period_end"
        label={
          <>
            <span style={{ color: 'red' }}>* </span>
            {t('Period End')}
          </>
        }
        error={!!errors.period_end}
        helperText={errors.period_end}
        onChange={(val) => {
          if (formData.period_begin && val.target.value <= formData.period_begin) {
            setOpenAlert(true);
            setOpenAlertMsg(t('time_before'));
          } else {
            setOpenAlert(false);
            handleChange_after(val);
          }
        }}
        value={formData.period_end}
        fullWidth
        variant="outlined"
      />
    </Box>
  );
}
