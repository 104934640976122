import React, { useState, useEffect, useImperativeHandle } from 'react';
// mui
import { Box, TextField, useTheme } from '@mui/material';
// components
import { useTranslation } from 'react-i18next';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// ----------------------------------------------------------------------

export default function RFIDInfo({ childRef, componentIndex, transData, handleRemoveForm, index, rfidCon }) {
  const { t } = useTranslation();
  useImperativeHandle(childRef, () => ({
    onSubmit,
  }));
  const theme = useTheme();
  const [formDataloc, setFormDataloc] = useState({
    // contract_id: '',
    // issuer: '',
    rfid_num: '',
    whitelist: 'ALWAYS',
    isVisible: true,
    // valid: false,
    visual_number: '',
    operType: 'add',
  });

  useEffect(() => {
    if (transData && Object.keys(transData).length !== 0) {
      console.log('transData', transData);
      setFormDataloc(transData);
    }
  }, [transData]);
  // const [errors, setErrors] = useState({ contract_id: '', issuer: '', whitelist: '', rfid_num: '' });
  const [errors, setErrors] = useState({ rfid_num: '' });
  const onSubmit = (opr) => {
    if (!rfidCon) {
      return;
    }
    if (opr == 'del') {
      return formDataloc;
    } else if (formDataloc.operType == 'del') {
      return formDataloc;
    }
    let empty = false;
    const newHasEmpty = { ...errors };
    for (const key in newHasEmpty) {
      if ((formDataloc.hasOwnProperty(key) && formDataloc[key] === '') || formDataloc[key] === null) {
        newHasEmpty[key] = t('required fields');
        empty = true;
      } else {
        newHasEmpty[key] = '';
      }
    }
    setErrors(newHasEmpty);
    if (empty) {
      return false;
    }
    return formDataloc;
  };

  const handle = (e) => {
    const { name, value } = e.target;
    setFormDataloc({
      ...formDataloc,
      [name]: value,
    });
    if (name !== 'visual_number') {
      setErrors({
        ...errors,
        [name]: '',
      });
    }
  };
  const remove = () => {
    handleRemoveForm(index, formDataloc.id ? formDataloc.id : null);
  };

  return (
    <div style={{ marginBottom: '0px', display: formDataloc.isVisible != false ? 'block' : 'none' }}>
      <div style={{ float: 'right', marginTop: '0px' }}>
        <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Remove')} onClick={remove} />
      </div>
      <Box sx={{ background: theme.palette.background.tableRow, marginBottom: '15px', padding: '10px' }}>
        {/* <TextField
          // disabled
          margin="dense"
          size="small"
          name="contract_id"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Contract Id')}
            </>
          }
          onChange={(e) => handle(e)}
          value={formDataloc.contract_id}
          fullWidth
          variant="outlined"
          error={!!errors.contract_id}
          helperText={errors.contract_id}
        />
        <TextField
          // disabled
          margin="dense"
          size="small"
          name="issuer"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Issuer')}
            </>
          }
          value={formDataloc.issuer}
          onChange={(e) => handle(e)}
          fullWidth
          variant="outlined"
          error={!!errors.issuer}
          helperText={errors.issuer}
        /> */}
        {/* <TextField
          margin="dense"
          size="small"
          select
          fullWidth
          name="whitelist"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('White List')}
            </>
          }
          value={formDataloc.whitelist}
          onChange={(e) => {
            handle(e);
          }}
          error={!!errors.whitelist}
          helperText={errors.whitelist}
        > */}
        {/* {whitelistOptions.map((option) => ( */}
        {/* <MenuItem key="ALLOWED" value="ALLOWED">
            {t('ALLOWED')}
          </MenuItem> */}
        {/* <MenuItem key="NOTALLOWED" value="NOTALLOWED">
            {t('NOT ALLOWED')}
          </MenuItem> */}
        {/* ))} */}
        {/* </TextField> */}
        <TextField
          margin="dense"
          size="small"
          name="rfid_num"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('RFID Num')}
            </>
          }
          onChange={(e) => handle(e)}
          value={formDataloc.rfid_num}
          fullWidth
          variant="outlined"
          error={!!errors.rfid_num}
          helperText={errors.rfid_num}
        />

        <TextField
          margin="dense"
          size="small"
          name="visual_number"
          label={t('Visual Number')}
          onChange={(e) => handle(e)}
          value={formDataloc.visual_number}
          fullWidth
          variant="outlined"
        />
        {/* <div style={{ padding: '10px 0 5px 0' }}>
          <span style={{ marginTop: '5px' }}>{t('Validated')}</span>
          <Switch
            checked={formDataloc.valid}
            onChange={(e) => {
              setFormDataloc({ ...formDataloc, valid: e.target.checked });
            }}
            name="valid"
          />
        </div> */}
      </Box>
    </div>
  );
}
