import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  Select,
  Button,
  Dialog,
  Tooltip,
  MenuItem,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Card,
  CardContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Icon from '../../../../components/customIcon/icon-kebab-menu-24.svg';
import { buttonR, buttonL } from '../../../../components/button/buttonStyle';
import CloseIcon from '../../../../components/customIcon/Close.svg';
import { fetchUserCP, fetchUserConnector, SubmitUserReserve } from '../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import { BorderBottom } from '@mui/icons-material';

// ----------------------------------------------------------------------

export default function UserReserve(props) {
  const { t } = useTranslation();
  const { info, chargingStationList } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [charging_station, setCharging_station] = useState();
  const [chargePointList, setChargePointList] = useState([]);
  const [charge_point_id, setChargePoint] = useState();
  const [loading_pre, setLoading_pre] = useState(false);
  // const mobile_id = info.mobile_id;
  const [infoData, setInfoData] = useState([]);
  // useEffect(() => {
  //   props.setError(error);
  //   props.setErrorMessage(errorMessage);
  // }, [error]);

  useEffect(() => {
    // console.log(info.rfid);
    if (info?.rfid) {
      setInfoData(info.rfid);
    }
  }, [info]);
  const handleClickOpen = () => {
    setOpen(true);
    // setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={t('More Information')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="UserReserve">
        {/* {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )} */}
        <DialogTitle id="UserReserve">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('RFID') + ' ' + t('Information')} ({info.name})
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" justifyContent="flex-start" spacing={2}>
            {infoData?.map((item, ind) => {
              return (
                <Card variant="outlined">
                  <CardContent>
                    <h3>
                      {t('RFID')} {ind + 1}
                    </h3>
                    <div style={{ marginTop: '10px' }}>
                      <ul style={{ marginLeft: '40px' }}>
                        <li>
                          {t('Contract Id')} :
                          <span style={{ borderBottom: '1px dashed #999' }}> {item.contract_id}</span>
                        </li>
                        <li>
                          {t('Issuer')} : <span style={{ borderBottom: '1px dashed #999' }}>{item.issuer}</span>
                        </li>
                        <li>
                          {t('White List')} : <span style={{ borderBottom: '1px dashed #999' }}>{item.whitelist}</span>
                        </li>
                        <li>
                          {t('RFID Num')} :{' '}
                          <span style={{ borderBottom: '1px dashed #999' }}>
                            {item.rfid_num ? item.rfid_num : 'Nil'}
                          </span>
                        </li>
                        <li>
                          {t('Visual Number')} :
                          <span style={{ borderBottom: '1px dashed #999' }}>{item.visual_number}</span>
                        </li>
                        <li>
                          {t('Validated')} :
                          <span style={{ borderBottom: '1px dashed #999' }}> {item.valid ? 'Yes' : 'No'}</span>
                        </li>
                      </ul>
                    </div>
                  </CardContent>
                </Card>
              );
            })}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button variant="outlined" onClick={handleClose} sx={buttonL}>
                {t('Back')}
              </Button>
              {/* <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
                {t('Submit')}
              </LoadingButton> */}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
