import * as React from 'react';
import { useEffect, useState } from 'react';
// mui
import { Grid, Box, Card, Stack, CardHeader, LinearProgress, useTheme } from '@mui/material';
// sections
import RevenueChart from '../../../../components/apexcharts/BarChart';
import RevenueWidget from '../../../../components/Widget';
import GraphSelect from '../../../../components/Select/GraphSelect';
import date from '../../../../components/LastDate';
// import { Last7Days, Last7Months, Last7Years, Last7DaysID, Last7MonthsID, Last7YearsID, test } from '../../../../components/LastDate';
import LoginAgain from '../../../../components/errors/LoginAgain';
import TotalRevenue from '../../../../components/customIcon/icon-revenue-40.svg';
import LowRevenue from '../../../../components/customIcon/icon-lowest-transaction-40.svg';
import HighRevenue from '../../../../components/customIcon/icon-highest-transaction-40.svg';
import { fetchRevenueOverview } from '../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function DashboardApp(props) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [dataRevenue, setDataRevenue] = useState([]);
  const [graphSelect, setGraphSelect] = useState(1);
  const [graphRevenue, setGraphRevenue] = useState([]);
  const [tableYRevenue, setTableYRevenue] = useState(date.Last7Days());
  const [dataIn, setDataIn] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [loading_pre, setLoading_pre] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    setLoading_pre(true);
    fetchRevenueOverview(setError, setErrorMessage, setUnauthorized).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setDataRevenue(promiseResult.data[0]);
        setGraphSelect(2);
        setDataIn(true);
      } else {
        props.setErrorMessage(promiseResult.response?.data?.message);
        props.setError(true);
      }
      setLoading_pre(false);
    });
  }, []);

  const handleCallbackGraphRevenue = (childData) => {
    setGraphSelect(childData);
  };

  useEffect(() => {
    props.locationList(dataRevenue.location);
  }, [dataRevenue]);

  useEffect(() => {
    if (graphSelect === 2) {
      setTableYRevenue(date.Last7Months);
      setGraphRevenue(dataRevenue.monthly);
    } else if (graphSelect === 3) {
      setTableYRevenue(date.Last7Years);
      setGraphRevenue(dataRevenue.yearly);
    } else {
      setTableYRevenue(date.Last7Days);
      setGraphRevenue(dataRevenue.daily);
    }
  }, [graphSelect]);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      {unauthorized && <LoginAgain />}
      {loading_pre && (
        <div style={{ marginTop: '0px', width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      {dataIn && (
        <Box>
          <Card
            sx={{
              mb: 2,
              borderRadius: '4px',
              p: 3,
              boxShadow: 0,
              background: theme.palette.background.pagerWhite,
              border: theme.palette.background.border,
            }}
          >
            <Stack direction="row" justifyContent="space-around" alignItems="flex-start" spacing={3}>
              <RevenueWidget
                title={t('Total Revenue')}
                currency={dataRevenue.currency}
                total={Number(dataRevenue.revenue).toFixed(2)}
                color="info"
                icon={TotalRevenue}
              />
              <RevenueWidget
                title={t('Highest Transaction')}
                currency={dataRevenue.currency}
                total={Number(dataRevenue.highest_transaction).toFixed(2)}
                color="info"
                icon={HighRevenue}
              />
              <RevenueWidget
                title={t('Lowest Transaction')}
                currency={dataRevenue.currency}
                total={Number(dataRevenue.lowest_transaction).toFixed(2)}
                color="info"
                icon={LowRevenue}
              />
            </Stack>
          </Card>

          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Card
                sx={{
                  boxShadow: 0,
                  borderRadius: '4px',
                  background: theme.palette.background.pagerWhite,
                  border: theme.palette.background.border,
                }}
              >
                <CardHeader
                  title={t('Dollars Earned')}
                  action={<GraphSelect parentCallback={handleCallbackGraphRevenue} datafromparent={graphSelect} />}
                />
                {graphRevenue && <RevenueChart yData={tableYRevenue} xLabel={t('Dollars ($)')} xData={graphRevenue} />}
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
