import React, { useState, useImperativeHandle, useEffect, useRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
// mui
import {
  Box,
  Switch,
  Snackbar,
  Alert,
  TextField,
  IconButton,
  InputLabel,
  Typography,
  FormControl,
  Chip,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

// import {  Charger_AddStation } from '../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import SourceModel from './source';
import Environmental from './environmental';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// ----------------------------------------------------------------------

export default function EnergyModel({ childRef, SetEnergyData, energyData, setRemoveId, removeId }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const componentRefs = useRef([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [addCount, setaddCount] = useState(1);
  const [components, setComponents] = useState([1]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertMsg, setOpenAlertMsg] = useState('');
  // ======
  const componentRefs_env = useRef([]);
  const [addCount_env, setaddCount_env] = useState(1);
  const [components_env, setComponents_env] = useState([1]);
  //++++++++

  useImperativeHandle(childRef, () => ({
    onSubmit,
  }));
  // const [sourceData, setSourceData] = useState([]);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const defaultSourceData = {
    source: '',
    percentage: '',
    isVisible: true,
  };
  const defaultEnvData = {
    category: '',
    amount: '',
    isVisible: true,
  };
  const [formData, setFormData] = useState({
    is_green_energy: false,
    cs_id: '',
    energy_product_name: '',
    supplier_name: '',
    energy_sources: [
      {
        source: '',
        percentage: '',
        isVisible: true,
      },
    ],
    environmental_impact: [
      {
        category: '',
        amount: '',
        isVisible: true,
      },
    ],
  });
  useEffect(() => {
    if (energyData && Object.keys(energyData).length !== 0) {
      setFormData(energyData);
      if (energyData.energy_sources) {
        // const formDataArray = [];
        const componentsArray = [];
        energyData.energy_sources.forEach((ele, index) => {
          // formDataArray.push(ele);
          componentsArray.push(index + 1);
        });
        setComponents(componentsArray);
        // setSourceData(formDataArray)
      }
      if (energyData.environmental_impact) {
        const componentsArray = [];
        energyData.environmental_impact.forEach((ele, index) => {
          componentsArray.push(index + 1);
        });
        setComponents_env(componentsArray);
      }
    }
  }, [energyData]);

  const [errors, setErrors] = useState({
    is_green_energy: false,
    cs_id: '',
    energy_product_name: '',
    supplier_name: '',
  });
  const limitRules = {
    supplier_name: { minLength: 0, maxLength: 64 },
    energy_product_name: { minLength: 0, maxLength: 64 },
    // cs_id: { minLength: 0, maxLength: 36 },
  };
  const handleChange_after = (e) => {
    const { name, value } = e.target;
    const errorArr = Object.keys(errors);
    setFormData({ ...formData, [name]: value });
    if (!errorArr.includes(name)) return;
    if (name == 'is_green_energy' || name == 'cs_id') return;
    const { minLength, maxLength } = limitRules[name];
    let errorMessage = '';

    if (value.length < minLength || value.length > maxLength) {
      errorMessage = t('Invalid length') + ` (minLength:${minLength}, maxLength:${maxLength}).`;
    }

    setErrors({ ...errors, [name]: errorMessage });
  };
  // +++++++
  const handleAddForm_source = () => {
    const getData = getComponentData();

    if (getData !== false) {
      setaddCount(addCount + 1);
      setComponents([...components, addCount * 10]);
      setFormData({
        ...formData,
        energy_sources: [...getData, defaultSourceData],
      });
    }
  };

  const handleRemoveForm_source = (index, id) => {
    const getData = getComponentData();
    const energyArr_source = getData.filter((element) => element.isVisible !== false);
    if (energyArr_source.length > 1) {
      const updatedFormData = getData.map((item, i) => {
        if (i === index) {
          return { ...item, isVisible: false };
        }
        return item;
      });
      setFormData({
        ...formData,
        energy_sources: updatedFormData,
      });
    }
  };
  const getComponentData = () => {
    let formDataArray = [];
    let hasError = false;
    componentRefs.current.forEach((ref) => {
      if (!ref.current) return;
      const childData = ref.current.onSubmit();
      if (childData === false) {
        hasError = true;
      } else {
        formDataArray.push(childData);
      }
    });

    if (hasError) {
      return false;
    } else {
      return formDataArray;
    }
  };
  //++++++++
  //===========
  const handleAddForm_env = () => {
    const getData = getComponentData_env();
    if (getData !== false) {
      setaddCount_env(addCount_env + 1);
      setComponents_env([...components_env, addCount_env * 10]);
      setFormData({
        ...formData,
        environmental_impact: [...getData, defaultEnvData],
      });
    }
  };
  // const handleRemoveForm_env = (index, id) => {
  //   if (components_env.length > 1) {
  //     const getData = getComponentData_env();

  //     if (getData !== false) {
  //       // id ? setRemoveId([...removeId, id]) : null;
  //       setComponents_env(components_env.filter((_, i) => i !== index));
  //       componentRefs_env.current = componentRefs_env.current.filter((ref, i) => i !== index);
  //       const updatedFormData = getData.filter((_, i) => i !== index);
  //       setFormData({
  //         ...formData,
  //         environmental_impact: updatedFormData,
  //       });
  //     }
  //   }
  // };
  const handleRemoveForm_env = (index, id) => {
    const getData = getComponentData_env();
    if (getData == false) {
      return;
    }
    const energyArr_env = getData.filter((element) => element.isVisible !== false);
    if (energyArr_env.length > 1) {
      const updatedFormData = getData.map((item, i) => {
        if (i === index) {
          return { ...item, isVisible: false };
        }
        return item;
      });
      setFormData({
        ...formData,
        environmental_impact: updatedFormData,
      });
    }
  };
  const getComponentData_env = () => {
    let formDataArray = [];
    let hasError = false;
    componentRefs_env.current.forEach((ref) => {
      if (!ref.current) return;
      const childData = ref.current.onSubmit();
      if (childData === false) {
        hasError = true;
      } else {
        formDataArray.push(childData);
      }
    });

    if (hasError) {
      return false;
    } else {
      return formDataArray;
    }
  };
  //==========
  const onSubmit = () => {
    const transData = { ...formData };
    // Not empty verify
    let hasError = false;
    for (const key in errors) {
      const er = errors;
      if (formData.hasOwnProperty(key) && formData[key] === '') {
        if (key == 'energy_product_name' || key == 'supplier_name' || key == 'cs_id') continue;
        setErrors({ ...errors, [key]: t('required fields') });
        er[key] = t('required fields');
        hasError = true;
      }
    }
    // source model
    const getData = getComponentData();
    if (getData !== false) {
      setFormData({
        ...formData,
        energy_sources: getData,
      });
      transData.energy_sources = getData;
      const arr = getData.filter((element) => element.isVisible !== false);
      if (arr.length !== 1) {
        let sum = 0;
        for (let index = 0; index < arr.length; index++) {
          if (arr[index].isVisible !== false) {
            const element = arr[index];
            sum += Number(element.percentage);
          }
        }
        if (sum !== 100) {
          hasError = true;
          setOpenAlert(true);
          setOpenAlertMsg(t('percentage_percentage'));
          // The sum of the percentage values must equal 100！
        }
      } else if (arr[0].source !== '') {
        if (Number(arr[0].percentage) !== 100) {
          hasError = true;
          setOpenAlert(true);
          setOpenAlertMsg(t('percentage_percentage'));
        }
      }
    } else {
      hasError = true;
    }
    // Environmental Impact model
    const getData_env = getComponentData_env();
    if (getData_env !== false) {
      setFormData({
        ...formData,
        environmental_impact: getData_env,
      });
      transData.environmental_impact = getData_env;
    } else {
      hasError = true;
    }

    //  do not submit
    if (hasError) {
      setLoading(false);
      return false;
    }

    console.log('data', transData);
    return transData;
  };

  return (
    <Box>
      <form onSubmit={onSubmit}>
        <Snackbar
          open={openAlert}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="warning">{openAlertMsg} </Alert>
          {/* The sum of the percentage values must equal 100! */}
        </Snackbar>

        {/* spacing={2} sx={{ width: 450 }} */}
        {/* <div style={{ color: 'red' }}>* {t('Mandatory fields')}</div> */}
        <div style={{ padding: '10px 0 5px 0' }}>
          <span style={{ marginTop: '5px' }}>
            <span style={{ color: 'red' }}>* </span>
            {t('Green Energy')}
          </span>
          <Switch
            checked={formData.is_green_energy}
            onChange={(e) => {
              setFormData({ ...formData, is_green_energy: e.target.checked });
            }}
            name="is_green_energy"
          />
        </div>

        {/* <TextField
          margin="dense"
          size="small"
          disabled
          //type="number"
          name="cs_id"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('cs_id')}
            </>
          } 
          onChange={(e) => {
            handleChange_after(e);
          }}
          value={formData.cs_id ? Number(formData.cs_id) : null}
          fullWidth
          variant="outlined"
        /> */}
        <TextField
          margin="dense"
          size="small"
          name="energy_product_name"
          label={<>{t('Energy Product Name')}</>}
          error={!!errors.energy_product_name}
          helperText={errors.energy_product_name}
          onChange={(e) => {
            handleChange_after(e);
          }}
          value={formData.energy_product_name}
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="dense"
          size="small"
          name="supplier_name"
          label={<>{t('Supplier Name')}</>}
          error={!!errors.supplier_name}
          helperText={errors.supplier_name}
          onChange={(e) => {
            handleChange_after(e);
          }}
          value={formData.supplier_name}
          fullWidth
          variant="outlined"
        />
        <Box sx={{ width: '100%', border: '1px solid #757575', padding: '10px', marginTop: '30px' }}>
          <Typography
            variant="h5"
            sx={{ marginTop: '-25px', width: '215px', background: theme.palette.background.paper, textAlign: 'center' }}
          >
            {t('Energy Sources')}
          </Typography>

          <div>
            <div style={{ float: 'right' }}>
              <AddCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Add')} onClick={handleAddForm_source} />
              {/* <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Remove')} onClick={handleRemoveForm} /> */}
            </div>
          </div>
          {components.map((index, ind) => {
            const childRef_source = React.createRef();
            componentRefs.current[index] = childRef_source;
            return (
              <div key={ind}>
                <SourceModel
                  childRef={childRef_source}
                  // updata={updata_data}
                  sourceData={formData.energy_sources[ind]}
                  key={ind}
                  index={ind}
                  handleRemoveForm={handleRemoveForm_source}
                  componentIndex={ind}
                  setOpenAlertMsg={setOpenAlertMsg}
                  setOpenAlert={setOpenAlert}
                ></SourceModel>
              </div>
            );
          })}
        </Box>
        <Box sx={{ width: '100%', border: '1px solid #757575', padding: '10px', marginTop: '30px' }}>
          <Typography
            variant="h5"
            sx={{ marginTop: '-25px', width: '215px', background: theme.palette.background.paper, textAlign: 'center' }}
          >
            {t('Environmental Impact')}
          </Typography>
          <div>
            <div style={{ float: 'right' }}>
              <AddCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Add')} onClick={handleAddForm_env} />
              {/* <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Remove')} onClick={handleRemoveForm} /> */}
            </div>
          </div>
          {components_env.map((index, ind) => {
            const childRef_env = React.createRef();
            componentRefs_env.current[index] = childRef_env;
            return (
              <div key={ind}>
                <Environmental
                  childRef={childRef_env}
                  // setSourceData={setFormData}
                  sourceData={formData.environmental_impact[ind]}
                  key={ind}
                  index={ind}
                  handleRemoveForm={handleRemoveForm_env}
                  componentIndex={ind}
                ></Environmental>
              </div>
            );
          })}
        </Box>
      </form>
    </Box>
  );
}
