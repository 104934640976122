import React, { useContext, useEffect, useState } from 'react';
import { TextField, Menu, MenuItem, Stack, Snackbar, Box, Alert, LinearProgress, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../../../App';
import IconEvse from '../../../../../components/customIcon/icon-evse.svg';

import type1Icon from '../../../../../components/customIcon/icon-type-1.svg';
import type1Icon_light from '../../../../../components/lightMode/icon-type-1.svg';
import type2Icon from '../../../../../components/customIcon/icon-type-2.svg';
import type2Icon_light from '../../../../../components/lightMode/icon-type-2.svg';

import ccstype1Icon from '../../../../../components/customIcon/icon-ccs-type 1.svg';
import ccstype1Icon_light from '../../../../../components/lightMode/icon-ccs-type 1.svg';
import ccstype2Icon from '../../../../../components/customIcon/icon-ccs-type 2.svg';
import ccstype2Icon_light from '../../../../../components/lightMode/icon-ccs-type 2.svg';

import chademoIcon from '../../../../../components/customIcon/icon-chademo-24.svg';
import chademoIcon_light from '../../../../../components/lightMode/icon-chademo-24.svg';
import chaojiIcon from '../../../../../components/customIcon/icon-chaoji-24.svg';
import chaojiIcon_light from '../../../../../components/lightMode/icon-chaoji-24.svg';
import gbtacIcon from '../../../../../components/customIcon/icon-gbtac-24.svg';
import gbtacIcon_light from '../../../../../components/lightMode/icon-gbtac-24.svg';
import gbtdcIcon from '../../../../../components/customIcon/icon-gbtdc-24.svg';
import gbtdcIcon_light from '../../../../../components/lightMode/icon-gbtdc-24.svg';

export default function EvseLayout({ evse, marginTop, isHighlighted, onClick }, props) {
  const { t } = useTranslation();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  const theme = useTheme();
  useEffect(() => {
    // setLocArrs();
  }, [props]);

  useEffect(() => { }, []);

  const connectType = (option) => {
    let iconSrc = '';
    switch (option) {
      case 'CHADEMO':
        iconSrc = darkTheme ? chademoIcon : chademoIcon_light;
        break;
      case 'CHAOJI':
        iconSrc = darkTheme ? chaojiIcon : chaojiIcon_light;
        break;
      case 'GBT_AC':
        iconSrc = darkTheme ? gbtacIcon : gbtacIcon_light;
        break;
      case 'GBT_DC':
        iconSrc = darkTheme ? gbtdcIcon : gbtdcIcon_light;
        break;
      case 'IEC_62196_T1':
        iconSrc = darkTheme ? type1Icon : type1Icon_light;
        break;
      case 'IEC_62196_T2':
        iconSrc = darkTheme ? type2Icon : type2Icon_light;
        break;
      case 'IEC_62196_T1_COMBO':
        iconSrc = darkTheme ? ccstype1Icon : ccstype1Icon_light;
        break;
      case 'IEC_62196_T2_COMBO':
        iconSrc = darkTheme ? ccstype2Icon : ccstype2Icon_light;
        break;
      default:
        break;
    }
    if (iconSrc !== '') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            marginLeft: '8px',
          }}
        >
          <Box
            component="img"
            title={option}
            src={iconSrc}
            sx={{ width: '24px', height: '50px', userSelect: 'none', WebkitUserDrag: 'none' }}
          />
          {/* {option} */}
        </div>
      );
    } else {
      return option;
    }
  };

  return (
    <Box
      backgroundColor={theme.palette.background.tableRow}
      width="270px"
      marginTop={marginTop}
      onClick={onClick}
      border={isHighlighted ? '1px solid #00ACBB' : 'none'}
    >
      <Box
        padding="8px"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '260px',
        }}
        title={evse.cp_id}
      >
        {evse.cp_id}
      </Box>
      <Box display="flex" padding="5px" backgroundColor={theme.palette.background.pagerWhite} width="100%">
        <Box component="img" textAlign="center" src={IconEvse} sx={{ userSelect: 'none', WebkitUserDrag: 'none' }} />
        <Box width="calc(100% - 25px)">
          <Box display="flex" alignItems="center" justifyContent="space-around" padding="5px">
            {/* {evse.connector?.map((item, ind) => connectType(item.connector_type))} */}
            {evse.connector && evse.connector.length != 0 ? (
              evse.connector.map((item, ind) => connectType(item.connector_type))
            ) : (
              <p
                style={{
                  color: theme.palette.title.main,
                  width: '60px',
                  height: '60px',
                  lineHeight: '60px',
                  userSelect: 'none',
                  WebkitUserDrag: 'none',
                }}
              >
                {t('No Data')}
              </p>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            backgroundColor={theme.palette.background.paper2}
            padding="7px"
          >
            <p>{evse.rated_power ? evse.rated_power + 'kW' : '0.00kW'}</p>
            {/* {evse.connector?.map((item, ind) => {
              return <p>{item.max_voltage ? item.max_voltage + 'kW' : '0.00kW'}</p>;
            })} */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
