import React, { useEffect } from 'react';
// mui
import {
  Stack,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Confirmation({ formData, setFormData }) {
  const { t } = useTranslation();
  const column = [
    {
      id: 'name',
      label: t('EVSE'),
      width: 70,
    },
    {
      id: 'connector',
      label: t('Connector'),
      width: 120,
    },
    {
      id: 'user',
      label: t('User'),
      width: 150,
    },
    {
      id: 'transaction_id',
      label: t('Transaction Id'),
      width: 120,
    },
    {
      id: 'function',
      label: t('Function'),
      width: 120,
    },
  ];
  useEffect(() => {
    setFormData({ ...formData, checkedid: formData.checkeduser.mobile_id });
  }, []);
  return (
    <Stack direction="column" spacing={3}>
      <Typography variant="body1">
        {t('tip_reviewTransaction')}.
        {/* Please review the selected EVSE and user for Start transaction to be carried out. */}
      </Typography>
      <Table aria-labelledby="tableTitles">
        <TableHead>
          <StyledTableRow>
            {column.map((headCell, index) => (
              <TableCell key={index} align="left" padding="normal" sx={{ width: headCell.width }}>
                {headCell.label}
              </TableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <TableRow key={'1'}>
            <StyledTableCell align="left" padding="normal">
              {formData.checked}
            </StyledTableCell>
            <StyledTableCell align="left" padding="normal">
              {formData.connectorName}
            </StyledTableCell>
            <StyledTableCell align="left" padding="normal">
              {/* {Object.entries(formData.checkeduser).map(([name, value]) => (
                <p key={value}>
                  {name}: {value}
                </p>
              ))} */}
              {formData.checkeduser ? <p>name: {formData.checkeduser.name}</p> : ''}
            </StyledTableCell>
            <StyledTableCell>1</StyledTableCell>
            <StyledTableCell>{t('Remote Start Transaction')}</StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));
