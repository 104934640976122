import React from 'react';
// mui
import { Stack, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectUnlockPage({ formData, setFormData, evseName, rows }) {
  const { t } = useTranslation();

  const handleChange = (event) => {
    setFormData({ ...formData, connectorName: `Connector ${event.target.value}`, connectorId: event.target.value });
  };
  return (
    <Stack direction="column" spacing={3}>
      <Typography variant="body1">
        {t('Unlock_selected', { selectName: evseName })}
        {/* {evseName} is selected for Unlock connector to be carried out. Please confirm the connector selected if there is
        more than 1 connector on the EVSE. */}
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="Connector selected">{t('Connector selected')}</InputLabel>
        <Select id="select-choice" value={formData.connectorId} label={t('Connector selected')} onChange={handleChange}>
          {rows.map((rows) => (
            <MenuItem key={rows.value} value={rows.value}>
              {rows.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
