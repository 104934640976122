import axios from 'axios';

// const BASE_URL = 'http://localhost:5000/';
// const BASE_URL = 'http://localhost:5000/web_api';
// const BASE_URL = 'http://192.168.1.31:5000/web_api';
const BASE_URL = 'https://api.energyon-csms.com/web_api';
// const BASE_URL = 'https://mrjgdsg5u4.execute-api.ap-southeast-1.amazonaws.com/dev';
// const BASE_URL = 'http://ec2-18-136-212-96.ap-southeast-1.compute.amazonaws.com/web_api';

export default axios.create({
  baseURL: BASE_URL,
});
