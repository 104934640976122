import React, { useEffect, useState } from 'react';
// mui
import { Stack, Box, DialogContent, DialogTitle, IconButton, Dialog, Button, LinearProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import CloseIcon from 'src/components/customIcon/Close.svg';
import { buttonR, buttonL } from 'src/components/button/buttonStyle';
// ----------------------------------------------------------------------

export default function PublishConfirmation({
  updateList,
  handleSubmit,
  base64Content,
  formData,
  //   loading_pro,
  //   setOpen,
  //   open,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading_pro, setLoading_pro] = useState(false);
  const handleClickOpen = () => {
    // VerifyData
    // getVerifyData() ? null : setOpen(true);
    setOpen(true);
  };

  const handleClose = (e, reason) => {
    if (reason == 'backdropClick') {
      return;
    }
    setOpen(false);
  };
  // save
  const handleConfirm = () => {
    handleSubmit('Published');
    setOpen(false);
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          marginLeft: '10px',
          width: '160px',
          height: '32px',
          background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
          boxShadow: 'none',
          color: 'primary.contrastText',
          fontFamily: 'Arial',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '22px',
          textAlign: 'center',
        }}
      >
        {t('Publish')}
      </Button>

      <Dialog open={open} onClose={handleClose} aria-labelledby="AdminDeleteDialog" sx={{ zIndex: 2000 }}>
        {loading_pro && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="Information">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {formData.schedule_type == 'Immediate'
              ? t('Publish this campaign immediately')
              : t('Publish this scheduled campaign')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {formData.schedule_type == 'Immediate' ? t('promo_imm_tip') : t('promo_sche_tip')}
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <LoadingButton variant="outlined" loading={loading_pro} onClick={handleClose} sx={buttonL}>
              {t('Cancel')}
            </LoadingButton>
            <LoadingButton onClick={handleConfirm} loading={loading_pro} sx={buttonR}>
              {t('Confirm')}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
