import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// socket-io
import io from 'socket.io-client';
import { socketUrl } from '../../../api/socket';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import useAuth from '../../../hooks/useAuth';
import { SubmitLogin } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

let socket1;

export default function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const cognito = location.state?.from?.pathname || '/AWSCognitoLogin';
  const from = location.state?.from?.pathname || '/pages/summary';
  const { setAuth } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConvergence, setPasswordConvergence] = useState(false);
  const [helperText, setHelperText] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setErrMsg('');
  }, [email, password]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('verify_emailAddress')),
    password: Yup.string(),
  });

  const methods = useForm({ resolver: yupResolver(LoginSchema) });

  const { handleSubmit } = methods;

  const onSubmit = () => {
    SubmitLogin(email, password, setErrMsg)
      .then((promiseResult) => {
        console.log(promiseResult);
        if (promiseResult.status === 401) {
          setErrMsg(promiseResult.message);
        } else {
          // debugger; //access_token  aws_token
          const { access_token, name, role } = promiseResult.data;
          setAuth({ email, access_token, name, role });
          sessionStorage.setItem('email', email);
          sessionStorage.setItem('access_token', access_token);
          sessionStorage.setItem('name', name);
          sessionStorage.setItem('role', role);
          setEmail('');
          setPassword('');
          if (email === octtUser) {
            navigate(octt, { replace: true });
          } else if (email === 'benjin.lau@liteon.com') {
            navigate(cognito, { replace: true });
          } else {
            navigate(from, { replace: true });
          }
          // socket1 = io.connect(socketUrl, {
          //   // transportOptions: {
          //   //   polling: {
          //   extraHeaders: {
          //     authorization: sessionStorage.getItem('access_token'),
          //   },
          //   //   },
          //   // },
          // });
        }
      })
      .catch((error) => {
        console.log('error:' + error);
      });
  };

  // mouse loses focus passWord cpmplexity verification
  const password_onBlur = (e) => {
    const password = e.target.value;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    setPasswordConvergence(true);
    if (password.length < 8) {
      setHelperText(t('veify_length8'));
    } else if (!lowercaseRegex.test(password)) {
      setHelperText(t('veify_lowercase'));
    } else if (!uppercaseRegex.test(password)) {
      setHelperText(t('veify_uppercase'));
    } else if (!specialCharRegex.test(password)) {
      setHelperText(t('veify_specialchar'));
    } else if (!digitRegex.test(password)) {
      setHelperText(t('veify_number'));
    } else {
      setHelperText('');
      setPasswordConvergence(false);
    }
  };

  return (
    <ContentStyle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Typography variant="subtitle1" sx={{ pl: 1, color: 'error.main' }}>
            {errMsg}
          </Typography>
          <RHFTextField
            name="email"
            label={t('Email Address')}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <RHFTextField
            error={passwordConvergence}
            margin="dense"
            helperText={helperText}
            name="password"
            label={t('Password')}
            onChange={(e) => {
              setPassword(e.target.value);
              setHelperText('');
              setPasswordConvergence(false);
            }}
            // onBlur={password_onBlur} // mouse loses focus
            value={password}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link variant="subtitle2" underline="hover" component={RouterLink} to="/forgotpassword">
            {t('Forgot password')}?
          </Link>
          <Link variant="subtitle2" underline="hover" component={RouterLink} to="/AWSCognitoLogin">
            {t('AWS_Login')}
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
            boxShadow: 0,
            color: 'primary.contrastText',
            textAlign: 'center',
          }}
        >
          {t('Login')}
        </LoadingButton>
      </FormProvider>
    </ContentStyle>
  );
}
export { socket1 };

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
}));
