import React, { useState } from 'react';
// mui
import { Box, Menu, MenuItem } from '@mui/material';
// component
import URL from './url/index';
import FILE from './file/index';
import Upload from './upload/index';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function UpdateFirmware(props) {
  const { t } = useTranslation();

  const { evseName, evseInfo } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMain = () => {
    setAnchorEl(null);
    props.handleCloseMain();
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <MenuItem onClick={handleClick} disabled={evseInfo.connector[0]?.status === 'Unknown'}>
        {t('Update Firmware')}
      </MenuItem>
      <Menu
        id="diagnosticreportmenu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Upload
          CloseMain={handleCloseMain}
          evseInfo={evseInfo}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
        />
        <FILE
          CloseMain={handleCloseMain}
          evseInfo={evseInfo}
          evseName={evseName}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
        />
        {/* <URL
          evseName={evseName}
          CloseMain={handleClose}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
        /> */}
      </Menu>
    </Box>
  );
}
