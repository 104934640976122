import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
// mui
import { TextField, MenuItem, Box, Typography, IconButton, Switch, useTheme, TextareaAutosize } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Iconify from '../../../../../components/Iconify';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { verify_inputNumber, verify_inputNumber_null } from '../../../../../components/verification';
// ----------------------------------------------------------------------
// import dayjs from 'dayjs';
// import 'dayjs/locale/zh';
// dayjs.locale('zh');

export default function Default({
  cRef,
  defaultData,
  setSteps,
  steps,
  setCurrency,
  setOpenAlert,
  setOpenAlertMsg,
  currency,
}) {
  const { t, i18n } = useTranslation();
  let currencyArr = ['SGD', 'TWD', 'USD', 'GBP', 'EUR'];
  const theme = useTheme();
  const [endDateError, setEndDateError] = useState(false);
  const [openPrice, setOpenPrice] = useState(false);
  const [descriptionError, setDescriptionError] = useState('');
  const [errors, setErrors] = useState({
    name: '',
    currency: '',
  });
  const [errorsOption, setErrorsOption] = useState({
    min_price: {
      excl_vat: '',
      incl_vat: '',
    },
    max_price: {
      excl_vat: '',
      incl_vat: '',
    },
  });
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const startData = {
    id: '',
    name: '',
    currency: currency,
    tariff_type: 'REGULAR',
    tariff_text: [],
    description: '',
    start_date_time: null,
    end_date_time: null,
    language: selectedLanguage,
    // elements: [],
    min_price: {
      excl_vat: '',
      incl_vat: '',
    },
    max_price: {
      excl_vat: '',
      incl_vat: '',
    },
    // reservation_show: false,
    // reservationExpires_show: false,
  };
  const [formData, setFormData] = useState(startData);
  useEffect(() => {
    if (Object.keys(defaultData) != 0) {
      setFormData(defaultData);
    } else {
      setFormData(startData);
    }
  }, [defaultData]);

  useImperativeHandle(cRef, () => ({
    getComponentData,
  }));
  const verify_minMaxData = () => {
    let hasError = false;
    let errorsOptionObj = {
      min_price: {
        excl_vat: '',
        incl_vat: '',
      },
      max_price: {
        excl_vat: '',
        incl_vat: '',
      },
    };
    if (
      formData.min_price.excl_vat &&
      formData.max_price.excl_vat &&
      Number(formData.min_price.excl_vat) > Number(formData.max_price.excl_vat)
    ) {
      hasError = true;

      errorsOptionObj['max_price']['excl_vat'] =
        t('Maximum') +
        ' ' +
        t('Price excl.Tax') +
        ' ' +
        t('cannot be less than') +
        ' ' +
        t('Minimum') +
        ' ' +
        t('Price excl.Tax');
      // ('max_price.excl_vat  min_price.excl_vat');
    }
    if (
      formData.min_price.incl_vat &&
      formData.max_price.incl_vat &&
      Number(formData.min_price.incl_vat) > Number(formData.max_price.incl_vat)
    ) {
      hasError = true;
      errorsOptionObj['min_price']['incl_vat'] =
        t('Minimum') +
        ' ' +
        t('Price incl.Tax') +
        ' ' +
        t('cannot be greater than') +
        ' ' +
        t('Maximum') +
        ' ' +
        t('Price incl.Tax');
      // ('min_price.incl_vat  max_price incl_vat');
    }
    if (
      formData.min_price.excl_vat &&
      formData.min_price.incl_vat &&
      Number(formData.min_price.excl_vat) > Number(formData.min_price.incl_vat)
    ) {
      hasError = true;
      errorsOptionObj['min_price']['excl_vat'] =
        t('Minimum') +
        ' ' +
        t('Price excl.Tax') +
        ' ' +
        t('cannot be greater than') +
        ' ' +
        t('Maximum') +
        ' ' +
        t('Price incl.Tax');
      // 'min_price excl_vat  incl_vat';
    }
    if (
      formData.max_price.excl_vat &&
      formData.max_price.incl_vat &&
      Number(formData.max_price.excl_vat) > Number(formData.max_price.incl_vat)
    ) {
      hasError = true;
      errorsOptionObj['max_price']['incl_vat'] =
        t('Maximum') +
        ' ' +
        t('Price excl.Tax') +
        ' ' +
        t('cannot be greater than') +
        ' ' +
        t('Maximum') +
        ' ' +
        t('Price incl.Tax');
      // 'max_price excl_vat  incl_vat';
    }
    setErrorsOption(errorsOptionObj);
    return hasError;
  };
  // handleSubmit
  const getComponentData = (event) => {
    // event.preventDefault();
    // not empty verify
    let hasError = false;
    let fd = formData;
    let errorObj = {};
    for (const key in fd) {
      const er = errors;
      if (fd.hasOwnProperty(key) && fd[key] === '' && er.hasOwnProperty(key)) {
        er[key] = t('required fields');
        errorObj = { ...errorObj, [key]: t('required fields') };

        hasError = true;
      }
    }
    if (verify_minMaxData()) {
      hasError = true;
    }
    const isAllEmpty = Object.values(errorsOption).every((price) => {
      return Object.values(price).every((value) => value === '');
    });
    setErrors({ ...errors, errorObj });

    // do not submit
    if (hasError || !isAllEmpty) {
      return false;
    } else {
      return formData;
    }
  };

  return (
    <div>
      <form onSubmit={getComponentData}>
        <Box>
          <TextField
            margin="dense"
            name="name"
            sx={{
              background: theme.palette.background.pagerWhite,
              marginRight: theme.spacing(1),
              width: '20ch',
              '& input:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.pagerWhite} inset`,
              },
            }}
            disabled={defaultData.id ? true : false}
            label={
              <>
                <span>* </span>
                {t('Profile Name')}
              </>
            }
            value={formData.name}
            size="small"
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value });
              setErrors({ ...errors, name: '' });
            }}
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            margin="dense"
            select
            sx={{ background: theme.palette.background.pagerWhite, marginRight: theme.spacing(1), width: '20ch' }}
            // fullWidth
            name="currency"
            label={
              <>
                <span>* </span>
                {t('Currency')}
              </>
            }
            value={formData.currency}
            onChange={(e) => {
              setFormData({ ...formData, currency: e.target.value });
              setErrors({ ...errors, currency: '' });
              setCurrency(e.target.value);
            }}
            size="small"
            error={!!errors.currency}
            helperText={errors.currency}
          >
            {currencyArr.map((label, index) => {
              return (
                <MenuItem key={label} value={label}>
                  {t(label)}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            margin="dense"
            select
            sx={{ background: theme.palette.background.pagerWhite, marginRight: theme.spacing(1), width: '20ch' }}
            // fullWidth
            name="tariff_type"
            label={t('Tariff Type')}
            value={formData.tariff_type}
            onChange={(e) => {
              setFormData({ ...formData, tariff_type: e.target.value });
              setErrors({ ...errors, tariff_type: '' });
            }}
            size="small"
          >
            {/* <MenuItem key="AD_HOC_PAYMENT" value="AD_HOC_PAYMENT">
              {t('Ad Hoc Payment')}
            </MenuItem> */}
            <MenuItem key="REGULAR" value="REGULAR">
              {t('Regular')}
            </MenuItem>
          </TextField>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              name="start_date_time"
              label={t('Start Date')}
              views={['year', 'month', 'day']}
              inputFormat="DD/MM/YYYY"
              value={formData.start_date_time}
              onChange={(date) => {
                // Check if the selected end date is before the start date
                if (formData.end_date_time && date !== null && date.isAfter(formData.end_date_time, 'day')) {
                  // Show an error or handle the invalid date
                  setOpenAlert(true);
                  setOpenAlertMsg(t('date_after'));
                } else {
                  // Clear the error state if the selected date is valid
                  setOpenAlert(false);
                  setFormData({ ...formData, start_date_time: date });
                }
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ background: theme.palette.background.pagerWhite, marginRight: theme.spacing(1), width: '20ch' }}
                  margin="dense"
                  size="small"
                  {...params}
                />
              )}
            />
            {/* </LocalizationProvider>{' '} 
            <LocalizationProvider dateAdapter={AdapterDayjs}> */}
            <DatePicker
              name="end_date_time"
              label={t('End Date')}
              views={['year', 'month', 'day']}
              inputFormat="DD/MM/YYYY"
              value={formData.end_date_time}
              onChange={(date) => {
                // Check if the selected end date is before the start date
                if (formData.start_date_time && date !== null && date.isBefore(formData.start_date_time, 'day')) {
                  // Show an error or handle the invalid date
                  setOpenAlert(true);
                  setOpenAlertMsg(t('date_before'));
                } else {
                  // Clear the error state if the selected date is valid
                  setOpenAlert(false);
                  setFormData({ ...formData, end_date_time: date });
                }
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ background: theme.palette.background.pagerWhite, marginRight: theme.spacing(1), width: '20ch' }}
                  margin="dense"
                  size="small"
                  // error={endDateError}
                  // helperText={endDateError ? t('date_before') : ''}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <TextareaAutosize
          margin="dense"
          name="description"
          label={t('Description')}
          value={formData.description}
          minRows={5}
          placeholder={t('Description') + '(maxLength:512)'}
          style={{
            padding: '10px 5px',
            textIndent: 16,
            marginTop: '10px',
            width: '60ch',
            background: theme.palette.background.pagerWhite,
            fontFamily: 'Arial',
            fontSize: '18px',
            color: theme.palette.title.main,
          }}
          // fullWidth
          size="small"
          onChange={(e) => {
            if (e.target.value.length > 513) {
              return;
            }
            setFormData({ ...formData, description: e.target.value });
          }}
        />
        <Box>
          <IconButton aria-label={t('expand row')} size="small" onClick={() => setOpenPrice(!openPrice)}>
            {openPrice ? (
              <ArrowDropDownIcon sx={{ color: '#00ACBB', fontSize: 30 }}></ArrowDropDownIcon>
            ) : (
              <ArrowRightIcon sx={{ color: '#00ACBB', fontSize: 30 }}></ArrowRightIcon>
            )}
          </IconButton>
          {t('mum_priceTax')}
          {/* Minimum and Maximum Price/Tax */}
        </Box>

        <Box sx={{ display: openPrice ? 'block' : 'none' }}>
          <TextField
            title={`${t('Minimum') + ' ' + t('Price excl.Tax')}`}
            margin="dense"
            name="excl_vat"
            // type="number"
            // label={t('Price excl.Tax')}
            label={`${t('Minimum') + ' ' + t('Price excl.Tax')}`}
            value={formData.min_price?.excl_vat}
            sx={{
              background: theme.palette.background.pagerWhite,
              marginRight: theme.spacing(1),
              width: '20ch',
              '& input:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.pagerWhite} inset`,
              },
            }}
            size="small"
            onChange={(e) => {
              let verify = verify_inputNumber_null(e.target.value.trim(), formData.min_price?.excl_vat);
              setFormData({
                ...formData,
                min_price: {
                  ...formData.min_price,
                  excl_vat: verify.value,
                },
              });
              setErrorsOption({
                ...errorsOption,
                min_price: {
                  ...errorsOption.min_price,
                  excl_vat: verify.error,
                },
              });
            }}
            error={!!errorsOption.min_price?.excl_vat}
            helperText={errorsOption.min_price?.excl_vat}
          />
          <TextField
            title={`${t('Minimum') + ' ' + t('Price incl.Tax')}`}
            margin="dense"
            name="incl_vat"
            // type="number"
            label={`${t('Minimum') + ' ' + t('Price incl.Tax')}`}
            value={formData.min_price?.incl_vat}
            sx={{
              background: theme.palette.background.pagerWhite,
              marginRight: theme.spacing(1),
              width: '20ch',
              '& input:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.pagerWhite} inset`,
              },
            }}
            size="small"
            onChange={(e) => {
              let verify = verify_inputNumber_null(e.target.value.trim(), formData.min_price?.incl_vat);
              setFormData({
                ...formData,
                min_price: {
                  ...formData.min_price,
                  incl_vat: verify.value,
                },
              });
              setErrorsOption({
                ...errorsOption,
                min_price: {
                  ...errorsOption.min_price,
                  incl_vat: verify.error,
                },
              });
            }}
            error={!!errorsOption.min_price?.incl_vat}
            helperText={errorsOption.min_price?.incl_vat}
          />
        </Box>
        <Box sx={{ display: openPrice ? 'block' : 'none' }}>
          <TextField
            title={`${t('Maximum') + ' ' + t('Price excl.Tax')}`}
            margin="dense"
            name="excl_vat"
            // type="number"
            // label={t('Price excl.Tax')}
            label={`${t('Maximum') + ' ' + t('Price excl.Tax')}`}
            value={formData.max_price?.excl_vat}
            sx={{
              background: theme.palette.background.pagerWhite,
              marginRight: theme.spacing(1),
              width: '20ch',
              '& input:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.pagerWhite} inset`,
              },
            }}
            size="small"
            onChange={(e) => {
              let verify = verify_inputNumber_null(e.target.value.trim(), formData.max_price?.excl_vat);
              setFormData({
                ...formData,
                max_price: {
                  ...formData.max_price,
                  excl_vat: verify.value,
                },
              });
              setErrorsOption({
                ...errorsOption,
                max_price: {
                  ...errorsOption.max_price,
                  excl_vat: verify.error,
                },
              });
            }}
            error={!!errorsOption.max_price?.excl_vat}
            helperText={errorsOption.max_price?.excl_vat}
          />
          <TextField
            margin="dense"
            name="incl_vat"
            title={`${t('Maximum') + ' ' + t('Price incl.Tax')}`}
            // type="number"
            // label={t('Price incl.Tax')}
            label={`${t('Maximum') + ' ' + t('Price incl.Tax')}`}
            value={formData.max_price?.incl_vat}
            sx={{
              background: theme.palette.background.pagerWhite,
              marginRight: theme.spacing(1),
              width: '20ch',
              '& input:-webkit-autofill': {
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.pagerWhite} inset`,
              },
            }}
            size="small"
            onChange={(e) => {
              let verify = verify_inputNumber_null(e.target.value.trim(), formData.max_price?.incl_vat);
              setFormData({
                ...formData,
                max_price: {
                  ...formData.max_price,
                  incl_vat: verify.value,
                },
              });
              setErrorsOption({
                ...errorsOption,
                max_price: {
                  ...errorsOption.max_price,
                  incl_vat: verify.error,
                },
              });
            }}
            error={!!errorsOption.max_price?.incl_vat}
            helperText={errorsOption.max_price?.incl_vat}
          />
        </Box>
        {/* <div style={{ padding: '10px 0 5px 0' }}>
          <span style={{ marginTop: '5px' }}>{t('Reservation')}</span>
          <Switch
            checked={formData.reservation_show}
            onChange={(event) => handleSwitch(event, event.target.name, 'Reservation')}
            name="reservation_show"
          />
        </div>
        <div style={{ padding: '10px 0 5px 0' }}>
          <span style={{ marginTop: '5px' }}>{t('Reservation Expires')}</span>
          <Switch
            checked={formData.reservationExpires_show}
            onChange={(event) => handleSwitch(event, event.target.name, 'Expires')}
            name="reservationExpires_show"
          />
        </div> */}
      </form>
    </div>
  );
}
