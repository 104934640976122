// input only key number
export function verify_inputNumber(inputValue, preData) {
  let value;
  const isValid = /^(\d+(\.\d*)?|\.\d+)$/.test(inputValue);
  if (!isValid && inputValue !== '') {
    // value = preData ? preData : '';
    value = inputValue;
  }
  let floatValue = inputValue;
  let error = '';
  if (/^\d+(\.\d+)?$/.test(inputValue)) {
    value = isNaN(floatValue) || floatValue < 0 ? '' : floatValue;
    // value = !isNaN(Number(value)) ? parseFloat(value).toFixed(2) : value;
    if (!isNaN(Number(value))) {
      if (value.split('.')[1]?.length > 2) {
        value = Number(value.substring(0, value.indexOf('.') + 3));
        // value = parseFloat(value).toFixed(2);
      } else {
        value !== '0.0' ? Number(value) : value;
      }
    } else {
      value = value;
    }
    // value = !isNaN(Number(value)) ? (value !== '0.0' ? Number(value) : value) : value;
  } else {
    error = 'Invalid value';
  }
  return { value, error };
}
// verify_inputNumber eg:
// {...
// value={ formData.price_component[0].price}
// type="number"
// onChange={(e) => {
//   let verify = verify_inputNumber(e.target.value.trim(), formData.price_component[0].price);
//   setFormData((prevFormData) => ({
//     ...prevFormData,
//     price_component: [
//       {
//         ...prevFormData.price_component[0],
//         price: verify.value,
//       },
//       ...prevFormData.price_component.slice(1),
//     ],
//   }));
//   setErrors({ ...errors, price: verify.tip });
// }}
// ...}

export function verify_inputNumber_null(inputValue, preData) {
  let value;
  const isValid = /^(\d+(\.\d*)?|\.\d+)$/.test(inputValue);
  if (!isValid && inputValue !== '') {
    // value = preData ? preData : '';
    value = inputValue;
  }
  let floatValue = inputValue;
  let error = '';
  if (/^\d+(\.\d+)?$/.test(inputValue)) {
    // debugger;
    // floatValue = parseFloat(inputValue);
    value = isNaN(floatValue) || floatValue < 0 ? '' : floatValue;
    // value = !isNaN(Number(value)) ? parseFloat(value).toFixed(2) : value;
    if (!isNaN(Number(value))) {
      if (value.split('.')[1]?.length > 2) {
        // value = parseFloat(value).toFixed(2);
        value = Number(value.substring(0, value.indexOf('.') + 3));
      } else {
        value !== '0.0' ? Number(value) : value;
      }
    } else {
      value = value;
    }
    // value = !isNaN(Number(value)) ? (value !== '0.0' ? Number(value) : value) : value;
  } else {
    if (inputValue == '') {
      value = null;
    } else {
      error = 'Invalid value';
    }
  }
  return { value, error };
}
