// scroll bar
import 'simplebar/src/simplebar.css';
// react
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// component
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvider';
import './i18n';
// language lib
import { I18nextProvider } from 'react-i18next';
// import Theme from './pages/theme';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
i18n.changeLanguage(localStorage.getItem('languageCode') ? localStorage.getItem('languageCode') : i18n.language);
root.render(
  <HelmetProvider>
    <AuthProvider>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </AuthProvider>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
