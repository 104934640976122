// mui
import { Card, Stack, Typography, alpha, styled, useTheme, Box } from '@mui/material';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: '80px',
  height: '80px',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function IconFormate(props) {
  const theme = useTheme();
  const { title, width, total, unit, icon, color, sx, ...other } = props;
  const number = parseFloat(total, 10).toFixed(2);
  return (
    <Box
      sx={{
        width: width,
        boxShadow: 0,
        textAlign: 'center',
        color: theme.palette.title.main,
        background: 'none',
      }}
    >
      <IconWrapperStyle
        sx={{
          background: theme.palette.background.pagerWhite,
          marginBottom: '10px',
          border: `1px solid ${props.color}`,
        }}
      >
        <Box
          component="img"
          src={icon}
          sx={{ width: '40px', height: '40px', userSelect: 'none', WebkitUserDrag: 'none' }}
        />
      </IconWrapperStyle>
      <Typography
        sx={{
          //   height: '30px',
          fontFamily: 'Arial',
          fontWeight: 700,
          fontSize: '0.7vw',
          // width: '90px',
          lineHeight: '20px',
          textAlign: 'center',
          // overflow: 'hidden',
          // textOverflow: 'ellipsis',
          // whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Typography>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Typography
          sx={{
            fontFamily: 'Arial',
            color: theme.palette.text.primary,
            background: theme.palette.background.pagerWhite,
            fontSize: '0.8vw',
            lineHeight: '25px',
            padding: '0 5px',
            textAlign: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={number ? number.toLocaleString() + unit : 0 + unit}
        >
          {number ? (number.toLocaleString() == 'NaN' ? '--' : number.toLocaleString()) : '--'}
          {unit}
        </Typography>
        {/* <Typography
          sx={{
            fontFamily: 'Arial',
            background: theme.palette.background.pagerWhite,
            color: theme.palette.text.primary,
            paddingRight: '10px',
            fontSize: '0.8vw',
            lineHeight: '25px',
            textAlign: 'center',
          }}
        >
          {unit}
        </Typography> */}
      </Stack>
      {/* <Typography variant="h3">{fShortenNumber(total)}</Typography> */}
    </Box>
  );
}
