// Import React and ReactDOM
import React from 'react';
// conponents
import DeleteAccountCN from './DeleteCN';
import RetrieveAccountCN from './RetrieveCN';
// components
import Page from 'src/components/Page';

export default function ChineseDeleteRetrieve() {
  return (
    <Page title={'Delete & Retrieve Account'}>
      <DeleteAccountCN />
      <RetrieveAccountCN />
    </Page>
  );
}

const styles = {
  pl: '100px',
  pr: '100px',
  pb: '20px',
  fontSize: '24px',
};
