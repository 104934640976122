import React, { useState } from 'react';
// mui
import { Box, IconButton, Menu, Tooltip } from '@mui/material';
// components
import EditStationDialog from './EditStationDialog/index';
import DeleteStationDialog from './DeleteStationDialog';
import Icon from '../../../../../../../components/customIcon/Union.svg';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function EditChargingStation(props) {
  const { station, Update, Close, locationName, url } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Tooltip title={t('Edit') + ' ' + t('Station')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="chargingstationmenu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {/* locationName={locationName}
            evseData={station.evse}
            station={station}
            Update={(childData) => props.Update(childData)}
            url={url}
            Close={(childData) => props.Close(childData)}
            setSuccess={(childData) => props.setSuccess(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={(childData) => props.setError(childData)} */}
        <EditStationDialog
          closeAll={handleClose}
          locationName={locationName}
          url={url}
          station={station}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
          // EditUpdate={Update}
          EditUpdate={(childData) => props.Update(childData)}
          EditClose={(childData) => props.Close(childData)}
        />
        <DeleteStationDialog
          closeAll={handleClose}
          stationData={station}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
          DeleteUpdate={props.Update}
          DeleteClose={(childData) => props.Close(childData)}
        />
      </Menu>
    </div>
  );
}
