import React, { useEffect, useState } from 'react';
// mui
import { Stack, Typography, Card, CardContent, CardHeader, ListItem, ListItemText, useTheme, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Con_parkTime({ defaultData, allData }) {
  const [formData, setFormData] = useState([]);
  const { t } = useTranslation();
  const theme = useTheme();
  function getDayType(daysArray) {
    // const weekdays = ['Friday', 'Monday', 'Thursday', 'Tuesday', 'Wednesday'];
    const weekdays = ['FRIDAY', 'MONDAY', 'THURSDAY', 'TUESDAY', 'WEDNESDAY'];
    //const weekends = ['Saturday', 'Sunday'];
    const weekends = ['SATURDAY', 'SUNDAY'];
    const sortedDays = [...daysArray].sort(); // Sort the days for comparison

    if (sortedDays.toString() === weekdays.toString()) {
      return 'weekdays';
    } else if (sortedDays.toString() === weekends.toString()) {
      return 'weekends';
    } else if (daysArray.length === 7) {
      return 'Every day';
    } else {
      return daysArray;
    }
  }
  useEffect(() => {
    if (defaultData) {
      setFormData(defaultData);
      //   console.log(defaultData);
      //   console.log(allData);
    }
  }, [defaultData]);

  return (
    <div>
      {/* Flat start or transaction fee */}
      {/* <Card variant="outlined" sx={{ background: theme.palette.background.tableHeader }}>
        <CardContent> */}
      {/* <h3>{t('Parking Time')}</h3> */}
      {formData.map((ele, ind) => {
        const content = ele.price_component[0];
        let sentence = '';

        if (ele.restrictions_show) {
          if (ele.restrictions.day_of_week.length !== 0) {
            if (ele.restrictions.day_of_week.length !== 0) {
              let trans = getDayType(ele.restrictions.day_of_week);
              if (trans == 'weekdays' || trans == 'weekends') {
                sentence += ' ' + t('sentence_on') + ' ' + t(trans);
              } else if (trans == 'Every day') {
                sentence += ' ' + t('Every day');
              } else {
                sentence += ' ' + t('sentence_on') + ' ';
                for (let index = 0; index < trans.length; index++) {
                  const element = trans[index];
                  sentence += t(element) + ',';
                }
              }
            }
            const start = ele.restrictions.start_time;
            const end = ele.restrictions.end_time;

            if (start && end && start !== '' && end !== '') {
              sentence += ` ${t('between_and', { start, end })},`;
            }
          }
          if (ele.restrictions.min_duration && ele.restrictions.min_duration !== '' && !ele.restrictions.max_duration) {
            sentence += t('Any_parking_after', {
              hour: parseFloat((ele.restrictions.min_duration / 3600).toFixed(3)),
            });
            // 'Any parking after ' + parseFloat((ele.restrictions.min_duration / 3600).toFixed(3)) + ' hours';
          } else if (ele.restrictions.min_duration !== '' && ele.restrictions.max_duration !== '') {
            sentence += t('parking_duration', {
              min: parseFloat((ele.restrictions.min_duration / 3600).toFixed(3)),
              max: parseFloat((ele.restrictions.max_duration / 3600).toFixed(3)),
            });
            // parseFloat((ele.restrictions.min_duration / 3600).toFixed(3)) +
            // ' to ' +
            // parseFloat((ele.restrictions.max_duration / 3600).toFixed(3)) +
            // ' hours of parking';
          } else if (
            !ele.restrictions.min_duration == '' &&
            ele.restrictions.max_duration !== '' &&
            ele.restrictions.max_duration
          ) {
            sentence += t('Any_parking_before', {
              hour: parseFloat((ele.restrictions.max_duration / 3600).toFixed(3)),
            });
            // 'Any parking before ' + parseFloat((ele.restrictions.min_duration / 3600).toFixed(3)) + ' hours';
          }
        }
        return (
          <Card
            sx={{
              boxShadow: 0,
              borderRadius: '0',
              color: 'texts.main',
              border: theme.palette.CollapseRow.border,
              background: theme.palette.background.pagerWhite,
              marginTop: '10px',
              padding: '10px 15px !important',
            }}
          >
            <Box key={ind}>
              <Typography sx={{ color: theme.palette.title.main }}>
                {t('Priority Level')}&nbsp;&nbsp;{ele.price_component[0].priority_level}
              </Typography>
              {sentence && (
                <ul style={{ marginLeft: '20px' }}>
                  <li>{sentence}</li>
                </ul>
              )}
              <ul style={{ marginLeft: '40px' }}>
                <li>
                  {content.price} {t(allData.currency)} {t('per_hour')}
                </li>
                <li>
                  {content.vat} % {t('VAT')}
                </li>
                <li>
                  {t('Billed_per_min', {
                    min: parseFloat((content.step_size / 60).toFixed(3)),
                    sec: content.step_size,
                  })}
                  {/* Billed per {parseFloat((content.step_size / 60).toFixed(3))} min ({content.step_size} {t('seconds')})*/}
                </li>
              </ul>
            </Box>
          </Card>
        );
      })}
      {/* </CardContent>
      </Card> */}
    </div>
  );
}
