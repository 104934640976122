import React, { useState, useEffect, useImperativeHandle, useContext } from 'react';
// mui
// mui
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  TextField,
  Tooltip,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  Typography,
  Chip,
  Autocomplete,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { verify_inputNumber } from '../../../../../../../../../components/verification';
import { useTranslation } from 'react-i18next';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import type1Icon from '../../../../../../../../../components/customIcon/icon-type-1.svg';
import type1Icon_light from '../../../../../../../../../components/lightMode/icon-type-1.svg';
import type2Icon from '../../../../../../../../../components/customIcon/icon-type-2.svg';
import type2Icon_light from '../../../../../../../../../components/lightMode/icon-type-2.svg';

import ccstype1Icon from '../../../../../../../../../components/customIcon/icon-ccs-type 1.svg';
import ccstype1Icon_light from '../../../../../../../../../components/lightMode/icon-ccs-type 1.svg';
import ccstype2Icon from '../../../../../../../../../components/customIcon/icon-ccs-type 2.svg';
import ccstype2Icon_light from '../../../../../../../../../components/lightMode/icon-ccs-type 2.svg';

import chademoIcon from '../../../../../../../../../components/customIcon/icon-chademo-24.svg';
import chademoIcon_light from '../../../../../../../../../components/lightMode/icon-chademo-24.svg';
import chaojiIcon from '../../../../../../../../../components/customIcon/icon-chaoji-24.svg';
import chaojiIcon_light from '../../../../../../../../../components/lightMode/icon-chaoji-24.svg';
import gbtacIcon from '../../../../../../../../../components/customIcon/icon-gbtac-24.svg';
import gbtacIcon_light from '../../../../../../../../../components/lightMode/icon-gbtac-24.svg';
import gbtdcIcon from '../../../../../../../../../components/customIcon/icon-gbtdc-24.svg';
import gbtdcIcon_light from '../../../../../../../../../components/lightMode/icon-gbtdc-24.svg';

import { ThemeContext } from '../../../../../../../../../App';
// ----------------------------------------------------------------------

export default function EditNewEVSE({ childRef, connectorData, componentIndex, tariffData, handleRemoveForm, index }) {
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  useImperativeHandle(childRef, () => ({
    onSubmit,
  }));
  // submit form
  const methods = useForm({
    reValidateMode: 'onBlur',
  });
  const { control } = methods;
  const theme = useTheme();
  const [formData, setFormData] = useState({
    isVisible: true,
    connector_type: '',
    connector_format: null,
    power_type: null,
    max_voltage: '',
    max_amperage: '',
    max_electric_power: '',
    terms: '',
    tariff: '',
    operType: 'add',
  });
  useEffect(() => {
    if (connectorData && Object.keys(connectorData).length !== 0) {
      console.log('connectorData=>', connectorData);
      // setFormData(connectorData)
      setFormData({
        isVisible: connectorData.isVisible != false ? true : false,
        operType: connectorData.operType,
        cnn_id: connectorData.cnn_id ? connectorData.cnn_id : null,
        connector_number: connectorData.connector_number ? connectorData.connector_number : null,
        connector_type: connectorData.connector_type ? connectorData.connector_type : '',
        status: connectorData.status ? connectorData.status : '',
        connector_format: connectorData.connector_format ? connectorData.connector_format : null,
        power_type: connectorData.power_type ? connectorData.power_type : null,
        max_voltage: connectorData.max_voltage ? connectorData.max_voltage : '',
        max_amperage: connectorData.max_amperage ? connectorData.max_amperage : '',
        max_electric_power: connectorData.max_electric_power ? connectorData.max_electric_power : '',
        terms: connectorData.terms ? connectorData.terms : '',
        tariff: connectorData.tariff ? connectorData.tariff : [],
      });
    }
  }, [connectorData]);
  const [errors, setErrors] = useState({
    // cp_id: '',
    // cs_id: null,
    // connector: {
    connector_type: '',
    connector_format: '',
    power_type: '',
    max_voltage: '',
    max_amperage: '',
    max_electric_power: '',
    terms: '',
    // tariff: '',
    // },
  });

  const powerTypeArr = [
    { label: t('AC') + '_' + t('1-Phase'), value: 'AC_1_PHASE' },
    { label: t('AC') + '_' + t('2-Phase'), value: 'AC_2_PHASE' },
    { label: t('AC') + '_' + t('2-Phase-Split'), value: 'AC_2_PHASE_SPLIT' },
    { label: t('AC') + '_' + t('3-Phase'), value: 'AC_3_PHASE' },
    { label: t('DC'), value: 'DC' },
  ];

  const onSubmit = (opr) => {
    if (opr == 'del') {
      return formData;
    } else if (formData.operType == 'del') {
      return formData;
    }
    let empty = false;
    const newHasEmpty = { ...errors };
    for (const key in newHasEmpty) {
      if (
        // key == 'max_voltage' ||
        // key == 'max_amperage' ||
        key == 'max_electric_power' ||
        key == 'terms' ||
        key == 'tariff'
      ) {
        continue;
      }
      if ((formData.hasOwnProperty(key) && formData[key] === '') || formData[key] === null) {
        newHasEmpty[key] = t('required fields');
        empty = true;
      } else {
        newHasEmpty[key] = '';
      }
    }
    setErrors(newHasEmpty);

    if (hasError || empty) {
      return false;
    }
    // setLoading(true);
    // console.log('connectorFata=>', formData);
    const data = {};

    for (let index = 0; index < Object.keys(formData).length; index++) {
      const element = Object.keys(formData)[index];
      if (element == 'max_electric_power' && formData.max_electric_power == '') {
        continue;
      }
      if (element == 'terms' && formData.terms == '') {
        continue;
      } else {
        data[element] = formData[element];
      }
    }
    return data;
    return formData;
  };
  const handleKeyPress = (e) => {
    // not allowed input小数点（.）and（e/E）
    if (e.key === '.' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    validateField(name, value);
    setErrors({
      ...errors,
      name: '',
    });
  };

  const validateField = (fieldName, value) => {
    let errorMessage = '';
    setHasError(false);
    if (fieldName === 'terms' || value == '') {
      if (value.length < 0 || value.length > 255) {
        setHasError(true);
        errorMessage = t('Invalid value') + ' (minLength:1, maxLength:255).';
      }
    } else if (fieldName === 'max_electric_power' || fieldName === 'max_amperage' || fieldName === 'max_voltage') {
      let inputNumber = parseInt(value, 10);
      if (inputNumber < 1 || inputNumber > 2500 || value == '') {
        setHasError(true);
        errorMessage = t('Invalid value') + ' (min:1, max:2500).';
        // inputNumber = 1;
      }
      setFormData({
        ...formData,
        [fieldName]: inputNumber,
      });
    }

    setErrors({
      ...errors,
      [fieldName]: errorMessage,
    });
  };

  const connectorTypeOptions = [
    { value: 'CHADEMO', label: t('CHADEMO') },
    { value: 'CHAOJI', label: t('CHAOJI') },
    { value: 'GBT_AC', label: t('GBT_AC') },
    { value: 'GBT_DC', label: t('GBT_DC') },
    { value: 'IEC_62196_T1', label: t('IEC_62196_T1') },
    { value: 'IEC_62196_T2', label: t('IEC_62196_T2') },
    { value: 'IEC_62196_T1_COMBO', label: t('IEC_62196_T1_COMBO') },
    { value: 'IEC_62196_T2_COMBO', label: t('IEC_62196_T2_COMBO') },
  ];
  const connectorFormatOptions = [
    { value: 'SOCKET', label: t('SOCKET') },
    { value: 'CABLE', label: t('CABLE') },
  ];
  const remove = () => {
    handleRemoveForm(index, formData.id ? formData.id : null);
  };
  const connectType = (option) => {
    let iconSrc = '';
    switch (option) {
      case 'CHADEMO':
        iconSrc = darkTheme ? chademoIcon : chademoIcon_light;
        break;
      case 'CHAOJI':
        iconSrc = darkTheme ? chaojiIcon : chaojiIcon_light;
        break;
      case 'GBT_AC':
        iconSrc = darkTheme ? gbtacIcon : gbtacIcon_light;
        break;
      case 'GBT_DC':
        iconSrc = darkTheme ? gbtdcIcon : gbtdcIcon_light;
        break;
      case 'IEC_62196_T1':
        iconSrc = darkTheme ? type1Icon : type1Icon_light;
        break;
      case 'IEC_62196_T2':
        iconSrc = darkTheme ? type2Icon : type2Icon_light;
        break;
      case 'IEC_62196_T1_COMBO':
        iconSrc = darkTheme ? ccstype1Icon : ccstype1Icon_light;
        break;
      case 'IEC_62196_T2_COMBO':
        iconSrc = darkTheme ? ccstype2Icon : ccstype2Icon_light;
        break;
      default:
        break;
    }
    if (iconSrc !== '') {
      return (
        <div style={{ display: 'flex' }}>
          <Box component="img" src={iconSrc} sx={{ width: '24px', height: '24px' }} />
          &nbsp;&nbsp;
          {option}
        </div>
      );
    } else {
      return option;
    }
  };
  return (
    <form style={{ marginBottom: '15px', display: formData.isVisible != false ? 'block' : 'none' }}>
      <div style={{ float: 'right', marginTop: '0px' }}>
        <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Remove')} onClick={remove} />
      </div>
      <Box sx={{ background: theme.palette.popPaper.paper2, marginBottom: '15px', padding: '10px' }}>
        {/* <Box sx={{ width: '100%', border: '1px solid #757575', padding: '10px', marginTop: '30px' }}>
        <Typography variant="h5" sx={{ marginTop: '-25px', width: '105px', background: '#17262A' }}>
          {t('Connector')}
        </Typography> */}
        <TextField
          margin="dense"
          select
          fullWidth
          name="connector_type"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Connector')}
              {t('Type')}
            </>
          }
          value={formData.connector_type ? formData.connector_type : ''}
          onChange={(e) => {
            setErrors({
              ...errors,
              connector_type: '',
            });
            handleInputChange(e);
          }}
          size="small"
          error={!!errors.connector_type}
          helperText={errors.connector_type}
        >
          {connectorTypeOptions.map((option) => {
            const data = connectType(option.label);
            return (
              <MenuItem key={option.value} value={option.value}>
                {data}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          margin="dense"
          select
          fullWidth
          name="connector_format"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Connector Format')}
            </>
          }
          value={formData.connector_format ? formData.connector_format : ''}
          onChange={(e) => {
            setErrors({
              ...errors,
              connector_format: '',
            });
            handleInputChange(e);
          }}
          size="small"
          error={!!errors.connector_format}
          helperText={errors.connector_format}
        >
          {connectorFormatOptions.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          margin="dense"
          select
          fullWidth
          name="power_type"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Power')}
              {t('Type')}
            </>
          }
          value={formData.power_type ? formData.power_type : ''}
          onChange={(e) => {
            setErrors({
              ...errors,
              power_type: '',
            });
            handleInputChange(e);
          }}
          size="small"
          error={!!errors.power_type}
          helperText={errors.power_type}
        >
          {powerTypeArr.map((data, index) => {
            return (
              <MenuItem key={data.label} value={data.value}>
                {data.label}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          margin="dense"
          size="small"
          // type="number"
          name="max_voltage"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Max Voltage')}
            </>
          }
          onChange={(e) => {
            const { name, value } = e.target;
            let verify = verify_inputNumber(value.trim(), formData.max_voltage);
            // handleInputChange(e);
            setFormData((prevData) => ({
              ...prevData,
              max_voltage: !isNaN(Number(verify.value)) ? Number(verify.value) : verify.value,
            }));
            if (verify.error == '') {
              validateField(name, value);
            } else {
              setHasError(true);
              setErrors({
                ...errors,
                max_voltage: verify.error,
              });
            }
          }}
          onKeyPress={handleKeyPress}
          error={!!errors.max_voltage}
          helperText={errors.max_voltage}
          value={formData.max_voltage}
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="dense"
          size="small"
          // type="number"
          name="max_amperage"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Max Amperage')}
            </>
          }
          onChange={(e) => {
            const { name, value } = e.target;
            let verify = verify_inputNumber(value.trim(), formData.max_amperage);
            // handleInputChange(e);
            setFormData((prevData) => ({
              ...prevData,
              max_amperage: verify.value,
            }));
            if (verify.error == '') {
              validateField(name, value);
            } else {
              setHasError(true);
              setErrors({
                ...errors,
                max_amperage: verify.error,
              });
            }
          }}
          onKeyPress={handleKeyPress}
          error={!!errors.max_amperage}
          helperText={errors.max_amperage}
          value={formData.max_amperage}
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="dense"
          size="small"
          // type="number"
          name="max_electric_power"
          label={
            <>
              {/* <span style={{ color: 'red' }}>* </span> */}
              {t('Max Electric Power')}
            </>
          }
          onKeyPress={handleKeyPress}
          onChange={(e) => {
            const { name, value } = e.target;
            let verify = verify_inputNumber(value.trim(), formData.max_electric_power);
            // handleInputChange(e);
            setFormData((prevData) => ({
              ...prevData,
              max_electric_power: verify.value == undefined ? '' : verify.value,
            }));
            if (verify.error == '') {
              validateField(name, value);
            } else {
              setHasError(true);
              setErrors({
                ...errors,
                max_electric_power: verify.value == undefined ? '' : verify.error,
              });
            }
          }}
          error={!!errors.max_electric_power}
          helperText={errors.max_electric_power}
          value={formData.max_electric_power == undefined ? '' : formData.max_electric_power}
          fullWidth
          variant="outlined"
        />
        <TextField
          margin="dense"
          size="small"
          // type="number"
          name="terms"
          label={
            <>
              {/* <span style={{ color: 'red' }}>* </span> */}
              {t('Terms')}
            </>
          }
          onChange={(e) => {
            // handleInputChange(e);
            const { name, value } = e.target;
            let verify = verify_inputNumber(value.trim(), formData.terms);
            setFormData((prevData) => ({
              ...prevData,
              terms: verify.value == undefined ? '' : verify.value,
            }));
            if (verify.error == '') {
              validateField(name, value);
            } else {
              setHasError(true);
              setErrors({
                ...errors,
                terms: verify.value == undefined ? '' : verify.error,
              });
            }
          }}
          value={formData.terms}
          fullWidth
          variant="outlined"
          error={!!errors.terms}
          helperText={errors.terms}
        />
        {/* <TextField
          margin="dense"
          select
          fullWidth
          name="tariff_option"
          label={<>{t('Tariff')}</>}
          value={formData.tariff_option ? formData.tariff_option : ''}
          onChange={(e) => {
            setFormData((prevData) => ({
              ...prevData,
              tariff_option: e.target.value,
              tariff: [e.target.value],
            }));
          }}
          size="small"
        >
          {tariffData.map((data, index) => {
            return (
              <MenuItem key={data.id} value={data.id}>
                {data.name}
              </MenuItem>
            );
          })}
        </TextField> */}
        {/* <FormControl fullWidth margin="dense" variant="outlined" size="small">
          <InputLabel>{t('Tariff') + '  (' + t('multiple') + ')'}</InputLabel>
          <Select
            multiple
            label=//{t('Tariff') + '(' + t('multiple') + ')'}
            value={formData.tariff}
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                tariff: e.target.value,
              }));
            }}
            renderValue={(selected) => (
              <div> 
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={tariffData.length != 0 ? tariffData.find((option) => option.id === value).name : value}
                  />
                ))}
              </div>
            )}
          >
            {tariffData.map((data, index) => {
              return (
                <MenuItem key={data.id} value={data.id}>
                  {data.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl> */}
        <Controller
          control={control}
          name={'tariff'}
          render={({ field: { ref, onChange, ...field } }) => (
            <Autocomplete
              // multiple
              // value={formData.tariff.map((id) => tariffData.find((option) => option.id === id) || '')}
              value={formData.tariff ? tariffData.find((option) => option.id === formData.tariff[0]) : ''}
              options={tariffData}
              getOptionLabel={(option) => t(option.name)}
              // onChange={(_, data) => {
              //   const selectedIds = data.map((option) => option.id);
              //   setFormData((prevData) => ({
              //     ...prevData,
              //     tariff: selectedIds,
              //   }));
              // }}
              onChange={(_, data) => {
                setFormData((prevData) => ({
                  ...prevData,
                  tariff: data ? [data.id] : [null],
                }));
              }}
              renderInput={(params) => (
                <TextField {...field} {...params} fullWidth inputRef={ref} variant="outlined" label={t('Tariff')} />
              )}
            />
          )}
        />
      </Box>
    </form>
  );
}
