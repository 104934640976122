import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tooltip,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Icon from '../../../../components/customIcon/icon-cancel-reserve-turquoise-16.svg';
import { buttonR, buttonL } from '../../../../components/button/buttonStyle';
import CloseIcon from '../../../../components/customIcon/Close.svg';
import { SubmitUserCancel } from '../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function UserCancel(props) {
  const { t } = useTranslation();
  const { info } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading_pre, setLoading_pre] = useState(false);
  const mobile_id = info.mobile_id;

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    setLoading_pre(true);
    SubmitUserCancel(mobile_id, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.CancelUpdate('search');
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
      setLoading_pre(false);
    });
  };

  return (
    <div>
      <Tooltip title={t('Cancel Reservation')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="cancel-dialog" sx={{ zIndex: 2000 }}>
        {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="cancel-dialog">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Cancel Reservation')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {t('tit_CancelReservation', { infoName: info.name, mobile_id: info.mobile_id })}
            {/* Cancel Reservation made for {info.name} (ID Tag: {info.mobile_id})? */}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={handleClose} sx={buttonL}>
              {t('No')}
            </Button>
            <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
              {t('Yes')}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
