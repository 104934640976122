import React, { useContext, useEffect, useState } from 'react';
import {
  TextField,
  Menu,
  MenuItem,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Snackbar,
  Alert,
  LinearProgress,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../../App';
import { fetchChargerStation } from '../../../../api/AllAPI';
import LoginAgain from '../../../../components/errors/LoginAgain';
import MainCon from './mainCon';
import StationInfo from './stationInfo';
import EvseInfo from './evseInfomation';
// import { ZoomOutIcon, ZoomInIcon, ControlCameraIcon } from '@mui/icons-material';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import IsoSharpIcon from '@mui/icons-material/IsoSharp';
import PhotoSizeSelectLargeSharpIcon from '@mui/icons-material/PhotoSizeSelectLargeSharp';

import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
export default function ViewInfo(props) {
  const { t } = useTranslation();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);

  const [loading_pro, setLoading_pro] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [unauthorized, setUnauthorized] = useState(false);
  const [evseArrData, setEvseArrData] = useState({});
  const [evseInfoData, setEvseInfoData] = useState({});
  const [panelShow, setPanelShow] = useState(true);
  let ChildRef = React.createRef();
  let ChildRef_station = React.createRef();
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  // reset diagram position
  const [formats, setFormats] = useState([]);
  const handleFormat = (event, newFormats) => {
    if (newFormats.includes('reset')) {
      ChildRef.current.resetPosition();
      return;
    }
    setFormats(newFormats);
  };
  const refreshDiagram = () => {
    ChildRef_station.current.refreshDiagram();
  };
  return (
    <Box position="relative" sx={{ height: '100%' }}>
      {unauthorized && <LoginAgain />}
      <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="success">Success!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      {loading_pro && (
        <div style={{ marginTop: '0px', width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      <ToggleButtonGroup
        sx={{ zIndex: '0', position: 'absolute', left: '-22px' }}
        value={formats}
        onChange={handleFormat}
        aria-label="text formatting"
        color="primary"
        orientation="vertical"
      >
        <ToggleButton value="zoom" aria-label="ZoomOutIcon" title={t('Zoom Out/Zoom In')}>
          {/* <ZoomOutIcon /> */}
          {/* <PhotoSizeSelectLargeSharpIcon/> */}
          <IsoSharpIcon />
        </ToggleButton>
        <ToggleButton value="drag" aria-label="ControlCameraIcon" title={t('Dragging')}>
          <ControlCameraIcon />
        </ToggleButton>
        <ToggleButton value="clean" aria-label="CleaningServicesIcon" title={t('Cleaning Screen')}>
          <CleaningServicesIcon />
        </ToggleButton>
        <ToggleButton value="reset" aria-label="RestartAltIcon" title={t('Reset Position')}>
          <RestartAltIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <MainCon
        allowOprate={formats}
        setEvseInfoData={setEvseInfoData}
        setPanelShow={setPanelShow}
        onRef={ChildRef}
        station={evseArrData}
        setLoading_pro={setLoading_pro}
        setSuccess={setSuccess}
        setError={setError}
        setErrorMessage={setErrorMessage}
        setUnauthorized={setUnauthorized}
      />
      <StationInfo
        onRef={ChildRef_station}
        allowOprate={formats}
        handleEvseClick={() => ChildRef.current.handleEvseClick(null)}
        setEvseArrData={setEvseArrData}
        setLoading_pro={setLoading_pro}
        setSuccess={setSuccess}
        setError={setError}
        setErrorMessage={setErrorMessage}
        setUnauthorized={setUnauthorized}
        panelShow={panelShow}
      />
      <EvseInfo
        refreshDiagram={refreshDiagram}
        allowOprate={formats}
        evseInfoData={evseInfoData}
        setPanelShow={setPanelShow}
        handleEvseClick={() => ChildRef.current.handleEvseClick(null)}
        setLoading_pro={setLoading_pro}
        setSuccess={setSuccess}
        setError={setError}
        setErrorMessage={setErrorMessage}
        setUnauthorized={setUnauthorized}
        panelShow={panelShow}
      />
    </Box>
  );
}
