// mui
import { Box, Card, Container, Typography, styled } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Page from '../../../components/Page';
import Background from '../../../components/customIcon/Group 676.svg';
import Logo from '../../../components/customIcon/logo-liteon-csms-xsmall.svg';
import Logo_photo from '../../../components/customIcon/logo-liteon-csms.svg';
import Logo_photo_light from '../../../components/lightMode/logo-liteon-csms-light.svg';
// sections
import LoginForm from './LoginForm';
import AWSLogin from '../AWSCognito/Login/LoginForm';
import { useTranslation } from 'react-i18next';
import { useRef, useContext } from 'react';
import { ThemeContext } from '../../../App';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxWidth: '677px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  // padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive('up', 'md');
  const { t } = useTranslation();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  return (
    <Page
      title={t('Login')}
      sx={{
        // width: '100%',
        height: '100%',
      }}
    >
      <RootStyle>
        {mdUp && (
          // <SectionStyle>
          <Box
            component="img"
            src={Background}
            sx={{
              height: '100%',
              // maxWidth: '600px',
              zIndex: 0,
            }}
          />
          //  </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            {/* <Box component="img" src={Logo} sx={{ width: '160px', height: '47px', mb: 1 }} /> */}
            {darkTheme ? (
              <Box component="img" src={Logo_photo} sx={{ mb: 1 }} />
            ) : (
              <Box component="img" src={Logo_photo_light} sx={{ mb: 1 }} />
            )}
            <Typography
              sx={{
                fontFamily: 'Arial',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '36px',
                color: 'texts.main',
                mb: 1,
              }}
            >
              {t('Welcome_word')}
              {/* Welcome to LITEON's Charging Station Management System (CSMS) */}
            </Typography>
            <AWSLogin />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
