import PropTypes from 'prop-types';
import React, { useMemo, useEffect, useState, useContext } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import { ThemeContext } from '../../App';
import wrongPalette from './wrongPalette';
import light from './lightPalette';
import dark from './darkPalette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider1({ children }) {
  const [palette, setPalette] = useState(dark);
  const themeContext = useContext(ThemeContext);
  useEffect(() => {
    setPalette(themeContext.darkTheme ? dark : light);
  }, [themeContext.darkTheme]);

  // useEffect(() => {
  //   if (themeContext) {
  //     if (themeContext.darkTheme) {
  //       setPalette(dark);
  //     } else {
  //       setPalette(light);
  //     }
  //   }
  // }, [themeContext]);
  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 2 },
      typography,
      // shadows,
      customShadows,
    }),
    [palette]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
