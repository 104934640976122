import React, { useState, useEffect, useRef } from 'react';
// mui
import { Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import img_res_time from '../../../../components/customIcon/tariffSummary/reservation.svg';
import img_platformFee_res from '../../../../components/customIcon/tariffSummary/platform-fee-res.svg';
import img_expires_time from '../../../../components/customIcon/tariffSummary/expiry.svg';
import img_platformFee_expires from '../../../../components/customIcon/tariffSummary/platform-fee-expire.svg';
import Part_information from './part_information';

export default function Tabs_charging({ propsData }) {
  const { t } = useTranslation();

  return (
    <Box display="flex" gap="20px">
      <Stack sx={{ width: 'calc(25% - 20px)' }}>
        <Box component="img" src={img_platformFee_res} sx={{}} />
        <Part_information defaultData={propsData} showType="res_platformFee" tabs="reservation" />
      </Stack>
      <Stack sx={{ width: 'calc(25% - 20px)' }}>
        <Box component="img" src={img_res_time} sx={{}} />
        <Part_information defaultData={propsData} showType="reservation_time" tabs="reservation" />
      </Stack>
      <Stack sx={{ width: 'calc(25% - 20px)' }}>
        <Box component="img" src={img_platformFee_expires} sx={{}} />
        <Part_information defaultData={propsData} showType="expires_platformFee" tabs="reservation" />
      </Stack>
      <Stack sx={{ width: 'calc(25% - 20px)' }}>
        <Box component="img" src={img_expires_time} sx={{}} />
        <Part_information defaultData={propsData} showType="expires_time" tabs="reservation" />
      </Stack>
    </Box>
  );
}
