import PropTypes from 'prop-types';
import { useRef, useContext } from 'react';
// @mui
import { Box } from '@mui/material';
// import liteon from './customIcon/logo-liteon-csms-xsmall.svg';
import liteon from './customIcon/logo-liteon-csms-bar.svg';
import Logo_img from './customIcon/logo_liteon.svg';
import Logo_sub from './customIcon/logo_CSMS.svg';
import { ThemeContext } from '../App';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  const logo = (
    <>
      {darkTheme ? (
        <Box aria-label="Logo" component="img" src={liteon} sx={{ pl: '8px', height: '75px', width: '100%' }} />
      ) : (
        <Box
          sx={{
            height: '70px',
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Box
            aria-label="Logo1"
            component="img"
            src={Logo_img}
            sx={{ pl: '29px', height: '35px', paddingLeft: '0' }}
          />
          <Box
            aria-label="Logo2"
            component="img"
            src={Logo_sub}
            sx={{ pl: '15px', paddingLeft: '0', height: '16px' }}
          />
        </Box>
      )}
    </>
  );

  return <>{logo}</>;
}
