import { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Link, Stack, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Cognito
import UserPool from '../UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ForgotPasswordForm({ setPage, setUsername }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();
  const from = '/Success';

  useEffect(() => {
    setErrMsg('');
  }, [email]);

  const onSubmit = (event) => {
    event.preventDefault();
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    user.forgotPassword({
      onSuccess: (data) => {
        console.log('onSuccess: ', data);
        navigate(from, { replace: true });
      },
      onFailure: (err) => {
        console.log('onFailure: ', err);
        setErrMsg(`${err}`);
      },
      inputVerificationCode: (data) => {
        console.log('inputVerificationCode: ', data);
        setUsername(email);
        setPage(2);
      },
    });
  };

  return (
    <>
      <Stack spacing={3}>
        <Typography variant="subtitle1" sx={{ pl: 1, color: 'error.main' }}>
          {errMsg}
        </Typography>
        <TextField name="email" label={t('Email Address')} onChange={(e) => setEmail(e.target.value)} value={email} />
      </Stack>

      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <LoadingButton
          fullWidth
          size="large"
          variant="outlined"
          sx={{
            boxShadow: 0,
            color: '#00B0B9',
            textAlign: 'center',
            padding: '0 !important',
          }}
        >
          <Link
            variant="subtitle2"
            underline="hover"
            component={RouterLink}
            to="/Login"
            sx={{ width: '100%', height: '100%', lineHeight: '46px' }}
          >
            {t('Back')}
          </Link>
        </LoadingButton>

        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          onClick={onSubmit}
          sx={{ background: 'linear-gradient(to left, #66D0D5, #00B0B9)' }}
        >
          {t('Send_Code')}
        </LoadingButton>
      </Stack>
    </>
  );
}
