import React, { useEffect, useState, useContext } from 'react';
import { AccountContext } from './Account';
// mui
import { Stack, TextField, Button, Container, Typography, styled, InputAdornment, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import Pool from './UserPool';
import { CognitoUserAttribute, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Success() {
  const navigate = useNavigate();
  const success = '/AWSCognitoLogin';
  const [status, setStatus] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [gender, setGender] = useState('');
  const [updated, setUpdated] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const { getSession, logout } = useContext(AccountContext);
  const { t } = useTranslation();

  useEffect(() => {
    getSession()
      .then((session) => {
        setEmail(session.idToken.payload.email);
        setRole(session.idToken.payload['custom:role']);
        setName(session.idToken.payload.name);
      })
      .catch(() => {
        console.log('no session');
        navigate(success, { replace: true });
      });
  }, [status]);

  const edit = (event) => {
    event.preventDefault();
    getSession().then(({ user }) => {
      const attributes = [new CognitoUserAttribute({ Name: 'name', Value: name })];
      user.updateAttributes(attributes, (err, results) => {
        if (err) {
          console.log(err);
        } else {
          console.log(results);
        }
      });
    });
  };

  const refresh = (event) => {
    event.preventDefault();
    getSession().then((session) => {
      const user = Pool.getCurrentUser();
      const refresh_token = new CognitoRefreshToken({ RefreshToken: session.refreshToken.token });
      user.refreshSession(refresh_token, (err, results) => {
        if (err) {
          console.log(err);
        } else {
          const idToken = results.getIdToken().getJwtToken();
          console.log('Refreshed ID token:', idToken);
          console.log(session.idToken.jwtToken);
        }
      });
    });
  };

  const changePassword = (event) => {
    event.preventDefault();
    getSession().then(({ user }) => {
      user.changePassword(password, newPassword, (err, result) => {
        if (err) {
          console.log(err);
        } else {
          console.log('Change Password: ', result);
          setPassword('');
          setNewPassword('');
        }
      });
    });
  };

  return (
    <Page title={t('Success')}>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h1" gutterBottom>
              {t('Success')}
            </Typography>
            <Stack direction="column" justifyContent="flex-start" spacing={2}>
              <TextField name="name" label={t('Name')} onChange={(e) => setName(e.target.value)} value={name} />
              <TextField id="email" label={t('Email')} value={email} disabled />
              <TextField name="role" label={t('Role')} value={role} disabled />
              <TextField name="phone number" label={t('Phone number')} value={phoneNumber} />
              <TextField name="birth date" label={t('Birth date')} value={birthDate} disabled />
              <TextField name="gender" label={t('Gender')} value={gender} disabled />
              <TextField name="updated at" label={t('Updated at')} value={updated} disabled />
            </Stack>
            <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
              <Button fullWidth variant="subtitle2" onClick={edit}>
                {t('Edit')}
              </Button>
              <Button fullWidth variant="subtitle2" onClick={refresh}>
                {t('Refresh')}
              </Button>
              <Button
                fullWidth
                variant="subtitle2"
                onClick={() => {
                  logout();
                  setStatus(false);
                }}
              >
                {t('Logout')}
              </Button>
            </Stack>
            <Stack direction="column" justifyContent="flex-start" spacing={2} sx={{ mt: 2 }}>
              <TextField
                name="oldPassword"
                label={t('Old Password')}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              <TextField
                name="password"
                label={t('New Password')}
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                type={showNewPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                        <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Stack>
            <Button fullWidth variant="subtitle2" onClick={changePassword} sx={{ mt: 2 }}>
              {t('Change password')}
            </Button>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
