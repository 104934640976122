import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import io from 'socket.io-client';
// @mui
import { Grid, Stack, Card, Typography, Box, LinearProgress, useTheme, Link } from '@mui/material';

import { fetchSummaryOverview } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../App';

// ----------------------------------------------------------------------

export default function DashboardOverview({ getOcpiData }) {
  const { darkTheme, toggleTheme } = useContext(ThemeContext);

  const { t } = useTranslation();
  const theme = useTheme();
  const multiTags = (data) => {
    return getOcpiData?.self_party?.endpoints.map((con, index) => (
      <Typography
        variant=""
        sx={{
          //   background: '#232F34',
          backgroundColor: darkTheme ? '#232F34' : theme.palette.background.tableHeader,
          color: theme.palette.texts.main,
          padding: '5px 8px',
          borderRadius: '5px',
          margin: index > 0 ? '5px' : '5px',
          fontSize: '14px',
        }}
      >
        {con}
      </Typography>
    ));
  };
  return (
    <Card
      sx={{
        mb: 2,
        borderRadius: '4px',
        pl: 3,
        pb: 3,
        pr: 3,
        padding: '10px',
        boxShadow: 0,
        background: theme.palette.background.pagerWhite,
        border: theme.palette.background.border,
      }}
    >
      <Stack direction="row" justifyContent=" " alignItems="flex-start" spacing={3} marginBottom="10px">
        <Box flex={1}>
          <Typography>{t('Party Name')}</Typography>
          {getOcpiData?.self_party?.party_name}
        </Box>
        <Box flex={1}></Box>
        <Box flex={1} sx={{ maxWidth: '50%' }}>
          <Typography>{t('URL')}</Typography>
          <Link
            title={getOcpiData?.ocpi_versions_url}
            href={getOcpiData?.ocpi_versions_url}
            style={{
              color: '#00ACBB',
            }}
          >
            <p
              style={{
                color: '#00ACBB',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {getOcpiData?.ocpi_versions_url}
            </p>
          </Link>
        </Box>
      </Stack>
      <Stack direction="row" flexWrap="wrap" alignItems="flex-start" spacing={1}>
        <Box flex={1}>
          <Typography variant="">{t('Tags Available')}</Typography>
          <br />
          <Box direction="row" display="flex" flexWrap="wrap" alignItems="flex-start">
            {multiTags()}
          </Box>
        </Box>
      </Stack>
    </Card>
  );
}
