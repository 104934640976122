import { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../auth/AWSCognito/Account';
// mui
import { Stack, Card, CardContent, TextField, Button } from '@mui/material';
// account
import { useTranslation } from 'react-i18next';
import { SubmitChangeName } from '../../../api/AllAPI';

// ----------------------------------------------------------------------

export default function UserProfileForm(props) {
  const [name, setName] = useState(sessionStorage.getItem('name'));
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const { getSession, logout } = useContext(AccountContext);
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  // const onSubmit = async () => {
  //   sessionStorage.setItem('name', name);
  //   SubmitChangeName(name, setErrorMessage, setError).then((promiseResult) => {
  //     if (promiseResult.category === 'Success') {
  //       props.setSuccess(true);
  //     } else {
  //       props.setErrorMessage(promiseResult.message);
  //       props.setError(true);
  //     }
  //   });
  // };
  const onSubmit = (event) => {
    event.preventDefault();
    getSession().then(({ user }) => {
      const attributes = [new CognitoUserAttribute({ Name: 'name', Value: name })];
      user.updateAttributes(attributes, (err, result) => {
        if (err) {
          props.setErrorMessage(err.message);
          props.setError(true);
        } else if (result) {
          if (result == 'SUCCESS') {
            props.setSuccess(true);
            sessionStorage.setItem('name', name);
          }
        }
      });
    });
  };
  return (
    <Card sx={{ width: 450 }}>
      <CardContent>
        <Stack direction="column" justifyContent="flex-start" spacing={3}>
          <TextField
            sx={{ width: 400 }}
            name="name"
            label={t('Name')}
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <TextField
            sx={{ width: 400 }}
            name="role"
            label={t('Role')}
            disabled
            defaultValue={`${sessionStorage.getItem('role').slice(0, 5)} ${sessionStorage.getItem('role').slice(5)}`}
          />
          <TextField
            sx={{ width: 400 }}
            id="email"
            label={t('Email')}
            disabled
            defaultValue={sessionStorage.getItem('email')}
          />
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Button size="large" type="submit" variant="outlined" onClick={onSubmit}>
              {t('Update')}
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
