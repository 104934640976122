import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
// mui
import { Box, Stack, TextField, Autocomplete, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Places(props) {
  const { t } = useTranslation();

  const { coordinates } = props;
  // const {
  //   ready,
  //   value,
  //   setValue,
  //   suggestions: { status, data },
  //   clearSuggestions,
  // } = usePlacesAutocomplete();

  const handleSelect = async (val) => {
    console.log(val);
    if (val === null) {
      props.setNull(null);
    } else {
      props.setCharger({ lat: parseFloat(val.lat), lng: parseFloat(val.lng) });
      props.setName(val.name);
      props.setAddress(val.address);
      props.setCP(val.no_of_cp);
      props.setCsId(val.cs_id);
    }

    // setValue(val, false);
    // clearSuggestions();

    // const results = await getGeocode({ address: val });
    // const { lat, lng } = await getLatLng(results[0]);
    // props.setCharger({ lat, lng });
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={2}>
        {/* <Typography variant="h5" gutterBottom>
          {t('Charger Location')}
        </Typography> */}
        <Autocomplete
          freeSolo
          size="small"
          sx={{ width: '20%' }}
          id="combo-box-demo"
          options={coordinates}
          onChange={(event, newValue) => {
            handleSelect(newValue);
          }}
          getOptionLabel={(coordinates) => coordinates.name}
          renderInput={(params) => <TextField {...params} label={t('Charging Station')} />}
        />
      </Stack>
    </Box>
  );
}
