import React, { useEffect, useState, useContext } from 'react';
// mui
import {
  Box,
  Alert,
  Table,
  Stack,
  Button,
  Snackbar,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  InputLabel,
  IconButton,
  FormControl,
  TableFooter,
  OutlinedInput,
  InputAdornment,
  TablePagination,
  tableCellClasses,
  LinearProgress,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// components
import TablePaginationActions from '../../../components/tablePaginationActions';
import { ThemeContext } from '../../../App';
import AddNewAdminDialog from './AdminAdd/index';
import AdminEditDialog from './AdminEditDialog';
import AdminDelete from './AdminDelete';
import useRefreshToken from '../../../hooks/useRefreshToken';
import LoginAgain from '../../../components/errors/LoginAgain';
import Icon from '../../../components/customIcon/icon-search-16.svg';
import Export from '../../../components/customIcon/icon-export-18.svg';
import { utils, writeFile } from 'xlsx';
import { fetchAdmin, fetchCS } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function AdminTable() {
  const [success, setSuccess] = useState(false);
  const theme = useTheme();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [admin, setAdmin] = useState([]);
  const [update, setUpdate] = useState();
  const [unauthorized, setUnauthorized] = useState(false);
  const [loading_pre, setLoading_pre] = useState(false);
  const [all_charging_station, setAll_charging_station] = useState([]);
  const refresh = useRefreshToken();
  const { t } = useTranslation();

  const columns = [
    { field: 'id', headerName: t('No.'), width: 50 },
    { field: 'name', headerName: t('Admin Name'), width: 200 },
    // { field: 'employee_id', headerName: t('Employee ID'), width: 150 },
    { field: 'email', headerName: t('Email Address'), width: 200 },
    { field: 'charging_station_name', headerName: t('Managed Location(s)'), width: 200 },
    { field: 'action', headerName: t('Actions'), width: 200 },
  ];

  useEffect(() => {
    setLoading_pre(true);
    fetchAdmin(setErrorMessage, setError, setUnauthorized).then((promiseResult) => {
      if (!promiseResult.message) {
        setAdmin(promiseResult);
      }
      setLoading_pre(false);
    });
  }, [update]);

  useEffect(() => {
    fetchCS(setError, setErrorMessage, setUnauthorized).then((promiseResult) => {
      if (!promiseResult.message) {
        setAll_charging_station(promiseResult);
      } else {
        setError(true);
        setErrorMessage(promiseResult.message);
      }
    });
  }, []);

  const filteredItems = getFilteredItems(query, admin);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
  };

  const handleExport = () => {
    const reordered = filteredItems.map((item, index) => ({
      No: index + 1,
      AdminName: item.name,
      Email: item.email,
      // EmployeeID: item.employee_id,
      // Email: item.email,
      Location: item.charging_station_name.charging_station_name,
    }));
    const wb = utils.book_new(),
      ws = utils.json_to_sheet(reordered);

    utils.book_append_sheet(wb, ws, 'MySheet1');
    writeFile(wb, 'MyAdminExcel.xlsx');
  };

  const multiTags = (data) => {
    return data.map((data, index) => (
      <Typography
        variant=""
        title={data.charging_station_name}
        sx={{
          //   background: '#232F34',
          backgroundColor: darkTheme ? '#232F34' : theme.palette.background.tableHeader,
          color: theme.palette.texts.main,
          padding: '8px 8px',
          borderRadius: '5px',
          margin: index > 0 ? '5px' : '5px',
          fontSize: '12px',
          maxWidth: '100px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {data.charging_station_name}
      </Typography>
    ));
  };

  const Pagination = () => {
    return (
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
        count={filteredItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={t('rows per page')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
        sx={{ color: 'texts.main', fontFamily: 'Arial', fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
      />
    );
  };
  return (
    <Box sx={{ height: 660, width: '100%', position: 'relative' }}>
      {unauthorized && <LoginAgain />}
      <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pb: 1 }}>
        <FormControl fullWidth sx={{ mr: 1, width: '100%' }}>
          <InputLabel htmlFor="outlined-adornment-search">{t('Search')}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            startAdornment={
              <InputAdornment position="start">
                <Box component="img" src={Icon} sx={{ width: '16px', height: '16px' }} />
              </InputAdornment>
            }
            label="Search"
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            sx={{ height: '32px' }}
          />
        </FormControl>
        <AddNewAdminDialog AddUpdate={(childData) => setUpdate(childData)} />
      </Stack>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ color: 'texts.main', fontFamily: 'Arial', fontWeight: 700, fontSize: '14px', lineHeight: '22px' }}
              colSpan={2}
            >
              {t('Total_admin', { length: admin.length })}
              {/* Total Admin: {admin.length} */}
              <StyledButton
                startIcon={<Box component="img" src={Export} sx={{ width: '16px', height: '16px' }} />}
                onClick={handleExport}
              >
                {t('Export')}
              </StyledButton>
            </TableCell>
            <Pagination />
          </TableRow>
          {loading_pre && (
            <div style={{ marginTop: '0px', width: '100%', position: 'absolute' }}>
              <LinearProgress />
            </div>
          )}
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell align="left" key={column.headerName} sx={{ width: column.width }}>
                {column.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filteredItems
          ).map((row, ind) => (
            <StyledTableRow align="left" key={row.id} hover>
              <StyledTableCell sx={sx}>{ind + 1}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.name}</StyledTableCell>
              {/* <StyledTableCell sx={sx}>{row.employee_id}</StyledTableCell> */}
              <StyledTableCell sx={sx}>{row.email}</StyledTableCell>
              <StyledTableCell sx={sx}>
                {multiTags(row.charging_station_name)}
                {/* {row.charging_station_name
                  .map((data) => data.charging_station_name)
                  .map((value, index) => (
                    <Typography variant="inline" key={value}>
                      {value}
                      {index !== row.charging_station_name.length - 1 && ','}
                    </Typography>
                  ))} */}
              </StyledTableCell>
              <StyledTableCell sx={sx}>
                <Stack direction="row" justifyContent="left" alignItems="center" spacing={0.5}>
                  <AdminEditDialog
                    all_charging_station={all_charging_station}
                    key={row.id}
                    info={row}
                    EditUpdate={(childData) => setUpdate(childData)}
                    setSuccess={(childData) => setSuccess(childData)}
                    setErrorMessage={(childData) => setErrorMessage(childData)}
                    setError={(childData) => setError(childData)}
                  />
                  <AdminDelete
                    key={row.name}
                    info={row}
                    DeleteUpdate={(childData) => setUpdate(childData)}
                    setSuccess={(childData) => setSuccess(childData)}
                    setErrorMessage={(childData) => setErrorMessage(childData)}
                    setError={(childData) => setError(childData)}
                  />
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}

const getFilteredItems = (query, admin) => {
  if (!query) {
    return admin;
  }
  const search = admin.filter((admin) => {
    const name = admin.name.toLowerCase().includes(query);
    const Name = admin.name.includes(query);
    const employeeId = admin.employee_id?.toLowerCase().includes(query);
    const EmployeeId = admin.employee_id?.includes(query);
    const email = admin.email?.toLowerCase().includes(query);
    const Email = admin.email?.includes(query);
    const location = admin.email?.toLowerCase().includes(query);
    const Location = admin.email?.includes(query);
    return Name || name || employeeId || EmployeeId || email || Email || location || Location;
  });
  return search;
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));

const sx = { pt: '3.5px', pb: '3.5px' };
const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: '15px',
  '&:hover': {
    color: '#00B0B9',
    background: theme.palette.action.hover,
  },
}));
