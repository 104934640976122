import React, { useState, useEffect, useRef } from 'react';
// mui
import {
  Box,
  Step,
  Stack,
  Dialog,
  Button,
  Stepper,
  Tooltip,
  StepLabel,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
// components
import SelectStartPage from './SelectStartPage';
import SelectUser from './SelectUser';
import Confirmation from './Confirmation';
import DisabledIcon from '../../../../../../../../components/customIcon/icon-on-off-green-16-disabled.svg';
import Icon from '../../../../../../../../components/customIcon/Frame 424.svg';
import { buttonR, buttonL } from '../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../components/customIcon/Close.svg';
import { SubmitStart } from '../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectStart(props) {
  const { t } = useTranslation();
  const { evseName, numConnector, evseInfo } = props;
  const childRef = useRef();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [evse_list_id, setEvse_list_id] = useState();
  const [rows, setRows] = useState([]);
  const [loading_pre, setLoading_pre] = useState(false);
  const steps = [
    {
      value: 'Select Start Page',
      label: t('Select Start Page'),
    },
    {
      value: 'Select User',
      label: t('Select User'),
    },
    {
      value: 'Confirmation',
      label: t('Confirmation'),
    },
  ];

  const [formData, setFormData] = useState({
    user: [],
    checked: [],
    connectorName: 'Connector 1',
    connectorId: 1,
    checkeduser: '',
    checkedid: '',
  });

  const mobile_id = formData.checkedid;
  const charge_point_id = evse_list_id;
  const connector_number = formData.connectorId;

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    const values = Array.from({ length: numConnector }, (_, index) => {
      return {
        name: `Connector ${index + 1}`,
        value: index + 1,
      };
    });
    setRows(values);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setFormData({ ...formData, checked: [evseName] });
    setEvse_list_id(evseName);
  };

  const handleClose = () => {
    setOpen(false);
    setPage(0);
    setFormData({ ...formData, checked: [], connector: '', checkeduser: '' });
  };

  const onSubmit = () => {
    setLoading(true);
    setLoading_pre(true);
    const data = {
      charge_point_id: evseInfo.id,
      connector_id: connector_number,
      mobile_id: mobile_id,
    }; //mobile_id, charge_point_id, connector_number
    SubmitStart(data, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.StartUpdate(Math.random());
        props.setSuccess(true);
        setEvse_list_id();
        handleClose();
        setPage(0);
      } else {
        props.setErrorMessage(promiseResult.response?.data?.message);
        props.setError(true);
      }
      setLoading(false);
      setLoading_pre(false);
    });
  };

  return (
    <Box>
      <Tooltip title={t('Start')}>
        {Object.keys(evseInfo).length !== 0 && evseInfo.connector[0]?.status === 'Unknown' ? (
          <IconButton onClick={handleClickOpen} disabled sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
            <Box component="img" src={DisabledIcon} sx={{ width: '100%', height: '100%' }} />
          </IconButton>
        ) : (
          <IconButton onClick={handleClickOpen} sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
            <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
          </IconButton>
        )}
        {/* <IconButton onClick={handleClickOpen} sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
            <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
          </IconButton> */}
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="StartDialog" fullWidth maxWidth="md">
        {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="Start">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Start')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
          <Stepper activeStep={page} sx={{ pt: 1 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label.value} {...stepProps}>
                  <StepLabel {...labelProps}>{label.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent>
          <PageDisplay
            page={page}
            evseInfo={evseInfo}
            childRef={childRef}
            formData={formData}
            setFormData={setFormData}
            rows={rows}
            setError={setError}
            setErrorMessage={setErrorMessage}
          />
          <br />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button
              sx={buttonL}
              variant="outlined"
              disabled={loading}
              onClick={() => {
                if (page === 0) {
                  handleClose();
                } else {
                  setPage((currPage) => currPage - 1);
                }
              }}
            >
              {page === 0 ? t('Cancel') : t('Back')}
            </Button>
            {page === steps.length - 1 ? (
              <Button onClick={onSubmit} disabled={loading} sx={buttonR}>
                {t('Submit')}
              </Button>
            ) : (
              <Button
                sx={buttonR}
                onClick={() => {
                  // verify empty
                  if (page == 0 && connector_number == '') {
                    childRef.current.handleVerification();
                  } else if (page == 1 && formData.checkeduser == '') {
                    childRef.current.handleVerification_selectUser();
                  } else {
                    setPage((currPage) => currPage + 1);
                  }
                }}
              >
                {t('Next')}
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

const PageDisplay = ({ page, childRef, evseInfo, formData, setFormData, rows, setError, setErrorMessage }) => {
  if (page === 0) {
    return <SelectStartPage cRef={childRef} formData={formData} setFormData={setFormData} rows={rows} />;
  }
  if (page === 1) {
    return (
      <SelectUser
        cRef={childRef}
        evseInfo={evseInfo}
        formData={formData}
        setFormData={setFormData}
        setError={setError}
        setErrorMessage={setErrorMessage}
      />
    );
  }
  return <Confirmation formData={formData} setFormData={setFormData} />;
};
