import React, { useState, useRef } from 'react';
// mui
import { Container, Stack, Box, Tooltip, Snackbar, Alert, useTheme } from '@mui/material';
// components
import Page from '../../../../components/Page';
import { useTranslation } from 'react-i18next';
import useResponsive from '../../../../hooks/useResponsive';
import Confirmation from './Confirmation';
import Discard from './discard';
import MainContent from './mainContent/index';
import backButton from '../../../../components/customIcon/icon-expandedarrow-24.svg';

// ----------------------------------------------------------------------

export default function TariffProfile({
  setLoading_pro,
  setChangePage,
  setPropsData,
  loading_pro,
  updateList,
  changePage,
  propsData,
  pageFunc,
  setOpen,
  open,
}) {
  const { t } = useTranslation();
  const childRef = useRef();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [parentGetData, setParentGetData] = useState({});
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');

  const onSubmit_ = () => {
    // Check if the ref is assigned and the method exists before calling it
    if (childRef.current && childRef.current.onSubmit) {
      return childRef.current.onSubmit(); // Call the update_list method on the child component
    }
  };
  const getVerifyData_ = () => {
    // Check if the ref is assigned and the method exists before calling it
    if (childRef.current && childRef.current.getVerifyData) {
      return childRef.current.getVerifyData(); // Call the update_list method on the child component
    }
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };
  const handleClickBack = () => {
    setChangePage('table');
  };
  return (
    <Page sx={{ display: changePage !== 'summary' ? 'block' : 'none' }}>
      <Container maxWidth="false" sx={{ position: 'relative' }}>
        <Snackbar open={success} autoHideDuration={4000} onClose={handleCloseSnackbar}>
          <Alert severity="success">{t('Success')}!</Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseSnackbar}>
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            position: 'fixed',
            top: '30px',
            height: '110px',
            paddingTop: '70px',
            paddingBottom: '30px',
            backgroundColor: theme.palette.background.default,
            width: isDesktop ? 'calc(100% - 330px)' : 'calc(100% - 45px)',
            zIndex: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={t('Back')}>
              <Box
                component="img"
                src={backButton}
                sx={{ transform: 'rotate(90deg)', cursor: 'pointer', marginRight: '10px' }}
                onClick={handleClickBack}
              />
            </Tooltip>
            <div
              style={{
                fontFamily: 'Arial',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '36px',
                color: 'texts.main',
                mb: 2,
              }}
            >
              {pageFunc === 'add' ? t('New Tariff Profile') : t('Edit Tariff Profile')}
            </div>
          </Box>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pb: 1 }}>
            <Discard type="discard" setChangePage={setChangePage} setPropsData={setPropsData}></Discard>
            <Confirmation
              updateList={updateList}
              defaultData={parentGetData}
              getVerifyData={getVerifyData_}
              onSubmit={onSubmit_}
              setLoading_pro={setLoading_pro}
              loading_pro={loading_pro}
              setOpen={setOpen}
              open={open}
            ></Confirmation>
          </Stack>
        </Stack>
        <Box sx={{ marginTop: isDesktop ? '30px' : '55px' }}>
          <MainContent
            updateList={updateList}
            operation={pageFunc}
            propsData={propsData}
            ref={childRef}
            setParentGetData={setParentGetData}
            setLoading_pro={setLoading_pro}
            loading_pro={loading_pro}
            setChangePage={setChangePage}
            setOpen={setOpen}
            open={open}
            setErrorMessage={setErrorMessage}
            setError={setError}
            setSuccess={setSuccess}
            changePage={changePage}
          />
        </Box>
      </Container>
    </Page>
  );
}
