import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Table,
  TableHead,
  TableBody,
  Radio,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { SubmitUpload, fetchFILE, SubmitFirmwareDelete } from '../../../../../../../../../../api/AllAPI';
import Icon from '../../../../../../../../../../components/customIcon/icon-delete-turquoise-16.svg';
// ----------------------------------------------------------------------

export default function SelectFile({
  formData,
  setFormData,
  evseName,
  list,
  setLoading_pre,
  setErrorMessage,
  setError,
  getListFunc,
}) {
  const { t } = useTranslation();
  const [filelist, setFileList] = useState();
  const handleCheckboxSelection = (option) => {
    setFormData({ ...formData, selectedOption: option });
  };
  // const getListFunc = () => {
  //   setLoading_pre(true);
  //   fetchFILE(setError, setErrorMessage).then((promiseResult) => {
  //     if (!promiseResult.message) {
  //       setFileList(promiseResult);
  //       // props.setSuccess(true);
  //     }
  //     setLoading_pre(false);
  //   });
  // };
  useEffect(() => {
    if (list) {
      setFileList(list);
    }
  }, [list]);
  const firmwareDelete = (name) => {
    setLoading_pre(true);
    SubmitFirmwareDelete({ charge_point_id: evseName, filename: name }, setError, setErrorMessage).then(
      (promiseResult) => {
        if (promiseResult.category === 'Success') {
          // setSuccess(true);
          getListFunc();
          if (formData.selectedOption == name) {
            setFormData({ ...formData, selectedOption: '' });
          }
        } else {
          setErrorMessage(promiseResult.message);
          setError(true);
          setLoading_pre(false);
        }
      }
    );
  };

  const column = [
    {
      id: 'title',
      label: t('Uploaded firmware files'),
      align: 'left',
      width: 180,
    },
    {
      id: 'Delete',
      label: 'Delete',
      align: 'center',
      width: 120,
    },
    {
      id: 'select',
      label: t('Select'),
      align: 'center',
      width: 90,
    },
  ];
  return (
    <Box>
      <Table aria-labelledby="tableTitles">
        <TableHead>
          <TableRow>
            {column.map((headCell) => (
              <TableCell key={headCell.id} align={headCell.align} padding="normal" sx={{ width: headCell.width }}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filelist?.map((data) => (
            <TableRow hover key={data}>
              <TableCell align="left">{data}</TableCell>
              <TableCell align="center">
                {/* <IconButton aria-label={t('delete')}>
                  <DeleteIcon />
                </IconButton> */}
                <Tooltip title={t('Delete')}>
                  <IconButton
                    onClick={() => firmwareDelete(data)}
                    sx={{ width: '35px', height: '35px', zIndex: 'modal' }}
                  >
                    <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell align="right" sx={{ pr: 4 }}>
                <FormControlLabel
                  key={data}
                  control={
                    <Radio checked={data === formData.selectedOption} onChange={() => handleCheckboxSelection(data)} />
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
