import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Step,
  Stack,
  Dialog,
  Button,
  Stepper,
  MenuItem,
  StepLabel,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
// components
import dayjs from 'dayjs';
import SelectPeriod from './SelectPeriod';
import Confirmation from './Confirmation';
import { buttonR, buttonL } from '../../../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../../../components/customIcon/Close.svg';
import { SubmitRequest } from '../../../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function RequestDiagnosticReport(props) {
  const { evseName } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [loading_pre, setLoading_pre] = useState(false);
  const [formData, setFormData] = useState({
    dateStart: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    dateEnd: dayjs().format('YYYY-MM-DD'),
  });
  const { t } = useTranslation();

  const cp_id = evseName;
  const start_date = formData.dateStart;
  const end_date = formData.dateEnd;

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPage(0);
    setFormData({ ...formData, searchuser: [], inserteduser: [], selecteduser: [], removeuser: [] });
  };

  const onSubmit = () => {
    const data = {
      cp_id: cp_id,
      retries: 5,
      retry_interval: 3,
      start_time: start_date + 'T00:00:00Z',
      stop_time: end_date + 'T00:00:00Z',
    };
    setLoading(true);
    setLoading_pre(true);
    SubmitRequest(data, props.setError, props.setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.CloseMain();
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
      setLoading_pre(false);
    });
  };
  const steps = [t('Select Period'), t('Confirmation')];

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>{t('Request Diagnostics')}</MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="RequestReportDialog" maxWidth="lg">
        {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="RequestReport">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Request_Report')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
          <Stepper activeStep={page} sx={{ pt: 1 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent>
          <PageDisplay page={page} formData={formData} setFormData={setFormData} evseName={evseName} />
          <br />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button
              variant="outlined"
              disabled={loading}
              onClick={() => {
                if (page === 0) {
                  handleClose();
                } else {
                  setPage((currPage) => currPage - 1);
                }
              }}
              sx={buttonL}
            >
              {page === 0 ? t('Cancel') : t('Back')}
            </Button>
            {page === steps.length - 1 ? (
              <Button onClick={onSubmit} disabled={loading} sx={buttonR}>
                {t('Submit')}
              </Button>
            ) : (
              <Button
                sx={buttonR}
                onClick={() => {
                  setPage((currPage) => currPage + 1);
                }}
              >
                {t('Next')}
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const PageDisplay = ({ page, formData, setFormData, evseName }) => {
  if (page === 0) {
    return <SelectPeriod formData={formData} setFormData={setFormData} evseName={evseName} />;
  }
  return <Confirmation formData={formData} evseName={evseName} />;
};
