import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Table,
  Stack,
  Dialog,
  Button,
  TableRow,
  MenuItem,
  TableCell,
  TableBody,
  TextField,
  TableHead,
  DialogTitle,
  DialogContent,
  InputAdornment,
  LinearProgress,
  IconButton,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
// components
import { fetchParameter, SubmitChangeParameter, getConfiguration } from '../../../../../../../../../api/AllAPI';
import { buttonR, buttonL } from '../../../../../../../../../components/button/buttonStyle';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ChangeParameter(props) {
  const { evseName, evseInfo, handleCloseMain } = props;
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [parameter, setParameter] = useState();
  const [key, setKey] = useState();
  const [new_value, setNew_value] = useState();
  const [update, setUpdate] = useState();
  const [value, setValue] = useState([]);
  const [handleItem, setHandleItem] = useState({});
  const [isDisabled, setIsDisabled] = useState([]);
  const [loading, setLoading] = useState(false);
  const cp_id = evseName;

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    if (evseInfo.connector[0]?.status === 'Unknown') {
      return;
    }
    setLoading(true);
    fetchParameter(cp_id, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult) {
        setParameter(promiseResult.configuration_key ? promiseResult.configuration_key : promiseResult.variables);
      }
      setLoading(false);
    });
  }, [update]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRefresh = () => {
    setLoading(true);
    getConfiguration(cp_id, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult) {
        setParameter();
        setUpdate(Math.random());
        props.setSuccess(true);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseMain();
  };

  const handleChange = (event, index, item) => {
    if (event.target.value === '') {
      const newInputDisabled = Array(parameter.length).fill(false);
      setIsDisabled(newInputDisabled);
    } else {
      const newInputDisabled = Array(parameter.length).fill(true);
      newInputDisabled[index] = false;
      setIsDisabled(newInputDisabled);
    }

    const newValue = [...value];
    newValue[index] = event.target.value;
    setValue(newValue);
    setKey(event.target.name);
    setNew_value(event.target.value);
    if (evseInfo.ocpp_version !== 'ocpp1.6') {
      setHandleItem(item);
    }
  };

  const onSave = () => {
    setLoading(true);
    setLoading(true);
    let data = {};
    if (evseInfo.ocpp_version == 'ocpp1.6') {
      data = {
        charge_point_id: cp_id,
        key: key,
        value: new_value,
      };
    } else {
      data = {
        charge_point_id: cp_id,
        set_variable_data: [
          {
            attribute_type: handleItem.attribute_type,
            attribute_value: new_value, //new_value
            component: {
              name: handleItem.component_name,
              instance: handleItem.component_instance,
            },
            variable: {
              name: key, //NetworkProfileConnectionAttempts
              instance: handleItem.variable_instance,
            },
          },
        ],
      };
    }
    SubmitChangeParameter(data, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        if (promiseResult.data.status === 'Accepted') {
          const newInputDisabled = Array(parameter.length).fill(false);
          setIsDisabled(newInputDisabled);
          const resetValue = value.map(() => '');
          setValue(resetValue);
          setUpdate(Math.random());
          props.setSuccess(true);
        } else {
          props.setErrorMessage(promiseResult.data.status);
          props.setError(true);
        }
      } else {
        props.setErrorMessage(promiseResult.response?.data?.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };
  const column = [
    {
      id: 'configuration',
      label: t('Configuration'),
      width: 200,
    },
    {
      id: 'currentValue',
      label: t('Current Value'),
      width: 200,
    },
    {
      id: 'newValue',
      label: t('New Value'),
      width: 200,
    },
  ];

  const column_2 = [
    {
      id: 'variable_name',
      label: t('Variable Name'),
      width: 290,
    },
    {
      id: 'component_name',
      label: t('Component Name'),
      width: 170,
    },
    {
      id: 'attribute_value',
      label: t('Attribute Value'),
      width: 320,
    },
    {
      id: 'variable_attribute',
      label: t('Variable Attribute'),
      width: 150,
    },
    {
      id: 'newValue',
      label: t('New Value'),
    },
  ];
  const renderInputs = (checked, index) => {
    return (
      <TableCell align="left" padding="normal">
        {checked.readonly === 'false' || checked.readonly === 'False' ? (
          <TextField
            size="small"
            label={t('New Value')}
            name={checked.key}
            value={value[index]}
            onChange={(event) => handleChange(event, index)}
            disabled={isDisabled[index]}
          />
        ) : (
          ''
        )}
      </TableCell>
    );
  };
  const renderInputs_2 = (item, index) => {
    return (
      <TableCell align="left" padding="normal">
        {item.variable_attribute !== 'ReadOnly' ? (
          <TextField
            size="small"
            type={item.variable_data_type == 'integer' ? 'number' : 'text'}
            // label={t('New Value')}
            label={t(item.variable_data_type == 'integer' ? 'Num' : 'Str')}
            name={item.variable_name}
            value={value[index]}
            onChange={(event) => handleChange(event, index, item)}
            disabled={isDisabled[index]}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       {t(item.variable_data_type !== 'integer' ? 'Str' : 'Num')}
            //     </InputAdornment>
            //   ),
            // }}
          />
        ) : (
          ''
        )}
      </TableCell>
    );
  };
  return (
    <Box>
      <MenuItem
        disabled={evseInfo.connector[0]?.status === 'Unknown'}
        onClick={handleClickOpen}
        sx={{ position: 'relative' }}
      >
        {evseInfo.ocpp_version == 'ocpp1.6' ? t('Change Configuration') : t('Variables Setting')}
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="ChangeAvailablity"
        fullWidth
        maxWidth={evseInfo.ocpp_version === 'ocpp1.6' ? 'md' : 'lg'}
      >
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="ChangeAvailablity">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {evseInfo.ocpp_version == 'ocpp1.6' ? t('Change Configuration') : t('Variables Setting')}
            <Stack direction="row" spacing={1}>
              <Button variant="outlined" disabled={loading} onClick={handleRefresh} sx={buttonL}>
                {t('Refresh')}
              </Button>
              <Button variant="outlined" disabled={loading} onClick={handleClose} sx={buttonL}>
                {t('close')}
              </Button>
              <Button variant="outlined" disabled={loading} endIcon={<SaveIcon />} onClick={onSave} sx={buttonR}>
                {t('Save')}
              </Button>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={0}>
            {/* <Button variant="outlined">Resync with EVSE</Button>
            <IconButton variant="outlined" onClick={props.Update}>
              <RefreshIcon />
            </IconButton> */}
          </Stack>
          <Table>
            <TableHead>
              <TableRow>
                {evseInfo.ocpp_version === 'ocpp1.6'
                  ? column.map((headCell) => (
                      <TableCell key={headCell.id} align="left" padding="normal" sx={{ width: headCell.width }}>
                        {headCell.label}
                      </TableCell>
                    ))
                  : column_2.map((headCell) => (
                      <TableCell key={headCell.id} align="left" padding="normal" sx={{ width: headCell.width }}>
                        {headCell.label}
                      </TableCell>
                    ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {evseInfo.ocpp_version === 'ocpp1.6'
                ? parameter?.map((checked, index) => {
                    const splitName = checked.value?.split(',');
                    return (
                      <TableRow key={checked.key}>
                        <TableCell align="left" padding="normal">
                          {checked.key}
                        </TableCell>
                        <TableCell align="left" padding="normal">
                          {splitName?.join('\n')}
                        </TableCell>
                        {renderInputs(checked, index)}
                      </TableRow>
                    );
                  })
                : parameter?.map((item, index) => {
                    const splitName = item.attribute_value?.split(',');
                    return (
                      <TableRow key={item.component_name}>
                        <TableCell align="left" padding="normal">
                          {item.variable_name}
                        </TableCell>
                        <TableCell align="left" padding="normal">
                          {item.component_name}
                        </TableCell>
                        {/* <TableCell align="left" padding="normal">
                      {item.attribute_type}
                    </TableCell> */}
                        <TableCell align="left" padding="normal">
                          {splitName?.join('\n')}
                        </TableCell>
                        {/* <TableCell align="left" padding="normal">
                      {item.component_instance}
                    </TableCell> */}

                        {/* <TableCell align="left" padding="normal">
                      {item.variable_instance}
                    </TableCell>*/}
                        <TableCell align="left" padding="normal">
                          {item.variable_attribute}
                        </TableCell>
                        {/* <TableCell align="left" padding="normal">
                      {item.variable_data_type}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {item.variable_max_limit}
                    </TableCell>  */}
                        {renderInputs_2(item, index)}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
