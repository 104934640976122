// mui
import { Card, Stack, Typography, alpha, styled, useTheme, Box } from '@mui/material';
// components
import Iconify from './Iconify';
import { over } from 'lodash';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function AppWidgetSummary(props) {
  const theme = useTheme();
  const { title, currency, total, unit, icon, color = 'primary', sx, ...other } = props;
  // const number = parseFloat(total, 10);
  const number = total;
  return (
    <Card
      sx={{
        width: '100%',
        maxHeight: 204,
        p: 3,
        boxShadow: 0,
        textAlign: 'center',
        color: theme.palette.title.main,
        background: theme.palette.bar.main,
        ...sx,
      }}
      {...other}
    >
      <Typography
        title={title}
        sx={{
          // opacity: 0.8,
          height: '45px',
          // color: 'white',
          fontFamily: 'Arial',
          fontWeight: 700,
          fontSize: '1.0vw',
          lineHeight: '20px',
          textAlign: 'center',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': '2',
          overflow: 'hidden',
        }}
      >
        {title}
      </Typography>

      <IconWrapperStyle
        sx={{
          color: theme.palette[color].main,
          background: theme.palette.background.pagerWhite,
          marginBottom: '15px',
        }}
      >
        <Box component="img" src={icon} sx={{ width: '60px', height: '60px' }} />
      </IconWrapperStyle>

      <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontFamily: 'Arial',
            // fontWeight: 700,
            fontSize: '1.4vw',
            lineHeight: '40px',
            textAlign: 'center',
          }}
        >
          {currency}
        </Typography>
        {/* {number && ( */}
        <Typography
          sx={{
            fontFamily: 'Arial',
            color: theme.palette.text.primary,
            fontSize: '1.4vw',
            lineHeight: '40px',
            textAlign: 'center',
          }}
        >
          {number ? number : 0}
        </Typography>
        {/* )} */}
        <Typography
          sx={{
            fontFamily: 'Arial',
            color: theme.palette.text.primary,
            fontSize: '1.4vw',
            lineHeight: '40px',
            textAlign: 'center',
          }}
        >
          {unit}
        </Typography>
      </Stack>
      {/* <Typography variant="h3">{fShortenNumber(total)}</Typography> */}
    </Card>
  );
}
