import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
// mui
import {
  Box,
  Grid,
  Alert,
  Radio,
  Stack,
  Button,
  Tooltip,
  Snackbar,
  useTheme,
  Checkbox,
  FormLabel,
  FormGroup,
  TextField,
  Container,
  Typography,
  FormControl,
  Autocomplete,
  LinearProgress,
  FormControlLabel,
  TextareaAutosize,
} from '@mui/material';
// components
import Page from 'src/components/Page';
import { s3Config } from '../../../../api/s3Config';
import { create_schedule, edit_schedule, list_schedule_detail, list_endpoint } from '../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { buttonL } from 'src/components/button/buttonStyle';
import { LoadingButton } from '@mui/lab';
import useResponsive from '../../../../hooks/useResponsive';
import bcakButton from 'src/components/customIcon/icon-expandedarrow-24.svg';
import { useForm, Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import PhotoIcon from '@mui/icons-material/Photo';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import PublishConfirmation from './confirmation';
// ----------------------------------------------------------------------

export default function PromoInfo({ setChangePage, updateList, changePage, propsData }) {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [file, setFile] = useState(null);
  const [base64Content, setBase64Content] = useState('');
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const [loading_pro, setLoading_pro] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [recipientsOptions, setRecipientsOptions] = useState([]);
  const methods = useForm({
    reValidateMode: 'onBlur',
  });
  const { control } = methods;
  const [errors, setErrors] = useState({
    schedule_name: '',
    title: '',
    message: '',
  });
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const startData = {
    id: '',
    schedule_name: '',
    user_ids: [],
    by_email: true,
    by_app: true,
    schedule_type: 'Immediate',
    schedule_time: null,
    title: '',
    message: '',
    status: '',
  };
  const [check, setCheck] = useState(false);
  const [formData, setFormData] = useState(startData);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };
  useEffect(() => {
    list_endpoint(setErrorMessage, setError).then((promiseResult) => {
      if (!promiseResult.message) {
        const optional = [];
        promiseResult.data.map((item, ind) => {
          optional.push({ id: item.user_id, name: item.name });
        });
        setRecipientsOptions(optional);
      } else {
        setRecipientsOptions([]);
        setError(true);
        setErrorMessage(promiseResult.message);
      }
    });
  }, []);
  useEffect(() => {
    setLoading_pro(true);
    if (changePage === 'info_edit' || changePage === 'info_view' || changePage === 'info_copy') {
      list_schedule_detail({ schedule_id: propsData.id }, setErrorMessage, setError).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          const data = promiseResult.data;
          setRecipients(data.user_ids);
          setFormData(data);
          setCheck(true); //this is to trigger remove name when copy
          const image_bucket_key = data.s3_image_bucket_key;
          if (image_bucket_key) {
            const url = `https://${s3Config.S3_BUCKET}.s3.${s3Config.REGION}.amazonaws.com/${image_bucket_key}`;
            fetch(url)
              .then((response) => response.blob())
              .then((blob) => {
                const fileName = image_bucket_key.split('/')[2];
                const fileType = fileName.split('.')[1] == 'svg' ? 'image/svg+xml' : fileName.split('.')[1];
                const dataFile = new File([blob], fileName, { type: fileType });
                setFile(dataFile);
                if (dataFile) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const base64 = e.target.result;
                    setBase64Content(base64);
                  };
                  reader.readAsDataURL(dataFile);
                }
              })
              .catch((error) => console.error(error));
          }
        } else {
          setError(true);
          setErrorMessage(promiseResult.message);
          // setErrorMessage(promiseResult.response.data.message);
        }
        setLoading_pro(false);
      });
    } else {
      setLoading_pro(false);
    }
  }, [propsData]);

  if (check === true) {
    // to remove name when copy
    if (changePage === 'info_copy') {
      setFormData({ ...formData, schedule_name: '' });
      setCheck(false);
    }
  }

  const handleClickBack = () => {
    setChangePage('table');
    setFile(null);
    setBase64Content('');
  };

  const handleCheckboxChange = (name) => (event) => {
    if (!event.target.checked && !formData[name === 'by_email' ? 'by_app' : 'by_email']) {
      return;
    }
    setFormData({ ...formData, [name]: event.target.checked });
  };
  function removeEmptyOrNull(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== null && value !== ''));
  }
  const handleSubmit = (subType) => {
    let transData = { ...formData };
    transData.status = subType;
    transData['schedule_id'] = formData.id;

    if (file) {
      transData.s3_image_bucket_key = sessionStorage.getItem('tenant_id') + '/promotion/' + file.name;
    }
    let idArr = [];
    recipients.forEach((item, ind) => {
      idArr.push(item.id);
    });
    transData.user_ids = idArr;
    const objKey = Object.keys(errors);
    const errObj = {};
    let hasError = false;
    for (const key of objKey) {
      if (!formData[key]) {
        errObj[key] = t('required fields');
        hasError = true;
      }
    }
    setErrors({ ...errors, ...errObj });
    if (hasError) return;
    setLoading_pro(true);
    console.log(transData, removeEmptyOrNull(transData));
    if (formData.schedule_time != null) {
      transData.schedule_time = new Date(formData.schedule_time);
    }
    (changePage === 'info_add' || changePage === 'info_copy') &&
      create_schedule(removeEmptyOrNull(transData), setErrorMessage, setError).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          if (file) {
            uploadFile();
          } else {
            handleClickBack();
            setSuccess(true);
            setTimeout(() => {
              updateList({ oprType: 'edit' });
            }, 200);
          }
        } else {
          setError(true);
          if (changePage === 'info_copy') {
            setErrorMessage(promiseResult.response?.data?.message);
          } else {
            setErrorMessage(promiseResult.message);
          }
        }
        setLoading_pro(false);
      });
    changePage === 'info_edit' &&
      edit_schedule(removeEmptyOrNull(transData), setErrorMessage, setError).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          if (file) {
            uploadFile();
          } else {
            handleClickBack();
            setSuccess(true);
            setTimeout(() => {
              updateList({ oprType: 'edit' });
            }, 200);
          }
        } else {
          setError(true);
          setErrorMessage(promiseResult.response?.data?.message);
        }
        setLoading_pro(false);
      });
  };

  const uploadFile = async () => {
    AWS.config.update({
      accessKeyId: s3Config.AWS_ACCESS_KEY,
      secretAccessKey: s3Config.AWS_SECRET_KEY,
    });
    const s3 = new AWS.S3({
      params: { Bucket: s3Config.S3_BUCKET },
      region: s3Config.REGION,
    });
    const params = {
      Bucket: s3Config.S3_BUCKET,
      Key: sessionStorage.getItem('tenant_id') + '/promotion/' + file.name,
      Body: file,
    };
    const upload = s3
      .putObject(params)
      .on('httpUploadProgress', (evt) => {
        console.log('Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%');
      })
      .promise();

    await upload
      .then((res) => {
        setSuccess(true);
        updateList({ oprType: 'edit' });
        handleClickBack();
        setLoading_pro(false);
      })
      .catch((err) => {
        setErrorMessage(
          promiseResult.response?.data?.message ? promiseResult.response?.data?.message : promiseResult.message
        );
        setError(true);
        setLoading_pro(false);
      });
  };
  return (
    <Page sx={{ height: 'calc(100% + 80px)', width: '100%', display: changePage !== 'table' ? 'block' : 'none' }}>
      <Container maxWidth="false" sx={{ position: 'relative', height: '100%', width: '100%' }}>
        <Snackbar open={success} autoHideDuration={4000} onClose={handleCloseSnackbar}>
          <Alert severity="success">{t('Success')}!</Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseSnackbar}>
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        {/*Top header */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            position: 'fixed',
            top: '30px',
            height: '110px',
            paddingTop: '70px',
            paddingBottom: '30px',
            backgroundColor: theme.palette.background.default,
            width: isDesktop ? 'calc(100% - 330px)' : 'calc(100% - 45px)',
            zIndex: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={t('Back')}>
              <Box
                component="img"
                src={bcakButton}
                sx={{ transform: 'rotate(90deg)', cursor: 'pointer', marginRight: '10px' }}
                onClick={handleClickBack}
              />
            </Tooltip>
            <div
              style={{
                fontFamily: 'Arial',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '36px',
                color: 'texts.main',
                mb: 2,
              }}
            >
              {(changePage === 'info_add' || changePage === 'info_copy') && t('Campaign Planner')}
              {changePage === 'info_edit' && t('Edit Campaign Planner')}
              {changePage === 'info_view' && t('View Campaign Planner')}
            </div>
          </Box>

          {changePage !== 'info_view' && (
            <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pb: 1 }}>
              <LoadingButton variant="outlined" sx={buttonL} onClick={() => handleSubmit('Draft')}>
                {t('Save as draft')}
              </LoadingButton>
              <PublishConfirmation
                formData={formData}
                base64Content={base64Content}
                handleSubmit={handleSubmit}
              ></PublishConfirmation>
            </Stack>
          )}
        </Stack>
        {loading_pro && (
          <div style={{ marginTop: '0px', width: 'calc(100% - 40px)', position: 'absolute' }}>
            <LinearProgress />
          </div>
        )}
        {/* main body */}
        <Box
          sx={{
            marginTop: isDesktop ? '30px' : '55px',
            height: 'calc(100% - 0px)',
            borderTop: `1px solid ${theme.palette.background.tableRowborder}`,
          }}
        >
          {/* left side */}
          <Box
            sx={{
              width: 'calc(40% - 30px)',
              height: 'calc(100% - 30px)',
              float: 'left',
              borderRight: `1px solid ${theme.palette.background.tableRowborder}`,
              padding: '15px 15px',
            }}
          >
            <Typography style={{ fontSize: '14px', color: theme.palette.title.main }}>
              * {t('contains mandatory fields')}
            </Typography>
            <Typography sx={{ margin: '5px 0' }}>{t('Subject')}</Typography>
            <TextField
              margin="dense"
              name="schedule_name"
              fullWidth
              sx={{
                marginRight: theme.spacing(1),
                '& input:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.pagerWhite} inset`,
                },
              }}
              disabled={changePage === 'info_view'}
              label={
                <>
                  <span>* </span>
                  {t('Campaign Name')}
                </>
              }
              value={formData.schedule_name}
              size="small"
              onChange={(e) => {
                setFormData({ ...formData, schedule_name: e.target.value });
                setErrors({ ...errors, schedule_name: '' });
              }}
              error={!!errors.schedule_name}
              helpertext={errors.schedule_name}
            />
            {/* multi select */}
            <Controller
              control={control}
              name={'user_ids'}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  multiple
                  value={recipients}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={recipientsOptions}
                  getOptionLabel={(option) => option.name}
                  key={(option) => option.id}
                  onChange={(_, data) => {
                    console.log(data);
                    setRecipients(data);
                  }}
                  disabled={changePage === 'info_view'}
                  renderInput={(params) => (
                    <TextField
                      {...field}
                      {...params}
                      fullWidth
                      size="small"
                      margin="dense"
                      inputRef={ref}
                      variant="outlined"
                      label={t('Recipients')}
                    />
                  )}
                />
              )}
            />
            <Typography style={{ fontSize: '14px', color: theme.palette.title.main }}>
              {t('recipients_tip')}
              {/* If there are no recipients, this event will be published publicly. */}
            </Typography>
            {/* <Typography style={{ fontSize: '16px' }}>{t('Method')}</Typography> */}
            {/* checkBox */}
            <FormControl component="fieldset" sx={{ margin: '10px 0' }}>
              <FormLabel component="legend">* {t('Method')}</FormLabel>
              <FormGroup>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={changePage === 'info_view'}
                          checked={formData.by_email}
                          onChange={handleCheckboxChange('by_email')}
                        />
                      }
                      label={t('Email')}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={changePage === 'info_view'}
                          checked={formData.by_app}
                          onChange={handleCheckboxChange('by_app')}
                        />
                      }
                      label={t('Notification')}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </FormControl>
            {/* Radio */}
            <Grid container justifyContent="space-between">
              <Grid
                item
                display="flex"
                alignItems="center"
                md={6.4}
                sx={{
                  border: `1px solid ${theme.palette.background.tableRowborder}`,
                  borderRadius: '5px',
                  padding: '10px 3px',
                  background: formData.schedule_type === 'Scheduled' ? theme.palette.background.tableRowborder : 'none',
                }}
              >
                <Radio
                  disabled={changePage === 'info_view'}
                  checked={formData.schedule_type === 'Scheduled'}
                  onChange={(e) => {
                    setFormData({ ...formData, schedule_type: e.target.value, schedule_time: null });
                  }}
                  value="Scheduled"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'A' }}
                />
                <Box>
                  <Typography> {t('Scheduled')} </Typography>
                  <Typography style={{ fontSize: '14px', color: theme.palette.title.main }}>
                    {t('Schedule date/time to publish')}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                display="flex"
                alignItems="center"
                md={5.3}
                sx={{
                  border: `1px solid ${theme.palette.background.tableRowborder}`,
                  borderRadius: '5px',
                  padding: '10px 3px',
                  background: formData.schedule_type === 'Immediate' ? theme.palette.background.tableRowborder : 'none',
                }}
              >
                <Radio
                  disabled={changePage === 'info_view'}
                  checked={formData.schedule_type === 'Immediate'}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      schedule_type: e.target.value,
                      // schedule_date: null,
                      schedule_time: null,
                    });
                  }}
                  value="Immediate"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'B' }}
                />
                <Box>
                  <Typography> {t('Immediate')} </Typography>
                  <Typography style={{ fontSize: '14px', color: theme.palette.title.main }}>
                    {t('Send out now')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {/* Date time */}
            <Box sx={{ display: formData.schedule_type === 'Scheduled' ? 'block' : 'none' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  disabled={changePage === 'info_view'}
                  label={t('Date&Time')}
                  inputFormat="DD/MM/YYYY HH:mm:ss"
                  value={formData.schedule_time}
                  minDateTime={changePage !== 'info_view' ? dayjs().add(5, 'minute') : undefined} // 5 minutes from now
                  onChange={(val) => {
                    setFormData({ ...formData, schedule_time: new Date(val) });
                  }}
                  renderInput={(params) => (
                    <TextField disabled={changePage === 'info_view'} margin="dense" size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Typography style={{ fontSize: '16px', marginTop: '15px' }}>{t('Content')}</Typography>
            {/* upload */}
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="10px">
              <Button
                component="label"
                disabled={changePage === 'info_view'}
                sx={{ marginTop: '0px', width: '160px' }}
                startIcon={<CloudUploadIcon />}
              >
                {t('Upload image')}
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const dataFile = e.target.files[0];
                    setFile(dataFile);
                    if (dataFile) {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        const base64 = e.target.result;
                        setBase64Content(base64);
                      };
                      reader.readAsDataURL(dataFile);
                    }
                  }}
                />
              </Button>
              <Typography sx={{ marginBottom: '0px', fontSize: '14px', color: theme.palette.title.main }}>
                {t('img_tip')}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              marginBottom="10px"
              visibility={file ? 'visible' : 'hidden'}
            >
              <Stack flexDirection="row">
                <PhotoIcon sx={{ color: '#00ACBB' }} />
                <span>{file?.name}</span>
              </Stack>
              <CloseIcon
                onClick={() => {
                  setFile(null);
                  setBase64Content('');
                }}
                sx={{ color: '#00ACBB', cursor: 'pointer' }}
              ></CloseIcon>
            </Box>
            <TextField
              margin="dense"
              name="title"
              fullWidth
              disabled={changePage === 'info_view'}
              sx={{
                marginRight: theme.spacing(1),
                '& input:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.pagerWhite} inset`,
                },
              }}
              label={
                <>
                  <span>* </span>
                  {t('Title')}
                </>
              }
              value={formData.title}
              size="small"
              onChange={(e) => {
                if (e.target.value.length < 151) {
                  setFormData({ ...formData, title: e.target.value });
                }
                setErrors({ ...errors, title: '' });
              }}
              error={!!errors.title}
              helpertext={errors.title}
            />
            <TextareaAutosize
              margin="dense"
              name="message"
              disabled={changePage === 'info_view'}
              label={
                <>
                  <span>* </span>
                  {t('Message')}
                </>
              }
              value={formData.message}
              minRows={5}
              placeholder={'*' + t('Message') + ' (maxLength:512)'}
              style={{
                padding: '10px 5px',
                textIndent: 16,
                marginTop: '10px',
                width: '100%',
                background: 'transparent',
                fontFamily: 'Arial',
                fontSize: '18px',
                color: theme.palette.title.main,
                border: !!errors.message && '1px solid #d32020',
              }}
              // fullWidth
              size="small"
              onChange={(e) => {
                setErrors({ ...errors, message: '' });
                if (e.target.value.length > 513) {
                  return;
                }
                setFormData({ ...formData, message: e.target.value });
              }}
              // error={!!errors.message}
              // helpertext={errors.message}
            />
          </Box>
          {/* right side */}
          <Box sx={{ width: '48%', height: '100%', float: 'left', margin: '25px 6%' }}>
            <Stack
              sx={{
                width: '100%',
                height: '50%',
                background: theme.palette.background.pagerWhite,
                border: `1px solid ${theme.palette.background.tableRowborder}`,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {base64Content ? (
                <img style={{ maxHeight: '100%', maxWidth: '100%' }} src={base64Content}></img>
              ) : (
                <PhotoIcon sx={{ width: '150px', height: '100px' }} />
              )}
            </Stack>
            <Typography sx={{ margin: '15px 0' }}>{formData.title ? formData.title : t('Title')}</Typography>
            <Typography style={{ color: theme.palette.title.main }}>
              {formData.message ? formData.message : t('Message')}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
