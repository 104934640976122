// mui
import { Box, Card, Stack, Container, Typography, styled } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Page from '../../../components/Page';
import Background from '../../../components/customIcon/Group 676.svg';
import Logo from '../../../components/customIcon/logo-liteon-csms-xsmall.svg';
// sections
import ForgotPasswordForm from './ForgotPasswordForm';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 677,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const mdUp = useResponsive('up', 'md');
  const { t } = useTranslation();

  return (
    <Page title={t('Forgot Password')}>
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <Box component="img" src={Background} sx={{ width: '100%', height: '100%', zIndex: 0 }} />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ width: 480 }}
            >
              <Typography
                sx={{
                  fontFamily: 'Arial',
                  fontWeight: 700,
                  fontSize: '24px',
                  lineHeight: '36px',
                  color: 'texts.main',
                }}
              >
                {t('Forgot Password')}?
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Arial',
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '22px',
                  color: 'texts.main',
                }}
              >
                {t('tip_emailForSent')}
                {/* Please enter email address for the reset password link to be sent to */}
              </Typography>
            </Stack>
            <ForgotPasswordForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
