import React from 'react';
import PropTypes from 'prop-types';
// mui
import {
  Stack,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectEVSE({ formData, setFormData }) {
  const { t } = useTranslation();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = formData.rows.map((n) => n.name);
      setFormData({ ...formData, checked: newSelected });
      return;
    }
    setFormData({ ...formData, checked: [] });
  };

  const handleClick = (event, name, status, connectorName) => {
    const selectedIndex = formData.checked.indexOf(name + '~' + connectorName);
    let newSelected = [];
    let newSelectedStatus = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(formData.checked, name + '~' + connectorName);
      newSelectedStatus = newSelectedStatus.concat(formData.checkedStatus, status);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(formData.checked.slice(1));
      newSelectedStatus = newSelectedStatus.concat(formData.checkedStatus.slice(1));
    } else if (selectedIndex === formData.checked.length - 1) {
      newSelected = newSelected.concat(formData.checked.slice(0, -1));
      newSelectedStatus = newSelectedStatus.concat(formData.checkedStatus.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        formData.checked.slice(0, selectedIndex),
        formData.checked.slice(selectedIndex + 1)
      );
      newSelectedStatus = newSelectedStatus.concat(
        formData.checkedStatus.slice(0, selectedIndex),
        formData.checkedStatus.slice(selectedIndex + 1)
      );
    }
    setFormData({ ...formData, checked: newSelected, checkedStatus: newSelectedStatus });
  };

  const isSelected = (name) => formData.checked.indexOf(name) !== -1;

  return (
    <div>
      <Stack direction="column" spacing={3}>
        <Typography variant="body1">
          {t('tit_SelectEVSE')}
          {/* Select the EVSE(s) to carry out change availability. */}
        </Typography>
        <Table aria-labelledby="tableTitle">
          <EnhancedTableHead onSelectAllClick={handleSelectAllClick} />
          <TableBody>
            {formData.rows &&
              Array.isArray(formData.rows) &&
              formData.rows.map((row, index) => {
                const isItemSelected = isSelected(row.charge_point_id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return row.connector?.map((item, ind) => (
                  <TableRow
                    hover
                    key={row.charge_point_id}
                    selected={isSelected(row.charge_point_id + '~' + item.connector_number)}
                  >
                    <StyledTableCell component="th" id={labelId} scope="row" padding="normal" sx={{ minWidth: 50 }}>
                      {row.charge_point_id}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ minWidth: 100 }}>
                      <React.Fragment key={ind}>
                        {`connector ${item.connector_number}: ${
                          item.status == 'Unknown' ? t('Offline') : t(item.status)
                        }`}
                        <br />
                      </React.Fragment>
                    </StyledTableCell>
                    <StyledTableCell padding="checkbox" align="left" sx={{ minWidth: 100 }}>
                      <Checkbox
                        key={ind}
                        disabled={item.status !== 'Available' && item.status !== 'Unavailable'}
                        onClick={(event) => handleClick(event, row.charge_point_id, item.status, item.connector_number)}
                        color="primary"
                        checked={isSelected(row.charge_point_id + '~' + item.connector_number)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </StyledTableCell>
                  </TableRow>
                ));
              })}
          </TableBody>
        </Table>
      </Stack>
    </div>
  );
}

EnhancedTableHead.propTypes = {
  onSelectAllClick: PropTypes.func.isRequired,
};

function EnhancedTableHead({ onSelectAllClick }) {
  const { t } = useTranslation();
  const column = [
    {
      id: 'name',
      label: t('EVSE'),
    },
    {
      id: 'status',
      label: t('Current Status'),
    },
  ];
  return (
    <TableHead>
      <StyledTableRow>
        {column.map((headCell) => (
          <TableCell key={headCell.id} align="left" padding="normal">
            {headCell.label}
          </TableCell>
        ))}
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all',
            }}
          />
          Select All */}
          {
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Select')}
            </>
          }
        </TableCell>
      </StyledTableRow>
    </TableHead>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));
