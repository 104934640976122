// mui
import { Container, Typography } from '@mui/material';
// components
import { useTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import AccountSidebar from '../AccountSidebar';
import Notification from './Notification';
import Alerts from './Alerts';

// ----------------------------------------------------------------------

export default function NotificationAlerts() {
  const { t } = useTranslation();

  return (
    <Page title={t('Nofication & Alerts')}>
      <Container maxWidth="xl">
        <AccountSidebar />
        <Typography variant="h4" gutterBottom>
          {t('Account Setting')}
        </Typography>
        <Notification />
        <br />
        <Alerts />
      </Container>
    </Page>
  );
}
