import React, { useContext, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EvseInfoLayout from './evseInfoLayout';
import { ArrowFlow_vertical, ArrowFlow_level } from './flowing';

export default function EvseLayout({ stationEvse, highlightedIndex, handleEvseClick }, props) {
  const { t } = useTranslation();
  const { times, setTimes } = useState(0);
  const theme = useTheme();
  useEffect(() => {
    // setLocArrs();
  }, [props]);

  return (
    <div>
      {stationEvse.map((item, index) => {
        if (index < Math.ceil(stationEvse.length / 2)) {
          const evse1 = stationEvse[index * 2];
          const evse2 = stationEvse[index * 2 + 1];

          return (
            <Box
              key={evse1?.cs_id + index}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                fontSize: '0.7vw',
              }}
            >
              <EvseInfoLayout
                evse={evse1}
                marginTop="20px"
                isHighlighted={highlightedIndex === index * 2}
                onClick={() => handleEvseClick(index * 2, evse1)}
              />
              <ArrowFlow_level
                direction="left"
                width="180"
                color1="#00ACBB"
                color2="#00ACBB"
                id="gradient5"
                marginTop="80px"
              />
              <ArrowFlow_vertical
                direction="down"
                height={
                  (index + 1) * 2 == stationEvse.length + 1 || (index + 1) * 2 == stationEvse.length ? '85' : '160'
                }
                color2="#00ACBB"
                color1="#00ACBB"
                id="gradient6"
              />
              {evse2 ? (
                <ArrowFlow_level
                  direction="right"
                  width="180"
                  color1="#00ACBB"
                  color2="#00ACBB"
                  id="gradient7"
                  marginTop="80px"
                />
              ) : (
                <div style={{ width: '180px', height: '80px', visibility: 'visible' }}></div>
              )}
              {evse2 ? (
                <EvseInfoLayout
                  evse={evse2}
                  marginTop="20px"
                  isHighlighted={highlightedIndex === index * 2 + 1}
                  onClick={() => handleEvseClick(index * 2 + 1, evse2)}
                />
              ) : (
                <div style={{ width: '266px', height: '80px', visibility: 'visible' }}></div>
              )}
            </Box>
          );
        }
        return null;
      })}
    </div>
  );
}

// {
//   /* evse layout multiple*/
// }
// const times = Math.ceil(stationEvse.length / 2);

// for (let index = 0; index < times; index++) {
//   const evse1 = stationEvse[index * 2];
//   const evse2 = stationEvse[index * 2 + 1];
//   renderedComponents.push(
//     <Box
//       key={evse1?.id + evse2?.id + index}
//       sx={{
//         width: '100%',
//         display: 'flex',
//         justifyContent: 'center',
//         fontSize: '0.7vw',
//       }}
//     >
//       <EvseLayout
//         evse={evse1}
//         marginTop="20px"
//         isHighlighted={highlightedIndex === index * 2}
//         onClick={() => handleEvseClick(index * 2, evse1)}
//       />
//       <ArrowFlow_level
//         direction="left"
//         width="180"
//         color1="#00ACBB"
//         color2="#00ACBB"
//         id="gradient5"
//         marginTop="70px"
//       />
//       <ArrowFlow_vertical
//         direction="down"
//         height={
//           (index + 1) * 2 == stationEvse.length + 1 || (index + 1) * 2 == stationEvse.length ? '75' : '150'
//         }
//         color2="#00ACBB"
//         color1="#00ACBB"
//         id="gradient6"
//       />
//       {evse2 ? (
//         <ArrowFlow_level
//           direction="right"
//           width="180"
//           color1="#00ACBB"
//           color2="#00ACBB"
//           id="gradient7"
//           marginTop="70px"
//         />
//       ) : (
//         <div style={{ width: '180px', height: '80px', visibility: 'visible' }}></div>
//       )}
//       {evse2 ? (
//         <EvseLayout
//           evse={evse2}
//           marginTop="20px"
//           isHighlighted={highlightedIndex === index * 2 + 1}
//           onClick={() => handleEvseClick(index * 2 + 1, evse2)}
//         />
//       ) : (
//         <div style={{ width: '266px', height: '80px', visibility: 'visible' }}></div>
//       )}
//     </Box>
//   );
// }
// return renderedComponents;
