import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  Dialog,
  Button,
  MenuItem,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
// components
import SelectUser from './SelectUser';
import { buttonR, buttonL } from '../../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../../components/customIcon/Close.svg';
import { fetchEVSE, SubmitLocalList } from '../../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function LocalList(props) {
  const { t } = useTranslation();
  const { evseName, evseInfo } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [local_list_version, setLocal_list_version] = useState(0);
  const [formData, setFormData] = useState({
    searchuser: [],
    inserteduser: [],
  });

  const cp_id = evseName;
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    if (evseInfo.connector[0]?.status === 'Unknown') {
      return;
    }
    setLoading(true);
    fetchEVSE(cp_id, setError, setErrorMessage).then((promiseResult) => {
      console.log('promiseResult', promiseResult);
      if (promiseResult.category == 'Success') {
        setFormData({
          ...formData,
          searchuser: promiseResult.data.accepted_user ? promiseResult.data.accepted_user : [],
          inserteduser: promiseResult.data.existing_user ? promiseResult.data.existing_user : [],
        });
        setLocal_list_version(promiseResult.data.version);
      } else if (promiseResult.response?.data) {
        setErrorMessage('local list=>' + promiseResult.response.data.message);
      }
      setLoading(false);
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    const user_list = [];
    formData.inserteduser.map((item, ind) => {
      user_list.push({ id_tag: item.mobile_id });
    });
    const data = {
      charge_point_id: cp_id,
      list_version: local_list_version + 1,
      update_type: 'Full',
      local_authorization_list: user_list,
    };
    SubmitLocalList(data, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.CloseMain();
        handleClose();
        props.CloseMain();
      } else {
        props.setErrorMessage(promiseResult.response?.data?.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <div>
      <MenuItem disabled={evseInfo.connector[0]?.status === 'Unknown'} onClick={handleClickOpen}>
        {t('Local List')}
      </MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="LocalListDialog" fullWidth maxWidth="md">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="LocalList">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Local List')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <SelectUser formData={formData} setFormData={setFormData} />
          <br />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button
              variant="outlined"
              disabled={loading}
              onClick={() => {
                handleClose();
              }}
              sx={buttonL}
            >
              {t('Cancel')}
            </Button>
            <Button onClick={onSubmit} disabled={loading} sx={buttonR}>
              {t('Submit')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
