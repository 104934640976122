import React, { useState, useEffect } from 'react';
// mui
import { Box, Container, Tab, Typography, styled, useTheme, AppBar, Toolbar, IconButton, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// sections
import ChargingStation from './station/index';
import ChargerLocation from './location/index';
// components
import Iconify from '../../../components/Iconify';
import Page from 'src/components/Page';
import { fetchCoordinate } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import icon_view from '../../../components/lightMode/icon-diagramview-default-40.svg';
import icon_loc from '../../../components/lightMode/icon-mapview-default-40.svg';
import icon_list from '../../../components/lightMode/icon-listview-onclick-40.svg';
import Iconshow from './iconShow';
import ViewInfomation from './viewInfomation';
// ----------------------------------------------------------------------

export default function Charger() {
  const [value, setValue] = useState('1');
  const [coordinates, setCoordinates] = useState();
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchCoordinate().then((promiseResult) => setCoordinates(promiseResult));
    sessionStorage.setItem('changer_diagram_locationData', '');
    sessionStorage.setItem('changer_diagram_stationData', '');
    sessionStorage.setItem('changer_diagram_evseData', '');
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue !== '3') {
      sessionStorage.setItem('changer_diagram_locationData', '');
      sessionStorage.setItem('changer_diagram_stationData', '');
      sessionStorage.setItem('changer_diagram_evseData', '');
    }
  };
  const tabOptions = [
    {
      value: '1',
      label: <Iconshow active={value} type="list" title={t('Table View')} />,
    },
    {
      value: '2',
      label: <Iconshow active={value} type="loc" title={t('Map View')} />,
    },
    {
      value: '3',
      label: <Iconshow active={value} type="view" title={t('Diagram View')} />,
    },
  ];

  return (
    <Page title={t('Charger Management')} sx={{ height: '100%' }}>
      <Container maxWidth="false" sx={{ height: '100%' }}>
        <TabContext value={value} sx={{ height: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={{ xs: 0.5, sm: 1 }}>
              <Typography
                sx={{
                  fontFamily: 'Arial',
                  fontWeight: 700,
                  fontSize: '24px',
                  lineHeight: '36px',
                  color: 'texts.main',
                  mb: 1,
                }}
              >
                {t('Charger Management')}
              </Typography>

              <TabList onChange={handleChange} aria-label={t('tabs')} textColor="secondary" indicatorColor="secondary">
                {tabOptions.map((tab) => (
                  <Tab
                    key={tab.value}
                    value={tab.value}
                    label={tab.label}
                    sx={{
                      fontFamily: 'Arial',
                      fontWeight: 700,
                      fontSize: '14px',
                      lineHeight: '22px',
                      padding: '0px',
                      minWidth: '50px',
                    }}
                  />
                ))}
              </TabList>
            </Stack>
          </Box>
          <TabPanel value="1">
            <ChargingStation setTableValue={setValue} />
          </TabPanel>
          <TabPanel value="2">
            <ChargerLocation coordinates={coordinates} setTableValue={setValue} />
          </TabPanel>
          <TabPanel value="3" sx={{ overflow: 'hidden', height: '100%' }}>
            <ViewInfomation />
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
}
