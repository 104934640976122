import React, { useState, useEffect, useRef, useImperativeHandle, useContext } from 'react';
// mui
import { TextField, Box, FormControlLabel, Checkbox, FormGroup, useTheme, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { verify_inputNumber, verify_inputNumber_null } from '../../../../../../components/verification';
import { ThemeContext } from '../../../../../../App';
// ----------------------------------------------------------------------

export default function Flat_advance({ adRef, defaultData, setOpenAlertMsg, setOpenAlert, switchSelete }) {
  const theme = useTheme();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  const { t } = useTranslation();
  // const [endDateError, setEndDateError] = useState(false);
  const [day_of_week_text, setDay_of_week_text] = useState(t('Tomorrow') + ': ' + getTomorrowDate());
  const [errorsOption, setErrorsOption] = useState({
    min_duration: '',
    max_duration: '',
  });
  const daysObj = {
    SUNDAY: false,
    MONDAY: false,
    TUESDAY: false,
    WEDNESDAY: false,
    THURSDAY: false,
    FRIDAY: false,
    SATURDAY: false,
  };
  const [checkedItems, setCheckedItems] = useState(daysObj);
  const [formData, setFormData] = useState({
    start_date: null,
    end_date: null,
    start_time: '',
    end_time: '',
    day_of_week: [],
    min_duration: '',
    max_duration: '',
  });
  useEffect(() => {
    if (!switchSelete) {
      setFormData({ start_date: null, end_date: null, start_time: '', end_time: '', day_of_week: [] });
      setDay_of_week_text(t('Tomorrow') + ': ' + getTomorrowDate());
      setCheckedItems(daysObj);
    }
  }, [switchSelete]);
  useEffect(() => {
    if (defaultData && defaultData.restrictions_show) {
      const transData = defaultData;
      setCheckedItems(daysObj);
      setDay_of_week_text(t('Tomorrow') + ': ' + getTomorrowDate());
      const data = [...new Set(defaultData.restrictions.day_of_week)];
      transData.restrictions.day_of_week = data;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const arr = daysObj;
        arr[element] = true;
        setCheckedItems(arr);
      }
      setFormData(defaultData.restrictions);
      if (data.length !== 0) {
        var sentence = '';
        let trans = getDayType(data);
        if (trans == 'weekdays' || trans == 'weekends') {
          sentence += ' ' + ' ' + t(trans);
        } else if (trans == 'Every day') {
          sentence += ' ' + t('Every day');
        } else {
          sentence = multiTags(trans);
          // sentence += ' ' + ' ';
          // for (let index = 0; index < trans.length; index++) {
          //   const element = trans[index];
          //   sentence += t(element) + ',';
          // }
        }
        setDay_of_week_text(sentence);
      }
    } else {
      setCheckedItems(daysObj);
      setDay_of_week_text(t('Tomorrow') + ': ' + getTomorrowDate());
    }
  }, [defaultData]);

  useImperativeHandle(adRef, () => ({
    getAdvanceData,
  }));
  // handleSubmit
  const getAdvanceData = (event) => {
    const err = errorsOption;
    if (err.min_duration !== '' || err.max_duration !== '') {
      return false;
    } else {
      return formData;
    }

    // console.log(formData); //get formData array
  };
  // day of week change
  const handleChange = (name) => {
    setCheckedItems((prevItems) => ({
      ...prevItems,
      [name]: !prevItems[name],
    }));
    setFormData((prevData) => {
      const arr = prevData.day_of_week.slice();
      const index = arr.indexOf(name);

      if (!prevData.day_of_week.includes(name)) {
        arr.push(name);
      } else if (index !== -1) {
        arr.splice(index, 1);
      }
      textShow(arr);
      return {
        ...prevData,
        day_of_week: arr,
      };
    });
  };
  const textShow = (data) => {
    if (data.length === 0) {
      setDay_of_week_text(t('Tomorrow') + ': ' + getTomorrowDate());
    } else {
      var sentence = '';
      let trans = getDayType(data);
      if (trans == 'weekdays' || trans == 'weekends') {
        sentence += ' ' + ' ' + t(trans);
      } else if (trans == 'Every day') {
        sentence += ' ' + t('Every day');
      } else {
        sentence = multiTags(trans);
        // sentence += ' ' + ' ';
        // for (let index = 0; index < trans.length; index++) {
        //   const element = trans[index];
        //   sentence += t(element) + ',';
        // }
      }
      setDay_of_week_text(sentence);
    }
  };
  const multiTags = (data) => {
    return data.map((con, index) => (
      <Typography
        key={index}
        variant=""
        title={t(con)}
        sx={{
          //   background: '#232F34',
          backgroundColor: darkTheme ? '#17262A' : theme.palette.background.tableHeader,
          color: theme.palette.texts.main,
          padding: '0px 8px',
          borderRadius: '5px',
          marginLeft: '2px',
          marginBottom: '5px',
          fontSize: '14px',
          lineHeight: '24px',
        }}
      >
        {t(con)}
      </Typography>
    ));
  };
  function getDayType(daysArray) {
    // const weekdays = ['Friday', 'Monday', 'Thursday', 'Tuesday', 'Wednesday'];
    const weekdays = ['FRIDAY', 'MONDAY', 'THURSDAY', 'TUESDAY', 'WEDNESDAY'];
    //const weekends = ['Saturday', 'Sunday'];
    const weekends = ['SATURDAY', 'SUNDAY'];
    const sortedDays = [...daysArray].sort(); // Sort the days for comparison

    if (sortedDays.toString() === weekdays.toString()) {
      return 'weekdays';
    } else if (sortedDays.toString() === weekends.toString()) {
      return 'weekends';
    } else if (daysArray.length === 7) {
      return 'Every day';
    } else {
      return daysArray;
    }
  }
  function getTomorrowDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dayOfWeek = daysOfWeek[tomorrow.getDay()];
    const month = months[tomorrow.getMonth()];
    const date = tomorrow.getDate();
    const year = tomorrow.getFullYear();

    return `${dayOfWeek} ${month} ${date} ${year}`;
  }
  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <p style={{ color: theme.palette.title.main }}>{t('Min Duration')}:</p>
        <TextField
          margin="dense"
          variant="standard"
          name="min_duration"
          // label={t('Min Duration')}
          value={formData.min_duration}
          sx={{ marginRight: theme.spacing(1), width: '8ch' }}
          onChange={(e) => {
            let verify = verify_inputNumber_null(e.target.value.trim(), formData.min_duration);
            setFormData({ ...formData, min_duration: verify.value });
            setErrorsOption({ ...errorsOption, min_duration: verify.error });
          }}
          error={!!errorsOption.min_duration}
          helperText={errorsOption.min_duration}
          inputProps={{
            sx: { textAlign: 'center' },
          }}
          InputProps={{
            endAdornment: t('sec'),
          }}
          size="small"
          // type="number"
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <p style={{ color: theme.palette.title.main }}>{t('Max Duration')}:</p>
        <TextField
          margin="dense"
          variant="standard"
          name="max_duration"
          // label={t('Max Duration')}
          value={formData.max_duration}
          sx={{ marginRight: theme.spacing(1), width: '8ch' }}
          inputProps={{
            sx: { textAlign: 'center' },
          }}
          InputProps={{
            endAdornment: t('sec'),
          }}
          onChange={(e) => {
            let verify = verify_inputNumber_null(e.target.value.trim(), formData.max_duration);
            setFormData({ ...formData, max_duration: verify.value });
            setErrorsOption({ ...errorsOption, max_duration: verify.error });
          }}
          error={!!errorsOption.max_duration}
          helperText={errorsOption.max_duration}
          size="small"
          // type="number"
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <p style={{ color: theme.palette.title.main }}>
          {/* <span style={{ color: 'red' }}>*</span> */}
          {t('Start - End Date')}:
        </p>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack display="flex" flexDirection="row" alignItems="center">
            <DatePicker
              name="start_date"
              // label={t('Start Date')}
              inputFormat="DD/MM/YYYY"
              inputProps={{
                sx: { textAlign: 'center' },
              }}
              views={['year', 'month', 'day']}
              value={formData.start_date}
              // InputProps={{
              //   endAdornment: <span style={{ display: 'none' }} />,
              // }}
              OpenPickerButtonProps={{
                sx: {
                  width: '7.5ch',
                  marginLeft: '-100px',
                  color: 'transparent',
                  borderRadius: '0',
                },
              }}
              onChange={(date) => {
                if (formData.end_date && date !== null && date.isAfter(formData.end_date, 'day')) {
                  setOpenAlert(true);
                  setOpenAlertMsg(t('date_after'));
                } else {
                  setOpenAlert(false);
                  setFormData({ ...formData, start_date: date });
                }
              }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  sx={{ marginLeft: theme.spacing(1), width: '10ch' }}
                  margin="dense"
                  size="small"
                  {...params}
                />
              )}
            />
            -
            <DatePicker
              name="end_date"
              // label={t('End Date')}
              inputFormat="DD/MM/YYYY"
              inputProps={{
                sx: { textAlign: 'center' },
              }}
              views={['year', 'month', 'day']}
              value={formData.end_date}
              onChange={(date) => {
                // Check if the selected end date is before the start date
                if (formData.start_date && date !== null && date.isBefore(formData.start_date, 'day')) {
                  setOpenAlert(true);
                  setOpenAlertMsg(t('date_before'));
                } else {
                  setOpenAlert(false);
                  setFormData({ ...formData, end_date: date });
                }
              }}
              OpenPickerButtonProps={{
                sx: {
                  width: '7.5ch',
                  marginLeft: '-100px',
                  color: 'transparent',
                  borderRadius: '0',
                },
              }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  sx={{ marginLeft: theme.spacing(1), width: '10ch' }}
                  margin="dense"
                  size="small"
                  // style={{ marginTop: '10px' }}
                  // error={endDateError}
                  // helperText={endDateError ? t('date_before') : ''}
                  {...params}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <p style={{ color: theme.palette.title.main }}>
          {/* <span style={{ color: 'red' }}>*</span> */}
          {t('Start - End Time')}:
        </p>
        <Stack display="flex" flexDirection="row" alignItems="center">
          <TextField
            variant="standard"
            margin="dense"
            size="small"
            type="time"
            sx={{
              marginLeft: theme.spacing(1),
              '& input[type="time"]::-webkit-calendar-picker-indicator': {
                filter: 'opacity(0)',
                marginLeft: '-50px',
                textAlign: 'center',
                width: '45px',
              },
            }}
            value={formData.start_time}
            onChange={(val) => {
              if (formData.end_time && val.target.value >= formData.end_time) {
                setOpenAlert(true);
                setOpenAlertMsg(t('time_after'));
              } else {
                setOpenAlert(false);
                setFormData({ ...formData, start_time: val.target.value });
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          -
          <TextField
            variant="standard"
            margin="dense"
            size="small"
            // label={t('End Time')}
            type="time"
            value={formData.end_time}
            sx={{
              marginLeft: theme.spacing(1),
              '& input[type="time"]::-webkit-calendar-picker-indicator': {
                filter: 'opacity(0)',
                marginLeft: '-50px',
                textAlign: 'center',
                width: '45px',
              },
            }}
            onChange={(val) => {
              if (formData.start_time && val.target.value <= formData.start_time) {
                setOpenAlert(true);
                setOpenAlertMsg(t('time_before'));
              } else {
                setOpenAlert(false);
                setFormData({ ...formData, end_time: val.target.value });
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
        </Stack>
      </Box>
      <Box>
        <p style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <p style={{ color: theme.palette.title.main, minWidth: '80px' }}>{t('Repeat on')}: </p>
          <p style={{ display: 'flex', flexWrap: 'wrap' }}> {day_of_week_text}</p>
        </p>
        <div style={{ wordBreak: 'break-all', width: '100%', marginTop: '10px' }}>
          {Object.keys(checkedItems).map((day) => (
            <span
              key={day}
              onClick={() => handleChange(day)}
              title={day}
              style={{
                textAlign: 'center',
                width: '35px',
                height: '35px',
                display: 'inline-block',
                borderRadius: '50%',
                userSelect: 'none',
                background: checkedItems[day] ? '#00ACBB' : '',
                padding: '5px',
                marginLeft: '5px',
                cursor: 'pointer',
                transition: 'background-color 0.5s ease',
              }}
              onMouseEnter={(event) => {
                if (!checkedItems[day]) {
                  event.target.style.background = '#363a3f';
                }
              }}
              onMouseLeave={(event) => {
                if (!checkedItems[day]) {
                  event.target.style.background = '';
                }
              }}
            >
              {day.charAt(0)}
            </span>
          ))}
        </div>
      </Box>
    </div>
  );
}
