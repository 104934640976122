import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
// mui
import {
  Box,
  Alert,
  Table,
  Button,
  Snackbar,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  InputLabel,
  FormControl,
  TableFooter,
  OutlinedInput,
  InputAdornment,
  TablePagination,
  tableCellClasses,
  LinearProgress,
} from '@mui/material';
// component
import TablePaginationActions from '../../../components/tablePaginationActions';
import useRefreshToken from '../../../hooks/useRefreshToken';
import LoginAgain from '../../../components/errors/LoginAgain';
import Icon from '../../../components/customIcon/icon-search-16.svg';
import Export from '../../../components/customIcon/icon-export-18.svg';
import { utils, writeFile } from 'xlsx';
import { fetchSessionLog } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SessionTable() {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sessionRows, setSessionRows] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [loading_pre, setLoading_pre] = useState(false);
  const columns = [
    { field: 'id', headerName: t('No.'), width: 30 },
    { field: 'user_id', headerName: t('Mobile ID'), width: 100 },
    {
      field: 'name',
      headerName: t('Name'),
      width: 110,
    },
    {
      field: 'charging_station',
      headerName: t('Charging Station'),
      width: 130,
    },
    {
      field: 'evse_id',
      headerName: t('EVSE ID'),
      width: 80,
    },
    {
      field: 'start',
      headerName: t('Start'),
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      width: 210,
    },
    {
      field: 'stop',
      headerName: t('Stop'),
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      width: 210,
    },
    {
      field: 'duration',
      headerName: t('Duration'),
      width: 80,
    },
    {
      field: 'energy',
      headerName: t('Energy Charged'),
      width: 130,
    },
    {
      field: 'cost',
      headerName: t('Total Cost'),
      width: 100,
    },
  ];
  const getlist = (opr) => {
    let transData = {};
    if (opr == 'start') {
      transData = {
        page_number: 1,
        page_size: 10,
        search: '',
      };
    } else {
      transData = {
        page_number: page + 1,
        page_size: rowsPerPage,
        search: query,
      };
    }
    setLoading_pre(true);
    fetchSessionLog(transData, setUnauthorized, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setSessionRows(promiseResult.data.data);
        setCount(promiseResult.data.total_record);
      } else {
        setErrorMessage(promiseResult.message);
        setError(true);
      }
      setLoading_pre(false);
    });
  };

  useEffect(() => {
    getlist('search');
  }, [query, rowsPerPage, page]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  console.log(sessionRows);

  const handleExport = () => {
    const reordered = sessionRows.map((item, index) => ({
      No: index + 1,
      'Mobile ID': item.user_id,
      Name: item.name,
      'Charging Station': item.charging_station,
      'Evse ID': item.evse_id,
      Start: item.start,
      Stop: item.stop,
      Duration: item.duration,
      'Energy Charged (kWh)': item.energy / 1000,
      'Total Cost': item.cost,
    }));
    const wb = utils.book_new(),
      ws = utils.json_to_sheet(reordered);

    utils.book_append_sheet(wb, ws, 'MySheet1');
    writeFile(wb, 'MyExcel.xlsx');
  };

  const Pagination = () => {
    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={t('rows per page')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
        sx={{ color: 'texts.main', fontFamily: 'Arial', fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
      />
    );
  };

  return (
    <Box style={{ height: 660, width: '100%', position: 'relative' }}>
      {unauthorized && <LoginAgain />}
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>

      <FormControl fullWidth sx={{ mr: 1, mb: 1, width: '100%' }}>
        <InputLabel htmlFor="outlined-adornment-search">{t('Search')}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-search"
          startAdornment={
            <InputAdornment position="start">
              <Box component="img" src={Icon} sx={{ width: '16px', height: '16px' }} />
            </InputAdornment>
          }
          label={t('Search')}
          onChange={(event) => {
            setQuery(event.target.value);
            setPage(0);
          }}
          sx={{ height: '32px' }}
        />
      </FormControl>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ color: 'texts.main', fontFamily: 'Arial', fontWeight: 700, fontSize: '14px', lineHeight: '22px' }}
              colSpan={3}
            >
              {t('Total_sessions', { total: count })}
              {/* Total Sessions: {sessionRows.length} */}
              <StyledButton
                startIcon={<Box component="img" src={Export} sx={{ width: '16px', height: '16px' }} />}
                onClick={handleExport}
              >
                {t('Export')}
              </StyledButton>
            </TableCell>
            <Pagination />
          </TableRow>
          {loading_pre && (
            <div style={{ marginTop: '0px', width: '100%', position: 'absolute' }}>
              <LinearProgress />
            </div>
          )}
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell align="left" key={column.headerName} sx={{ width: column.width }}>
                {column.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {(rowsPerPage > 0
            ? filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filteredItems
          ) */}
          {sessionRows.map((row, index) => (
            <StyledTableRow align="left" key={row.id} hover>
              <StyledTableCell sx={sx}>{index + 1}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.user_id}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.name}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.charging_station}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.evse_id}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.start}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.stop}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.duration}</StyledTableCell>
              <StyledTableCell sx={sx}>{(row.energy / 1000).toFixed(2)} kWh</StyledTableCell>
              <StyledTableCell sx={sx}>
                {row.currency} {row.cost}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination />
          </TableRow>
        </TableFooter>
      </Table>
      {/* <button onClick={() => refresh()}>Refresh</button> */}
    </Box>
  );
}

const getFilteredItems = (query, sessionRows) => {
  if (!query) {
    return sessionRows;
  }
  const search = sessionRows.filter((session) => {
    const userId = session.user_id.toLowerCase().includes(query);
    const UserId = session.user_id.includes(query);
    const name = session.name.toLowerCase().includes(query);
    const Name = session.name.includes(query);
    const chargingStation = session.charging_station.toLowerCase().includes(query);
    const ChargingStation = session.charging_station.includes(query);
    const evseId = session.evse_id.toLowerCase().includes(query);
    const EvseId = session.evse_id.includes(query);
    const start = session.start.toLowerCase().includes(query);
    const Start = session.start.includes(query);
    const stop = session.stop.toLowerCase().includes(query);
    const Stop = session.stop.includes(query);
    const duration = session.duration.toLowerCase().includes(query);
    const Duration = session.duration.includes(query);
    const energy = session.energy.toLowerCase().includes(query);
    const Energy = session.energy.includes(query);
    const cost = session.cost.toLowerCase().includes(query);
    const Cost = session.cost.includes(query);
    return (
      Name ||
      name ||
      userId ||
      UserId ||
      chargingStation ||
      ChargingStation ||
      evseId ||
      EvseId ||
      start ||
      Start ||
      stop ||
      Stop ||
      duration ||
      Duration ||
      energy ||
      Energy ||
      cost ||
      Cost
    );
  });
  return search;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));

const sx = { pt: '3.5px', pb: '3.5px', height: '45px' };
const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: '15px',
  '&:hover': {
    color: '#00B0B9',
    background: theme.palette.action.hover,
  },
}));
