import React, { useContext, useEffect, useState, useImperativeHandle } from 'react';
import { TextField, Menu, MenuItem, Stack, Snackbar, Box, Alert, LinearProgress, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../../../App';
import IconFormate from './iconFormate';
import Solar from '../../../../../components/customIcon/evseInfo/icon-solar-panel-48.svg';
import Building from '../../../../../components/customIcon/evseInfo/icon-building-48.svg';
import Power from '../../../../../components/customIcon/evseInfo/power 1.svg';
import Battery from '../../../../../components/customIcon/evseInfo/icon-battery-default-48.svg';
import line_Battery from '../../../../../components/customIcon/evseInfo/Vector 467.svg';
import line_Power from '../../../../../components/customIcon/evseInfo/Vector 481.svg';
import line_solar from '../../../../../components/customIcon/evseInfo/Vector 482.svg';
import { ArrowFlow_vertical, ArrowFlow_level } from './flowing';
import EvseLayout from './evseLayout';
import EvseInfoLayout from './evseInfoLayout';
import { size } from 'lodash';

export default function Maincon(props) {
  const { t } = useTranslation();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  const [stationEvse, setStationEvse] = useState([]);
  useEffect(() => {
    // console.log(props.station);
    if (props.station?.evse) {
      setStationEvse(props.station.evse);
    } else {
      setStationEvse([]);
    }
  }, [props.station]);

  useImperativeHandle(props.onRef, () => {
    return {
      handleEvseClick,
      resetPosition,
    };
  });
  //  handleEvseClick  cilck evse  Highlight
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const handleEvseClick = (index, stationevse) => {
    setHighlightedIndex(index);
    if (index !== null) {
      console.log(stationevse);
      props.setEvseInfoData(stationevse); //evse info data
      props.setPanelShow(false); //evse info panel
      return;
    }
    props.setPanelShow(true); //station info panel
  };

  // scale zoom
  const [scale, setScale] = useState(1);

  const handleWheel = (e) => {
    if (!props.allowOprate.includes('zoom')) {
      return;
    }
    e.preventDefault();
    const currentScale = scale;
    const scaleFactor = e.deltaY > 0 ? 0.9 : 1.1;
    const newScale = currentScale * scaleFactor;
    const minScale = 0.5;
    const maxScale = 3;
    if (newScale >= minScale && newScale <= maxScale) {
      setScale(newScale);
    }
  };
  // dragging
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const handleMouseDown = (e) => {
    if (!props.allowOprate.includes('drag')) {
      return;
    }
    setIsDragging(true);
    setStartPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseUp = () => {
    if (!props.allowOprate.includes('drag')) {
      return;
    }
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!props.allowOprate.includes('drag')) {
      return;
    }
    if (isDragging) {
      setPosition({
        x: e.clientX - startPosition.x,
        y: e.clientY - startPosition.y,
      });
    }
  };
  const resetPosition = (e) => {
    setPosition({ x: 0, y: 0 });
    setStartPosition({ x: 0, y: 0 });
    setScale(1);
  };
  return (
    <Box sx={{ width: 'calc(100% - 340px)', minWidth: '915px', height: '100%', marginLeft: '20px' }}>
      <Card
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onWheel={handleWheel}
        sx={{
          transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
          cursor: isDragging ? 'grabbing' : 'grab',
          transformOrigin: '0 0',
          // width: 'calc(100% - 400px)',
          minWidth: '690px',
          p: 3,
          boxShadow: 0,
          background: 'none',
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* ICON layout */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconFormate
            title={t('Solar')}
            currency=""
            width="100%"
            total="-- kW"
            color="#FAC858"
            icon={darkTheme ? Solar : Solar}
            unit="kW"
          />
          <ArrowFlow_vertical
            direction="down"
            height="50"
            color2="#00acbb"
            color1="#fac858"
            id="gradient1"
            status={stationEvse && stationEvse.length > 0 ? 'dynamic' : 'static'}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            width: '100%',
          }}
        >
          <Box display="flex">
            <IconFormate
              title={t('Battery')}
              currency=""
              width="80px"
              total="-- kW"
              color="#5470C6"
              icon={darkTheme ? Battery : Battery}
              unit="kW"
            />
            <ArrowFlow_level
              direction="right"
              width="240"
              color1="#5470C6"
              color2="#00ACBB"
              id="gradient2"
              marginTop="40px"
              status={stationEvse && stationEvse.length > 0 ? 'dynamic' : 'static'}
            />
          </Box>
          <IconFormate
            width="80px"
            title={t('Utility Grid Total Power')}
            total="-- kW"
            color="#00ACBB"
            icon={darkTheme ? Power : Power}
            unit="kW"
          />

          <Box display="flex">
            <ArrowFlow_level
              direction="right"
              width="240"
              color1="#00ACBB"
              color2="#91CC75"
              id="gradient3"
              marginTop="40px"
              status={stationEvse && stationEvse.length > 0 ? 'dynamic' : 'static'}
            />
            <IconFormate
              title={t('Building Load')}
              width="80px"
              total="-- kW"
              color="#91CC75"
              icon={darkTheme ? Building : Building}
              unit="kW"
            />
          </Box>
        </Box>
        {/* evse List layout */}
        {stationEvse && stationEvse.length > 0 ? (
          stationEvse.length === 1 ? (
            <Box textAlign="center">
              <ArrowFlow_vertical direction="down" height="70" color2="#00acbb" color1="#00ACBB" id="gradient4" />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '0.7vw',
                }}
              >
                <EvseInfoLayout
                  evse={stationEvse[0]}
                  isHighlighted={highlightedIndex === 0}
                  onClick={() => handleEvseClick(0, stationEvse[0])}
                />
              </Box>
            </Box>
          ) : (
            //  multiple evse layout
            <>
              {stationEvse.length > 1 && (
                <EvseLayout
                  stationEvse={stationEvse}
                  highlightedIndex={highlightedIndex}
                  handleEvseClick={handleEvseClick}
                />
              )}
            </>
          )
        ) : null}
      </Card>
    </Box>
  );
}
