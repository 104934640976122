import React, { useState, useEffect } from 'react';
// mui
import { Stack, Snackbar, Alert, LinearProgress } from '@mui/material';
// sections
import StationChargingLocation from './CL/index';
import StationAddDialog from './StationAddDialog';
import { tariffListFunction, tariffSelectionListFunction } from '../../../../api/AllAPI';
import StationSearch from './StationSearch';
import StationFilter from './StationFilter';

// ----------------------------------------------------------------------

export default function ChargingStation(props) {
  const [LocationUpdate, setLocationUpdate] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [tariffData, setTariffData] = useState([]);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  useEffect(() => {
    // Tariff list functio
    tariffSelectionListFunction(setErrorMessage, setError).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        const tariffTrans = [];
        for (let index = 0; index < promiseResult.data.length; index++) {
          const element = promiseResult.data[index];
          tariffTrans.push({
            name: element.name,
            id: element.id,
          });
        }
        setTariffData(tariffTrans);
      } else {
        // setErrorMessage('tariff list:', promiseResult.message);
      }
    });
  }, []);

  const handleButton = () => {
    if (sessionStorage.getItem('role') === 'SuperAdmin') {
      return (
        <StationAddDialog
          LocationUpdate={(childData) => {
            setLocationUpdate(childData);
          }}
          setLoading={setLoading}
          setSuccess={setSuccess}
          setError={setError}
          setErrorMessage={(childData) => setErrorMessage(childData)}
        />
      );
    }
    return;
  };

  return (
    <>
      <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="success">Success!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>

      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mb: '17px' }}>
        {handleButton()}
        {/* <StationFilter />
            <StationSearch /> */}
      </Stack>
      {loading && (
        <div style={{ marginTop: '-15px', width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      <StationChargingLocation
        setTableValue={props.setTableValue}
        tariffData={tariffData}
        LocationUpdate={LocationUpdate}
        setLoading={setLoading}
        StationCoordinate={(childData) => props.StationCoordinate(childData)}
        setSuccess={setSuccess}
        setError={setError}
        setErrorMessage={(childData) => setErrorMessage(childData)}
      />
    </>
  );
}
