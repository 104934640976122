import React, { useState, useEffect } from 'react';
// mui
import { Box, Card, Stack, Collapse, IconButton, Typography, Divider, LinearProgress, useTheme } from '@mui/material';
// component
import StationEVSE from './CP';
import StationChargingStationAddDialog from './adddialog/index';
import StationChargingStationEditDialog from './editdialog/index';
import StationChargingStationMenu from './menu/index';
import down from '../../../../../../components/customIcon/icon-expandedarrow-24.svg';
import right from '../../../../../../components/customIcon/icon-collapsedarrow-24.svg';
import { fetchEVSECSS } from '../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function StationTable(props) {
  const { dataforstation, locationName, locationData, url, updated, Update, tariffData } = props;

  return (
    <Box>
      {dataforstation.map((station) => (
        <StationRow
          setTableValue={props.setTableValue}
          tariffData={tariffData}
          key={station.name}
          station={station}
          locationData={locationData}
          url={url}
          Close={(childData) => props.Close(childData)}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
          updated={updated}
          delayUpdate={(childData) => props.delayUpdate(childData)}
          Update={(childData) => props.Update(childData)}
        />
      ))}
    </Box>
  );
}

function StationRow(props) {
  const { station, locationData, url, updated } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const theme = useTheme();
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const cs_name = station.name;

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      fetchEVSECSS(JSON.stringify({ cs_name: cs_name }), setError, setErrorMessage).then((promiseResult) => {
        setLoading(false);
        setData(promiseResult);
      });
    }, 1000);

  }, []);

  const handleButton = () => {
    if (sessionStorage.getItem('role') === 'SuperAdmin') {
      return (
        <StationChargingStationAddDialog
          tariffData={props.tariffData}
          station={station}
          Update={(childData) => props.Update(childData)}
          Close={(childData) => props.Close(childData)}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
          updated={props.updated}
          delayUpdate={(childData) => props.delayUpdate(childData)}
        />
      );
    }
    return;
  };

  return (
    <Card
      sx={{
        mt: '15px',
        ml: '12px',
        mr: '5px',
        p: 1,
        boxShadow: 0,
        background: theme.palette.background.paper2,
        border: theme.palette.CollapseRow.border,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <IconButton aria-label={t('expand row')} size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <Box component="img" src={down} sx={{ width: '30px', height: '30px' }} />
            ) : (
              <Box component="img" src={right} sx={{ width: '30px', height: '30px' }} />
            )}
          </IconButton>
          <Typography
            title={station.name}
            sx={{
              fontFamily: 'Arial',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '22px',
              color: 'texts.main',
            }}
          >
            {station.name} <br />
            {station.address}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          {handleButton()}
          <StationChargingStationEditDialog
            locationName={locationData.name}
            station={station}
            Update={(childData) => props.Update(childData)}
            url={url}
            Close={(childData) => props.Close(childData)}
            setSuccess={(childData) => props.setSuccess(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={(childData) => props.setError(childData)}
          />
          <StationChargingStationMenu
            setTableValue={props.setTableValue}
            locationName={locationData.name}
            locationData={locationData}
            evseData={data} //data
            station={station}
            stationID={station.cs_id}
            setSuccess={(childData) => props.setSuccess(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={(childData) => props.setError(childData)}
            CAUpdate={(childData) => props.Update(childData)}
          />
        </Stack>
      </Stack>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1, overflow: 'auto' }}>
          {loading && (
            <div style={{ marginTop: '0px', width: '100%' }}>
              <LinearProgress />
            </div>
          )}
          <Divider />
          {data && (
            <StationEVSE
              tariffData={props.tariffData}
              dataforevse={data.evse}
              setSuccess={(childData) => props.setSuccess(childData)}
              setError={(childData) => props.setError(childData)}
              setErrorMessage={(childData) => props.setErrorMessage(childData)}
              updated={updated}
              Close={(childData) => props.Close(childData)}
              Update={(childData) => props.Update(childData)}
              delayUpdate={(childData) => props.delayUpdate(childData)}
            />
          )}
        </Box>
      </Collapse>
    </Card>
  );
}
