// mui
import { Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import SessionTable from './SessionTable';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function Charger() {
  const { t } = useTranslation();
  return (
    <Page title={t('Session Summary')}>
      <Container maxWidth="false">
        <Typography
          sx={{
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '36px',
            color: 'texts.main',
            mb: 2,
          }}
        >
          {t('Session Summary')}
        </Typography>
        <SessionTable />
      </Container>
    </Page>
  );
}
