import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Step,
  Stack,
  Dialog,
  Button,
  Stepper,
  Tooltip,
  StepLabel,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
// components
import SelectRestartPage from './SelectRestartPage';
import DateTime from './DateTime';
import Confirmation from './Confirmation';
import DisabledIcon from '../../../../../../../../components/customIcon/icon-refresh-16-disabled.svg';
import Icon from '../../../../../../../../components/customIcon/icon-refresh-16.svg';
import { buttonR, buttonL } from '../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../components/customIcon/Close.svg';
import { SubmitRestart } from '../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectRestart(props) {
  const { t } = useTranslation();
  const steps = [t('Select Restart Page'), t('DateTime'), t('Confirmation')];
  const { evseName, evseInfo } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [reset_type, setReset_type] = useState('Soft');
  const [formData, setFormData] = useState({
    checked: [evseName],
    when: 'ASAP',
    DateTime: false,
    valueDateTime: new Date(),
  });

  const evse_list_id = evseName;

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);
  useEffect(() => {
    setFormData({ ...formData, checked: [evseName] });
  }, [evseName]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPage(0);
  };

  const onSubmit = async () => {
    setLoading(true);
    SubmitRestart(evse_list_id, reset_type, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        setReset_type('Soft');
        handleClose();
      } else {
        // debugger;
        props.setErrorMessage(promiseResult?.response?.data.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <Box>
      <Tooltip title={t('Restart')}>
        {/* <IconButton
          disabled={evseInfo.connector[0]?.status === 'Unknown'}
          onClick={handleClickOpen}
          sx={{ width: '40px', height: '40px', zIndex: 'modal' }}
        >
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton> */}
        {Object.keys(evseInfo).length !== 0 && evseInfo.connector[0]?.status === 'Unknown' ? (
          <IconButton onClick={handleClickOpen} disabled sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
            <Box component="img" src={DisabledIcon} sx={{ width: '100%', height: '100%' }} />
          </IconButton>
        ) : (
          <IconButton onClick={handleClickOpen} sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
            <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
          </IconButton>
        )}
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="RestartDialog" fullWidth maxWidth="md">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="Restart">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Restart')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
          <Stepper activeStep={page} sx={{ pt: 1 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent>
          <PageDisplay
            page={page}
            formData={formData}
            setFormData={setFormData}
            setReset_type={setReset_type}
            reset_type={reset_type}
          />
          <br />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button
              sx={buttonL}
              variant="outlined"
              disabled={loading}
              onClick={() => {
                if (page === 0) {
                  handleClose();
                } else {
                  setPage((currPage) => currPage - 1);
                }
              }}
            >
              {page === 0 ? t('Cancel') : t('Back')}
            </Button>
            {page === steps.length - 1 ? (
              <Button onClick={onSubmit} disabled={loading} sx={buttonR}>
                {t('Submit')}
              </Button>
            ) : (
              <Button
                sx={buttonR}
                onClick={() => {
                  setPage((currPage) => currPage + 1);
                }}
              >
                {t('Next')}
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

const PageDisplay = ({ page, formData, setFormData, setReset_type, reset_type }) => {
  if (page === 0) {
    return <SelectRestartPage formData={formData} setReset_type={setReset_type} reset_type={reset_type} />;
  }
  if (page === 1) {
    return <DateTime formData={formData} setFormData={setFormData} />;
  }
  return <Confirmation formData={formData} setFormData={setFormData} reset_type={reset_type} />;
};
