import React, { useState, useEffect, useRef } from 'react';
// mui
import {
  Box,
  Step,
  Stack,
  Button,
  Dialog,
  Switch,
  Tooltip,
  Stepper,
  StepLabel,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
import { buttonR, buttonL } from '../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../components/customIcon/Close.svg';
import { SubmitUserEdit } from '../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import InfoForm from './rfidInfo';
import Information from './Information';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Icon from '../../../../../components/customIcon/Union.svg';
// ----------------------------------------------------------------------

export default function userEdit(props) {
  const { t } = useTranslation();
  const { dataInfo, editUpdate } = props;
  const childRef_evse = useRef();
  const childRef_con = useRef();
  // const [removeId, setRemoveId] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rfidCon, setRfidCon] = useState(false);
  const [steps, setSteps] = useState([
    { value: 'Information', label: t('Information') },
    { value: 'RFID', label: t('RFID') },
  ]);
  const [formData, setFormData] = useState({
    name: '',
    status: '',
    whitelist: 'ALWAYS',
    default_profile_type: 'REGULAR',
    expiry: null,
    // reservation: false,
    rfid: [],
  });

  // const [operType, setOperType] = useState(null);
  const defaultEnvData = {
    contract_id: '',
    issuer: '',
    rfid_num: '',
    whitelist: 'ALWAYS',
    isVisible: true,
    valid: false,
    visual_number: '',
    operType: 'add',
  };
  // ======
  const componentRefs_env = useRef([]);
  const [addCount_env, setaddCount_env] = useState(1);
  const [components_env, setComponents_env] = useState([1]);
  //++++++++

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);
  // useEffect(() => {
  //   console.log('index_get', formData);
  // }, [formData]);
  useEffect(() => {
    if (dataInfo) {
      setFormData(dataInfo);
      const arr = [];
      let sum = 0;
      for (let index = 0; index < dataInfo.rfid.length; index++) {
        sum += 1;
        arr.push(index + 1);
      }
      setaddCount_env(sum == 0 ? 1 : sum);
      setComponents_env(arr.length == 0 ? [1] : arr);
      sum == 0 ? setRfidCon(false) : setRfidCon(true);
    }
  }, [dataInfo]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData(dataInfo);
    setPage(0);

    const arr = [];
    let sum = 0;
    for (let index = 0; index < dataInfo.rfid.length; index++) {
      sum += 1;
      arr.push(index + 1);
    }
    setaddCount_env(sum == 0 ? 1 : sum);
    setComponents_env(arr.length == 0 ? [1] : arr);
    sum == 0 ? setRfidCon(false) : setRfidCon(true);
  };

  const onNext = () => {
    const name = steps[page].value;

    if (name === 'Information') {
      const verify = verifyData(childRef_evse);
      if (verify === false) {
        return false;
      } else {
        const objArr = Object.keys(verify);
        let transData = {};
        for (let index = 0; index < objArr.length; index++) {
          const element = objArr[index];
          transData[element] = verify[element];
        }
        setFormData(verify);
        setPage((currPage) => currPage + 1);
      }
    }
    if (name === 'RFID') {
      const verify = verifyData(childRef_con);
      if (verify === false) {
        return false;
      } else {
        setFormData({ ...formData, rfid: verify });
        // console.log('ConnectorData', verify);
        setPage((currPage) => currPage + 1);
      }
    }
  };
  const verifyData = (childRef) => {
    if (childRef.current == null) {
      console.log('childRef=null');
      return;
    }
    // let hasError = true;
    let verifyData = childRef.current.onSubmit();
    return verifyData || false;
  };

  //===========
  const handleAddForm_env = () => {
    const getData = getComponentData_env('add');
    if (getData !== false) {
      // setOperType('add');
      setaddCount_env(addCount_env + 1);
      setComponents_env([...components_env, addCount_env * 10]);
      setFormData({ ...formData, rfid: [...getData, defaultEnvData] });
    }
  };
  const handleRemoveForm_env = (index, id) => {
    const getData = getComponentData_env('del');
    if (getData == false) {
      return;
    }
    const Arr_env = getData.filter((element) => element.isVisible != false);
    if (Arr_env.length > 1) {
      const updatedFormData = getData.map((item, i) => {
        if (i === index) {
          return { ...item, isVisible: false, operType: 'del' };
        }
        return item;
      });
      setFormData({ ...formData, rfid: [...updatedFormData] });
    }
  };

  const getComponentData_env = (opr) => {
    let formDataArray = [];
    let hasError = false;
    componentRefs_env.current.forEach((ref) => {
      if (!ref.current) return;
      const childData = ref.current.onSubmit(opr);
      if (childData === false) {
        hasError = true;
      } else {
        childData ? formDataArray.push(childData) : null;
      }
    });

    if (hasError) {
      return false;
    } else {
      return formDataArray;
    }
  };
  //==========
  const onSubmit = (opr) => {
    setLoading(true);
    let hasError = false;

    const getData_env = getComponentData_env();
    const transData = formData;
    transData.expiry == 'None' || transData.expiry == null ? delete transData.expiry : transData.expiry;
    if (getData_env !== false) {
      transData.rfid = getData_env.filter((element) => element?.isVisible !== false);
    } else {
      hasError = true;
    } //  do not submit
    if (hasError) {
      setLoading(false);
      return false;
    }
    console.log('transData.rfid ', transData.rfid);
    setFormData(transData);
    const arr = [];
    let sum = 0;
    for (let index = 0; index < transData.rfid.length; index++) {
      sum += 1;
      arr.push(index + 1);
    }
    setaddCount_env(sum == 0 ? 1 : sum);
    setComponents_env(arr.length == 0 ? [1] : arr);
    sum == 0 ? setRfidCon(false) : setRfidCon(true);

    SubmitUserEdit(transData, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.EditUpdate('edit');
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.response?.data.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <Box>
      <Tooltip title={t('Edit')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '35px', height: '35px', zIndex: 'fab' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="editEvseDialog" fullWidth maxWidth="sm">
        {loading && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="userEditDialog">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Edit') + ' ' + t('User') + ' ' + t('Information')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
          <Stepper activeStep={page} sx={{ pt: 1 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label.value} {...stepProps}>
                  <StepLabel {...labelProps}>{label.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent>
          {/* <PageDisplay page={page} /> */}
          <form>
            {page === 0 ? (
              <Information childRef={childRef_evse} formData={formData} setFormData={setFormData} />
            ) : (
              <>
                <div style={{ padding: '10px 0 5px 0' }}>
                  <span style={{ marginTop: '5px' }}>
                    {/* <span style={{ color: 'red' }}>* </span> */}
                    {t('RFID')}
                  </span>
                  <Switch
                    checked={rfidCon}
                    onChange={(e) => {
                      setRfidCon(e.target.checked);
                    }}
                  />
                </div>
                <Box sx={{ display: rfidCon ? 'block' : 'none' }}>
                  <div style={{ color: 'red' }}>* {t('Mandatory fields')}</div>
                  <div style={{ float: 'right' }}>
                    <AddCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Add')} onClick={handleAddForm_env} />
                  </div>
                  {components_env.map((index, ind) => {
                    const childRef_con = React.createRef();
                    componentRefs_env.current[index] = childRef_con;

                    return (
                      <div key={ind}>
                        <InfoForm
                          rfidCon={rfidCon}
                          childRef={childRef_con}
                          transData={formData.rfid ? formData.rfid[ind] : {}}
                          key={ind}
                          index={ind}
                          handleRemoveForm={handleRemoveForm_env}
                          componentIndex={ind}
                        />
                      </div>
                    );
                  })}
                </Box>
              </>
            )}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button
                variant="outlined"
                onClick={() => {
                  if (page === 0) {
                    handleClose();
                  } else {
                    setPage((currPage) => currPage - 1);
                  }
                }}
                sx={buttonL}
              >
                {page === 0 ? t('Cancel') : t('Back')}
              </Button>
              {page === steps.length - 1 ? (
                <Button onClick={onSubmit} disabled={loading} sx={buttonR}>
                  {t('Submit')}
                </Button>
              ) : (
                <Button
                  sx={buttonR}
                  onClick={onNext}
                  // onClick={() => {
                  //   setPage((currPage) => currPage + 1);
                  // }}
                >
                  {t('Next')}
                </Button>
              )}
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
