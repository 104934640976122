import React, { useState, useEffect } from 'react';
// mui
import { Box, Card, Stack, Collapse, IconButton, Typography, Tooltip, Snackbar, Alert, useTheme } from '@mui/material';
// component
import Iconify from '../../../../../components/Iconify';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function TableRow(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    pageDisplay,
    partName,
    // transItem,
    // childRef_default,
    // childRef_reservation,
    // childRef_expires,
    // defaultData,
    // setSteps,
    // steps,
    // setCurrency,
    // currency,
    // removeId,
    // setRemoveId,
    // childRef_elements,
  } = props;
  // const [element, setElement] = useState();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(true);
  }, [partName]);

  return (
    <Card
      sx={{
        boxShadow: 0,
        // mb: 1,
        // color: theme.palette.texts.main,
        color: 'texts.main',
        border: theme.palette.CollapseRow.border,
        // background: theme.palette.background.pagerWhite,
        background: 'none',
        // p: 1,
      }}
    >
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ background: theme.palette.background.pagerWhite, padding: '5px ', marginBottom: '5px' }}
        >
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <IconButton aria-label={t('expand row')} size="small" onClick={() => setOpen(!open)}>
              {open ? (
                <Iconify sx={{ color: '#00ACBB' }} icon="akar-icons:chevron-down" width={20} height={20} />
              ) : (
                <Iconify sx={{ color: '#00ACBB' }} icon="akar-icons:chevron-right" width={20} height={20} />
              )}
            </IconButton>
            <Typography
              sx={{
                fontFamily: 'Arial',
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '22px',
                color: 'texts.main',
              }}
            >
              {t(partName)}&nbsp;&nbsp;
              <span style={{ fontSize: '14px', color: theme.palette.title.main }}>
                * {t('contains mandatory fields')}
              </span>
            </Typography>
          </Stack>
        </Stack>
        {/* <Collapse in={open} timeout="auto" unmountOnExit> */}
        <div style={{ padding: '10px', display: open ? 'block' : 'none' }}>{pageDisplay}</div>
        {/* </Collapse> */}
      </Box>
    </Card>
  );
}
