// routes
import Router from './routes';
// theme
import React, { useState, useEffect } from 'react';
// import ThemeProvider1 from './pages/theme';
import ThemeProvider1 from './pages/theme/index';
// components
import ScrollToTop from './components/ScrollToTop';
import { Account } from './pages/auth/AWSCognito/Account';
import { Mode } from '@mui/icons-material';

// ----------------------------------------------------------------------

export const ThemeContext = React.createContext();

export default function App() {
  const [darkTheme, setDarkTheme] = useState(
    localStorage.getItem('darkTheme') ? JSON.parse(localStorage.getItem('darkTheme')) : true
  );
  localStorage.setItem(
    'darkTheme',
    localStorage.getItem('darkTheme') ? JSON.parse(localStorage.getItem('darkTheme')) : true
  );
  const toggleTheme = () => {
    setDarkTheme(!JSON.parse(localStorage.getItem('darkTheme')));
    localStorage.setItem('darkTheme', !JSON.parse(localStorage.getItem('darkTheme')));
  };

  return (
    <ThemeContext.Provider value={{ darkTheme, toggleTheme }}>
      <ThemeProvider1>
        <Account>
          <ScrollToTop />
          <Router />
        </Account>
      </ThemeProvider1>
    </ThemeContext.Provider>
  );
}
