import axios from './axios';
const URL = 'https://mrjgdsg5u4.execute-api.ap-southeast-1.amazonaws.com/dev';
// const URL = 'https://mrjgdsg5u4.execute-api.ap-southeast-1.amazonaws.com/dev';
// const URL_tariff = 'https://z6xfokuxzc.execute-api.ap-southeast-1.amazonaws.com/dev';

// const URL_dashboard = 'http://10.24.32.82:6002';
// const URL_charger = 'http://10.24.32.82:6001';
// const URL_OCPI = 'http://10.24.32.82:6003';
// const URL_OCPP = 'http://10.24.32.82:6004';
// const URL_revenue = 'http://10.24.32.82:6005';
// const URL_summary = 'http://10.24.32.82:6006';
// const URL_user = 'http://10.24.32.82:6008';
// const URL_tariff = 'http://10.24.32.82:6007';
const URL_admin = 'https://unqvhc9awb.execute-api.ap-southeast-1.amazonaws.com/dev';
const URL_charger = 'https://d1ddouw1if.execute-api.ap-southeast-1.amazonaws.com/dev';
const URL_dashboard = 'https://iqgaliux09.execute-api.ap-southeast-1.amazonaws.com/dev';
const URL_OCPI = 'https://jzxbfalto4.execute-api.ap-southeast-1.amazonaws.com/dev';
const URL_OCPP = 'https://iuwml0f5l1.execute-api.ap-southeast-1.amazonaws.com/dev';
const URL_revenue = 'https://nlqo8pvc3j.execute-api.ap-southeast-1.amazonaws.com/dev';
const URL_summary = 'https://v2x12p35ij.execute-api.ap-southeast-1.amazonaws.com/dev';
const URL_tariff = 'https://0haagwxnc6.execute-api.ap-southeast-1.amazonaws.com/dev';
const URL_user = 'https://ruf10vnf3a.execute-api.ap-southeast-1.amazonaws.com/dev';
const URL_promotion = 'https://z7bgkefq7c.execute-api.ap-southeast-1.amazonaws.com/dev';
const privacy_page = 'https://v29874m4xe.execute-api.ap-southeast-1.amazonaws.com/dev';
const invoice_page = 'https://mseinvoice.energyon-csms.com/';
// https://mobile.energyon-csms.com/no_auth';

//++++++++trail API URL start++++++++++++++++++++++++
// const URL_dashboard = 'https://9cic53dswl.execute-api.ap-southeast-1.amazonaws.com/dev';
// const URL_charger = 'https://6vbwrpd4w8.execute-api.ap-southeast-1.amazonaws.com/dev';
// const URL_OCPI = 'https://unsmventti.execute-api.ap-southeast-1.amazonaws.com/dev';
// const URL_user = 'https://yf18crjyx0.execute-api.ap-southeast-1.amazonaws.com/dev';
// const URL_revenue = 'https://plcx9iws35.execute-api.ap-southeast-1.amazonaws.com/dev';
// const URL_summary = 'https://mlwgexdsnf.execute-api.ap-southeast-1.amazonaws.com/dev';
// const URL_tariff = 'https://bfqzc3f9fb.execute-api.ap-southeast-1.amazonaws.com/dev';
//++++++++trail API URL end++++++++++++++++++++++++

// session_summary++++++++++++++++++++++++++++++++++++++++++++++++++++
const SESSION_URL = URL_summary + '/session_summary/list/'; //1
// dashboard ++++++++++++++++++++++++++++++++++++++++++++++++++++
const OVERVIEW_URL = URL_dashboard + '/dashboard/overview/'; //1
const LOCATION_URL = URL_dashboard + '/dashboard/location/'; //1
const ALERT_URL = URL_dashboard + '/dashboard/get_alerts';
const NOTIFICATION_URL = URL_dashboard + '/dashboard/get_notifications';
const TENANT_CONFIGURATION = URL_dashboard + '/dashboard/get_tenant_configuration';
const READ_ALERT_URL = URL_dashboard + '/dashboard/read_alerts';
const READ_NOTIFICATION_URL = URL_dashboard + '/dashboard/read_notifications';
const DELETE_ALERT_URL = URL_dashboard + '/dashboard/delete_alerts';
const DELETE_NOTIFICATION_URL = URL_dashboard + '/dashboard/delete_notifications';
// REVENUE_URL++++++++++++++++++++++++++++++++++++++++++++++++++++
const REVENUE_URL = URL_revenue + '/revenue/location/'; //1
const OVERVIEWREVENUE_URL = URL_revenue + '/revenue/overview/'; //1

// user_management++++++++++++++++++++++++++++++++++++++++++++++++++++
const USER_URL = URL_user + '/user_management/list/user'; //1
const LISTCP_URL = URL_user + '/user_management/list/charge_point'; //1
const LISTCONNECTOR_URL = URL_user + '/user_management/list/connector'; //0

const ADDUSER_URL = URL_user + '/user_management/add_dbuser/'; // benjin edited
const LISTUSER_URL = URL_user + '/user_management/list_mobile_user/';
const USERLISTCS_URL = URL_user + '/user_management/list/charging_station'; //1
const EDITUSER_URL = URL_user + '/user_management/edit'; //0
const DELETEUSER_URL = URL_user + '/user_management/delete/'; //

const RESERVE_URL = URL_user + '/user_management/make_reserve';
const CANCELRESERVE_URL = URL_user + '/user_management/cancel_reserve/'; //

// admin_management++++++++++++++++++++++++++++++++++++++++++++++++++++
const ADMIN_URL = URL_admin + '/admin_management/list/';
const LISTCS_URL = URL_admin + '/admin_management/list_charging_stations';
const DELETEADMIN_URL = URL_admin + '/admin_management/delete_admin';
const EDITADMIN_URL = URL_admin + '/admin_management/edit_admin';
const ADDADMIN_URL = URL_admin + '/admin_management/add/';

// ocpp_log++++++++++++++++++++++++++++++++++++++++++++++++++++//？

// ocpp
// const CANCELRESERVE_URL = URL_OCPP + '/ocpp/cancel_reservation';
// const RESERVE_URL = URL_OCPP + '/ocpp/reserve_now';
const CLEARCACHE_URL = URL_OCPP + '/ocpp/clear_cache';

const LISTLL_URL = URL_OCPP + '/ocpp/get_local_list';
const LOCALLIST_URL = URL_OCPP + '/ocpp/send_local_list';
const SELECTRESTART_URL = URL_OCPP + '/ocpp/send_reset';
const SELECTUNLOCK_URL = URL_OCPP + '/ocpp/unlock_connector';
const SAVE_URL = URL_OCPP + '/ocpp/set_configuration_variables';
const OCPP_URL = URL_OCPP + '/ocpp/get_local_list';
const GETCONFIGURATION_URL = URL_OCPP + '/ocpp/get_configuration_variables';

const LOGIN_URL = '/login/';
const CHANGENAME_URL = '/account_settings/edit/';
const CHANGEPW_URL = '/change_password/';
const FORGOTPASSWORD_URL = '/forgotpassword/';

// tariff_management++++++++++++++++++++++++++++++++++++++++++++++++++++//1
const TARIFF_URL = URL_tariff + '/tariff_management/list/';
const TARIFF_SELECTION_URL = URL_tariff + '/tariff_management/list_selection';
const TARIFFEDIT_URL = URL_tariff + '/tariff_management/edit/';
const TARIFFADD_URL = URL_tariff + '/tariff_management/add';
const TARIFFLIST_URL = URL_tariff + '/tariff_management/list';
const TARIFF_EDIT_URL = URL_tariff + '/tariff_management/edit';
const TARIFF_DELETE_URL = URL_tariff + '/tariff_management/delete';
const TARIFF_SEARCH_URL = URL_tariff + '/tariff_management/search'; //1
const TARIFF_DISCOUNT = URL_tariff + '/tariff_management/email_domain_discount';
const TARIFF_DISCOUNT_SET = URL_tariff + '/tariff_management/email_domain_discount/set';

// charger_management++++++++++++++++++++++++++++++++++++++++++++++++++++
const MOVETO_URL = URL_charger + '/charger_management/move_to'; //1
const Retry_URL = URL_charger + '/charger_management/retry_connection';
const LISTMT_URL = URL_charger + '/charger_management/move_to_list_station/';
const DELETELOCATION_URL = URL_charger + '/charger_management/delete_location/';
const EDITLOCATION_URL = URL_charger + '/charger_management/edit_location/';
const CHANGEAVAILABILITY_URL = URL_charger + '/charger_management/change_availability';
const LISTCOORDINATE_URL = URL_charger + '/charger_management/list_coordinate/';
const CHARGERSTATION_URL = URL_charger + '/charger_management/list_location';
const LISTL_URL = URL_charger + '/charger_management/move_to_list_location';
const CHARGERMAN_ADDEVSE_URL = URL_charger + '/charger_management/add_evse';
const CHARGERMAN_DELETEEVSE_URL = URL_charger + '/charger_management/delete_evse';
const CHARGERMAN_EDITEVSE_URL = URL_charger + '/charger_management/edit_evse';
const CHARGERMAN_ADDLOCATION_URL = URL_charger + '/charger_management/add_location';
const CHARGERMAN_ADDSTATIONENERGY_URL = URL_charger + '/charger_management/add_station_energy';
const CHARGERMAN_EDITSTATIONENERGY_URL = URL_charger + '/charger_management/edit_station_energy';
const CHARGERMAN_ADDSTATIONOPENING_URL = URL_charger + '/charger_management/add_station_opening_hour';
const CHARGERMAN_EDITSTATIONOPENING_URL = URL_charger + '/charger_management/edit_station_opening_hour';
const CHARGERMAN_ADDSTATION_URL = URL_charger + '/charger_management/add_station';
const CHARGERMAN_EDITSTATION_URL = URL_charger + '/charger_management/edit_station';
const CHARGERMAN_DELETESTATION_URL = URL_charger + '/charger_management/delete_station';
const CHARGERMAN_LISTEVSE_URL = URL_charger + '/charger_management/list_evse';
const CHARGERMAN_LISTSTATION_URL = URL_charger + '/charger_management/list_station';
const CHARGERMAN_LISTFACILITY_URL = URL_charger + '/charger_management/list_facility';
const CHARGERSTATIONSTATUS_URL = URL_charger + '/charger_management/list_station_status'; //1
const SELECTSTART_URL = URL_charger + '/charger_management/remote_start_transaction';
const SELECTSTOP_URL = URL_charger + '/charger_management/remote_stop_transaction';

//_smart_charging
const LISTSMARTCHARG = URL_charger + '/charger_management/list_smart_charging_config';
const ADDSMARTCHARG = URL_charger + '/charger_management/add_smart_charging_config';
const EDITSMARTCHARG = URL_charger + '/charger_management/edit_smart_charging_config';
// diagnostic
const REQUEST_URL = URL_charger + '/charger_management/get_diagnostic';
const LISTD_URL = URL_charger + '/charger_management/list_diagnostic_directory';
const DOWNLOAD_URL = URL_charger + '/charger_management/download_diagnostic_report';
const DIAGNOSTICDELETE_URL = URL_charger + '/charger_management/delete_diagnostic_report';
// firmware
const UPLOAD_URL = URL_charger + '/charger_management/upload_firmware';
const LISTFM_URL = URL_charger + '/charger_management/list_firmware_directory';
const UPDATE_URL = URL_charger + '/charger_management/update_firmware';
const CHARGERMAN_firmware_URL = URL_charger + '/charger_management/delete_firmware';

const LISTAVAILABILITY_URL = URL_charger + '/charger_management/change_availability_list_charge_point'; //0
const LISTPARAMETER_URL = URL_charger + '/charger_management/list_configuration_variables';

//=OCPI management======================
const OCPI_REGISTER_URL = URL_OCPI + '/ocpi_management/register_new_party';
const OCPI_UPDATE_URL = URL_OCPI + '/ocpi_management/update_party';
const OCPI_DELETE_URL = URL_OCPI + '/ocpi_management/delete_party';
const OCPI_VIEWALL_URL = URL_OCPI + '/ocpi_management/view_all_parties';
const OCPI_SYNC_URL = URL_OCPI + '/ocpi_management/sync_party'; //0
const OCPI_HANDSHARK_URL = URL_OCPI + '/ocpi_management/start_handshake'; //0
const OCPI_OCPIVERSION_URL = URL_OCPI + '/ocpi_management/view_server_ocpi_versions';
const OCPI_PARTYINFO_URL = URL_OCPI + '/ocpi_management/view_party_info';
//promotion
const CREATE_SCHEDULE = URL_promotion + '/promotion/create_schedule';
const EDIT_SCHEDULE = URL_promotion + '/promotion/edit_schedule';
const SCHEDULE_TABLE = URL_promotion + '/promotion/list_schedule';
const DELETE_SCHEDULE = URL_promotion + '/promotion/delete_schedule';
const LIST_SCHEDULE_DETAIL = URL_promotion + '/promotion/list_schedule_detail';
const LIST_CALENDAR_VIEW = URL_promotion + '/promotion/list_calendar_view';
const LIST_ENDPOINT = URL_promotion + '/promotion/list_endpoint';

const DELETE_MOBILE_ACCOUNT = privacy_page + '/deactivate';

// invoice
const LIST_INVOICE_URL = invoice_page + '/invoice/list_track_number';
const UPDATE_INVOICE_URL = invoice_page + '/invoice/update_track_number';
const REFRESH_INVOICE_URL = invoice_page + '/invoice/resend_invoice';

//Manage Invoice======================
export const refreshInvoice = async (month_year) => {
  try {
    const response = await axios.post(
      REFRESH_INVOICE_URL,
      { month_year },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};
export const listInvoice = async () => {
  try {
    const response = await axios.post(
      LIST_INVOICE_URL,
      { tenant_id: sessionStorage.getItem('tenant_id') },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};
export const updateInvoice = async (prefix, first_number, last_number, month_year) => {
  try {
    const response = await axios.post(
      UPDATE_INVOICE_URL,
      { tenant_id: sessionStorage.getItem('tenant_id'), prefix, first_number, last_number, month_year },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};

//======================
export const deleteMobileAccount = async (email, password, id) => {
  try {
    const response = await axios.post(
      DELETE_MOBILE_ACCOUNT,
      { username: email, password_base64string: btoa(password), userpool_id: id },
      {}
    );
    return response.data;
  } catch (err) {
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

const handleSuccess = async (url, value1, value2) => {
  const response = await axios.post(
    url,
    { value1, value2 },
    {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    }
  );
  return response.data.data;
};
//promotion management======================
export const create_schedule = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(CREATE_SCHEDULE, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log('create_schedule', err);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const edit_schedule = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(EDIT_SCHEDULE, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log('edit_schedule', err);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const schedule_table = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(SCHEDULE_TABLE, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log('schedule_table', err);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const list_schedule_detail = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(LIST_SCHEDULE_DETAIL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log('list_schedule_detail', err);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const list_calendar_view = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(LIST_CALENDAR_VIEW, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log('list_calendar_view', err);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const delete_scheduleFunc = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(DELETE_SCHEDULE, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log('delete_scheduleFunc', err);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const list_endpoint = async (setErrorMessage, setError) => {
  try {
    const response = await axios.post(
      LIST_ENDPOINT,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log('list_endpoint', err);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

// OCPI management ==========================================
export const OCPIVERSION = async (setErrorMessage, setError) => {
  try {
    const response = await axios.post(
      OCPI_OCPIVERSION_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log('OCPIVERSION', err);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const OCPIHANDSHARK = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(OCPI_HANDSHARK_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log('OCPI_HANDSHARK', err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const OCPISYNC = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(OCPI_SYNC_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    // console.log('OCPI_SYNC', err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const OCPIPARTYINFO = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(OCPI_PARTYINFO_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    // console.log('OCPIPARTYINFO', err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const OCPIVIEWALL = async (data, setErrorMessage, setError, setUnauthorized) => {
  try {
    const response = await axios.post(OCPI_VIEWALL_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log('OCPIVIEWALL', err.message);
    if (err.code == 'ERR_NETWORK') {
      setUnauthorized(true);
    }
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const OCPIADDFUNC = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(OCPI_REGISTER_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log('OCPIADDFUNC', err.message);
    // setErrorMessage('OCPIADDFUNC=>', err.message);
    // setError(true);
    return err;
  }
};
export const OCPIEDITFUNC = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(OCPI_UPDATE_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log('OCPIEDITFUNC', err.message);
    setErrorMessage('OCPIEDITFUNC=>', err.message);
    setError(true);
    return err;
  }
};

export const DeleteOCPIFunction = async (id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      OCPI_DELETE_URL,
      { id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
// OCPI management End ==========================================
// revenue ==========================================
export const fetchRevenueOverview = async (setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(
      OVERVIEWREVENUE_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response?.status === 401) {
      setUnauthorized(true);
    }
    if (err.response?.status === 422) {
      setUnauthorized(true);
    }
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const fetchRevenueLocation = async (location, setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(
      REVENUE_URL,
      { location },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response?.status === 401) {
      setUnauthorized(true);
    }
    if (err.response?.status === 422) {
      setUnauthorized(true);
    }
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
// revenue ==========================================
//user_management==========================================
export const SubmitUserAddDialog = async (name, email, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      ADDUSER_URL,
      { name, email },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitDeleteUser = async (mobile_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      DELETEUSER_URL,
      { mobile_id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const SubmitUserReserve = async (charge_point_id, mobile_id, connector_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      RESERVE_URL,
      { charge_point_id, mobile_id, connector_id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitUserEdit = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(EDITUSER_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitUserCancel = async (mobile_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      CANCELRESERVE_URL,
      { mobile_id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

// export const fetchUselistCS = async (setErrorMessage, setError, setUnauthorized) => {
//   try {
//     return handleFetchSuccess(USERLISTCS_URL);
//   } catch (err) {
//     return handleFetchError(err, setErrorMessage, setError, setUnauthorized);
//   }
// };
export const fetchUselistCS = async (setErrorMessage, setError, setUnauthorized) => {
  try {
    const response = await axios.post(
      USERLISTCS_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`fetchUselistCS Error: ${err.message}`);
    if (err.response?.status === 401) {
      setUnauthorized(true);
    }
    if (err.response?.status === 422) {
      setUnauthorized(true);
    }
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const fetchUserConnector = async (connectors, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTCONNECTOR_URL,
      { charge_point: connectors },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    return handleSetError(err, setErrorMessage, setError);
  }
};

// export const fetchUserCP = async (charging_station, setError, setErrorMessage) => {
//   try {
//     return handleSuccess(LISTCP_URL, charging_station);
//   } catch (err) {
//     return handleSetError(err, setErrorMessage, setError);
//   }
// };
export const fetchUserCP = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(LISTCP_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

// export const fetchUser = async (setErrorMessage, setError, setUnauthorized) => {
//   try {
//     debugger;
//     return handleSuccess(USER_URL);
//   } catch (err) {
//     debugger;
//     return handleFetchError(err, setErrorMessage, setError, setUnauthorized);
//   }
// };
export const fetchUser = async (data, setErrorMessage, setError, setUnauthorized) => {
  try {
    const response = await axios.post(USER_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data.data;
  } catch (err) {
    console.log(`userList_Error: ${err.message}`);
    if (err.response?.status === 401) {
      setUnauthorized(true);
    }
    if (err.response?.status === 422) {
      setUnauthorized(true);
    }
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

//user_management end==========================================
//Session Summary==========================================
export const fetchSessionLog = async (data, setUnauthorized, setErrorMessage, setError) => {
  try {
    const response = await axios.post(SESSION_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    if (err.response?.status === 401) {
      setUnauthorized(true);
    }
    if (err.response?.status === 422) {
      setUnauthorized(true);
    }
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
//Session Summary end==========================================
//=charger management================================================================================
export const ListSmartCharging = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(LISTSMARTCHARG, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const AddSmartCharging = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(ADDSMARTCHARG, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const EditSmartCharging = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(EDITSMARTCHARG, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const addEvseFunction = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_ADDEVSE_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const EditEvseFunction = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_EDITEVSE_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const DeleteEvseFunction = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_DELETEEVSE_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });

    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const Charger_AddStation = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_ADDSTATION_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const Charger_EditStation = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_EDITSTATION_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const Charger_EditStationEnergy = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_EDITSTATIONENERGY_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const Charger_EditStationOpening = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_EDITSTATIONOPENING_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const Charger_Listfacility = async (setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      CHARGERMAN_LISTFACILITY_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const Charger_DeleteStation = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_DELETESTATION_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });

    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const Charger_AddStationEnergy = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_ADDSTATIONENERGY_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const Charger_AddStationOpening = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_ADDSTATIONOPENING_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitMoveTo = async (transData, setError, setErrorMessage) => {
  try {
    const response = await axios.post(MOVETO_URL, transData, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(`MoveTo Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const SubmitRetry = async (transData, setError, setErrorMessage) => {
  try {
    const response = await axios.post(Retry_URL, transData, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(`Retry Error: ${err.message}`);
    setErrorMessage('Retry Error:' + err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const fetchL = async (setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTL_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    // console.log(`List_evse_Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const fetchCP = async (location, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTMT_URL,
      { location },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`moveTo List Error: ${err.message}`);
    setErrorMessage('moveTo List Error:' + err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const getConfiguration = async (charge_point_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      GETCONFIGURATION_URL,
      { charge_point_id, key: [], get_variable_data: [] },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Get Configuration Error: ${err.message}`);
    setErrorMessage('Get Configuration Error:' + err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const fetchParameter = async (cp_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTPARAMETER_URL,
      { cp_id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Parameter Error: ${err.message}`);
    setErrorMessage('Parameter Error:' + err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const fetchEVSECSS = async (cs_name, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      // EVSE_URL,
      CHARGERMAN_LISTEVSE_URL,
      cs_name,
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`list-evse_Error${cs_name}: ${err.message}`);
    setErrorMessage('list-evse-name' + cs_name, err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const Charger_ListStation = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_LISTSTATION_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const Charger_AddLocation = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_ADDLOCATION_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const fetchCoordinate = async () => {
  try {
    const response = await axios.post(
      LISTCOORDINATE_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    return err;
  }
};

export const SubmitDeleteLocation = async (location, setError, setErrorMessage) => {
  //1
  try {
    const response = await axios.post(
      DELETELOCATION_URL,
      { location },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitEditLocation = async (location, location_name, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      EDITLOCATION_URL,
      { location, location_name },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const fetchChargerStation = async (setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(
      CHARGERSTATION_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    // if (err.response?.status === 401) {
    //   setUnauthorized(true);
    // }
    // if (err.response?.status === 422) {
    //   setUnauthorized(true);
    // }
    return err;
  }
};
export const fetchStationStatus = async (data, setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(CHARGERSTATIONSTATUS_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    if (err.response?.status === 401) {
      setUnauthorized(true);
    }
    if (err.response?.status === 422) {
      setUnauthorized(true);
    }
    return err;
  }
};

//=charger management================================================================================
//=Tariff============================================================================================
export const emailDomainDiscount = async (setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      TARIFF_DISCOUNT,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const setEmailDomainDiscount = async (email_domain, discount_percent, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      TARIFF_DISCOUNT_SET,
      { email_domain, discount_percent },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const searchTariffFunction = async (info, setError, setErrorMessage) => {
  try {
    const response = await axios.post(TARIFF_SEARCH_URL, info, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const DeleteTariffFunction = async (tariff_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      TARIFF_DELETE_URL,
      { tariff_id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const editTariffFunction = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(TARIFF_EDIT_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const addTariffFunction = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(TARIFFADD_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('aws_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const tariffSelectionListFunction = async (setErrorMessage, setError) => {
  try {
    const response = await axios.post(
      TARIFF_SELECTION_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error-tariffList: ${err.message}`);
    setErrorMessage('tariffList=>', err.message);
    setError(true);
    return err;
  }
};
export const tariffListFunction = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(TARIFFLIST_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(`Error-tariffList: ${err.message}`);
    setErrorMessage('tariffList=>', err.message);
    setError(true);
    return err;
  }
};
//=Tariff end============================================================================================
const handleFetchError = (err, setErrorMessage, setError, setUnauthorized) => {
  console.log(`Error: ${err.message}`);
  setError(true);
  setErrorMessage(err.response?.data?.message);
  if (err.response?.status === 401) {
    setUnauthorized(true);
  }
  if (err.response?.status === 422) {
    setUnauthorized(true);
  }
  return err;
};

const handleSetError = (err, setError, setErrorMessage) => {
  console.log(`Error: ${err.message}`);
  setError(true);
  setErrorMessage(err.response?.data?.message);
  return err;
};

const handleFetchSuccess = async (url, value1, value2) => {
  const response = await axios.post(
    url,
    { value1, value2 },
    {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
    }
  );

  return response?.data?.data;
};
export const fetchAdmin = async (setErrorMessage, setError, setUnauthorized) => {
  try {
    const response = await axios.post(
      ADMIN_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response?.data?.data;
  } catch (err) {
    console.log(`Error-: ${err.message}`);
    setErrorMessage(`Error-fetchAdmin: ${err.message}`);
    setError(true);
    return err;
  }
};
// export const fetchAdmin = (setErrorMessage, setError, setUnauthorized) => {
//   try {
//     return handleFetchSuccess(ADMIN_URL);
//   } catch (err) {
//     return handleFetchError(err, setErrorMessage, setError, setUnauthorized);
//   }
// };

export const fetchCS = async (setErrorMessage, setError, setUnauthorized) => {
  try {
    const response = await axios.post(
      LISTCS_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response?.data?.data;
  } catch (err) {
    console.log(`Error-: ${err.message}`);
    setErrorMessage(`Error-fetchAdmin: ${err.message}`);
    setError(true);
    return err;
  }
};

export const fetchTariffLocation = async (setUnauthorized, setErrorMessage, setError) => {
  try {
    return handleFetchSuccess(TARIFF_URL);
  } catch (err) {
    return handleFetchError(err, setErrorMessage, setError, setUnauthorized);
  }
};

export const fetchFILE = async (setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTFM_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const fetchEVSE = async (cp_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTLL_URL,
      { charge_point_id: cp_id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log('fetchEVSE', err.message);
    setErrorMessage('fetchEVSE:' + err.message);
    setError(true);
    return err;
  }
};

export const fetchDiagnosticReport = async (cp_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTD_URL,
      { cp_id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const fetchUserStart = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(LISTUSER_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data.data;
  } catch (err) {
    console.log(`UserStart Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const fetchEVSECS = async (charging_station, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTAVAILABILITY_URL,
      { cs_id: charging_station },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitAdminDeleteDialog = async (email, setError, setErrorMessage, setLoading) => {
  try {
    const response = await axios.post(
      DELETEADMIN_URL,
      { id: email },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    setLoading(false);
    return err;
  }
};
//  email,name,
export const SubmitAdminEditDialog = async (id, charging_station_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      EDITADMIN_URL, //email, name,
      { id, charging_stations: charging_station_id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitAdminAddDialog = async (
  name,
  employee_id,
  email,
  password,
  charging_station_id,
  setError,
  setErrorMessage
) => {
  try {
    const response = await axios.post(
      ADDADMIN_URL,
      { name, employee_id, email, password, charging_station_id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const submitAC = async (id, ac_tariff, ac_currency_code, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      TARIFFEDIT_URL,
      { id, ac_tariff, ac_currency_code },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const submitDC = async (id, dc_tariff, dc_currency_code, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      TARIFFEDIT_URL,
      { id, dc_tariff, dc_currency_code },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitFirmwareUpdate = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(UPDATE_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const SubmitFirmwareDelete = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHARGERMAN_firmware_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitUpload = async (file, cp_id, filename, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      UPLOAD_URL,
      { file_base64: file, cp_id, filename },
      {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: sessionStorage.getItem('aws_token'),
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitLocalList = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(LOCALLIST_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitDownload = async (cp_id, diagnostic_report_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      DOWNLOAD_URL,
      { charge_point_id: cp_id, filename: diagnostic_report_id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
        // responseType: 'blob',
      }
    );
    return response.data;
  } catch (err) {
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitDelete_diagnostic = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(DIAGNOSTICDELETE_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};
export const SubmitRequest = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(REQUEST_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

// export const SubmitDeleteEVSE = async (cp_id, setError, setErrorMessage) => {
//   try {
//     const response = await axios.post(
//       DELETEEVSE_URL,
//       { cp_id },
//       {
//         headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
//       }
//     );
//     return response.data;
//   } catch (err) {
//     console.log(err.message);
//     setErrorMessage(err.response?.data?.message);
//     setError(true);
//     return err;
//   }
// };

export const SubmitClearCache = async (evse_list_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      CLEARCACHE_URL,
      { charge_point_id: evse_list_id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );

    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitChangeParameter = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(SAVE_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitChangeAvailabilityCP = async (transData, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHANGEAVAILABILITY_URL, transData, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });

    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitChangeAvailabilityCS = async (formData, setError, setErrorMessage) => {
  try {
    const response = await axios.post(CHANGEAVAILABILITY_URL, formData, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitRestart = async (evse_list_id, reset_type, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      SELECTRESTART_URL,
      { charge_point_id: evse_list_id, type: reset_type },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitStart = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(SELECTSTART_URL, data, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });

    return response.data;
  } catch (err) {
    console.log(err);
    setErrorMessage(err.response?.data?.message);
    // setErrorMessage(err.response?.data?.message);
    // setError(true);
    return err;
  }
};

export const SubmitStop = async (mobile_id, evseName, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      SELECTSTOP_URL,
      { mobile_id, charge_point_id: evseName },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    setErrorMessage(err.response?.data?.message);
    // setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

export const SubmitUnlock = async (cp_id, connector_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      SELECTUNLOCK_URL,
      { charge_point_id: cp_id, connector_id },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

// export const SubmitEditStation = async (name, cs_id, setError, setErrorMessage) => {
//   try {
//     const response = await axios.post(
//       EDITSTATION_URL,
//       { name, cs_id },
//       {
//         headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
//       }
//     );

//     return response.data;
//   } catch (err) {
//     console.log(err.message);
//     setErrorMessage(err.response?.data?.message);
//     setError(true);
//     return err;
//   }
// };

export const SubmitAddCS = async (cs_name, evse, evse_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      ADDEVSE_URL,
      { cs_name, evse, evse_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.response?.data?.message);
    setError(true);
    return err;
  }
};

// export const SubmitAddCL = async (
//   //lsy
//   location,
//   name,
//   address,
//   postal_code,
//   country,
//   operatingHours,
//   startTime,
//   stopTime,
//   setError,
//   setErrorMessage
// ) => {
//   try {
//     const response = await axios.post(
//       ADDSTATION_URL,
//       {
//         location,
//         name,
//         address,
//         postal_code,
//         country,
//         operatingHours,
//         startTime,
//         stopTime,
//       },
//       {
//         headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
//       }
//     );

//     return response.data;
//   } catch (err) {
//     console.log(err.message);
//     setErrorMessage(err.response?.data?.message);
//     setError(true);
//     return err;
//   }
// };

export const fetchAlert = async () => {
  try {
    const response = await axios.post(
      ALERT_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Alert Error: ${err.message}`);
    return err;
  }
};

export const fetchNotification = async () => {
  try {
    const response = await axios.post(
      NOTIFICATION_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Notification Error: ${err.message}`);
    return err;
  }
};

export const fetchReadAlert = async (transData) => {
  try {
    const response = await axios.post(READ_ALERT_URL, transData, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data.data;
  } catch (err) {
    console.log(`Alert Error: ${err.message}`);
    return err;
  }
};

export const fetchReadNotification = async (transData) => {
  try {
    const response = await axios.post(READ_NOTIFICATION_URL, transData, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data.data;
  } catch (err) {
    console.log(`Notification Error: ${err.message}`);
    return err;
  }
};

export const fetchDeleteAlert = async (transData) => {
  try {
    const response = await axios.post(DELETE_ALERT_URL, transData, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data.data;
  } catch (err) {
    console.log(`Alert Error: ${err.message}`);
    return err;
  }
};
export const fetchDeleteNotification = async (transData) => {
  try {
    const response = await axios.post(DELETE_NOTIFICATION_URL, transData, {
      headers: { Authorization: sessionStorage.getItem('aws_token') },
    });
    return response.data.data;
  } catch (err) {
    console.log(`Notification Error: ${err.message}`);
    return err;
  }
};

export const fetchTenantConfiguration = async () => {
  try {
    const response = await axios.post(
      TENANT_CONFIGURATION,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Teant Configuration Error: ${err.message}`);
    return err;
  }
};

export const SubmitLogin = async (email, password, setErrMsg) => {
  try {
    const response = await axios.post(LOGIN_URL, { email, password });
    return response.data;
  } catch (err) {
    if (!err?.response) {
      setErrMsg('No Server Response');
    } else if (err.response?.status === 400) {
      setErrMsg('Error 400');
    } else if (err.response?.status === 401) {
      setErrMsg('Error 401');
    } else {
      setErrMsg('Else Error');
    }
    return err;
  }
};

export const SubmitChangeName = async (name, setErrorMessage, setError) => {
  try {
    const response = await axios.post(
      CHANGENAME_URL,
      { name },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitChangePassword = async (password, old_password, confirm_password, setErrorMessage, setError) => {
  try {
    const response = await axios.post(
      CHANGEPW_URL,
      { password, old_password, confirm_password },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchSummaryOverview = async (setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(
      OVERVIEW_URL,
      {},
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    setError(true);
    setErrorMessage(err.message);
    if (err.response?.status === 401) {
      setUnauthorized(true);
    }
    if (err.response?.status === 422) {
      setUnauthorized(true);
    }
    return err;
  }
};

export const fetchSummaryLocation = async (location, setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(
      LOCATION_URL,
      { location },
      {
        headers: { Authorization: sessionStorage.getItem('aws_token') },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response?.status === 401) {
      setUnauthorized(true);
    }
    if (err.response?.status === 422) {
      setUnauthorized(true);
    }
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchOCPPLog = async (setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(
      OCPP_URL,
      {},
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    if (err.response?.status === 401) {
      setUnauthorized(true);
    }
    if (err.response?.status === 422) {
      setUnauthorized(true);
    }
    return err;
  }
};

export const SubmitForgetPassword = async (email, setErrMsg) => {
  try {
    const response = await axios.post(
      FORGOTPASSWORD_URL,
      { email },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrMsg(err.message);
    return err;
  }
};
