import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
// mui
import {
  Box,
  Alert,
  Stack,
  Table,
  Button,
  Snackbar,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  InputLabel,
  IconButton,
  FormControl,
  TableFooter,
  OutlinedInput,
  InputAdornment,
  TablePagination,
  tableCellClasses,
  LinearProgress,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
// components
import TablePaginationActions from '../../../components/tablePaginationActions';
import LoginAgain from '../../../components/errors/LoginAgain';
import Icon from '../../../components/customIcon/icon-search-16.svg';
import { listInvoice, refreshInvoice } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function InvoiceTable() {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dbdata, setDbdata] = useState([]);
  const [count, setCount] = useState(0);
  const [update, setUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const columns = [
    { field: 'id', headerName: t('No.'), width: 60 },
    { field: 'month_year', headerName: t('Invoice Period'), width: 150 },
    { field: 'company_code', headerName: t('Company Code'), width: 140 },
    { field: 'receipt_start', headerName: t('Receipt Start No.'), width: 150 },
    { field: 'receipt_end', headerName: t('Receipt End No.'), width: 150 },
    { field: 'counter', headerName: t('Counter'), width: 100 },
    { field: 'actions', headerName: t('Actions') },
  ];

  useEffect(() => {
    setLoading(true);
    setDbdata([]);
    setCount(0);
    listInvoice().then((promiseResult) => {
      if (promiseResult.data) {
        setDbdata(promiseResult.data);
        setCount(promiseResult.data.length);
        setSuccess(true);
      } else {
        setError(true);
        setErrorMessage(t('Loading failed!'));
      }
      setLoading(false);
    });
  }, [update]);

  const handleRefresh = (month_year) => {
    setLoading(true);
    refreshInvoice(month_year).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.data) {
        setSuccess(true);
      } else {
        setError(true);
        setErrorMessage(t('Refresh failed!'));
      }
      setLoading(false);
    });
  };

  const filteredItems = getFilteredItems(query, dbdata);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
  };

  const Pagination = () => {
    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        count={filteredItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={t('rows per page')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
        sx={{ color: 'texts.main', fontFamily: 'Arial', fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
      />
    );
  };

  return (
    <Box sx={{ height: 660, width: '100%', position: 'relative' }}>
      {unauthorized && <LoginAgain />}
      <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pb: 1 }}>
        <FormControl fullWidth sx={{ mr: 1, width: '100%' }}>
          <InputLabel htmlFor="outlined-adornment-search">{t('Search')}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            startAdornment={
              <InputAdornment position="start">
                <Box component="img" src={Icon} sx={{ width: '16px', height: '16px' }} />
              </InputAdornment>
            }
            label={t('Search')}
            onChange={(event) => {
              setQuery(event.target.value);
              setPage(0);
            }}
            sx={{ height: '32px' }}
          />
        </FormControl>
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ color: 'texts.main', fontFamily: 'Arial', fontWeight: 700, fontSize: '14px', lineHeight: '22px' }}
              colSpan={2}
            >
              {t('total_invoice', { length: count })}
            </TableCell>
            <Pagination />
          </TableRow>
          {loading && (
            <div style={{ marginTop: '0px', width: '100%', position: 'absolute' }}>
              <LinearProgress />
            </div>
          )}
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell align="left" sx={{ width: column.width }} key={column.headerName}>
                {column.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filteredItems
          ).map((row, index) => (
            <StyledTableRow align="left" key={index + 1} hover>
              <StyledTableCell sx={sx}>{index + 1}</StyledTableCell>
              <StyledTableCell sx={sx}>{calculate(row.month_year)}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.prefix}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.first_number}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.last_number}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.counter}</StyledTableCell>
              <StyledTableCell sx={sx}>
                <IconButton
                  aria-label="refresh"
                  disabled={row.pending_invoices > 0 ? false : true}
                  onClick={() => handleRefresh(row.month_year)}
                >
                  <RefreshIcon />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}

const calculate = (data) => {
  const firstFour = parseInt(data.substring(0, 4), 10); // Extracts the first 4 characters and converts them to an integer
  const lastTwo = parseInt(data.substring(data.length - 2), 10); // Extracts the last 2 characters and converts them to an integer
  return `${firstFour - 1911}0${lastTwo}`;
};
const getFilteredItems = (query, user) => {
  if (!query) {
    return user;
  }
  const search = user.filter((user) => {
    const prefix = user.prefix.toLowerCase().includes(query);
    const Prefix = user.prefix.includes(query);
    return prefix || Prefix;
  });
  return search;
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#17262A',
    // color: '#F4F4F4',
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    // color: '#F4F4F4',
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));
const sx = { pt: '3.5px', pb: '3.5px' };
