import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
// mui
import { Box, Card, Stack, LinearProgress, TablePagination, Snackbar, Alert } from '@mui/material';
// component
// import Iconify from '../../../../../components/Iconify';
import LoginAgain from '../../../../../components/errors/LoginAgain';
import TablePaginationActions from '../../../../../components/tablePaginationActions';
import { useTranslation } from 'react-i18next';
import Table from './table';
import { tariffListFunction } from '../../../../../api/AllAPI';

// ----------------------------------------------------------------------

let CollapsibleTable = ({ setSearchWord, searchWord, setPageFunc, setPropsData, setChangePage }, ref) => {
  const { t, i18n } = useTranslation();
  const [dbdata, setDbdata] = useState([]);
  // const [update, setUpdate] = useState();
  const [unauthorized, setUnauthorized] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [Amount_data, setAmount_data] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };
  useImperativeHandle(ref, () => ({
    update_List,
  }));
  // trans form data to display
  const formatDataFunc = (transData) => {
    const newGetdata = [];
    for (let index = 0; index < transData.length; index++) {
      const transelement = transData[index];
      const getData = {};
      let eleData = [];
      // General Information
      const dataArr = Object.keys(transelement);
      for (let index = 0; index < dataArr.length; index++) {
        const element = dataArr[index];
        if (element !== 'elements') {
          getData[element] = transelement[element];
        } else {
          eleData = transelement[element];
        }
      }
      getData.no_of_cs = transelement.no_of_cs;
      getData.tariff_type = transelement.tariff_type ? transelement.tariff_type : null;
      getData.min_price = {
        excl_vat: getData.min_price_excl_vat ? getData.min_price_excl_vat : null,
        incl_vat: getData.min_price_incl_vat ? getData.min_price_incl_vat : null,
      };
      getData.max_price = {
        excl_vat: getData.max_price_excl_vat ? getData.max_price_excl_vat : null,
        incl_vat: getData.max_price_incl_vat ? getData.max_price_incl_vat : null,
      };
      if (transelement.tariff_text != 0) {
        getData.description = transelement.tariff_text[0].description;
      }

      for (let index = 0; index < transelement.tariff_text.length; index++) {
        const element = transelement.tariff_text[index];
        if (element.language === selectedLanguage) {
          getData.description = element.description;
        }
      }
      let getElesArrData = [];
      for (let index = 0; index < eleData.length; index++) {
        const ele = eleData[index];
        let obj = {
          eleType:
            ele.type == 'FLAT' ? 'elements_platForm' : ele.type == 'TIME' ? 'elements_charging' : 'elements_energy',
          price_component: [],
          id: ele.id ? ele.id : null,
          restrictions_show: false,
        };
        if (ele.reservation && ele.reservation === 'RESERVATION' && ele.type == 'TIME') {
          obj.eleType = 'Reservation_time';
        }
        if (ele.reservation && ele.reservation === 'RESERVATION_EXPIRES' && ele.type == 'TIME') {
          obj.eleType = 'Expiry_time';
        }
        if (ele.reservation && ele.type == 'FLAT' && ele.reservation === 'RESERVATION_EXPIRES') {
          obj.eleType = 'Expiry_flat';
        }
        if (ele.reservation && ele.type == 'FLAT' && ele.reservation === 'RESERVATION') {
          obj.eleType = 'Reservation_Expiry_flat';
        }
        obj.price_component = [
          {
            price: ele.price,
            step_size: ele.step_size,
            type: ele.type,
            vat: ele.vat,
            priority_level: ele.priority_level,
          },
        ];
        obj.restrictions = {
          end_date: ele.end_date || null,
          end_time: ele.end_time || null,
          start_date: ele.start_date || null,
          start_time: ele.start_time || null,
          day_of_week: ele.day_of_week || null,
        };
        obj.restrictions_show =
          ele.end_date !== null ||
          ele.end_time !== null ||
          ele.start_date !== null ||
          ele.start_time !== null ||
          ele.day_of_week.length !== 0;
        [
          'max_duration',
          'min_duration',
          'min_kwh',
          'max_kwh',
          'min_power',
          'max_power',
          'min_current',
          'max_current',
        ].forEach((property) => {
          if (ele[property]) {
            obj.restrictions[property] = ele[property];
            obj.restrictions_show = true;
          }
        });

        getElesArrData.push(obj);
        getData.elements = getElesArrData;
      }
      newGetdata.push(getData);
    }
    return newGetdata;
  };
  const [pageCount, setpageCount] = useState(0);
  useEffect(() => {
    if (pageCount !== 0) {
      update_List({ oprType: 'change' });
    } else {
      setpageCount(pageCount + 1);
    }
  }, [page, rowsPerPage]);

  const update_List = (dataType) => {
    setDbdata([]);
    const type = dataType.oprType;
    let data = {
      page_number: page + 1,
      page_size: rowsPerPage,
      name: searchWord,
    };
    switch (type) {
      case 'add':
        setPage(0);
        data.page_number = 1;
        setSearchWord('');
        break;
      case 'edit':
        break;
      case 'delete':
        break;
      case 'change':
        break;
      case 'search':
        setPage(0);
        data.page_number = 1;
        break;
      default:
        break;
    }
    setLoading(true);
    tariffListFunction(JSON.stringify(data), setErrorMessage, setError).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setDbdata(formatDataFunc(promiseResult.data.data));
        setAmount_data(promiseResult.data.total_record);
        setSuccess(true);
        setUnauthorized(false);
      } else {
        setErrorMessage(promiseResult.message);
        setError(true);
        setDbdata([]);
        setAmount_data(0);
        setPage(0);
        setUnauthorized(false);
        if (promiseResult.message == 'Network Error') {
          setUnauthorized(true);
        }
      }
      setLoading(false);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
  };

  const Pagination = () => {
    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 50, { label: t('All'), value: -1 }]}
        count={Number(Amount_data)}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={t('rows per page')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
        sx={{
          fontFamily: 'Arial',
          fontWeight: 400,
          fontSize: '14px',
          border: 'none',
        }}
      />
    );
  };

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
          alignContent: 'center',
        }}
      >
        <p
          style={{
            lineHeight: '22px',
            heght: '35px',
            padding: '22px 16px',
            fontSize: '14px',
            fontWeight: 700,
            fontFamily: 'Arial',
          }}
        >
          {t('Total Profile(s)')}: {Amount_data}
        </p>
        <Pagination />
      </div>
      {unauthorized && <LoginAgain />}
      <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>

      {loading && (
        <div style={{ marginTop: '-15px', width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      <Table
        setPageFunc={setPageFunc}
        setChangePage={setChangePage}
        setPropsData={setPropsData}
        transdata={dbdata}
        updateList={update_List}
        setSuccess={(childData) => setSuccess(childData)}
        setError={(childData) => setError(childData)}
        setErrorMessage={(childData) => setErrorMessage(childData)}
      ></Table>
      <Pagination />
    </Box>
  );
};

export default forwardRef(CollapsibleTable);
