import React from 'react';
import { Box, Dialog, Button, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
// component
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function VersionErrorDialog({ setOpen }) {
  const { t } = useTranslation();
  const open = true;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="version-error-dialog-title"
        aria-describedby="version-error-dialog-description"
      >
        <DialogTitle id="version-error-dialog-title">{t('Unauthorized access')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="version-error-dialog-description">{t('SC_essential_message')}.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('close')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
