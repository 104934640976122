import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Step,
  Stack,
  Dialog,
  Button,
  Tooltip,
  Stepper,
  StepLabel,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
// components
import SelectRestartPage from './SelectUnlockPage';
import Confirmation from './Confirmation';
import Icon from '../../../../../../../../components/customIcon/icon-unlock-16.svg';
import { buttonR, buttonL } from '../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../components/customIcon/Close.svg';
import DisabledIcon from '../../../../../../../../components/customIcon/icon-unlock-16-disabled.svg';
import { SubmitUnlock } from '../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectUnlock(props) {
  const { t } = useTranslation();
  const { evseName, numConnector, evseInfo } = props;
  const [error, setError] = useState(false);
  const [loading_pre, setLoading_pre] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState({
    connectorName: 'Connector 1',
    connectorId: 1,
  });
  const cp_id = evseName;
  const connector_id = formData.connectorId;
  const steps = [
    { value: 'Select Unlock Page', label: t('Select Unlock Page') },
    { value: 'Confirmation', label: t('Confirmation') },
  ];
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    const values = Array.from({ length: numConnector }, (_, index) => {
      return {
        name: `Connector ${index + 1}`,
        value: index + 1,
      };
    });
    setRows(values);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPage(0);
  };

  const onSubmit = () => {
    setLoading(true);
    setLoading_pre(true);
    SubmitUnlock(cp_id, connector_id, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        if (promiseResult.data.status === 'NotSupported') {
          props.setErrorMessage(t('EVSE Does Not Support Unlock'));
          props.setError(true);
          handleClose();
        } else {
          props.setSuccess(true);
          handleClose();
        }
      } else {
        props.setErrorMessage(promiseResult.response?.data?.message);
        props.setError(true);
      }
      setLoading(false);
      setLoading_pre(false);
    });
  };

  return (
    <Box>
      <Tooltip title={t('Unlock')}>
        {/* <IconButton onClick={handleClickOpen} sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton> */}
        {evseInfo && Object.keys(evseInfo).length !== 0 && evseInfo.connector[0]?.status === 'Unknown' ? (
          <IconButton onClick={handleClickOpen} disabled sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
            <Box component="img" src={DisabledIcon} sx={{ width: '100%', height: '100%' }} />
          </IconButton>
        ) : (
          <IconButton onClick={handleClickOpen} sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
            <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
          </IconButton>
        )}
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="UnlockDialog" fullWidth maxWidth="md">
        {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="Unlock">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Unlock')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
          <Stepper activeStep={page} sx={{ pt: 1 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label.value} {...stepProps}>
                  <StepLabel {...labelProps}>{label.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent>
          <PageDisplay page={page} formData={formData} setFormData={setFormData} evseName={evseName} rows={rows} />
          <br />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button
              sx={buttonL}
              variant="outlined"
              disabled={loading}
              onClick={() => {
                if (page === 0) {
                  handleClose();
                } else {
                  setPage((currPage) => currPage - 1);
                }
              }}
            >
              {page === 0 ? t('Cancel') : t('Back')}
            </Button>
            {page === steps.length - 1 ? (
              <Button onClick={onSubmit} disabled={loading} sx={buttonR}>
                {t('Submit')}
              </Button>
            ) : (
              <Button
                sx={buttonR}
                onClick={() => {
                  setPage((currPage) => currPage + 1);
                }}
              >
                {t('Next')}
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

const PageDisplay = ({ page, formData, setFormData, evseName, rows }) => {
  if (page === 0) {
    return <SelectRestartPage formData={formData} setFormData={setFormData} evseName={evseName} rows={rows} />;
  }
  return <Confirmation formData={formData} setFormData={setFormData} evseName={evseName} rows={rows} />;
};
