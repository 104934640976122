import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
// mui
import {
  Box,
  Stack,
  Dialog,
  Button,
  MenuItem,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Table,
  TableHead,
  TableBody,
  Radio,
  TableRow,
  TableCell,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
// components
import { buttonR, buttonL } from '../../../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../../../components/customIcon/Close.svg';
import { SubmitUpload, fetchFILE, SubmitFirmwareDelete } from '../../../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import { s3Config } from '../../../../../../../../../../api/s3Config';
import Icon from '../../../../../../../../../../components/customIcon/icon-delete-turquoise-16.svg';
// ----------------------------------------------------------------------

export default function UploadFile(props) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [list, setList] = useState();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('Choose firmware to be uploaded');
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [loading_pre, setLoading_pre] = useState(false);
  const [base64Content, setBase64Content] = useState('');
  // const column = [
  //   {
  //     id: 'title',
  //     label: t('Uploaded firmware files'),
  //     align: 'left',
  //     width: 120,
  //   },
  //   {
  //     id: 'action',
  //     label: t('Delete'),
  //     align: 'right',
  //     width: 30,
  //   },
  // ];
  // const getListFunc = () => {
  //   setLoading_pre(true);
  //   fetchFILE(setError, setErrorMessage).then((promiseResult) => {
  //     if (!promiseResult.message) {
  //       setList(promiseResult);
  //       props.setSuccess(true);
  //     }
  //     setLoading_pre(false);
  //   });
  // };
  // useEffect(() => {
  //   getListFunc();
  // }, []);

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName('Choose firmware to be uploaded');
    setFile(null);
    props.CloseMain();
  };
  // const firmwareDelete = (name) => {
  //   SubmitFirmwareDelete({ charge_point_id: props.evseInfo.cp_id, filename: name }, setError, setErrorMessage).then(
  //     (promiseResult) => {
  //       if (promiseResult.category === 'Success') {
  //         props.setSuccess(true);
  //       } else {
  //         props.setErrorMessage(promiseResult.message);
  //         props.setError(true);
  //       }
  //       setLoading(false);
  //       setLoading_pre(false);
  //     }
  //   );
  // };
  const onSubmit = () => {
    if (!file) {
      props.setErrorMessage(t('Upload Firmware for EVSE'));
      props.setError(true);
      return;
    }
    setLoading(true);
    setLoading_pre(true);
    uploadFile();
    // base64Content
    SubmitUpload(base64Content, props.evseInfo.cp_id, name, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.CloseMain();
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
      setLoading_pre(false);
    });
  };

  const uploadFile = async () => {
    AWS.config.update({
      accessKeyId: s3Config.AWS_ACCESS_KEY,
      secretAccessKey: s3Config.AWS_SECRET_KEY,
    });
    const s3 = new AWS.S3({
      params: { Bucket: s3Config.S3_BUCKET },
      region: s3Config.REGION,
    });
    const params = {
      Bucket: s3Config.S3_BUCKET,
      Key: sessionStorage.getItem('tenant_id') + '/firmware/' + file.name,
      Body: file,
    };
    var upload = s3
      .putObject(params)
      .on('httpUploadProgress', (evt) => {
        // File uploading progress
        console.log('Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%');
      })
      .promise();

    await upload
      .then((res) => {
        // console.log(res);
        props.setSuccess(true);
        props.CloseMain();
        handleClose();
        // getListFunc();
        setName('Upload Firmware for EVSE');
        setFile(null);
        setLoading(false);
        setLoading_pre(false);
      })
      .catch((err) => {
        props.setErrorMessage(
          promiseResult.response?.data?.message ? promiseResult.response?.data?.message : promiseResult.message
        );
        props.setError(true);
        setLoading(false);
        setLoading_pre(false);
      });
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>{t('Upload File')}</MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="UploadFirmwareDialog" fullWidth maxWidth="sm">
        {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="UploadFirmware">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Upload Firmware for EVSE')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="space-between" marginBottom="15px">
            {/* <Typography>{t('Choose firmware to be uploaded')}</Typography> */}
            <Button variant="contained" component="label" size="small">
              {t(name)}
              <input
                hidden
                // accept="image/*"
                // multiple
                type="file"
                onChange={(e) => {
                  const dataFile = e.target.files[0];
                  setFile(dataFile);
                  if (dataFile) {
                    //file to base64
                    setName(dataFile.name);
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const base64 = e.target.result;
                      setBase64Content(base64);
                    };
                    reader.readAsDataURL(dataFile);
                  }
                }}
              />
            </Button>
          </Box>

          {/* <Table aria-labelledby="tableTitles" size="small">
            <TableHead>
              <TableRow>
                {column.map((headCell) => (
                  <TableCell key={headCell.id} align={headCell.align} padding="normal" sx={{ width: headCell.width }}>
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.map((data) => (
                <TableRow hover key={data}>
                  <TableCell align="left">{data}</TableCell>

                  <TableCell align="right" sx={{}}>
                    <Tooltip title={t('Delete')}>
                      <IconButton
                        onClick={() => firmwareDelete(data)}
                        sx={{ width: '35px', height: '35px', zIndex: 'modal' }}
                      >
                        <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table> */}

          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mt: 1 }}>
            <Button variant="outlined" disabled={loading} onClick={() => handleClose()} sx={buttonL}>
              {t('Close')}
            </Button>
            <Button onClick={onSubmit} disabled={loading} sx={buttonR}>
              {t('Submit')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
