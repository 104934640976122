import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
// mui
import { Container, Box, Snackbar, Alert, useTheme, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// components
import Page from '../../../../components/Page';
import useResponsive from '../../../../hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import Incomplete from './incomplete';
// ----------------------------------------------------------------------

export default function PromoTable({
  setTableValue,
  setChangePage,
  changePage,
  setSearchWord,
  searchWord,
  setPropsData,
  refs,
}) {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('1');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const childRef = useRef();
  const childRef_com = useRef();
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };
  useImperativeHandle(refs, () => ({
    updateList,
    updateList_com,
  }));

  const updateList = (oprType) => {
    if (tabValue !== '1') return;
    childRef.current.update_List(oprType);
  };
  const updateList_com = (oprType) => {
    if (tabValue !== '2') return;
    childRef_com.current.update_List(oprType);
  };

  useEffect(() => {
    updateList({ oprType: 'search' });
  }, []);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue == '2') {
      setTimeout(() => {
        childRef_com.current.update_List({ oprType: 'search' });
      }, 200);
    } else {
      setTimeout(() => {
        childRef.current.update_List({ oprType: 'search' });
      }, 200);
    }
  };
  return (
    <Page>
      <Container maxWidth="false" sx={{ position: 'relative', padding: '0 !important' }}>
        <Snackbar open={success} autoHideDuration={4000} onClose={handleCloseSnackbar}>
          <Alert severity="success">{t('Success')}!</Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseSnackbar}>
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>

        {/* change the tabs */}
        <Box>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: '0' }}>
              <TabList onChange={handleChange} aria-label={t('tabs')} textColor="secondary" indicatorColor="secondary">
                <Tab
                  label={t('Incomplete')}
                  value="1"
                  sx={{
                    fontFamily: 'Arial',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '22px',
                  }}
                />
                <Tab
                  label={t('Complete')}
                  value="2"
                  sx={{
                    fontFamily: 'Arial',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '22px',
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: '15px 0px' }}>
              <Incomplete
                ref={childRef}
                setPropsData={setPropsData}
                tableType="Incompleted"
                setSearchWord={setSearchWord}
                searchWord={searchWord}
                setChangePage={setChangePage}
                setTableValue={setTableValue}
                tabValue={tabValue}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: '15px 0px' }}>
              <Incomplete
                ref={childRef_com}
                tableType="Completed"
                setSearchWord={setSearchWord}
                searchWord={searchWord}
                setChangePage={setChangePage}
                setPropsData={setPropsData}
                setTableValue={setTableValue}
                tabValue={tabValue}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </Page>
  );
}
