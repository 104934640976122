import React, { useEffect, useState } from 'react';
// mui
import { Stack, Box, DialogContent, DialogTitle, IconButton, Dialog, Button, LinearProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import Time from './Time';
import Parking_time from './Parking_time';
import Energy from './Energy';
import Flat from './Flat';
import Reservation from './Reservation';
import Limit_min from './limit_min';
import Limit_max from './limit_max';
import CloseIcon from '../../../../../components/customIcon/Close.svg';
import { buttonR, buttonL } from '../../../../../components/button/buttonStyle';
// ----------------------------------------------------------------------

export default function Confirmation({
  updateList,
  onSubmit,
  defaultData,
  getVerifyData,
  setLoading_pro,
  loading_pro,
  setOpen,
  open,
}) {
  const [allData, setAllData] = useState({});
  const [groups, setGroups] = useState({});
  const [groupsArr, setGroupsArr] = useState([]);
  const [groups_reservation, setGroups_reservation] = useState({});
  const [groupsArr_reservation, setGroupsArr_reservation] = useState([]);
  const [groups_expires, setGroups_expires] = useState({});
  const [groupsArr_expires, setGroupsArr_expires] = useState([]);
  const [haslimit, sethaslimit] = useState(false);
  const [limitData, setlimitData] = useState({});
  const { t } = useTranslation();
  // const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    // VerifyData
    getVerifyData() ? null : setOpen(true);
  };

  const handleClose = (e, reason) => {
    if (reason == 'backdropClick') {
      return;
    }
    setOpen(false);
  };
  // save
  const handleConfirm = () => {
    // setOpen(false);
    // setChangePage(true);
    // updateList({ oprType: 'add' });
    onSubmit();
    // console.log(defaultData);
  };

  const processDataByType = (data, typeKey) => {
    const typeGroups = {};

    data.forEach((item) => {
      if (item.price_component) {
        const type = item.price_component[0].type;
        if (!typeGroups[type]) {
          typeGroups[type] = [];
        }
        typeGroups[type].push(item);
      }
    });

    const setGroupsFunc = `setGroups_${typeKey}`;
    const setGroupsArrFunc = `setGroupsArr_${typeKey}`;

    return {
      [setGroupsFunc]: typeGroups,
      [setGroupsArrFunc]: Object.keys(typeGroups),
    };
  };

  useEffect(() => {
    if (defaultData?.elements) {
      setAllData(defaultData);
      // 'elements_platForm'   'elements_charging'  'elements_energy'
      const transData_element = defaultData.elements.filter(
        (item) =>
          item.eleType === 'elements_platForm' ||
          item.eleType === 'elements_charging' ||
          item.eleType === 'elements_energy'
      );
      const transData_reservation = defaultData.elements.filter(
        (item) => item.eleType === 'Reservation_time' || item.eleType === 'Reservation_Expiry_flat'
      );
      const transData_expires = defaultData.elements.filter(
        (item) => item.eleType === 'Expiry_flat' || item.eleType === 'Expiry_time'
      );
      const elementData = processDataByType(transData_element, 'element');
      const reservationData = processDataByType(transData_reservation, 'reservation');
      const expiresData = processDataByType(transData_expires, 'expires');

      setGroups(elementData.setGroups_element);
      setGroupsArr(elementData.setGroupsArr_element);

      setGroups_reservation(reservationData.setGroups_reservation);
      setGroupsArr_reservation(reservationData.setGroupsArr_reservation);

      setGroups_expires(expiresData.setGroups_expires);
      setGroupsArr_expires(expiresData.setGroupsArr_expires);

      setlimitData({ max_price: defaultData.max_price, min_price: defaultData.min_price });
    }
  }, [defaultData]);
  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          marginLeft: '10px',
          width: '160px',
          height: '32px',
          background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
          boxShadow: 'none',
          color: 'primary.contrastText',
          fontFamily: 'Arial',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '22px',
          textAlign: 'center',
        }}
      >
        {t('Submit')}
      </Button>

      <Dialog open={open} onClose={handleClose} aria-labelledby="AdminDeleteDialog" sx={{ zIndex: 2000 }}>
        {loading_pro && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="Information">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Confirm to commit changes')}?
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <div>
            {/* {limitData.max_price &&
            limitData.max_price.excl_vat !== '' &&
            Number(limitData.max_price.excl_vat) !== 0 &&
            limitData.max_price.excl_vat !== null ? (
              <Limit_max defaultData={limitData} allData={allData}></Limit_max>
            ) : (
              ''
            )}
            {limitData.min_price &&
            limitData.min_price.excl_vat !== '' &&
            Number(limitData.min_price.excl_vat) !== 0 &&
            limitData.min_price.excl_vat !== null ? (
              <Limit_min defaultData={limitData} allData={allData}></Limit_min>
            ) : (
              ''
            )}

            {groupsArr.map((ele, ind) => {
              switch (ele) {
                case 'FLAT':
                  return <Flat key={ind} defaultData={groups[ele]} allData={allData} />;
                case 'TIME':
                  return <Time key={ind} defaultData={groups[ele]} allData={allData} />;
                case 'PARKING_TIME':
                  return <Parking_time key={ind} defaultData={groups[ele]} allData={allData} />;
                case 'ENERGY':
                  return <Energy key={ind} defaultData={groups[ele]} allData={allData} />;
                default:
                  return null;
              }
            })} */}
            {/* {groupsArr_reservation.length > 0 ? (
              <Reservation type="reservation" defaultData={groups_reservation} allData={allData}></Reservation>
            ) : (
              ''
            )}
            {groupsArr_expires.length > 0 ? (
              <Reservation type="expires" defaultData={groups_expires} allData={allData}></Reservation>
            ) : (
              ''
            )} */}

            {/* {groupsArr_reservation.map((ele, ind) => {
        switch (ele) {
          case 'FLAT':
            return <Flat key={ind} defaultData={groups_reservation[ele]} allData={allData} />;
          case 'TIME':
            return <Time key={ind} defaultData={groups_reservation[ele]} allData={allData} />;
          default:
            return null;
        }
      })} */}
            {/* {groupsArr_expires.map((ele, ind) => {
        switch (ele) {
          case 'FLAT':
            return <Flat key={ind} defaultData={groups_expires[ele]} allData={allData} />;
          case 'TIME':
            return <Time key={ind} defaultData={groups_expires[ele]} allData={allData} />;
          default:
            return null;
        }
      })} */}
          </div>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <LoadingButton variant="outlined" loading={loading_pro} onClick={handleClose} sx={buttonL}>
              {t('Cancel')}
            </LoadingButton>
            <LoadingButton onClick={handleConfirm} loading={loading_pro} sx={buttonR}>
              {t('Confirm')}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
