import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../../components/Iconify';
import { FormProvider } from '../../../../components/hook-form';
// Cognito
import UserPool from '../UserPool';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

let socket1;

export default function SignUpForm({ setPage, setUsername }) {
  const [name, setName] = useState('');
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [tenantID, setTenantID] = useState('');
  const [birthDate, setBirthDate] = useState(new Date());
  const [gender, setGender] = useState('');
  const [role, setRole] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setErrMsg('');
  }, [username, password]);

  const onSubmit = (event) => {
    event.preventDefault();
    // debugger;
    UserPool.signUp(
      username,
      password,
      [
        {
          Name: 'name',
          Value: name,
        },
        {
          Name: 'custom:role',
          Value: role,
        },
        // {
        //   Name: 'phone_number',
        //   Value: phoneNumber,
        // },
        {
          Name: 'custom:tenant_id',
          Value: tenantID,
        },
        // {
        //   Name: 'birthdate',
        //   Value: birthDate,
        // },
        // {
        //   Name: 'gender',
        //   Value: gender,
        // },
        // {
        //   Name: 'updated_at',
        //   Value: new Date(),
        // },
      ],
      null,
      (err, data) => {
        if (err) {
          console.log(err);
          setErrMsg(`${err}`);
        }
        if (data !== null) {
          console.log(data);
          setPage(true);
          setUsername(username);
        }
      }
    );
  };

  return (
    <FormProvider>
      <Stack spacing={3}>
        <Typography variant="subtitle1" sx={{ pl: 1, color: 'error.main' }}>
          {errMsg}
        </Typography>
        <TextField name="name" label={t('Name')} onChange={(e) => setName(e.target.value)} value={name} />
        <TextField
          name="email"
          label={t('Email Address')}
          onChange={(e) => setEmail(e.target.value)}
          value={username}
        />
        <TextField
          name="password"
          label={t('Password')}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="roles-select-label">{t('Roles')}</InputLabel>
          <Select
            labelId="roles-select-label"
            id="roles"
            value={role}
            label={t('Roles')}
            onChange={(event) => setRole(event.target.value)}
          >
            <MenuItem value={'SuperAdmin'}>{t('Super Admin')}</MenuItem>
            <MenuItem value={'Admin'}>{t('Admin')}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          name="tenantId"
          label={t('Tenant ID')}
          onChange={(e) => setTenantID(e.target.value)}
          value={tenantID}
        />
        {/* <TextField
          name="phoneNumber"
          label={t('Phone number')}
          onChange={(e) => setPhoneNumber(e.target.value)}
          value={phoneNumber}
        /> */}
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t('Birth Date')}
            views={['year', 'month', 'day']}
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider> */}
        {/* <FormControl fullWidth>
          <InputLabel id="gender-select-label">{t('Gender')}</InputLabel>
          <Select
            labelId="gender-select-label"
            id="gender"
            value={gender}
            label={t('Gender')}
            onChange={(event) => setGender(event.target.value)}
          >
            <MenuItem value={'Male'}>{t('Male')}</MenuItem>
            <MenuItem value={'Female'}>{t('Female')}</MenuItem>
          </Select>
        </FormControl> */}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" component={RouterLink} to="/">
          {t('Back')}
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        // type="submit"
        variant="contained"
        onClick={onSubmit}
        sx={{ background: 'linear-gradient(to left, #66D0D5, #00B0B9)' }}
      >
        {t('Sign Up')}
      </LoadingButton>
    </FormProvider>
  );
}
