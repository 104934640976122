import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import { Button, Box, Stack, IconButton, Typography, useTheme } from '@mui/material';
import Elements from './Elements';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useTranslation } from 'react-i18next';

export default function Index({
  type,
  cRef,
  defaultData,
  tenantConfiguration,
  removeId,
  setRemoveId,
  currency,
  setOpenAlert,
  setOpenAlertMsg,
}) {
  const childRefs = useRef([]);
  const theme = useTheme();
  const componentRefs = useRef([]);
  // const [componentCount, setComponentCount] = useState(1);
  const [components, setComponents] = useState([]);
  const [partTitle, setPartTitle] = useState();
  const { t } = useTranslation();
  const [addCount, setaddCount] = useState(1);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(true);
  const defaultDefaultData = {
    price_component: [
      {
        step_size: '1',
        type: type == 'Reservation_Expiry_flat' || type == 'Expiry_flat' ? 'FLAT' : 'TIME',
        vat: '0.00',
        price: '',
        priority_level: 1,
      },
    ],
    restrictions: {
      reservation:
        type == 'Reservation_time' || type == 'Reservation_Expiry_flat' ? 'RESERVATION' : 'RESERVATION_EXPIRES',
    },
    restrictions_show: false,
    eleType: type,
  };
  useEffect(() => {
    switch (type) {
      case 'Reservation_Expiry_flat':
        setPartTitle('Platform Fee For Reservation');
        break;
      case 'Expiry_flat':
        setPartTitle('Platform Fee For Expiry');
        break;
      case 'Reservation_time':
        setPartTitle('Time_type_reservation');
        break;
      case 'Expiry_time':
        setPartTitle('Time_type_expires');
        break;
      default:
        break;
    }
  }, [type]);
  useImperativeHandle(cRef, () => ({
    getComponentData,
  }));
  useEffect(() => {
    if (defaultData.elements) {
      const transData = defaultData.elements.filter(
        (item) => item.eleType === 'Expiry_flat' && item.price_component[0].type == 'FLAT'
      );

      if (transData.length != 0) {
        const formDataArray = [];
        const componentsArray = [];

        transData.forEach((ele, index) => {
          formDataArray.push(ele);
          componentsArray.push(index + 1);
        });

        // Update formData and components arrays together
        setFormData(formDataArray);
        setComponents(componentsArray);
        // setComponentCount(componentsArray[componentsArray.length - 1]);
      }
    }
  }, [defaultData.elements]);

  const handleAddForm = () => {
    const getData = getComponentData();
    setOpen(true);
    if (getData !== false) {
      setaddCount(addCount + 1);
      // setEnergyObj(false);
      setComponents([...components, addCount * 10]);
      // if (components.length == 0) {
      //   setFormData([
      //     ...getData,
      //     {
      //       ...defaultDefaultData,
      //       price_component: [{ ...defaultDefaultData.price_component[0], priority_level: 99999 }],
      //     },
      //   ]);
      // } else {
      setFormData([...getData, defaultDefaultData]);
      // }
    }
  };

  const handleRemoveForm = (index, id) => {
    if (components.length > 0) {
      const getData = getComponentData();

      if (getData !== false) {
        id ? setRemoveId([...removeId, id]) : null;
        setComponents(components.filter((_, i) => i !== index));
        componentRefs.current = componentRefs.current.filter((ref, i) => i !== index);

        const updatedFormData = getData.filter((_, i) => i !== index);
        setFormData(updatedFormData);
      }
    }
  };
  const getComponentData = () => {
    let formDataArray = [];
    let hasError = false;
    componentRefs.current.forEach((ref) => {
      if (!ref.current) return;
      const childData = ref.current.getComponentData();
      if (childData === false) {
        hasError = true;
      } else {
        formDataArray.push(childData);
      }
    });

    if (hasError) {
      return false;
    } else {
      return formDataArray;
    }
  };
  const handleReorder = () => {
    if (components.length > 1) {
      const getData = getComponentData();
      setComponents([]);
      setFormData({});
      setTimeout(() => {
        if (getData !== false) {
          const sortedArray = getData.sort(
            (a, b) => a.price_component[0].priority_level - b.price_component[0].priority_level
          );
          const formDataArray = [];
          const componentsArray = [];
          sortedArray.forEach((ele, index) => {
            formDataArray.push(ele);
            componentsArray.push(index + 1);
          });
          setFormData(formDataArray);
          setComponents(componentsArray);
        }
      }, 50);
    }
  };
  return (
    <div>
      <Box
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ background: theme.palette.popPaper.paper2, marginBottom: '10px' }}
      >
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <IconButton aria-label={t('expand row')} size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <ArrowDropDownIcon sx={{ color: '#00ACBB', fontSize: 30 }}></ArrowDropDownIcon>
            ) : (
              // <Iconify icon="akar-icons:chevron-down" width={20} height={20} />
              <ArrowRightIcon sx={{ color: '#00ACBB', fontSize: 30 }}></ArrowRightIcon>
              // <Iconify icon="akar-icons:chevron-right" width={20} height={20} />
            )}
          </IconButton>
          <Typography
            sx={{
              fontFamily: 'Arial',
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '22px',
              color: theme.palette.title.main,
            }}
          >
            {t(partTitle)} ({components.length})
            {/* <span style={{ fontSize: '14px', color: theme.palette.title.main }}> 
            </span> */}
          </Typography>
        </Stack>
        <Box alignItems="center" display="flex">
          <Stack
            style={{
              marginRight: '10px',
              cursor: 'pointer',
              color: '#00ACBB',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onClick={handleAddForm}
            title={t('Add')}
          >
            <AddCircleOutlineIcon sx={{ marginRight: '5px', width: '20px', height: '20px' }} />
            <span>{t('New Priority')}</span>
          </Stack>
          <Stack
            onClick={handleReorder}
            title={t('Reorder')}
            style={{
              marginRight: '10px',
              cursor: 'pointer',
              color: '#00ACBB',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <RefreshIcon sx={{ marginRight: '5px', width: '20px', height: '20px' }} />
            <span>{t('Reorder Priority')}</span>
          </Stack>
        </Box>
      </Box>

      <Box sx={{ padding: '0 10px', display: open ? 'block' : 'none' }}>
        <Stack style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {components.map((index, ind) => {
            const ref = React.createRef();
            componentRefs.current[index] = ref;
            return (
              <Elements
                type={type}
                key={ind}
                index={ind}
                handleRemoveForm={handleRemoveForm}
                defaultData={formData[ind]}
                componentIndex={ind}
                cRef={ref}
                currency={currency}
                setOpenAlert={setOpenAlert}
                setOpenAlertMsg={setOpenAlertMsg}
                tenantConfiguration={tenantConfiguration}
              />
            );
          })}
        </Stack>
      </Box>
    </div>
  );
}
