//yarn add react-i18next i18next
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './constants/languagePacks/en.json';
import zh_cn from './constants/languagePacks/zh_cn.json';
import zh_tw from './constants/languagePacks/zh_tw.json';
import indonesian from './constants/languagePacks/Ind.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: english,
      // {
      //   title: "Multi-language app",
      //   label: "Select another language!",
      //   about: "About",
      //   home: "Home",
      // },
    },
    'zh-CN': {
      translation: zh_cn,
      // {
      //   title: "多语言应用",
      //   label: "选择另一种语言！",
      //   about: "关于",
      //   home: "主页",
      // },
    },
    'zh-TW': {
      translation: zh_tw,
    },
    Indonesian: {
      translation: indonesian,
    },
  },
});

export default i18n;
