import React, { useState, useEffect } from 'react';
// mui
import { Box, Card, Stack, Collapse, IconButton, Typography, useTheme } from '@mui/material';
// components
import ChargingStation from './CS/index';
import StationChargingLocationAddDialog from './addbutton/index';
import StationChargingLocationEditDialog from './editbutton/index';
import LoginAgain from '../../../../../components/errors/LoginAgain';
import down from '../../../../../components/customIcon/icon-expandedarrow-24.svg';
import right from '../../../../../components/customIcon/icon-collapsedarrow-24.svg';
import { fetchChargerStation } from '../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function LocationTable(props) {
  const theme = useTheme();
  const [dbdata, setDbdata] = useState([]);
  const [transTraiff, setTransTraiff] = useState([]);
  const [update, setUpdate] = useState();
  const { t } = useTranslation();
  const [updated, setUpdated] = useState();
  const [unauthorized, setUnauthorized] = useState(false);
  const [Close, setClose] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);
  const delayUpdate = (childData) => {
    setTimeout(() => {
      setUpdate(childData);
    }, 2000);
  };

  useEffect(() => {
    props.setLoading(true);
    setDbdata([]);
    fetchChargerStation(setError, setErrorMessage, setUnauthorized).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setDbdata(promiseResult.data.chargerstation_data);
        setUrl(promiseResult.data.url);
        props.setSuccess(true);
        props.setLoading(false);
        setUnauthorized(false);
      } else {
        setUnauthorized(false);
        props.setError(true);
        props.setLoading(false);
        props.setErrorMessage(promiseResult.message);
      }
    });
    setUpdated(Math.random());
    setClose(Math.random());
  }, [props.LocationUpdate, update]);

  return (
    <Box>
      {unauthorized && <LoginAgain />}
      {dbdata.map((location) => (
        <LocationRow
          setTableValue={props.setTableValue}
          tariffData={props.tariffData}
          key={location.id}
          url={url}
          location={location}
          updated={updated}
          setSuccess={props.setSuccess}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={props.setError}
          Update={(childData) => setUpdate(childData)}
          delayUpdate={(childData) => delayUpdate(childData)}
          Close={Close}
        />
      ))}
    </Box>
  );
}

function LocationRow(props) {
  const theme = useTheme();
  const { location, updated, Close, url, tariffData } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setOpen(false);
  }, [Close]);

  const handleButton = () => {
    if (sessionStorage.getItem('role') === 'SuperAdmin') {
      return (
        <StationChargingLocationAddDialog
          location={location.name}
          AddCSUpdate={(childData) => props.delayUpdate(childData)}
          Update={(childData) => props.Update(childData)}
          url={url}
          setSuccess={props.setSuccess}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={props.setError}
        />
      );
    }
    return;
  };

  return (
    <Card
      sx={{
        boxShadow: 0,
        border: theme.palette.CollapseRow.border,
        background: theme.palette.background.pagerWhite,
        mb: 1,
        p: 1,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <IconButton aria-label={t('expand row')} size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <Box component="img" src={down} sx={{ width: '30px', height: '30px' }} />
            ) : (
              <Box component="img" src={right} sx={{ width: '30px', height: '30px' }} />
            )}
          </IconButton>
          <Typography
            title={location.name}
            sx={{
              fontFamily: 'Arial',
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '22px',
              color: 'texts.main',
            }}
          >
            {location.name}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          {handleButton()}
          <StationChargingLocationEditDialog
            locationName={location.name}
            Update={(childData) => props.Update(childData)}
            setSuccess={props.setSuccess}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={props.setError}
          />
        </Stack>
      </Stack>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <ChargingStation
            setTableValue={props.setTableValue}
            tariffData={tariffData}
            locationName={location.name}
            locationData={location}
            dataforstation={location.station}
            url={url}
            Close={(childData) => setOpen(childData)}
            setSuccess={props.setSuccess}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={props.setError}
            updated={updated}
            delayUpdate={(childData) => props.delayUpdate(childData)}
            Update={(childData) => props.Update(childData)}
          />
        </Box>
      </Collapse>
    </Card>
  );
}
