import React from 'react';
// mui
import { Stack, Radio, Typography, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectRestartPage({ formData, setReset_type, reset_type }) {
  const { t } = useTranslation();

  const handleChange = (event) => {
    setReset_type(event.target.value);
  };
  return (
    <Stack direction="column" spacing={3}>
      <Typography variant="body1">{t('checked_Restart', { checked: formData.checked })}</Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={reset_type}
          onChange={handleChange}
        >
          <FormControlLabel value="Soft" control={<Radio />} label="Soft" />
          <FormControlLabel value="Hard" control={<Radio />} label="Hard" />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}
