import React, { useContext, useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../App';

export default function ThemeMode() {
  const { t } = useTranslation();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  const [localTheme, setLocalTheme] = useState();

  const ModeChange = () => {
    toggleTheme();
  };

  return (
    <>
      <Tooltip title={localStorage.getItem('darkTheme') == 'false' ? t('Light mode') : t('Dark mode')}>
        <IconButton onClick={ModeChange} sx={{ width: 50, height: 50, zIndex: 'modal' }}>
          {darkTheme ? (
            <LightModeIcon style={{ color: '#00B0B9', background: 'black', fontSize: '30px', borderRadius: '50%' }} />
          ) : (
            <DarkModeIcon style={{ color: '#00B0B9', background: '#e1e1e1', fontSize: '30px', borderRadius: '50%' }} />
          )}
        </IconButton>
      </Tooltip>
    </>
  );
}
