import React, { useState, useRef } from 'react';
// mui
import { Box } from '@mui/material';
import TariffTable from './table/index';
import TariffProfile from './tariffProfile/index';
import TariffSummary from './summary_tariff/index';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Tariff() {
  const { t } = useTranslation();
  const childRef = useRef();
  const [changePage, setChangePage] = useState('table');
  const [pageFunc, setPageFunc] = useState('add');
  const [propsData, setPropsData] = useState({});
  const [loading_pro, setLoading_pro] = useState(false);
  const [open, setOpen] = useState(false);

  const update_List = (oprType) => {
    // Check if the ref is assigned and the method exists before calling it
    if (childRef.current?.updateList) {
      childRef.current.updateList(oprType); // Call the update_list method on the child component
    }
  };

  return (
    <Box>
      <TariffTable
        setChangePage={setChangePage}
        changePage={changePage}
        setPageFunc={setPageFunc}
        setPropsData={setPropsData}
        ref={childRef}
      ></TariffTable>
      {(changePage == 'edit' || changePage == 'add') && (
        <TariffProfile
          setChangePage={setChangePage}
          changePage={changePage}
          updateList={update_List}
          pageFunc={pageFunc}
          propsData={propsData}
          setPropsData={setPropsData}
          setLoading_pro={setLoading_pro}
          loading_pro={loading_pro}
          setOpen={setOpen}
          open={open}
        ></TariffProfile>
      )}
      {changePage == 'summary' && (
        <TariffSummary
          setChangePage={setChangePage}
          changePage={changePage}
          propsData={propsData}
          setPropsData={setPropsData}
        />
      )}
    </Box>
  );
}
