import React, { useState } from 'react';
// mui
import { Container, Typography } from '@mui/material';
// sections

// components
import Page from 'src/components/Page';
import Test from './googlemaps/index';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function ChargerLocation(props) {
  const { t } = useTranslation();
  const { coordinates, setTableValue } = props;
  // console.log(coordinates);

  return (
    <Page title={t('Charger Location')}>
      <Container maxWidth="false">
        <Test coordinates={coordinates} setTableValue={setTableValue} />
      </Container>
    </Page>
  );
}
