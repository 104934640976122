import React, { useState, useEffect, useRef } from 'react';
// mui
import {
  Container,
  Typography,
  Stack,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Box,
  FormControl,
  Snackbar,
  Alert,
} from '@mui/material';
// components
import Page from '../../../components/Page';
import OcpiTable from './ocpiTable/index';
import AddOcpi from './addOcpi';
// import { searchTariffFunction } from '../../../api/AllAPI';
import Icon from '../../../components/customIcon/icon-search-16.svg';
import { useTranslation } from 'react-i18next';
import { OCPIVERSION } from '../../../api/AllAPI';
import CompanyInfo from './companyInfo';
// ----------------------------------------------------------------------

export default function OcpiFunc() {
  const { t } = useTranslation();
  const childRef = useRef();
  const [searchWord, setSearchWord] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [versionArr, setVersionArr] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [query, setQuery] = useState('');
  const [getOcpiData, setGetOcpiData] = useState([]);
  useEffect(() => {
    OCPIVERSION(setErrorMessage, setError).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setVersionArr(promiseResult.data);
      } else {
        setError(true);
        setErrorMessage(promiseResult.message);
      }
    });
  }, []);
  const updateList = (oprType) => {
    childRef.current.update_List(oprType);
  };
  return (
    <Page title={t('OCPI')}>
      <Container maxWidth="false">
        <Snackbar open={success} autoHideDuration={5000}>
          <Alert severity="success">{t('Success')}!</Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={5000}>
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        {/* <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography
            sx={{
              fontFamily: 'Arial',
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '36px',
              color: 'texts.main',
              mb: 2,
            }}
          >
            {t('OCPI Management')}
          </Typography>
        </Stack> */}
        {/* CompanyInfo */}
        <Box direction="row" sx={{ width: '65%' }}>
          <CompanyInfo getOcpiData={getOcpiData}></CompanyInfo>
        </Box>

        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pb: 1 }}>
          <FormControl fullWidth sx={{ mr: 1, width: '100%' }}>
            <InputLabel htmlFor="outlined-adornment-search">{t('Search')}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-search"
              startAdornment={
                <InputAdornment position="start">
                  <Box component="img" src={Icon} sx={{ width: '16px', height: '16px' }} />
                </InputAdornment>
              }
              label={t('Search')}
              onChange={(event) => {
                setQuery(event.target.value);
                setPage(0);
              }}
              sx={{ height: '32px' }}
            />
          </FormControl>
          <AddOcpi versionArr={versionArr} operation="add" updateList={updateList}></AddOcpi>
        </Stack>
        <OcpiTable versionArr={versionArr} ref={childRef} setGetOcpiData={setGetOcpiData} />
      </Container>
    </Page>
  );
}
