import React, { useState, useEffect, useContext } from 'react';
// mui
import {
  Box,
  Table,
  Stack,
  TableRow,
  tableCellClasses,
  TableBody,
  TableCell,
  TableHead,
  DataGrid,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/customIcon/Union.svg';
import moveIcon from '../../../../components/customIcon/icon-move-16.svg';
import ReserveIcon from '../../../../components/customIcon/icon-reserve-turquoise-16.svg';
import EditOcpi from '../addOcpi';
import RowDelete from './tableRowDelete';
import ActiionConfirm from './actiionConfirm';
import { OCPIPARTYINFO, OCPIHANDSHARK, OCPISYNC } from '../../../../api/AllAPI';
import infoTipIcon from '../../../../components/customIcon/information-variant-circle-outline.svg';
import infoTipIcon_light from '../../../../components/lightMode/information-variant-circle-outline.svg';
import SyncSharpIcon from '@mui/icons-material/SyncSharp';
import HandshakeSharpIcon from '@mui/icons-material/HandshakeSharp';
import { ThemeContext } from '../../../../App';
import Icon_validated from '../../../../components/customIcon/icon-validated-select.svg';
import Icon_novalidated from '../../../../components/customIcon/icon-validated-noselect.svg';
// ----------------------------------------------------------------------

export default function TableSec(props) {
  const { t } = useTranslation();
  const { rowData, setError, setErrorMessage, setSuccess, updateList, versionArr, setLoading_pro } = props;
  const [data, setdata] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirType, setOpenConfirType] = useState();
  const [randomSum, setrandomSum] = useState(0);
  const [editData, setEditData] = useState(0);
  const theme = useTheme();
  const { darkTheme, toggleTheme } = useContext(ThemeContext);
  // const [loading_pro, setLoading_pro] = useState(false);
  const columns = [
    { field: 'party_name', headerName: t('Party Name'), width: 100, align: 'left' },
    {
      field: 'generated_token',
      width: 120,
      align: 'left',
      // headerName: t('Generated Token'),
      numeric: true,
      renderHeader: () => (
        <>
          {t('Generated Token')}
          <Tooltip title={t('ocpi_tip')}>
            <IconButton sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
              <Box
                component="img"
                src={darkTheme ? infoTipIcon : infoTipIcon_light}
                sx={{ width: '18px', height: '18px' }}
              />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    { field: 'received_token', headerName: t('Received Token'), width: 80, align: 'left' },
    { field: 'version', headerName: t('Version'), width: 40, align: 'left' },
    { field: 'last_updated', headerName: t('Last Updated'), width: 60, align: 'left' },
    { field: 'Validated', headerName: t('Validated'), width: 20, align: 'left' },
    { field: 'tags', headerName: t('Tags'), width: 220, align: 'left' },
    { field: 'action', headerName: t('Actions'), width: 70, align: 'center' },
  ];
  useEffect(() => {
    if (rowData) {
      setdata(rowData);
    }
  }, [rowData]);

  const handleEdit = (row) => {
    setOpenEdit(true);
    setrandomSum(Math.random());
    setEditData(row);
  };
  const handleSync = (row) => {
    setOpenConfirm(true);
    setEditData(row);
    setOpenConfirType('Sync');
    // setLoading_pro(true);
    // OCPISYNC({ id: row.id }, setErrorMessage, setError).then((promiseResult) => {
    //   if (promiseResult.category === 'Success') {
    //     setSuccess(true);
    //   } else {
    //     setErrorMessage(promiseResult.response?.data?.message);
    //     setError(true);
    //   }
    //   setLoading_pro(false);
    // });
  };
  const handshake = (row) => {
    setOpenConfirm(true);
    setEditData(row);
    setOpenConfirType('Handshake');
    // setLoading_pro(true);
    // OCPIHANDSHARK({ id: row.id }, setErrorMessage, setError).then((promiseResult) => {
    //   if (promiseResult.category === 'Success') {
    //     setSuccess(true);
    //     updateList({ oprType: 'change' });
    //   } else {
    //     setErrorMessage(promiseResult.response?.data?.message);
    //     setError(true);
    //   }
    //   setLoading_pro(false);
    // });
  };
  const add8hours = (originalDateString) => {
    const originalDate = new Date(originalDateString);
    originalDate.setHours(originalDate.getHours() + 8);

    // Format the date as "YYYY-MM-DD HH:mm:ss"
    const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')} ${originalDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${originalDate.getMinutes().toString().padStart(2, '0')}:${originalDate
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;

    return formattedDate;
  };
  const multiTags = (data) => {
    return data.map((con, index) => (
      <Typography
        variant=""
        title={con}
        sx={{
          //   background: '#232F34',
          backgroundColor: darkTheme ? '#232F34' : theme.palette.background.tableHeader,
          color: theme.palette.texts.main,
          padding: '5px 8px',
          borderRadius: '5px',
          margin: index > 0 ? '5px' : '5px',
          fontSize: '12px',
          maxWidth: '100px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {con}
      </Typography>
    ));
  };
  return (
    <Box>
      {/* <DataGrid rows={data} columns={columns} pageSize={5}   /> */}

      <Table>
        {/* <TableHead sx={{ background: '#232F34' }}>
          <StyledTableRow>
            {columns.map((column) => (
              <StyledTableCell align={column.align} sx={{ width: column.width }} key={column.headerName}>
                {column.headerName}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead> */}

        <TableHead sx={{ background: '#232F34' }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                align={column.align}
                sx={{
                  width: column.width,
                  backgroundColor: theme.palette.background.tableHeader,
                  color: theme.palette.texts.main,
                }}
                key={column.field}
              >
                {column.renderHeader ? column.renderHeader() : column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody sx={{ background: '#34495533' }}>
          {data?.map((item) => (
            <StyledTableRow key={item.party_name} hover>
              <StyledTableCell align="left">{item.party_name}</StyledTableCell>
              <StyledTableCell>{item.generated_token ? item.generated_token : '--'}</StyledTableCell>
              <StyledTableCell>{item.received_token ? item.received_token : '--'}</StyledTableCell>

              <StyledTableCell align="left">{item.version}</StyledTableCell>
              <StyledTableCell>{item.last_updated ? add8hours(item.last_updated.split('.')[0]) : '--'}</StyledTableCell>
              <StyledTableCell>
                <Box
                  component="img"
                  src={item.validated ? Icon_validated : Icon_novalidated}
                  sx={{ width: '20px', height: '20px' }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Box direction="row" display="flex" flexWrap="wrap" alignItems="flex-start">
                  {multiTags(item.endpoints)}
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <Tooltip title={t('Edit')}>
                    <IconButton
                      onClick={() => handleEdit(item)}
                      sx={{ width: '38px', height: '38px', zIndex: 'modal' }}
                    >
                      <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
                    </IconButton>
                  </Tooltip>
                  <RowDelete
                    info={item}
                    update_List={updateList}
                    setSuccess={setSuccess}
                    setErrorMessage={setErrorMessage}
                    setError={setError}
                  ></RowDelete>
                  <Tooltip title={t('Handshake')}>
                    <IconButton onClick={() => handshake(item)} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
                      <HandshakeSharpIcon sx={{ color: '#00ACBB' }}></HandshakeSharpIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Sync')}>
                    <IconButton
                      onClick={() => handleSync(item)}
                      sx={{ width: '38px', height: '38px', zIndex: 'modal' }}
                    >
                      <SyncSharpIcon sx={{ color: '#00ACBB' }}></SyncSharpIcon>
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {openEdit ? (
        <EditOcpi
          versionArr={versionArr}
          operation="edit"
          rowData={editData}
          randomSum={randomSum}
          updateList={updateList}
          setSuccess={setSuccess}
        ></EditOcpi>
      ) : (
        ''
      )}
      {openConfirm ? (
        <ActiionConfirm
          openConfirType={openConfirType}
          rowData={editData}
          updateList={updateList}
          openConfirm={openConfirm}
          setOpenConfirm={setOpenConfirm}
          setSuccess={setSuccess}
        ></ActiionConfirm>
      ) : (
        ''
      )}
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.tableHeader,
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.texts.main,
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    borderBottom: `1px solid ${theme.palette.background.tableRowborder}`,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.tableRow,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.tableRow,
  },
}));
