import { useState, useMemo, useCallback, useRef } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import Places from './places';
import { Stack, Tooltip, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
const mapContainerStyle = {
  position: 'relative',
  height: 'calc(100vh - 150px)',
};

export default function GMap(props) {
  const { coordinates, setTableValue } = props;
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedName, setSelectedName] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [selectedCP, setSelectedCP] = useState();
  const [CsId, setCsId] = useState('');
  const mapRef = useRef();
  const center = useMemo(() => ({ lat: 1.35, lng: 103.86 }), []);
  const { t } = useTranslation();

  const options = useMemo(
    () => ({
      mapId: 'c2279378ccdef06',
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    []
  );
  const onLoad = useCallback((map) => (mapRef.current = map), []);
  const handleClick = () => {
    setTableValue('3');
    sessionStorage.setItem(
      'changer_diagram_stationData',
      JSON.stringify({
        name: selectedName,
        cs_id: CsId,
      })
    );
  };
  return (
    <div>
      <Stack direction="column" spacing={1}>
        <Places
          coordinates={coordinates}
          setCharger={(position) => {
            mapRef.current?.panTo(position);
            setSelectedMarker(position);
          }}
          setName={(name) => setSelectedName(name)}
          setAddress={(address) => setSelectedAddress(address)}
          setCP={(CP) => setSelectedCP(CP)}
          setNull={(val) => setSelectedMarker(val)}
          setCsId={(id) => setCsId(id)}
        />
        <GoogleMap zoom={15} center={center} mapContainerStyle={mapContainerStyle} options={options} onLoad={onLoad}>
          {coordinates?.map((value) => (
            <Marker
              key={value.cs_id}
              position={{ lat: parseFloat(value.lat), lng: parseFloat(value.lng) }}
              onClick={() => {
                setSelectedMarker({ lat: parseFloat(value.lat), lng: parseFloat(value.lng) });
                setSelectedName(value.name);
                setSelectedAddress(value.address);
                setSelectedCP(value.no_of_cp);
                setCsId(value.cs_id);
              }}
            />
          ))}
          {selectedMarker ? (
            <InfoWindow
              position={selectedMarker}
              onCloseClick={() => {
                setSelectedMarker(null);
              }}
            >
              <div>
                <h2 style={style}>{selectedName}</h2>
                <br />
                <h3 style={style}>
                  {t('Address')}: {selectedAddress}
                </h3>
                <br />
                <div style={{ display: 'flex', color: 'black', alignItems: 'center', justifyContent: 'space-between' }}>
                  <h3>
                    {t('No. of Charge Point')}: {selectedCP}
                  </h3>
                  <Button
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AccountTreeOutlinedIcon />}
                  >
                    Diagram View
                  </Button>
                </div>
              </div>
            </InfoWindow>
          ) : null}
        </GoogleMap>
      </Stack>
    </div>
  );
}

const style = {
  color: 'black',
};
