// component
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'OCTT',
    path: '/pages/octt',
    icon: getIcon('ion:key-outline'),
  },
];

export default navConfig;
