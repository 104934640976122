import React, { useState, useRef } from 'react';
// mui
import {
  Tab,
  Box,
  Stack,
  Button,
  useTheme,
  Container,
  InputLabel,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Icon from 'src/components/customIcon/icon-search-16.svg';
// sections
import PromoTable from './promoTable';
import Calendar from './calendar';
import PromoInfo from './promoInfo';
// components
import Page from 'src/components/Page';
// import { fetchCoordinate } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import Iconshow from './iconShow';
// ----------------------------------------------------------------------

export default function PrmotionIndex() {
  const [value, setValue] = useState('1');
  const [changePage, setChangePage] = useState('table');
  const { t } = useTranslation();
  const theme = useTheme();
  const childRefs = useRef(null);
  const calendarRef = useRef(null);
  const [searchWord, setSearchWord] = useState('');
  const [propsData, setPropsData] = useState({});

  const updateTable = (oprType) => {
    childRefs.current.updateList(oprType);
    childRefs.current.updateList_com(oprType);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == '2') {
      setPropsData({});
    }
  };
  const tabOptions = [
    {
      value: '1',
      label: <Iconshow active={value} type="list" title={t('Table View')} />,
    },
    {
      value: '2',
      label: <Iconshow active={value} type="calendar" title={t('Calendar View')} />,
    },
  ];
  const handleClickNewPromo = () => {
    setChangePage('info_add');
  };

  return (
    <Box sx={{ height: 'calc(100% - 80px)', width: '100%' }}>
      <Page title={t('Campaign Planner')} sx={{ height: '100%', display: changePage == 'table' ? 'block' : 'none' }}>
        <Container maxWidth="false" sx={{ height: '100%' }}>
          <TabContext value={value} sx={{ height: '100%', padding: 0 }}>
            <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={{ xs: 0.5, sm: 1 }}>
                <Typography
                  sx={{
                    fontFamily: 'Arial',
                    fontWeight: 700,
                    fontSize: '24px',
                    lineHeight: '36px',
                    color: 'texts.main',
                    mb: 1,
                  }}
                >
                  {t('Campaign Planner')}
                </Typography>
                <TabList
                  onChange={handleChange}
                  aria-label={t('tabs')}
                  textColor="secondary"
                  indicatorColor="secondary"
                >
                  {tabOptions.map((tab) => (
                    <Tab
                      key={tab.value}
                      value={tab.value}
                      label={tab.label}
                      sx={{
                        fontFamily: 'Arial',
                        fontWeight: 700,
                        fontSize: '14px',
                        lineHeight: '22px',
                        padding: '0px',
                        minWidth: '50px',
                      }}
                    />
                  ))}
                </TabList>
              </Stack>
            </Box>
            {/* Search */}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pb: 1, mt: 2 }}>
              <FormControl fullWidth sx={{ mr: 1, width: '100%' }}>
                <InputLabel htmlFor="outlined-adornment-search">{t('Search')}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-search"
                  startAdornment={
                    <InputAdornment position="start">
                      <Box component="img" src={Icon} sx={{ width: '16px', height: '16px' }} />
                    </InputAdornment>
                  }
                  label={t('Search')}
                  onChange={(event) => {
                    setSearchWord(event.target.value);
                    setPage(0);
                    if (value == '1') {
                      setTimeout(() => {
                        updateTable({ oprType: 'search' });
                      }, 100);
                    } else {
                      setTimeout(() => {
                        calendarRef.current.searchIndexList();
                      }, 100);
                    }
                  }}
                  sx={{ height: '32px' }}
                />
              </FormControl>
              {/* New Promo Button*/}
              <Button
                variant="contained"
                onClick={handleClickNewPromo}
                sx={{
                  width: '160px',
                  height: '32px',
                  background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
                  boxShadow: 'none',
                  color: 'primary.contrastText',
                  fontFamily: 'Arial',
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '22px',
                  textAlign: 'center',
                }}
              >
                + {t('New Campaign')}
              </Button>
            </Stack>

            <TabPanel value="1" sx={{ padding: 0 }}>
              <PromoTable
                refs={childRefs}
                setSearchWord={setSearchWord}
                searchWord={searchWord}
                setTableValue={setValue}
                setChangePage={setChangePage}
                setPropsData={setPropsData}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: '15px 0' }}>
              <Calendar refs={calendarRef} propsData={propsData} searchWord={searchWord} />
            </TabPanel>
          </TabContext>
        </Container>
      </Page>
      {changePage !== 'table' && (
        <PromoInfo
          changePage={changePage}
          setChangePage={setChangePage}
          propsData={propsData}
          updateList={updateTable}
        />
      )}
    </Box>
  );
}
