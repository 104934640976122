import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Step,
  Stack,
  Dialog,
  Button,
  Stepper,
  MenuItem,
  StepLabel,
  IconButton,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from '@mui/material';
// components
import SelectFile from './SelectFile';
import DateTime from './DateTime';
import Confirmation from './Confirmation';
import { buttonR, buttonL } from '../../../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../../../components/customIcon/Close.svg';
import { fetchFILE, SubmitFirmwareUpdate } from '../../../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function UpdateFile(props) {
  const { t } = useTranslation();
  const { evseName, evseInfo } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [list, setList] = useState(null);
  const [formData, setFormData] = useState({
    when: 'ASAP',
    selectedOption: null,
    valueDateTime: new Date(),
  });
  const [loading_pre, setLoading_pre] = useState(false);
  const cp_id = evseName;
  const firmware_id = formData.selectedOption;

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);
  const getListFunc = () => {
    fetchFILE(props.setError, props.setErrorMessage).then((promiseResult) => {
      if (!promiseResult.message) {
        setList(promiseResult);
      }
      setLoading_pre(false);
    });
  };
  useEffect(() => {
    setLoading_pre(true);
    getListFunc();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPage(0);
    props.CloseMain();
  };

  const onSubmit = () => {
    setLoading(true);
    setLoading_pre(true);
    let data = {};
    data = {
      cp_id: cp_id,
      filename: formData.selectedOption,
      retrieve_date: formData.valueDateTime,
      retries: 5,
      retry_interval: 3,
    };
    SubmitFirmwareUpdate(data, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.response?.data?.message);
        props.setError(true);
      }
      setLoading(false);
      setLoading_pre(false);
    });
  };

  const steps = [
    { value: 'Select Firmware File', label: t('Select Firmware File') },
    { value: 'Date & Time', label: t('Date & Time') },
    { value: 'Confirmation', label: t('Confirmation') },
  ];

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>{t('Use File')}</MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="UpdateFirmwareDialog" fullWidth maxWidth="sm">
        {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="UpdateFirmware">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Update Firmware for EVSE')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
          <Stepper activeStep={page} sx={{ pt: 1 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label.value} {...stepProps}>
                  <StepLabel {...labelProps}>{label.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent>
          <PageDisplay
            setLoading_pre={setLoading_pre}
            page={page}
            formData={formData}
            setFormData={setFormData}
            list={list}
            evseName={evseName}
            setErrorMessage={props.setErrorMessage}
            setError={props.setError}
            getListFunc={getListFunc}
          />
          <br />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button
              variant="outlined"
              onClick={() => {
                if (page === 0) {
                  handleClose();
                } else {
                  setPage((currPage) => currPage - 1);
                }
              }}
              sx={buttonL}
            >
              {page === 0 ? 'Cancel' : 'Back'}
            </Button>
            {page === steps.length - 1 ? (
              <Button onClick={onSubmit} disabled={loading} sx={buttonR}>
                {t('Submit')}
              </Button>
            ) : (
              <Button
                sx={buttonR}
                onClick={() => {
                  if (formData.selectedOption) {
                    setPage((currPage) => currPage + 1);
                  }
                }}
              >
                {t('Next')}
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const PageDisplay = ({
  page,
  formData,
  setFormData,
  list,
  evseName,
  setLoading_pre,
  setErrorMessage,
  setError,
  getListFunc,
}) => {
  if (page === 0) {
    return (
      <SelectFile
        setLoading_pre={setLoading_pre}
        formData={formData}
        setFormData={setFormData}
        evseNam={evseName}
        list={list}
        setErrorMessage={setErrorMessage}
        setError={setError}
        getListFunc={getListFunc}
      />
    );
  }
  if (page === 1) {
    return <DateTime formData={formData} setFormData={setFormData} />;
  }
  return <Confirmation formData={formData} evseName={evseName} />;
};
