import { Link as RouterLink } from 'react-router-dom';
// mui
import { Card, Link, Container, Typography, styled } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Page from '../../../components/Page';
import Logo from '../../../components/Logo';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const mdUp = useResponsive('up', 'md');
  const { t } = useTranslation();

  return (
    <Page title={t('Reset Message')}>
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <img
              alt="register"
              src="/static/illustrations/resetmessage.png"
              width={350}
              style={{ alignSelf: 'center' }}
            />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
              {t('Hi')},
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ color: 'text.secondary', mb: 4 }}>
              {t('tip_temporaryLink')}
              {/* Here is a temporary password reset link for your LOS CSMS account. It can only be used once and will expire after 5 minutes. Please click on the link below to reset the password for your account: */}
            </Typography>
            <Link variant="subtitle1" component={RouterLink} to="/resetpassword">
              {t('Reset Password')}
            </Link>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
