import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Dialog, Button, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
// component
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function LoginAgainDialog() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const open = true;

  const handleClose = () => {
    navigate('/', { replace: true });
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('Unauthorized access')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t('tip_accessExpired')}.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Okay')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
