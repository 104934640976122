import { useState, useEffect } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
// mui
import {
  Box,
  Stack,
  Alert,
  Button,
  Dialog,
  Avatar,
  Snackbar,
  TextField,
  IconButton,
  DialogTitle,
  Autocomplete,
  DialogContent,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  LinearProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import UserPool from '../../../../pages/auth/AWSCognito/UserPool';
import LoginAgain from '../../../../components/errors/LoginAgain';
import { FormProvider } from '../../../../components/hook-form';
import Iconify from '../../../../components/Iconify';
import { buttonR, buttonL } from '../../../../components/button/buttonStyle';
import CloseIcon from '../../../../components/customIcon/Close.svg';
import { useTranslation } from 'react-i18next';
import Verification from './Verification';
// ----------------------------------------------------------------------

export default function AddNewAdminDialog(props) {
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [displaySignUp, setdisplaySignUp] = useState(true);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [birthDate, setBirthDate] = useState(new Date());
  const [gender, setGender] = useState('');
  const [role, setRole] = useState('Admin');
  const [errMsg, setErrMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading_pre, setLoading_pre] = useState(false);
  const [name, setName] = useState();
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
  const [unauthorized, setUnauthorized] = useState(false);
  const [getCode, setgetCode] = useState(false);
  const handleClickOpen = () => {
    // fetchCS(setError, setErrorMessage, setUnauthorized).then((promiseResult) => setAll_charging_station(promiseResult));
    setOpen(true);
  };
  useEffect(() => {
    setEmail('');
    setPassword('');
  }, []);
  const handleClose = (e, reason) => {
    if (reason == 'backdropClick') {
      return;
    }

    setName('');
    setEmail('');
    setPassword('');
    setOpen(false);
    setPhoneNumber('');
    setErrMsg('');
    setgetCode(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  // submit form
  const methods = useForm({
    reValidateMode: 'onBlur',
  });

  const onSubmit = (event) => {
    // setLoading(true);
    // event.preventDefault();
    if (email == '' || name == '') {
      setErrMsg(t('Required fields cannot be empty'));
      setgetCode(false);
      return false;
    }
    UserPool.signUp(
      email,
      password,
      [
        {
          Name: 'name',
          Value: name,
        },
        {
          Name: 'custom:role',
          Value: role,
        },
        {
          Name: 'phone_number',
          Value: phoneNumber,
        },
        {
          Name: 'custom:tenant_id',
          Value: sessionStorage.getItem('tenant_id'),
        },
        // {
        //   Name: 'birthdate',
        //   Value: birthDate,
        // },
        // {
        //   Name: 'gender',
        //   Value: gender,
        // },
        // {
        //   Name: 'updated_at',
        //   Value: new Date(),
        // },
      ],
      null,
      (err, data) => {
        if (err) {
          // setdisplaySignUp(true);
          setErrorMessage(err.message);
          // setErrMsg(err.message);
          setError(true);
          // setgetCode(false);
          setgetCode(false);
        }
        if (data !== null) {
          console.log(data);
          // setdisplaySignUp(false);
          // setgetCode(true);
          // setPage(true);
          // setUsername(username);
          setErrMsg('');
          setgetCode(data);
        }
      }
    );
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          width: '153px',
          height: '32px',
          background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
          boxShadow: 'none',
          color: 'primary.contrastText',
          fontFamily: 'Arial',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '22px',
          textAlign: 'center',
        }}
      >
        + {t('Add New Admin')}
        <Snackbar open={success} autoHideDuration={4000} onClose={handleCloseSnackbar}>
          <Alert severity="success">Success!</Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth aria-labelledby="AddNewAdminDialog">
        {unauthorized && <LoginAgain />}
        {loading_pre && (
          <div style={{ marginTop: '0px', width: '100%' }}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle id="AddNewAdminDialog">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Add New Admin')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {/*  */}
          {/* {displaySignUp ? ( */}
          <Box>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
              <IconButton color="primary" aria-label={t('upload picture')} component="label">
                {/* <input hidden accept="image/*" type="file" /> */}
                <Avatar
                  alignItems="center"
                  justifyContent="center"
                  src="/broken-image.jpg"
                  Alt="Profile Picture"
                  sx={{ m: 4, width: 80, height: 80 }}
                />
              </IconButton>
            </Box>
            <FormProvider methods={methods}>
              <Stack spacing={3} direction={{ xs: 'column' }}>
                <TextField
                  name="name"
                  label={
                    <>
                      <span style={{ color: 'red' }}>* </span>
                      {t('Name')}
                    </>
                  }
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <TextField
                  name="email"
                  label={
                    <>
                      <span style={{ color: 'red' }}>* </span>
                      {t('Email Address')}
                    </>
                  }
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <TextField
                  name="password"
                  label={
                    <>
                      <span style={{ color: 'red' }}>* </span>
                      {t('Password')}
                    </>
                  }
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl fullWidth>
                  <InputLabel id="roles-select-label">{t('Roles')}</InputLabel>
                  <Select
                    disabled
                    labelId="roles-select-label"
                    id="roles"
                    value={role}
                    label={t('Roles')}
                    onChange={(event) => setRole(event.target.value)}
                  >
                    <MenuItem value={'SuperAdmin'}>{t('Super Admin')}</MenuItem>
                    <MenuItem value={'Admin'}>{t('Admin')}</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  name="phoneNumber"
                  label={t('Phone number')}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                />
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={t('Birth Date')}
                    views={['year', 'month', 'day']}
                    value={birthDate} inputFormat="DD/MM/YYYY"
                    onChange={(e) => setBirthDate(e.target.value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider> */}
                {/* <FormControl fullWidth>
                  <InputLabel id="gender-select-label">{t('Gender')}</InputLabel>
                  <Select
                    labelId="gender-select-label"
                    id="gender"
                    value={gender}
                    label={t('Gender')}
                    onChange={(event) => setGender(event.target.value)}
                  >
                    <MenuItem value={'Male'}>{t('Male')}</MenuItem>
                    <MenuItem value={'Female'}>{t('Female')}</MenuItem>
                  </Select>
                </FormControl> */}
                {/* <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                  <Button variant="outlined" onClick={handleClose} sx={buttonL}>
                    {t('Cancel')}
                  </Button>
                  <LoadingButton onClick={onSubmit} sx={buttonR}>
                    {t('Verify')}
                  </LoadingButton>
                </Stack> */}
              </Stack>
            </FormProvider>
          </Box>
          {/* ) : ( */}
          <Verification
            email={email}
            getCode={getCode}
            handleClose={handleClose}
            getVerifyCode={onSubmit}
            AddUpdate={(childData) => props.AddUpdate(childData)}
            // setdisplaySignUp={setdisplaySignUp}
            setLoading_pre={setLoading_pre}
          ></Verification>
          {/* )} */}
          <Typography variant="subtitle1" sx={{ pl: 1, color: 'error.main' }}>
            {errMsg}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
